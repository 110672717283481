import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { addToForm } from "../../api/utils";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import LargeFileTable from "../common/LargeFileTable";

import { createSelector } from "../common/orm";
import { Documentsviolation } from "../mypage/models";
import { DocumentTypes } from "../common/SelectOptions";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";


const getviolationDocuments =  createSelector(
    (state, ownProps) => ownProps.violation,
    (session, violation) => {
        var temp = session.Documentsviolation.filter(vd => vd.violation_id === violation.id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "doc";
        });
        return temp;
    }
);

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    pad_2: {
        marginRight: 100,
    }
});

const photoColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "caption", numeric: false, label: "Document Title", allowSort: false, required: true, type: "text" },
    {id: "type", numeric: false, label: "Type", allowSort: true, type: "select", options: DocumentTypes },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: true, type: "date" },
    {id: "notes", numeric: false, label: "Notes", allowSort: false, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file", required:true },
]
const photokeyData = photoColumnData.slice(1);

class Attachments extends Component {
    state = {
        forceReset: performance.now()
    }

    submitPhoto = (values, file_interface) => {
        const { ormDocumentsviolationUpdatePromise, ormDocumentsviolationCreateForm, violation, saveBad, saveGood } = this.props;
        delete values["deleteProp"];
        if(values.id){
            ormDocumentsviolationUpdatePromise({
                drainage_id: violation.drainage_id,
                violation_id: violation.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        } else {
            var form_data = new FormData();
            form_data.append('drainage_id', violation.drainage_id);
            form_data.append('violation_id', violation.id);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'notes', values.notes);
            addToForm(form_data, 'uploaddate', values.uploaddate);
            addToForm(form_data, 'type', values.type);
            form_data.append('link', file_interface, file_interface.name);
            ormDocumentsviolationCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }
        this.props.updateViolation();
    }

    deleteDoc = (id) => {
        const { saveBad, saveGood } = this.props;
        this.props.ormDocumentsviolationDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.updateViolation();
    }

    render(){
        const { authState, classes, docs, system, violation, fileSize } = this.props;
        const { forceReset } = this.state;
        const viewOnly = authState.user.role === "View Only";

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Violation #: " + violation.vnum} />
                <Divider className={classes.mb}/>
                <Typography variant="h5" style={{marginTop: 20}}>Documents</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable
                    name="violation_doc_attachement" 
                    id="violation-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly}
                    data={docs}
                    noHelp
                    title="Document"
                    emptyTxt="No Documents"
                    columns={photoColumnData} 
                    keys={photokeyData}
                    parentSubmitForm={this.submitPhoto}
                    addFiles={true}
                    forceReset={forceReset}
                    deleteProp={{"doc": this.deleteDoc}}
                    fileSize={fileSize}
                />  
            </Grid>
        );
    }
}

Attachments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        docs: getviolationDocuments(state, ownProps)
    }),
    {
        ...Documentsviolation.actions,
        panelChange
    }
)(Attachments);

export default withStyles(styles)(withRouter(Attachments));