import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { MAKE_OPTIONS } from '../../api/constants';

import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, Button, Typography } from "@material-ui/core";

import Select from "../common/Select";
import Checkbox from "../common/Checkbox";
import DatePicker from "../common/DatePicker";
import { createSelector } from "../common/orm";
import { BoardDate } from "../mypage/models";

const getDate = createSelector(schema => {
    return schema.BoardDate.first();
});

const styles = theme => ({
    left: {
        marginTop: 20,
        marginLeft: 20
    },
    space: {
        margin: "5px 5px 5px 0px"
    },
    top: {
        marginTop: 58
    },
    minWidth: {
        minWidth: 300
    }
});

const OffSet = [
    {
        value: 0,
        label: "1"
    },
    {
        value: 1,
        label: "2"
    },
    {
        value: 2,
        label: "3"
    },
    {
        value: 3,
        label: "4"
    }
];

function getWeek(num){
    if(num === 0 || num === null){
        return "1st";
    } else if(num === 1){
        return "2nd";
    } else if(num === 2){
        return "3rd";
    } else if(num === 3){
        return "4th";
    }
}

function getDay(day){
    if(day === "Tue" || day === null){
        return "Tues.";
    } else if(day === "Thu"){
        return "Thurs.";
    } else {
        return day + ".";
    }
}

const WeekMask = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
];

class ManageBoardDate extends Component {
    submitForm = (values) => {
        const { ormBoardDateUpdatePromiseData, saveBad, saveGood } = this.props;
        values.override = values.shouldoverride;
        ormBoardDateUpdatePromiseData({
            ...values
        }).then(data => {
            if(!data){
                this.setState({snackOpen: true});
                saveBad();
            } else {
                this.formApi.setValue("date", data.date);
                saveGood();
            }
        });
    }

    errorValidatorLayer = (values) => {
        const required = (value) => {
            if(!value || value === ""){
                return "required";
            }
            return null;
        }
        
        return {
            date: values.shouldoverride ? required(values.date) : null
        }
    }

    handleEvent = (e, formApi) => {
        const { ormBoardDateUpdatePromiseData, saveBad, saveGood } = this.props;
        if(!e){
            ormBoardDateUpdatePromiseData({
                ...formApi.values,
                override: formApi.values.shouldoverride
            }).then(data => {
                if(!data){
                    this.setState({snackOpen: true});
                    saveBad();
                } else {
                    formApi.setValue("date", data.date);
                    saveGood();
                }
            });
        }
    }
    
    render() {
        const { date, classes } = this.props;
        const newDate = {...date, shouldoverride: false};

        return (
            <div className={classes.top}>
                <Form
                    getApi={el => (this.formApi = el)}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.errorValidatorLayer}
                    onSubmit={this.submitForm}
                    defaultValues={newDate}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={3} className={classes.left}>
                                <Grid item xs={6}>
                                    <Select
                                        className={classes.minWidth}
                                        field="offset" 
                                        label="Week of the Month"
                                        options={OffSet} 
                                    />
                                    <Select
                                        style={{ marginLeft: 5 }}
                                        className={classes.minWidth}
                                        field="week_mask" 
                                        label="Day of the week"
                                        options={MAKE_OPTIONS(WeekMask)} 
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography><b>Skipping over Holidays is not supported currently.</b></Typography>
                                    <Typography><b>If Week of the Month and Day of the week are not set the default is 1st Tues. of each Month.</b></Typography>
                                    <br />
                                    <Typography>
                                        The Board Meeting Date is configured to be the <b>{getWeek(date.offset)} {getDay(date.week_mask)} of each Month</b>.</Typography>
                                    <Typography>The next Board Meeting Date will be calculated as <b>{date.next_date}</b></Typography>
                                    <Typography>Once the date passes the following meeting date will be calculated as <b>{date.future_date}</b></Typography>
                                </Grid>
                                <Grid item xs={6} style={{display: "flex"}}>
                                    <div style={{paddingTop: 25}}>
                                        <FormControlLabel
                                            className={classes.minWidth}
                                            style={{ marginLeft: 5 }}
                                            control={
                                                <Checkbox color="primary" field="shouldoverride" eventHandle={(e) => this.handleEvent(e, formApi)}/>
                                            }
                                            label="Override next board date"
                                        /> 
                                    </div>
                                    <DatePicker
                                        label="Planned Board Action Meeting Date" 
                                        field="date"
                                        required={formApi.values["shouldoverride"]}
                                        disabled={!formApi.values["shouldoverride"]}
                                        style={{minWidth: 250}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </Grid>
                            </Grid>
                        </form>
                        )}
                </Form>
            </div>
        );
    }
}

ManageBoardDate = connect(
    state => ({ 
        date: getDate(state)
    }),
    {
        ...BoardDate.actions
    }
)(ManageBoardDate);

export default withStyles(styles)(withRouter(ManageBoardDate));