import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { ExcelIcon, ShapeIcon} from '../common/IconFunctions';
import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';
import ExpansionPanel from "../common/ExpansionPanel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import { formateDate } from "../../api/utils";
import * as authActions from "../auth/actions";


const styles = theme => ({
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    mB: {
        marginBottom: 10
    },
    mr: {
        marginRight: 5
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px"
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
    }
});

const columnData = [
    { id: "drainage", numeric: false, label: "System Name", allowSort: false },
    { id: "repair", numeric: true, label: "Repair #", allowSort: true, required: true },
    { id: "datereceived", date: true, label: "Date Received", allowSort: true },
    { id: "township", numeric: false, label: "Township", allowSort: true },
    { id: "section", numeric: false, label: "Section", allowSort: true },
    { id: "landowner", numeric: false, label: "Landowner", allowSort: true, required: true },
    { id: "request", numeric: false, label: "Person Requesting Repair", allowSort: true, required: true  },
    { id: "work", numeric: false, label: "Problem/Proposed Work", allowSort: true, required: true  }
];

class RepairBoard extends Component {
    constructor(props){
        super(props);

        this.state = {
            rowsPerPage: 100,
            page: 0,
            status: props.match.path === "/list/approval" ? "For Approval" : "For Payment",
            data: [],
            shapeFileType: props.match.path === "/list/approval" ? "generateShapefilesForApproval" : "generateShapefilesForPayment",
        }

        if(props.authState && props.authState.user){
            this.getData();
        }
    }

    getData = () => {
        const { authState } = this.props; 

        fetch(`/list/approval?status=${this.state.status}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            this.setState({data: data});
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {                
                this.setState({
                    data: []
                });
            }
        });
    }


    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    go = (e) => {
        this.props.history.push(`/repair/${e.repair_id}/${e.drainage_id}/${e.drainage}`)
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const data = this.state.data.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy, data });
    }
    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    return true;
                }
            });
        } else {
            return data;
        }
    }

    getSearchStr = () => {
        if(this.filters && this.filters.column !== "" && this.filters.filter !== ""){
            return `?status=${this.state.status}&column=${this.filters.column}&searchstring=${this.filters.filter}`;
        } else {
            return `?status=${this.state.status}`;
        }
    }

    render(){
        const { classes } = this.props;
        const { data, shapeFileType, status, page, rowsPerPage } = this.state;

        const filtered = this.filter(data);

        return(
            <AppContainer authenticated adminPage={true} noScroll>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Repairs - {status}</Typography>
                        <Divider className={classes.mB}/>
                        <ExpansionPanel noSlice onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData}/>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10}}>
                        <div className={classes.root}>
                            <Button 
                                variant="contained"
                                target="_blank" 
                                href={`/report/list/approval${this.getSearchStr()}`}
                                className={classes.mr}>
                                <ExcelIcon /> 
                                    &nbsp;&nbsp;Export to Excel
                            </Button>
                            <Button
                                variant="contained"
                                target="_blank" 
                                href={`/export/shapefile/${shapeFileType}${this.getSearchStr()}`}>
                                <ShapeIcon />
                                &nbsp;&nbsp;Export to Shapefile
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} columnData={columnData} onRequestSort={this.handleRequestSort}/>
                            <TableBody>
                                {(rowsPerPage > 0 ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filtered).map(x => (
                                    <TableRow key={x.id} hover onClick={(e) => this.go(x)}>
                                        <CustomTableCell>{x.drainage}</CustomTableCell>
                                        <CustomTableCell>{x.repair}</CustomTableCell>
                                        <CustomTableCell>{formateDate(x.datereceived)}</CustomTableCell>
                                        <CustomTableCell>{x.township}</CustomTableCell>
                                        <CustomTableCell>{x.section}</CustomTableCell>
                                        <CustomTableCell>{x.landowner}</CustomTableCell>
                                        <CustomTableCell>{x.request}</CustomTableCell>
                                        <CustomTableCell>{x.work}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={columnData.length}
                                        count={filtered.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                        rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                </Grid>    
            </AppContainer>
        )
    }
}

RepairBoard = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...authActions
    }
)(RepairBoard);

export default withStyles(styles)(withRouter(RepairBoard));
