import React, { Component } from "react";
import classNames from "classnames";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

const styles = theme => ({
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarContainerSuccess: {
        backgroundColor: "#43a047"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(4)
    }
});

class SnackbarerrorWrapper extends Component {
    render() {
        const { classes, open, autoHideDuration, customMessage, close, message, ...rest } = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                className={classes.snackbar}
                open={open}
                autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
                onClose={close}
                {...rest}>
                <SnackbarContent
                    className={classes.snackbarContainer}
                    message={ message ? message :
                        <span className={classes.snackbarMessage}>
                            <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                            { customMessage ? customMessage : "Error!"}
                        </span>
                    }
                />
            </Snackbar>
        );
    }
}

export default withStyles(styles)(SnackbarerrorWrapper);
