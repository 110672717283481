import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { formateDateSlash } from "../../api/utils";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import BaseMapControl from "../common/esrimap/BaseMapControl";
import {ExcelIcon} from '../common/IconFunctions';
import AssignmentIcon from "@material-ui/icons/Assignment";
import { DrainageSystem } from '../mypage/models'
import { createSelector } from '../common/orm';

const getDrainageSystem = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageSystem.filter(sys => sys.id === system.id).toRefArray()[0];
    }
);

const styles = theme => ({
    bNone: {
        '& td': {
            border: "none"
        }
    },
});

class OverView extends Component {

    render() {
        const { classes, system, style } = this.props;

        return (
            <Grid container style={{...style, height: "calc(100% - 48px)"}}>
                <Grid item xs={2} style={{marginTop: 48}}>
                    <br />
                    <Typography><b>Drainage System: {system.drainage}</b></Typography>
                    <Typography><b>Year Established:</b> {system.year}</Typography>
                    <Typography><b>Last Redetermination:</b> {system.rob ? formateDateSlash(system.rob) : ""}</Typography>
                    <Typography><b>Next Scheduled Inspection Year:</b> {system.inspectyear}</Typography>
                    <Typography>Last Updated {system.modified_date} by {system.modified_user}</Typography>
                    <br />
                    <br />
                    <Typography><b>Tasks</b></Typography>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.bNone}>
                                <TableCell padding="checkbox">
                                    <IconButton 
                                        aria-label="Generate Drainage System Word Report"
                                        target="_blank" 
                                        href={`/reports/drainage/system/${system.id}/pdf`}
                                    >
                                        <AssignmentIcon /> 
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography>Drainage System Report</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.bNone}>
                                <TableCell padding="checkbox">
                                    <IconButton 
                                        aria-label="Generate Drainage System Excel Report"
                                        target="_blank" 
                                        href={"/reports/drainage/system/" + system.drainage + "/excel"}
                                    >
                                        <ExcelIcon /> 
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography>Excel Drainage System Report</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={10} style={{marginTop: 58}}>
                    <BaseMapControl drainagePage style={{marginTop: 10}}/>
                </Grid>
            </Grid>
        );
    }
}

OverView = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        system: getDrainageSystem(state, ownProps) 
    }),
    {
        ...DrainageSystem.actions
    }
)(OverView);

export default withStyles(styles)(withRouter(OverView));
