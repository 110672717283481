import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";


import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';
import ExpansionPanel from "../common/ExpansionPanel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import { createSelector } from "../common/orm";

import * as authActions from "../auth/actions";
import NotReady from '../common/NotReady';

const getDrainageSystem = createSelector(session => {
    return session.DrainageSystem.all().orderBy("drainage", "asc").toRefArray();
});

const styles = theme => ({
    space: {
        marginTop: 48
    }
});

const columnData = [
    { label: "System", id: "drainage__drainage", allowSort: true },
    { label: "Repair", id: "repair", allowSort: true },
    { label: "Status", id: "status", allowSort: true },
    { label: "Date Received" , id: "date", allowSort: true, date: true },
    { label: "Township", id: "township", allowSort: true },
    { label: "Section", id: "section", allowSort: true },
    { label: "Search Text", id: "searchText", allowSort: true },
];

class ActivitySearch extends Component {
    constructor(props){
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 100,
            data: [],
            error: false,
            key: props.navState.searchKey
        }
    }

    componentDidMount = () => {
        const { authState } = this.props;
        if(authState && authState.user){
            this.search();
        }
    }

    componentDidUpdate = () => {
        const { authState, navState } = this.props;
        const { key } = this.state;
        if(navState && navState.searchKey !== key && authState && authState.user){
            this.setState({key: navState.searchKey});
            this.search();
        }
    }

    search = () => {
        const { authState, navState } = this.props;
        this.setState({ loading: true });
        fetch(`/search?searchname=${navState.searchInput}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            this.setState({data: data.list, loading: false});
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({data: [], loading: false, error: true});
            }
        });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        // Total Length is calculated on fly during render so do same for sort
        if(property === "totallength"){
            const _this = this;
            const data = this.state.data.sort(function(a, b) {
                const aa = _this.displayMiles(a);
                const bb = _this.displayMiles(b);

                if (aa === bb) return 0;
                else if (aa === null || aa === "" || typeof aa === "undefined") return 1;
                else if (bb === null || bb === "" || typeof bb === "undefined") return -1;

                if (order === "desc") return parseFloat(bb || 0) < parseFloat(aa || 0) ? -1 : 1;
                else return parseFloat(aa || 0) < parseFloat(bb || 0) ? -1 : 1;
            });
            this.setState({ order, orderBy, data });
            return;
        }

        const data = this.state.data.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy, data });
    }

    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    if(x[this.filters.column] && x[this.filters.column].length !== 0)
                        return true;
                    else 
                        return false;
                }
            });
        } else {
            return data;
        }
    }

    go = (x) => {
        this.props.history.push(`/repair/${x.id}/${x.drainage}/${x.drainage__drainage}`)
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    render(){
        const { classes } = this.props;
        const { 
            loading, 
            error,
            data,
            order, 
            orderBy,
            rowsPerPage,
            page,
        } = this.state;

        if(loading){
            return (
                <AppContainer authenticated adminPage={true}>
                    <NotReady message="Loading..." />
                </AppContainer>
            );
        }

        if(data.length === 0){
            return (
                <AppContainer authenticated adminPage={true}>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Search Results</Typography>
                            {error && (<Typography>Error...</Typography>)}
                            {!error && (<Typography>No results found... please try again.</Typography>)}
                        </Grid>
                    </Grid>
                </AppContainer>
            );
        }

        const filteredData = this.filter(data);

        return(
            <AppContainer authenticated adminPage={true}>
                <div className={classes.space}>
                    <ToolBar>
                        <Typography variant="h5" className={classes.grow}>Search Results</Typography>
                    </ToolBar>
                    <Divider className={classes.mb}/>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item xs={12}>
                            <ExpansionPanel noSlice onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData}/>
                        </Grid>
                        <Grid item xs={12} style={{overflowX: "auto"}}>
                            <Table>
                                <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                                <TableBody>
                                    {(rowsPerPage > 0 ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredData).map(x => (
                                        <TableRow hover onClick={(e) => this.go(x)} key={x.id}>
                                            <CustomTableCell>{x.drainage__drainage}</CustomTableCell>
                                            <CustomTableCell>{x.repair}</CustomTableCell>
                                            <CustomTableCell>{x.status}</CustomTableCell>
                                            <CustomTableCell>{x.date}</CustomTableCell>
                                            <CustomTableCell>{x.township}</CustomTableCell>
                                            <CustomTableCell>{x.section}</CustomTableCell>
                                            <CustomTableCell>{x.searchText}</CustomTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={columnData.length}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                            rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                    </Grid>    
                </div>
            </AppContainer>
        )
    }
}

ActivitySearch = connect(
    (state, ownProps) => ({
        authState: state.auth,
        navState: state.nav,
        systems: getDrainageSystem(state, ownProps)
    }),
    {
        ...authActions
    }
)(ActivitySearch);

export default withStyles(styles)(withRouter(ActivitySearch));