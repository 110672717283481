import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ManageRepairs from "./ManageRepairs";
import Delete from "./Delete";
import ManageBMPs from "./ManageBMPs";
import ManageConditions from "./ManageConditions";
import ManageViolations from "./ManageViolations";



const styles = theme => ({
    space: {
        marginTop: 88
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
});



class ManageActivities extends Component {
    state = {
    }

    render() {
        const { classes, saveGood, saveBad } = this.props;

        return (
            <div className={classes.space}>
                <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Manage Repairs</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ManageRepairs saveGood={saveGood} saveBad={saveBad}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Manage Violations</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ManageViolations saveGood={saveGood} saveBad={saveBad}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Manage Conditions</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ManageConditions saveGood={saveGood} saveBad={saveBad}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Manage Bmps</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ManageBMPs saveGood={saveGood} saveBad={saveBad}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Delete saveGood={saveGood} saveBad={saveBad} type={'agol'} />
            </div>
        );
    }
}

ManageActivities = connect(
    (state, ownProps) => ({
        authState: state.auth,
    }),
    {
    }
)(ManageActivities);

export default withStyles(styles)(withRouter(ManageActivities));
