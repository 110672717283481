import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { formateDateSlash } from "../../api/utils";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";

import { ExcelIcon, ShapeIcon, WordIcon} from '../common/IconFunctions';

import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import DatePicker from "..//common/DatePicker";
import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';

import { createSelector } from "../common/orm";

import * as authActions from "../auth/actions";

const getDrainageSystem = createSelector(session => {
    return session.DrainageSystemView.all().orderBy("drainage", "asc").toRefArray();
});
const getCommissioners = createSelector(session => {
    return session.Local_commish.all().orderBy("district", "asc").toRefArray().map(x => x.district);
});
const getMapSetting = createSelector(schema => {
    return schema.MapSetting.first();
});


const styles = theme => ({
    space: {
        marginTop: 48
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    mr: {
        marginRight: 5
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px"
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    maxWidth: {
        maxWidth: 535
    },
    centerAlign: {
        textAlign: "center"
    },
    allButton: {
        color : "#fff"
    }
});


const AllColumns = [
    { id: "include", label: "Include in Exports or Report", allowSort: false },
    { id: "drainage", label: "System Name", allowSort: true },
    { id: "a_id", label: "Activity ID", allowSort: true },
    { id: "type", label: "Activity Type", allowSort: true },
    { id: "date", label: "Date Created", allowSort: true },
    { id: "commissioner", label: "Commissioner District", allowSort: true },
    { id: "status", label: "Status", allowSort: true },
    { id: "township", label: "Township", allowSort: true },
    { id: "range", label: "Range", allowSort: true },
    { id: "section", label: "Section", allowSort: true },
    { id: "preview", label: "Preview", allowSort: false },
    { id: "activity", label: "Go To Activity", allowSort: false },
];

function FormatDate(date){
    return date.getFullYear() +
    "-" +
    (date.getMonth() + 1 < 10 ? "0" : "") +
    (date.getMonth() + 1) +
    "-" +
    ((date.getDate() < 10 ? "0" : "") + date.getDate());
}

class ActivitySearch extends Component {
    state = {
        page: 0,
        rowsPerPage: 100,
        snackbarOpen: false,
        noResults: false,
        dialog: false,
        range: "Current",
        status: "For Review",

        attachments: [],
        data: [],
        pageData: [],
        repair_list: [],
        violation_list: [],
        condition_list: [],
        bmp_list: [],

        dateError: false,
        dateMessage: "One or more dates are invalid",
        start: FormatDate(new Date()),
        end: FormatDate(new Date()),

        drainage_str: this.props.systems[0] ? this.props.systems[0].drainage : "",
        status_str: "For Review",
        monitor_str: "Yes",
        dist_str: this.props.commissioners[0] ? this.props.commissioners[0].district : "",
        county_str: "",
        priority_str: "High",
        repair_type_str: "Open Ditch",

        drainage: false,
        status_c: false,
        monitor: false,
        district: false,
        county: false,
        priority_c: false,
        repair_type: false,
        fema: false,

        all_check: false,
        selectAll: false,



        drainage_name: true,
        repair: false,
        branch: false,
        commissioner: true,
        date: true,
        source_date: true,
        township: true,
        section: true,
        quarter: true,
        priority: true,
        request: true,
        vnum: true,
        violation_type: true,
        next_compliance_inspection: true,
        cnum: true,
        notes: true,
        bmpnum: true,
        bmp_notes: true,
        descending: false,
        sort_column: "",
        searched: false
    }

    handleActivityChange = (e) => {
        var status_str = "";
        if(e.target.value === "Repair"){
            status_str = "For Review";
        } else if(e.target.value === "Violation"){
            status_str = "Compliant";
        } else if(e.target.value === "Bmp"){
            status_str = "Existing";
        }

        this.setState({
            activity: e.target.value,
            data: [],
            pageData: [],
            status_str,
            repair_list: [],
            violation_list: [],
            condition_list: [],
            bmp_list: []
        });
    }

    handleCountyChange = (e) => {
        this.setState({county_select: e.target.value});
    }

    handleDistChange = (e) => {
        this.setState({dist_select: e.target.value});
    }

    handleChange = (e, key) => {
        this.setState({[key]: e.target.value});
    }

    handleCheckBox = (e, key) => {
        if (key === 'selectAll') {
            const {pageData, data} = this.state
            let obj = {}
            Array.from(Array(pageData.length).keys()).forEach(i => {
                data[i].checked = pageData[i].checked = e.target.checked
                if (!e.target.checked) {
                    obj.repair_list = obj.violation_list = obj.condition_list = obj.bmp_list = []
                } else {
                    obj.repair_list = pageData.filter(x => x.type === "Repair").map(x => x.id);
                    obj.condition_list = pageData.filter(x => x.type === "Condition").map(x => x.id);
                    obj.violation_list = pageData.filter(x => x.type === "Violation").map(x => x.id);
                    obj.bmp_list = pageData.filter(x => x.type === "Bmp").map(x => x.id);
                }
            })
            this.setState({[key]: e.target.checked, data, pageData, ...obj});
            return
        }
        this.setState({[key]: e.target.checked});
    }

    handleRange = (e) => {
        let obj = {range: e.target.value, all_check: false}
        if (e.target.value === "All") {
            obj.all_check = true
        }
        this.setState({...obj});
    }

    search = async (e, sorting=false) => {
        const { authState } = this.props;
        const {
            activity,
            repair,
            violation,
            condition,
            bmp,
            pstatus,
            county,
            county_select,
            district,
            dist_select,
            start,
            end,
            review,
            progress,
            closed,
            noncom,
            com,
            existing,
            recommended,
            all_check,
            descending,
            sort_column
        } = this.state;

        var other = {};

        if(this.state.range === "Date Range"){
            other.range = true;
            const start_error = this.state.start && (this.state.start === "Invalid Date" || this.state.start === "Invalid Year");
            const end_error = this.state.end && (this.state.end === "Invalid Date" || this.state.end === "Invalid Year");
            const start_required = this.state.start && (this.state.start === "Required");
            const end_required = this.state.end && (this.state.end === "Required");

            if(start_error || end_error){
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates are invalid"
                });
                return;
            } else if (start_required || end_required) {
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates require input"
                });
                return;
            }
        }

        var status_list = []
        if(pstatus){
            if(repair){
                if(!review && !progress && !closed){
                    this.setState({status_error: true, message: "Please Select a Repair Status!"});
                    return;
                } else {
                    if (review)
                        status_list.push("For Review")
                    if (progress)
                        status_list.push("In Progress")
                    if (closed)
                        status_list.push("Closed")
                }
            }

            if(violation){
                if(!noncom && !com){
                    this.setState({status_error: true, message: "Please Select a Violation Status!"});
                    return;
                } else {
                    if (noncom)
                        status_list.push("noncom")
                    if (com)
                        status_list.push("com")
                }
            }


            if(bmp){
                if(!existing && !recommended){
                    this.setState({status_error: true, message: "Please Select a BMP Status!"});
                    return;
                } else {
                    if (existing)
                        status_list.push("Existing")
                    if (recommended)
                        status_list.push("Recommended")
                }
            }
        }

        if(!activity || activity === ""){
            this.setState({status_error: true, message: "Please Select a Drainage System!"});
            return;
        }
        if(!repair && !condition && !bmp && !violation){
            this.setState({status_error: true, message: "Please Select a Activity Type!"});
            return;
        }

        if (!sorting) {
            await this.setState({sort_column: "", descending: false})
        }

        fetch(`/annualreport`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                drainage_id: activity,
                review: review,
                progress: progress,
                closed: closed,
                noncom: noncom,
                com: com,
                existing: existing,
                recommended: recommended,
                repair: repair,
                violation: violation,
                condition: condition,
                county_select: county_select,
                dist_select: dist_select,
                bmp: bmp,
                pstatus: pstatus,
                county: county,
                district: district,
                start: start,
                end: end,
                status_list,
                all: all_check,
                descending: !sorting ? false : descending,
                order_by: !sorting ? "": sort_column,
                ...other
            })
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            const {rowsPerPage, page} = this.state
            const pageData = rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data

            var repair_list = pageData.filter(x => x.type === "Repair").map(x => x.id);
            var condition_list = pageData.filter(x => x.type === "Condition").map(x => x.id);
            var violation_list = pageData.filter(x => x.type === "Violation").map(x => x.id);
            var bmp_list = pageData.filter(x => x.type === "Bmp").map(x => x.id);

            this.setState({
                data,
                pageData,
                searched: data.length>0,
                repair_list,
                condition_list,
                violation_list,
                bmp_list,
                noResults: data && data.length === 0,
                selectAll: data.length > 0
            });
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({data: []});
            }
        });
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    goActivity = (x) => {
        const { history } = this.props;
        const { activity } = this.state;
        if(activity === "Repair") history.push(`/repair/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if(activity === "Violation") history.push(`/violation/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if(activity === "Condition") history.push(`/condition/${x.id}/${x.drainage_id}/${x.drainage_name}`);
        else if(activity === "Bmp") history.push(`/bmp/${x.id}/${x.drainage_id}/${x.drainage_name}`);
    }

    display = (x, z) => {
        if(z.id === "estimate_total" || z.id === "invoice_total"){
            return `$${x[z.id].toLocaleString(undefined, {minimumFractionDigits: 2})}`;
        } else if(z.id === "date" || z.id === "source_date"){
            return x[z.id] ? formateDateSlash(x[z.id]) : "-";
        } else {
            return x[z.id] ? x[z.id] : "-";
        }
    }

    formatList = () => {
        const { repair_list, violation_list, condition_list, bmp_list, descending, sort_column } = this.state;

        var str = '';
        str += `drainage_system=${this.state.activity}`
        str += "&repair_list=" + repair_list.join(',');
        str += "&violation_list=" + violation_list.join(',');
        str += "&condition_list=" + condition_list.join(',');
        str += "&bmp_list=" + bmp_list.join(',');
        str += "&order_by=" + sort_column;
        str += "&descending=" + descending;
        return str;
    }

    checkRow = (e) => {
        const { data, pageData, repair_list, violation_list, condition_list, bmp_list } = this.state;

        var name = e.target.name;
        var ids = name.split(":");
        var index = data.findIndex(x => x.type === ids[0] && x.id === parseInt(ids[1]) && x.drainage_id === parseInt(ids[2]));

        if(index > -1){
            data[index].checked = e.target.checked;
        }

        // update list for exports/reports
        var other = {};
        if(!e.target.checked){
            if(ids[0] === "Repair"){
                other.repair_list = repair_list.filter(x => x !== parseInt(ids[1]))
            } else if (ids[0] === "Violation") {
                other.violation_list = violation_list.filter(x => x !== parseInt(ids[1]))
            } else if (ids[0] === "Condition") {
                other.condition_list = condition_list.filter(x => x !== parseInt(ids[1]))
            } else if (ids[0] === "Bmp") {
                other.bmp_list = bmp_list.filter(x => x !== parseInt(ids[1]))
            }
        } else {
            if(ids[0] === "Repair"){
                other.repair_list = [parseInt(ids[1]), ...repair_list];
            } else if (ids[0] === "Violation") {
                other.violation_list = [parseInt(ids[1]), ...violation_list];
            } else if (ids[0] === "Condition") {
                other.condition_list = [parseInt(ids[1]), ...condition_list];
            } else if (ids[0] === "Bmp") {
                other.bmp_list = [parseInt(ids[1]), ...bmp_list];
            }
        }

        if (!e.target.checked) {
            other.selectAll = false
        } else if (!pageData.map(d => d.checked).some(x => !x)) {
            other.selectAll = true
        }

        this.setState({ data, ...other})
    }

    goToActivity = (x) => {
        this.props.history.push(`/${x.type.toLowerCase()}/${x.id}/${x.drainage_id}/${x.drainage}`)
    }

    preview = (x) => {
        const { authState } = this.props;

        this.setState({ loaded: false, dialog: true, preview: x });

        fetch(`annaulreprot/load/attachment`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                type: x.type,
                id: x.id
            })
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(attachments => {
            this.setState({
                loaded: true,
                attachments
            });
        })
        .catch(e => {
            var message = e.message;
            if(message && message === `{"detail":"Token has expired."}`){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({
                    loaded: true,
                    attachments: []
                });
            }
        });
    }

    handleChangePage = (event, page) => {
        const {data, rowsPerPage} = this.state
        const pageData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        const repair_list = pageData.filter(x => x.type === "Repair").map(x => x.id);
        const condition_list = pageData.filter(x => x.type === "Condition").map(x => x.id);
        const violation_list = pageData.filter(x => x.type === "Violation").map(x => x.id);
        const bmp_list = pageData.filter(x => x.type === "Bmp").map(x => x.id);

        this.setState({ page, pageData, repair_list, condition_list, violation_list, bmp_list, selectAll: true});
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = async (column) => {
        const {sort_column, descending, searched} = this.state
        let key = ""

        if (!searched) {
            return
        }

        switch (column) {
            case 'System Name':
                key = 'drainage'
                break
            case 'Activity ID':
                key="a_id"
                break
            case 'Activity Type':
                key="type"
                break
            case 'Date Created':
                key = 'date'
                break
            case 'Commissioner District':
                key = 'commissioner'
                break
            case 'County':
                key = 'county'
                break
            case 'Status':
                key = 'status'
                break
            case 'Township':
                key = 'township'
                break
            case 'Range':
                key = 'range'
                break
            case 'Section':
                key = 'section'
                break
        }

        if (key) {
            await this.setState({ descending: key===sort_column && !descending, sort_column: key });
            await this.search('', true)
        }
    }

    setDate = (val, key) => {
        const date = new Date(val);
        var formatDate = FormatDate(date);
        if(formatDate !== "NaN-NaN-NaN"){
            this.setState({[key]: formatDate});
        } else {
            this.setState({[key]: val});
        }
    }

    render(){
        const { classes, commissioners, systems, settings } = this.props;
        const {
            noResults,
            activity,
            range,
            status_error,
            message,
            data,
            pageData,
            dateError,
            dateMessage,
            review,
            progress,
            closed,
            noncom,
            com,
            existing,
            recommended,
            repair,
            violation,
            condition,
            bmp,
            pstatus,
            county,
            county_select,
            district,
            dist_select,
            order,
            orderBy,
            rowsPerPage,
            page,
            preview,
            attachments,
            loaded,
            selectAll
        } = this.state;

        if (settings) {
            if (settings.jurisdiction === "Watershed District") {
                AllColumns[5] = { id: "county", label: "County", allowSort: true }
            }
            if (settings.jurisdiction === "County") {
                AllColumns[5] =  { id: "commissioner", label: "Commissioner District", allowSort: true }
            }
        }

        return(
            <AppContainer authenticated adminPage={true}>
                <div className={classes.space}>
                    <ToolBar>
                        <Typography variant="h5" className={classes.grow}>Annual Inspection Report</Typography>
                    </ToolBar>
                    <Divider className={classes.mb}/>
                    <ExpansionPanel defaultExpanded={true} style={{marginTop: 10}}>
                            <ExpansionPanelSummary
                                className={classes.summary}
                                expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h5">
                                        <AssessmentIcon className={classes.expansionIcon} /> Search Filters
                                    </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={10}>
                                    <Grid item xs={12} md={9} lg={9} xl={8}>
                                        <Grid container spacing={3}>

                                        <Grid item xs={6}>
                                        <Typography id="proposal-activity-label">Drainage System:</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-activity-label"
                                            id="proposal-activity"
                                            value={activity}
                                            onChange={this.handleActivityChange}
                                            input={<Input />}>
                                            {systems.map(x => (
                                                <MenuItem key={x.id} value={x.id}>
                                                    <ListItemText primary={x.drainage} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography id="proposal-years-checkbox-label">Select Year(s) :</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="master"
                                                    name="master1"
                                                    value={range}
                                                    onChange={this.handleRange}>
                                                    <FormControlLabel
                                                        value="Current"
                                                        control={<Radio color="primary" />}
                                                        label="Current"
                                                    />
                                                    <FormControlLabel
                                                        value="Date Range"
                                                        control={<Radio color="primary" />}
                                                        label="Date Range"
                                                    />
                                                    <FormControlLabel
                                                        value="All"
                                                        control={<Radio color="primary" />}
                                                        label="All"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <Form>
                                                {(formApi) => (
                                                    <form>
                                                        <div>
                                                            <DatePicker
                                                                disabled={range === "Current" || range === "All"}
                                                                field="start"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "start")}
                                                            />
                                                        </div>
                                                        <div>
                                                            <DatePicker
                                                                disabled={range === "Current" || range === "All"}
                                                                field="end"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "end")}
                                                            />
                                                        </div>
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <Typography style={{display: "inline-flex", marginRight: 33}}>Activity Type:</Typography>
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "repair")}
                                                    control={<Checkbox checked={repair} color="primary" />}
                                                    label="Repair:"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "violation")}
                                                    control={<Checkbox checked={violation} color="primary" />}
                                                    label="Violation:"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "condition")}
                                                    control={<Checkbox checked={condition} color="primary" />}
                                                    label="Condition:"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "bmp")}
                                                    control={<Checkbox checked={bmp} color="primary" />}
                                                    label="BMP:"
                                                    labelPlacement="end"
                                                />
                                            </div>
                                            <div>
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "pstatus")}
                                                    control={<Checkbox checked={pstatus} color="primary" />}
                                                    label="Status:"
                                                    labelPlacement="end"
                                                    style={{marginRight: 40}}
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "review")}
                                                    control={<Checkbox checked={review} color="primary" />}
                                                    disabled={!repair || !pstatus}
                                                    label="For Review"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "progress")}
                                                    control={<Checkbox checked={progress} color="primary" />}
                                                    disabled={!repair || !pstatus}
                                                    label="In Progress"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "closed")}
                                                    control={<Checkbox checked={closed} color="primary" />}
                                                    disabled={!repair || !pstatus}
                                                    label="Closed"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "noncom")}
                                                    control={<Checkbox checked={noncom} color="primary" />}
                                                    disabled={!violation || !pstatus}
                                                    label="Non-Compliant"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "com")}
                                                    control={<Checkbox checked={com} color="primary" />}
                                                    disabled={!violation || !pstatus}
                                                    label="Compliant"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "existing")}
                                                    control={<Checkbox checked={existing} color="primary" />}
                                                    disabled={!bmp || !pstatus}
                                                    label="Existing"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    onChange={(e) => this.handleCheckBox(e, "recommended")}
                                                    control={<Checkbox checked={recommended} color="primary" />}
                                                    disabled={!bmp || !pstatus}
                                                    label="Recommended"
                                                    labelPlacement="end"
                                                />
                                            </div>
                                            {settings && (
                                                <>
                                                    {settings.jurisdiction === "County" &&(
                                                        <div>
                                                            <FormControlLabel
                                                                onChange={(e) => this.handleCheckBox(e, "district")}
                                                                control={<Checkbox checked={district} color="primary" />}
                                                                label="Commissioner District:"
                                                                labelPlacement="end"
                                                                style={{marginRight: 40}}
                                                            />
                                                            <Select
                                                                className={classes.selectbox}
                                                                inputProps={{ 'aria-label': 'Commissioner District' }}
                                                                value={dist_select}
                                                                onChange={this.handleDistChange}
                                                                input={<Input />}>
                                                                {commissioners.map(x => (
                                                                    <MenuItem key={x} value={x}>
                                                                        <ListItemText primary={x} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                    {settings.jurisdiction === "Watershed District" &&(
                                                        <div>
                                                            <FormControlLabel
                                                                onChange={(e) => this.handleCheckBox(e, "county")}
                                                                control={<Checkbox checked={county} color="primary" />}
                                                                label="County:"
                                                                labelPlacement="end"
                                                                style={{marginRight: 40}}
                                                            />
                                                            <Select
                                                                className={classes.selectbox}
                                                                inputProps={{ 'aria-label': 'County' }}
                                                                value={county_select}
                                                                onChange={this.handleCountyChange}
                                                                input={<Input />}>
                                                                {settings["counties"].split(";").map(x => (
                                                                    <MenuItem key={x} value={x}>
                                                                        <ListItemText primary={x} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid container>
                                            <Grid xs={12} md={9} lg={9} xl={8}>
                                                <Button
                                                    disable={!activity || activity===""}
                                                    onClick={this.search}
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<SearchIcon />}
                                                >
                                                Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10}}>
                            <div className={classes.root}>
                                <Button
                                    variant="contained"
                                    disabled={pageData.length < 1}
                                    target="_blank"
                                    href={`/annualreport/excel?${this.formatList()}`}
                                    className={classes.mr}>
                                    <ExcelIcon />
                                        &nbsp;&nbsp;Export to Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={pageData.length < 1}
                                    target="_blank"
                                    href={`/export/annual/shapefile?${this.formatList()}`}
                                    className={classes.mr}
                                >
                                    <ShapeIcon />
                                    &nbsp;&nbsp;Export to Shapefile
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={pageData.length < 1}
                                    target="_blank"
                                    href={`/annualreport/summary/word?${this.formatList()}`}
                                    className={classes.mr}>
                                    <WordIcon />
                                    &nbsp;&nbsp;Generate Draft Summary Word Report
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={pageData.length < 1}
                                    target="_blank"
                                    href={`/annualreport/full/word?${this.formatList()}`}
                                    className={classes.mr}>
                                    <WordIcon />
                                    &nbsp;&nbsp;Generate Draft Detailed Word Report
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{overflowX: "auto"}}>
                            <Table>
                                <EnhancedTableHead
                                    columnData={AllColumns}
                                    order={order}
                                    alloption={!!pageData.length && (
                                        <FormControlLabel
                                            onChange={(e) => this.handleCheckBox(e, "selectAll")}
                                            control={<Checkbox checked={selectAll} color="primary" />}
                                            disabled={!pageData.length}
                                            label={<Typography className={classes.allButton}>
                                                {selectAll ? 'Uncheck All' : 'All'}</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                    orderBy={orderBy}
                                    onRequestSort={(e) =>
                                       this.handleRequestSort(typeof e === 'string'? e: e.target.textContent)}
                                />
                                <TableBody>
                                    {pageData.map(x => (
                                        <TableRow key={x.a_id}>
                                            <CustomTableCell>
                                                <Checkbox
                                                    name={x.type + ":" + x.id + ":" + x.drainage_id}
                                                    checked={x.checked}
                                                    onChange={this.checkRow}
                                                />
                                            </CustomTableCell>
                                            <CustomTableCell>{x.drainage}</CustomTableCell>
                                            <CustomTableCell>{x.a_id}</CustomTableCell>
                                            <CustomTableCell>{x.type}</CustomTableCell>
                                            <CustomTableCell>{x.date}</CustomTableCell>
                                            {settings && (
                                                <>
                                                    {settings.jurisdiction === "County" && (
                                                        <CustomTableCell>{x.commissioner}</CustomTableCell>
                                                    )}
                                                    {settings.jurisdiction === "Watershed District" && (
                                                        <CustomTableCell>{x.county}</CustomTableCell>
                                                    )}
                                                </>
                                            )}
                                            <CustomTableCell>{x.status}</CustomTableCell>
                                            <CustomTableCell>{x.township}</CustomTableCell>
                                            <CustomTableCell>{x.range}</CustomTableCell>
                                            <CustomTableCell>{x.section}</CustomTableCell>
                                            <CustomTableCell>
                                                <Tooltip title="Preview">
                                                    <Button onClick={e => this.preview(x)}>
                                                        <VisibilityIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                <Tooltip title="Go To">
                                                    <Button onClick={e => this.goToActivity(x)}>
                                                        <ExitToAppIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                        </TableRow>
                                    ))}
                                    {pageData.length === 0 && (
                                        <CustomTableCell
                                            colSpan={AllColumns.length}
                                            className={classes.centerAlign}>
                                            No Records
                                        </CustomTableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={12}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                            rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                    </Grid>
                </div>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.state.dialog}
                    onClose={() => {
                        this.setState({ dialog: false });
                    }}
                    disableBackdropClick={true}>
                    <DialogTitle
                        className={classes.dialogTitleMargin}
                        id="form-dialog-title">
                        <Typography variant="title">{preview ? preview.type : ""} - {preview ? preview.a_id : ""}</Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            className={classes.closeButton}
                            onClick={() => {
                                this.setState({ dialog: false });
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {preview && (
                            <>
                                {preview && preview.type === "Repair" && (
                                    <>
                                        <Typography style={{textDecoration: "underline"}}><b>Problem/Proposed Work</b></Typography>
                                        <br />
                                        <Typography>{preview.comments}</Typography>
                                        <br />
                                        <Typography style={{textDecoration: "underline"}}><b>Total Cost</b></Typography>
                                        <br />
                                        <Typography>${preview.cost ? preview.cost.toLocaleString() : 0}</Typography>
                                    </>
                                )}
                                {preview && preview.type !== "Repair" && (
                                    <>
                                        <Typography style={{textDecoration: "underline"}}><b>Notes</b></Typography>
                                        <Typography>{preview.notes}</Typography>
                                    </>
                                )}
                                <Typography style={{textDecoration: "underline"}}><b>Thumbnails</b></Typography>
                                {preview && (preview.type === "Repair" || preview.type === "Violation") && loaded && (
                                    <div>
                                        {attachments.map(x => (
                                            <>
                                                <span>Date({x.inspectiondate}) - Inspector({x.modified_user})</span>
                                                <hr />
                                                <br />
                                                {x.list && x.list.map(obj => (
                                                    <div>
                                                        <span>{obj.uploaddate} - {obj.caption}</span>
                                                        <hr />
                                                        <br />
                                                        <img src={obj.link} alt="Couldn't Find!" className={classes.maxWidth}/>
                                                        <br />
                                                    </div>
                                                ))}
                                            </>
                                        ))}
                                    </div>
                                )}
                                {preview && (preview.type !== "Repair" && preview.type !== "Violation") && loaded && (
                                    <div>
                                        {attachments.map(obj => (
                                            <div>
                                                <span>{obj.uploaddate} - {obj.caption}</span>
                                                <br />
                                                <img src={obj.link} alt="Couldn't Find!" className={classes.maxWidth}/>
                                                <br />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </DialogContent>
                </Dialog>
                <Snackbar
                    time={5000}
                    customMessage={message}
                    snackbarOpen={status_error}
                    handleSnackbarClose={() => this.setState({ status_error: false })}
                    isSuccess={false}
                />
                <SnackBarError
                    open={noResults}
                    close={e => this.setState({noResults: false})}
                    customMessage="No records match your query"
                />
                <SnackBarError
                    open={dateError}
                    close={e => this.setState({dateError: false})}
                    customMessage={dateMessage}
                />
            </AppContainer>
        )
    }
}

ActivitySearch = connect(
    (state, ownProps) => ({
        authState: state.auth,
        systems: getDrainageSystem(state, ownProps),
        settings: getMapSetting(state, ownProps),
        commissioners: getCommissioners(state, ownProps)
    }),
    {
        ...authActions
    }
)(ActivitySearch);

export default withStyles(styles)(withRouter(ActivitySearch));