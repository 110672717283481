import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { MAKE_OPTIONS_VID } from "../../api/constants";

import { withStyles, Grid, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from "../common/Select";

import RecordTable from "../common/RecordTable";
import WarningDialog from "../common/WarningDialog";

import { createSelector } from '../common/orm';
import { Notification } from "../mypage/models";


const getUsers = createSelector(session => {
    return session.User.all().orderBy('name').toModelArray();
});

const getRecipients = createSelector(session => {
    return session.Notification.all().orderBy('user_label').toModelArray();
});

const getNotifications = createSelector(session => {
    return session.NotificationType.all().orderBy('name').toModelArray();
});

const styles = theme => ({
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    header: {
        backgroundColor: "#333333",
        color: "#ffffff"
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        grow: 1,
        display: "inline-flex",
    },
    right: {
        float: "right"
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    titlePadding: {
        padding: "18px 8px 18px 8px"
    }
});

const columnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "notification_type_label", numeric: false, label: "Notification Type", allowSort: true },
    {id: "user_label", numeric: false, label: "User", allowSort: true },
]

class NotificationPanel extends Component {
    state = {
        expanded: false,
        dialog: false
    }

    save = (values) => {
        const { ormNotificationCreate, saveBad, saveGood } = this.props;
        ormNotificationCreate(values).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
                this.setState({dialog: false, active: null});
            }
        })
    }

    errorValidator = (values) => {
        const isRequired = (value) => {
            if (value === undefined || value === null) return "Required";
            else return null;
        };

        return {
            user: isRequired(values.user),
            notification_type: isRequired(values.notification_type)
        };
    }

    render() {
        const { 
            classes, 
            notifications, 
            reciepents,
            users
        } = this.props;
        const { 
            dialog, 
            expanded,
            id, 
            active,
        } = this.state;

        return (
            <>
                <ExpansionPanel
                    expanded={expanded}
                    onChange={(e, expanded) => {
                        this.setState({expanded})
                    }}
                    >
                    <ExpansionPanelSummary 
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <div className={classes.titlePadding}>
                                <Typography>
                                    Notification Recipients
                                </Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>Notification Types:</Typography>
                                    <ol style={{ listStyleType: 'decimal' }}>
                                        {notifications.map(x => (
                                            <li>
                                                <Typography>
                                                    <b>{x.name}</b> - {x.description}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ol>
                                </Grid>
                                <Grid item xs={12}>
                                    <RecordTable 
                                        noScroll
                                        viewOnly={false}
                                        deletable={true}
                                        creatable={true}
                                        columnData={columnData}
                                        data={reciepents}
                                        addName="Add Notification Recipient"
                                        addRecord={() => this.setState({ dialog: true, id: null })}
                                        editRecord={(values) => this.setState({dialog: true, active: values})}
                                        deleteRecord={(id) => this.setState({id: id, delete: true})}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                </ExpansionPanel>
                <Dialog 
                    open={dialog} 
                    maxWidth="md"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Nofitication Recipient</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({dialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            id="recipientForm"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={active}
                            onSubmit={values => this.save(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select 
                                        required
                                        field="notification_type" 
                                        label="Notification Type" 
                                        options={MAKE_OPTIONS_VID(notifications, "name")}
                                        fullWidth
                                    />
                                    <Select 
                                        required
                                        field="user" 
                                        label="Recipient" 
                                        options={MAKE_OPTIONS_VID(users, "username")}
                                        fullWidth
                                    />
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.props.ormNotificationDeletePromise(id).then(id => {
                            this.setState({ id: false });
                            if(!id){
                                this.props.saveBad();
                            } else {
                                this.props.saveGood();
                            }
                        });
                    }}
                    cancelAction={() => this.setState({ id: false })}
                    open={!!id}
                    title="Delete Notification Recipient"
                    text={"Are you sure you wish to permanently delete?"}
                />
            </>
        );
    }
}

NotificationPanel = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        users: getUsers(state),
        reciepents: getRecipients(state),
        notifications: getNotifications(state)
    }),
    {
        ...Notification.actions
    }
)(NotificationPanel);

export default withStyles(styles)(withRouter(NotificationPanel));
