import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Divider } from "@material-ui/core";
import { connect } from "react-redux";

import { LegalProcceding } from "../common/SelectOptions";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import LegalTable from "../page/Legal/Table";
import RecordTable from "../common/RecordTable";

import Grid from "@material-ui/core/Grid";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from '@material-ui/icons/Assignment';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DrainageLegal } from '../mypage/models';
import { createSelector } from '../common/orm';

const getDrainageLegal = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageLegal.filter(sys => sys.drainage === system.id).toRefArray();
    }
);

const columnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "proceed_title", numeric: false, label: "Proceeding Title", allowSort: true },
    {id: "proceedtype", numeric: false, label: "Proceeding Type", allowSort: true },
    {id: "recentdate", date: true, label: "Date of First Activity", allowSort: true },
    {id: "orderdate", date: true, label: "Date of Last Activity", allowSort: true },
    {id: "status", numeric: false, label: "Status(Open or Closed)", allowSort: true },
    {id: "proceed_notes", numeric: false, label: "Proceeding Note", allowSort: true },
];

const styles = theme => ({
    space: {
        marginTop: 48
    },
    m: {
        marginTop: 40
    },
    mb: {
        marginBottom: 20
    },
    min: {
        paddingLeft: 20,
        minWidth: 200,
        marginBottom: 20
    },
    small: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: 10,
        fontWeight: "bold"
    }
});

class Attachments extends Component {
    state = {
        proceedingID: null, 
        proceedingDelete: false,
        proceedingType: LegalProcceding[0].value,
        edit: false
    };

    back = () => {
        this.setState({edit: false})
    }

    createNew = () => {
        const {ormDrainageLegalCreate, system, saveBad } = this.props;
        const {proceedingType} = this.state;

        var test = ormDrainageLegalCreate({
            drainage_id: system.id,
            proceedtype: proceedingType
        });

        test.then( returnID => {
            if(!returnID){
                saveBad();
            } else {
                this.setState({id: returnID, edit: true});
            }
        });
        this.props.updateSystem();
    }

    customEdit = (l) => {
        this.setState({id: l.id, edit: true});
    }

    deleteLegal = (id) => {
        const { ormDrainageLegalDeletePromise, saveBad, saveGood } = this.props;
        ormDrainageLegalDeletePromise(id).then(id => {
            if(!id){
                saveBad()
            } else {
                saveGood();
            }
        });
        this.props.updateSystem();
    }

    report = (l) => {
        window.open(`/reports/drainagelegal/${l.id}/word`);
    }

    render(){
        const { 
            authState, 
            classes, 
            fileSize, 
            proceedings, 
            ormDrainageLegalUpdate, 
            ormDrainageLegalUpdatePromise, 
            ormDrainageLegalDeletePromise,
            system, 
            saveBad, 
            saveGood,
            updateSystem
        } = this.props;
        const { 
            edit, 
            id, 
            proceedingType,
            proceedingID,
            proceedingDelete
        } = this.state;

        const viewOnly = authState.user.role === "View Only";

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system.drainage} />
                {edit ? 
                    <div style={{flexGrow: 1}}>
                        <LegalTable 
                            updateSystem={this.props.updateSystem}
                            system={system}
                            back={this.back} 
                            proceedings={proceedings} 
                            id={id}
                            ormDrainageLegalUpdate={ormDrainageLegalUpdate}
                            ormDrainageLegalUpdatePromise={ormDrainageLegalUpdatePromise}
                            ormDrainageLegalDetailUpdateBatch={this.props.ormDrainageLegalDetailUpdateBatch}
                            saveBad={saveBad}
                            saveGood={saveGood}
                            fileSize={fileSize}
                        />
                        <Typography className={classes.small}>Disclaimer: The legal proceeding tracking page is meant to be a tool to guide a drainage authority through legal
                            proceeding processes. It is not meant to be an official legal record. A drainage authority should always consultant their
                            legal counsel for questions and decisions made by a drainage authority in regards to legal proceedings. 
                            Houston Engineering, Inc. assumes no responsibility or liability in any way for actions taken by a drainage authority in the use of this tool.
                            By using this tool you accept full responsibility of actions and decisions made by the drainage authority.
                        </Typography>
                    </div>
                :
                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography variant="h5" style={{marginTop: 20}}>Legal Proceedings</Typography>
                        <Divider className={classes.mb}/>
                        {!viewOnly && (
                            <Grid container style={{marginTop: 10, marginBottom: 10}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.createNew} 
                                    >
                                        <AddCircleOutlineIcon />
                                        &nbsp;&nbsp;Create New Proceeding
                                </Button>
                                <TextField 
                                    disabled={viewOnly}
                                    select 
                                    className={classes.min} 
                                    onChange={(e) => this.setState({proceedingType: e.target.value})} 
                                    value={proceedingType} >
                                    {LegalProcceding.map(({ label, value }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}
                        <Typography className={classes.mb}>Disclaimer: The legal proceeding tracking page is meant to be a tool to guide a drainage authority through legal
                            proceeding processes. It is not meant to be an official legal record. A drainage authority should always consultant their
                            legal counsel for questions and decisions made by a drainage authority in regards to legal proceedings. 
                            Houston Engineering, Inc. assumes no responsibility or liability in any way for actions taken by a drainage authority in the use of this tool.
                            By using this tool you accept full responsibility of actions and decisions made by the drainage authority.
                        </Typography>
                        <RecordTable
                            viewOnly={viewOnly} 
                            customActions={!viewOnly ?[
                            {
                                title: "Report",
                                color: "primary",
                                event_func: this.report,
                                icon: AssignmentIcon
                            }] : []}
                            columnData={columnData}
                            data={proceedings}
                            deletable={true}
                            deleteRecord={(id) => this.setState({proceedingID: id, proceedingDelete: true})}
                            editable={true}
                            editRecord={this.customEdit}
                        />
                        <WarningDialog
                            confirmAction={() => {
                                ormDrainageLegalDeletePromise(proceedingID).then(id => {
                                    if(!id){
                                        saveBad();
                                    } else {
                                        saveGood();
                                    }
                                    this.setState({proceedingDelete: false});
                                });
                                updateSystem();
                            }}
                            cancelAction={() => {
                                this.setState({proceedingDelete: false});
                            }}
                            confirmText="Delete"
                            cancelText="Cancel"
                            open={proceedingDelete}
                            title={"Delete Proceeding"}
                            text={"Are you sure you wish to delete?"}
                        />
                    </Grid>
                }
            </Grid>
        )
    }
}

Attachments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proceedings: getDrainageLegal(state, ownProps)
    }),
    {
        ...DrainageLegal.actions
    }
)(Attachments);

export default withStyles(styles)(withRouter(Attachments));
