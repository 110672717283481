import React, { Component } from "react";
import { withStyles, Grid, DialogTitle } from "@material-ui/core";
import {Form} from "react-form";

import WarningDialog from "../common/WarningDialog";
import Snackbar from "../common/Snackbar";

import classNames from "classnames";

import Checkbox from "../common/Checkbox";
import FileFormInput from "../common/FileFormInput";
import DatePicker from "../common/DatePicker";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import HelpLabel from "../common/HelpLabel";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";


import TablePaginationActionsWrapped from "./Paginator";
import CustomTableCell from '../common/TableCell';
import EnhancedTableHead from "../common/EnhancedTableHead";

const styles = theme => ({
    minWidth: {
        minWidth: 400,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
    flex: {
        paddingTop: 5,
        display: "inline-flex",
        flex: 1
    },
    right: {
        float: "right"
    },
    space: {
        marginTop: 10,
        marginBottom: 10
    },
    mt: {
        marginTop: 10
    },
    noOverFlow: {
        overflowX: "auto"
    },
    centerAlign: {
        textAlign: "center"
    },
    submitWrapper: {
        position: "relative"
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    }
});

class LargeFileTable extends Component {
    constructor(props){
        super(props);

        //loop through columns if type is select and required key to list. If file add to key to list

        var requiredList = [];
        var fileList = [];
        props.columns.forEach(x => {
            if(x.type === "file"){
                requiredList.push(x.id);
                fileList.push(x.id);
            }
            if(x.type === "select" && x.required){
                requiredList.push(x.id);
            }
        });

        this.state = {
            edit: false,
            page: 0,
            rowsPerPage: 25,
            order: "asc",
            orderBy: props.by? props.by : "name",
            dialogOpen: false,
            deleteDialog: false,
            snackbarOpen: false,
            messagebaropen: false,
            active: {},
            required: requiredList,
            files: fileList,
            loading: false,
            confirmUpload: false,
            calculated: ""
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = (event, property) => {
        const {  data, columns } = this.props;
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        data.sort((a,b) => {
            var date = columns.find(cD => cD.id === orderBy).date;
            var numeric = columns.find(cD => cD.id === orderBy).numeric;
            var bool = columns.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy });
    };

    handleFiles = (field, val, formApi) => {
        const { fileSize } = this.props;
        
        this.setState({ file_interface: val });

        if(!val){
            this.setState({confirmUpload: false, calculated: "0 Minutes"});
            formApi.setValue("lock_submit", false);
            return;
        }

        const size = val.size / 1000000;
        if(size > 250 && fileSize){ 
            formApi.setValue(field, null);
            formApi.setValue("lock_submit", false);
            this.setState({confirmUpload: false });
            fileSize();
        } else if(size > 100){
            const calculated = Math.round(size / 40);
            this.setState({confirmUpload: true, calculated: `${calculated} Minutes`});
        } else {
            //if new file is selected and less than 100 MB hide and reset lock
            this.setState({confirmUpload: false, calculated: "0 Minutes"});
            formApi.setValue("lock_submit", false);
        }
    }

    checkType = (formApi, x, index, loading) => {
        const { classes, name } = this.props;
        const { edit } = this.state;

        if(x.type === "check"){
            return (
                <Checkbox 
                    field={x.id} 
                    label={x.label} 
                    key={index + name}
                    disabled={loading}
                />
            )
        }
        if(x.type === "date" && !x.noEdit){
            return(
                <DatePicker 
                    field={x.id} 
                    label={x.label} 
                    autoPopulate={true} 
                    len150 
                    className={classes.space} 
                    key={index + name}
                    disabled={loading}
                />
            );
        }
        if(x.type === "text" && !x.noEdit){
            return(
                <TextField 
                    key={index + name}
                    useDecimalFormat={x.format === "decimal"}
                    useIntegerFormat={x.format === "int"}
                    userPercentageFormat={x.format === "perc"}
                    useTextFormat={x.format === "tetx"}
                    field={x.id}
                    fullWidth 
                    disabled={loading}
                    required={x.required}
                    className={classes.space}
                    label={x.label}
                />
            );
        }
        if(x.type === "file" && !edit){
            // add file type 
            /*return(
                <FileInput
                    accept={x.accept}
                    view={false}
                    key={index + name} 
                    field={x.id}
                    id={new Date().getTime()}
                    fullWidth 
                    label="Upload File"
                />
            );*/
            return(
                <FileFormInput
                    eventHandle={val => this.handleFiles(x.id, val, formApi)}
                    accept={x.accept}
                    view={false}
                    key={index + name} 
                    field={x.id}
                    id={new Date().getTime()}
                    fullWidth 
                    disabled={loading}
                    label="Upload File"
                />
            );
        }
        if(x.type === "select"){
            return (
                <Select
                    key={index + name} 
                    options={x.options}
                    field={x.id}
                    label={x.label}
                    fullWidth 
                    disabled={loading}
                    className={classes.space}/>
            );
        }
    }

    errorValidator = values => {
        const { customValidation } = this.props;
        const {required} = this.state;
        var list = {};

        const checkIfEmpty = value => {
            if (!value) {
                return "Required";
            }
            return null;
        }

        required.forEach(x => {
            list[x] = checkIfEmpty(values[x]); 
        });

        if(customValidation){
            list = {...list, ...customValidation(values)};
        }

        return list;
    }

    close = () => {
        this.setState({ dialogOpen: false, confirmUpload: false });
    }

    onSubmit = (e) => {
        const { parentSubmitForm, addFiles} = this.props;
        const { edit, files, file_interface} = this.state;

        this.setState({loading: true});

        if(edit){
            delete e[files[0]];
        }

        if(addFiles){
            parentSubmitForm(e, file_interface);
        } else {
            parentSubmitForm(e);
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        var changed = {};
        Object.entries(this.props).forEach(([key, val]) => {
            if(prevProps[key] !== val) {
                changed[key] = true;
            }
        });

        if(changed["forceReset"]){
            this.setState({loading: false, dialogOpen: false, confirmUpload: false});
        }
    }

    render(){
        const {
            classes, 
            customEdit, 
            canReport, 
            canEdit,
            canDelete,
            deleteProp, 
            hideAdd, 
            data, 
            emptyTxt, 
            columns, 
            keys, 
            name, 
            reportHref, 
            title, 
            viewOnly,
            setError
        } = this.props;
        const { 
            active, 
            deleteDialog, 
            dialogOpen, 
            confirmUpload,
            loading,
            order, 
            orderBy, 
            snackbarOpen, 
            messagebaropen,
            calculated,
            page, 
            rowsPerPage
        } = this.state;

        return(
            <>
                <Grid container>
                    <Grid container spacing={3} className={classes.mB}>
                        {!hideAdd && (
                            <Grid item xs={12}>
                                <Button 
                                    onClick={() => this.setState({active: {}, dialogOpen: true, edit: false})}
                                    variant="contained" 
                                    color="primary" 
                                    fullWidth
                                    style = {{display: viewOnly && "none "}}
                                    >
                                    <AddCircleOutlineIcon /> 
                                    &nbsp;&nbsp;Add
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                <Grid item xs={12} className={classes.noOverFlow}>
                    <Table className={classNames(classes.mt, classes.minWidth)}>
                        <EnhancedTableHead columnData={columns ? columns : []} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} name={name}/>
                        <TableBody>
                            {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((l,index) => {
                                return (
                                    <TableRow hover key={name + index}>
                                        <CustomTableCell className={classes.noWrap}>
                                            {canReport && (
                                                <Tooltip title="Report">
                                                    <Button
                                                        href={reportHref.replace("{id}", l.id)}
                                                        className={classes.deleteWidth}>
                                                        <AssignmentIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {canEdit && (
                                                customEdit ? 
                                                <Tooltip title="Edit">
                                                    <Button
                                                        onClick={(e) => this.props.customEdit(e, l)}
                                                        className={classes.deleteWidth}>
                                                        <EditIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Edit">
                                                    <Button
                                                        onClick={() => this.setState({active: l, dialogOpen: true, edit: true})}
                                                        className={classes.deleteWidth}>
                                                        <EditIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {canDelete && (<Tooltip title="Delete">
                                                {
                                                    l.deleteProp ? 
                                                        <Button
                                                            onClick={() => this.setState({deleteDialog: true, active: l})}
                                                            className={classes.deleteWidth}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    :
                                                    <Button
                                                        onClick={() => this.setState({messagebaropen: true})}
                                                        className={classes.deleteWidth}>
                                                        <DeleteIcon style={{color: "green"}}/>
                                                    </Button>
                                                }
                                            </Tooltip>)
                                            }
                                        </CustomTableCell>
                                        {keys.map((x,index) => {
                                            if(x.openWindow){
                                                return (
                                                    <CustomTableCell key={name + "_cell_" + index}>
                                                        <Tooltip title="View Attachment">
                                                            <a target="_blank" rel="noopener noreferrer" href={l[x.id]} className={classes.linkColor}>
                                                                Open File
                                                            </a>
                                                            </Tooltip>        
                                                    </CustomTableCell>
                                                )
                                            } else {
                                                return <CustomTableCell key={index}>{
                                                    typeof(l[x.id]) === "boolean" ? l[x.id].toString() : l[x.id]
                                                    }</CustomTableCell>
                                            }
                                        })
                                        }
                                    </TableRow>
                                );
                            })}
                            {data.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={columns.length} className={classes.centerAlign}>
                                        {emptyTxt}
                                    </CustomTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={columns.length}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                    rowsPerPageOptions={[25, 50, { label: 'All', value: -1 }]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
            </Grid>
            <Dialog open={dialogOpen} maxWidth='md' fullWidth>
            <DialogTitle disableTypography>
                <Typography variant="h5" className={classes.flex}>{title}s</Typography>
                <IconButton aria-label="close" className={classes.right} disabled={loading} onClick={this.close}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogMin}>
                <Form
                    id="form"
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.errorValidator}
                    defaultValues={{...active, lock_submit: false}}
                    onSubmit={this.onSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className={classes.mt}>
                                        {
                                            keys.map( (x, index) => {
                                                return this.checkType(formApi, x, index, loading)
                                            })
                                        }
                                        {confirmUpload && (
                                            <Subquestion
                                                component={
                                                    <RadioGroup
                                                        field="lock_submit"
                                                        name="lock_submit"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                title={true}
                                                                showLabel={true}
                                                                helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                            />
                                                        }
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        disabled={loading}
                                                        alignment={true}></RadioGroup>
                                                }
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.submitWrapper}>
                                            <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                                Save
                                                {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </DialogContent>
            </Dialog>
            <WarningDialog
                    confirmAction={() => {
                        var deleteAction = deleteProp[active.deleteProp];
                        if(setError){
                            deleteAction(active.id).then(id => {
                                if(!id){
                                    setError();
                                }
                            });
                        } else {
                            deleteAction(active.id);
                        }
                        //this.setState({ deleteDialog: false, snackbarOpen: true });
                        this.setState({ deleteDialog: false, snackbarOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialog: false })}
                    open={deleteDialog}
                    title={"Delete " + title}
                    text={"Are you sure you wish to permanently delete?"}
                />
            <Snackbar
                snackbarOpen={snackbarOpen}
                handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                isSuccess
                operationType="setting"
            />
            <Snackbar
                snackbarOpen={messagebaropen}
                handleSnackbarClose={() => this.setState({ messagebaropen: false })}
                message="Deleting Legal Proceedings attachments is not allowed!"
                operationType="setting"
            />
        </>
        )
    }
}

export default withStyles(styles)(LargeFileTable);
