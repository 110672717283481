import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import SupportIcon from "./icons/SupportIcon";

const styles = theme => ({
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 32
    },
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    titleContainer2: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        "& path, & circle": {
            stroke: "#fff"
        }
    },
    questionTitle: {
        marginTop: ".6em",
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    nooverflow: {
        overflow: "hidden"
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    }
});

const HelpDialog = ({
    question,
    text,
    classes,
    confirmAction,
    cancelAction,
    confirmText,
    cancelText,
    onClose,
    showHelp,
    ...other
}) => (
    <Dialog onClose={onClose} {...other} classes={{ paper: classes.nooverflow }}>
        <DialogTitle className={showHelp ? classes.titleContainer2 : classes.titleContainer}>
            {!showHelp && <ErrorOutline className={classes.supportIcon} />}
            {showHelp && <SupportIcon className={classes.supportIcon} />}
            <IconButton onClick={onClose} className={classes.closeIcon}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography variant="title" className={classes.questionTitle} gutterBottom>
                {question}
            </Typography>
            <DialogContentText>{text}</DialogContentText>
            {confirmAction && (
                <DialogActions className={classes.centerButtons}>
                    <Button variant="contained" onClick={confirmAction} color="secondary">
                        {confirmText ? confirmText : "Ok"}
                    </Button>
                    <Button variant="contained" onClick={cancelAction} className={classes.greyButton} autoFocus>
                        {cancelText ? cancelText : "Cancel"}
                    </Button>
                </DialogActions>
            )}
            {!confirmAction && (
                <DialogActions className={classes.centerButtons}>
                    <Button variant="contained" onClick={onClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            )}
        </DialogContent>
    </Dialog>
);

export default withStyles(styles)(HelpDialog);
