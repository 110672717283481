import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { ExcelIcon, ShapeIcon} from '../common/IconFunctions';
import RecordTable from "../common/RecordTable";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import { createSelector } from '../common/orm';

const getRepairs = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.Repair.filter(repair => repair.drainage === system.id).orderBy('repair').toModelArray().map(repair => {
            const { ref } = repair;
            const temp = repair.repair_landowner.filter(x => x.repair === repair.id).toRefArray();
            const total = repair.repair_invoice.filter(x => x.repair === repair.id).toRefArray().reduce((a, b) => a + parseFloat(b.totalcost || 0), 0);

            return {
                ...ref,
                systemname: repair.drainage.drainage ? repair.drainage.drainage : "",
                status: repair.status && repair.status.length !== "" ? repair.status : "Unknown",
                name: temp.length > 0 ? temp[0].name : null,
		totalcost: total,
                totalcost_label: "$" + total.toLocaleString(undefined, {minimumFractionDigits: 2})
            };
        });
    }
);

const getViolations = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.Violation.filter(violation => violation.drainage === system.id).orderBy('vnum').toRefArray();
    }
);

const getConditions = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.Condition.filter(condition => condition.drainage === system.id).orderBy("cnum").toModelArray().map(condition => {
            const { ref } = condition;
            return {
                ...ref,
                monitor: condition.monitor ? "Yes" : "No"
            }
        });
    }
);

const getBmps = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.Bmp.filter(b => b.drainage === system.id).orderBy("bmpnum").toRefArray();
    }
);

const styles = theme => ({
    header: {
        marginTop: 20,
    },
    mb: {
        marginBottom: 10
    },
    mr: {
        marginRight: 5
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    summaryView: {
        display: "flex",
        flexGrow: 1,
        padding: 8,
        alignItems: "center",
        justifyContent: "space-between"
    },
});

const ActivityTypes = [
    {
        type: "repair",
        title: "Repair",
        key: "repairs",
        excel: "drainagerepair",
        shapefile: "allShapeFilesRepairByDrianage",
        check: ["totalcost_label"],
        data: [
            {id: "actions", numeric: false, label: "", allowSort: false },
            {id: "repair", numeric: false, label: "Repair #", allowSort: true },
            {id: "branch", numeric: false, label: "Branch", allowSort: true },
            {id: "datereceived", numeric: false, label: "Date Created", allowSort: true, date: true },
            {id: "status", numeric: false, label: "Status", allowSort: true },
            {id: "comments", numeric: false, label: "Proposed Work", allowSort: true },
            {id: "township", numeric: false, label: "Township", allowSort: true },
            {id: "section", numeric: false, label: "Section", allowSort: true },
            {id: "name", numeric: false, label: "Landowner", allowSort: true },
            {id: "totalcost_label", numeric: true, label: "Total Invoice Cost", allowSort: true },
        ]
    },
    {
        type: "violation",
        title: "Violation",
        key: "violations",
        excel: "drainageviolation",
        shapefile: "allShapeFilesViolationByDrainage",
        check: ["statuscomply"],
        data: [
            {id: "actions", numeric: false, label: "", allowSort: false },
            {id: "vnum", numeric: false, label: "Violation #", allowSort: true },
            {id: "branch", numeric: false, label: "Branch", allowSort: true },
            {id: "violationdate", numeric: false, label: "Source Date", allowSort: true },
            {id: "statuscomply", numeric: false, label: "Status", allowSort: true },
            {id: "violation_type", numeric: false, label: "Violation Type", allowSort: true },
            {id: "township", numeric: false, label: "Township", allowSort: true },
            {id: "section", numeric: false, label: "Section", allowSort: true },
            {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
            {id: "next_compliance_inspection", numeric: false, label: "Next Scheduled Inspection Date", allowSort: true },
        ]
    },
    {
        type: "condition",
        title: "Condition",
        key: "conditions",
        excel: "drainagecondition",
        shapefile: "allShapeFilesConditionByDrainage",
        check: ["monitor"],
        data: [
            {id: "actions", numeric: false, label: "", allowSort: false },
            {id: "cnum", numeric: false, label: "Condition #", allowSort: true },
            {id: "branch", numeric: false, label: "Branch", allowSort: true },
            {id: "conditiondate", numeric: false, label: "Date Created", allowSort: true },
            {id: "monitor", numeric: false, label: "Monitor", allowSort: true },
            {id: "township", numeric: false, label: "Township", allowSort: true },
            {id: "section", numeric: false, label: "Section", allowSort: true },
            {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
            {id: "notes", numeric: false, label: "Notes", allowSort: true }
        ]
    },
    {
        type: "bmp",
        title: "Bmp",
        key: "bmps",
        excel: "drainagebmp",
        shapefile: "allShapeFilesBmpByDrainage",
        data: [
            {id: "actions", numeric: false, label: "", allowSort: false },
            {id: "bmpnum", numeric: false, label: "BMP #", allowSort: true },
            {id: "branch", numeric: false, label: "Branch", allowSort: true },
            {id: "bmpdate", numeric: false, label: "Date Created", allowSort: true },
            {id: "status", numeric: false, label: "Status", allowSort: true },
            {id: "bmp_type", numeric: false, label: "BMP Type", allowSort: true },
            {id: "township", numeric: false, label: "Township", allowSort: true },
            {id: "section", numeric: false, label: "Section", allowSort: true },
            {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
            {id: "notes", numeric: false, label: "Notes", allowSort: true }
        ]
    }
];

class Activites extends Component {
    state = {
        filters: null,
        confirmExport: false
    }

    createNew = (type) => {
        const { authState, history, system } = this.props;
        
        fetch(`manage/${type}/new/${system.id}`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => result.json())
        .then( data => {
            if(data.detail && (data.detail === "Invalid token." || data.detail === "Token has expired.")){
                this.props.authLogout();
                this.props.history.push("/");
            } else if(data.route){
                history.push(data.route);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    view = (id, type) => {
        const {system} = this.props;
        this.props.history.push(`/${type}/` + id + "/" + system.id + "/" + system.drainage)
    }

    parentStateSet = (type, filters) => {
        this.setState({[`${type}filters`]: filters});
    }

    getSearchStr = (type, leadingChar) => {
        const filters = this.state[`${type}filters`]

        if(filters && filters.column !== "" && filters.filter !== ""){
            return `${leadingChar}column=${filters.column}&searchstring=${filters.filter}`;
        } else {
            return ``;
        }
    }

    checkFilter = (e, type, check, url) => {
        const filters = this.state[`${type}filters`]

        if(filters && check && check.includes(filters.column)){
            e.preventDefault();
            this.setState({confirmExport: true, url: url.split("column")[0]});
        }
    }

    render(){
        const {
            authState, 
            classes, 
            system 
        } = this.props;
        const { confirmExport, url } = this.state;

        const viewOnly = authState.user.role === "View Only";
    
        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system.drainage} />
                <Grid item xs={12} style={{marginTop: 20}}>
                    {ActivityTypes.map(x => (
                        <ExpansionPanel>
                            <ExpansionPanelSummary 
                                className={classes.summary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                    <div className={classes.summaryView}>
                                        <div>
                                            <Typography>
                                                {x.title}
                                            </Typography>
                                        </div>
                                        <div style={{display: "inline-flex"}}>
                                            <div style={{marginRight: 30}}>
                                                <Typography variant="h5">Count: {this.props[`${x.key}`].length}</Typography>
                                            </div>
                                        </div>
                                    </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} md={10} lg={8} xl={6} style={{display: "inline", marginBottom: 10}}>
                                                {!viewOnly && ( 
                                                    <Button onClick={() => this.createNew(x.type)} className={classes.mr} variant="contained" >
                                                        <AddCircleOutlineIcon />
                                                        &nbsp;&nbsp;Create New {x.title}
                                                    </Button>
                                                )}
                                                <Button
                                                    onClick={(e) => this.checkFilter(e, x.type, x.check, `/reports/${x.excel}/${system.id}/${system.drainage}/excel` + this.getSearchStr(x.type, "?"))}  
                                                    variant="contained"
                                                    target="_blank" 
                                                    href={`/reports/${x.excel}/${system.id}/${system.drainage}/excel` + this.getSearchStr(x.type, "?")}
                                                    className={classes.mr}>
                                                    <ExcelIcon /> 
                                                    &nbsp;&nbsp;Export to Excel
                                                </Button>
                                                <Button
                                    onClick={(e) => this.checkFilter(e, x.type, x.check, `/export/shapefile/${x.shapefile}?id=${system.id}${this.getSearchStr(x.type, "&")}`)}
                                                    variant="contained"
                                                    target="_blank" 
                                                    href={`/export/shapefile/${x.shapefile}?id=${system.id}${this.getSearchStr(x.type, "&")}`}>
                                                    <ShapeIcon />
                                                    &nbsp;&nbsp;Export to Shapefile
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <RecordTable
                                        viewOnly={viewOnly} 
                                        columnData={x.data}
                                        data={this.props[x.key]}
                                        viewRecord={(id) => this.view(id, x.type)}
                                        deletable={false}
                                        editable={false}
                                        viewable={true}
                                        parentStateSet={(filters) => this.parentStateSet(x.type, filters)}
                                    />
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        window.open(url);
                        this.setState({ confirmExport: false });
                    }}
                    cancelAction={() => this.setState({ confirmExport: false })}
                    open={confirmExport}
                    cancelText="cancel"
                    confirmText="Export"
                    title="Filter on Column Not Supported"
                    text={"The filter will not be applied to your export are you sure you wish to export?"}
                />
            </Grid>
        )
    }
}

Activites = connect(
    (state, ownProps) => ({
        authState: state.auth,
        repairs: getRepairs(state, ownProps),
        violations: getViolations(state, ownProps),
        conditions: getConditions(state, ownProps),
        bmps: getBmps(state, ownProps),
    }),
    null
)(Activites);

export default withStyles(styles)(withRouter(Activites));