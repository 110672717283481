import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { loadModules } from "esri-loader";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { MAKE_OPTIONS } from '../../api/constants';

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import HelpLabel from "../common/HelpLabel";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, withStyles } from "@material-ui/core";

import CheckBox from "../common/Checkbox";
import Close from "@material-ui/icons/Close";
import { createSelector } from "../common/orm";
import CustomTableCell from '../common/TableCell';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Select from '../common/Select';
import Switch from '../common/Switch';
import RadioGroup from '../common/RadioGroup';
import Subquestion from '../common/Subquestion';
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";
import { FeatureTypes } from "../common/SelectOptions";
import {
    Agol,
    MapLayer,
    MapSetting,
    GISAutoPopulate,
    PictometrySetting
} from '../mypage/models';

const dict = {
    "Repair": 1,
    "Violation": 2,
    "Condition": 3,
    "Bmp": 4
};

const getGISAutoPopulate = GISAutoPopulate.selectFirst();
const getBaseMapLayer = createSelector(schema => {
    return schema.MapLayer.all().filter(x => x.is_basemap).orderBy('order').toModelArray();
});

const getRefenceMapLayer = createSelector(schema => {
    return schema.MapLayer.all().filter(x => !x.is_basemap).orderBy('order').toModelArray();
});

const getMapSetting = createSelector(schema => {
    return schema.MapSetting.first();
});

const getPictometrySetting = createSelector(schema => {
    return schema.PictometrySetting.first();
});

const getServices = createSelector(schema => {
    return schema.Agol.all().orderBy([x => dict[x.type], x => x.geometry_type === "Line"]).toModelArray();
});

const styles = theme => ({
    centerAlign: {
        textAlign: "center"
    },
    noOverFlow: {
        overflowX: "auto"
    },
    mLeft_4: {
        marginLeft: 40
    },
    Bottom: {
        marginBottom: 40,
    },
    mBottom: {
        marginBottom: 40,
        marginTop: 40
    },
    minWidth: {
        minWidth: 400
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    flex: {
        flex: 1
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    space: {
        marginTop: 58
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
});

const baseColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: false, label: "Layer Name", allowSort: true },
    { id: "order", numeric: true, label: "Map Order", allowSort: true },
    { id: "layer_type", numeric: false, label: "Layer Type", allowSort: true },
    //{ id: "on_by_default", bool: true, label: "On By Default", allowSort: true },
    { id: "date_modified", date: true, label: "Modified Date", allowSort: true }
];

const refenceColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: false, label: "Layer Name", allowSort: true },
    { id: "order", numeric: true, label: "Map Order", allowSort: true },
    { id: "layer_type", numeric: false, label: "Layer Type", allowSort: true },
    { id: "on_by_default", bool: true, label: "On By Default", allowSort: true },
    { id: "date_modified", date: true, label: "Modified Date", allowSort: true }
];

const agolColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "type", numeric: false, label: "Feature Type", allowSort: false },
    { id: "geometry_type", numeric: true, label: "Geometry Type", allowSort: false },
    { id: "agol_service", numeric: false, label: "Service", allowSort: false },
    { id: "agol_username", bool: true, label: "Username", allowSort: false },
];

const layerData = [
    { label: "ArcGIS", value: "ArcGIS" },
    { label: "Web Map", value: "Web Map" },
    { label: "Tiled", value: "Tiled" },
    { label: "WMS", value: "WMS" },
];

const baseLayerData = [
    { label: "ArcGIS", value: "ArcGIS" },
    { label: "Tiled", value: "Tiled" },
    { label: "Vector Tile", value: "Vector Tile" },
    { label: "WMS", value: "WMS" },
    { label: "WMTS", value: "WMTS" }
];

const ArcGISType = [
    { label: "Dynamic", value: "Dynamic" },
    { label: "Tiled", value: "Tiled" },
    { label: "Feature", value: "Feature" },
];

// eslint-disable-next-line
const ArcGISBaseType = [
    { label: "Streets", value: "Streets" },
    { label: "Topographic", value: "Topographic" },
    //{label: "Oceans", value: "Oceans"},
    { label: "NationalGeographic", value: "NationalGeographic" },
    { label: "Imagery", value: "Imagery" },
    { label: "Imagery Hybrid", value: "hybrid" },
    { label: "Gray", value: "Gray" },
];

const ArcGISBaseTypeApiKey = [
    { value: "arcgis-imagery", label: "Imagery Hybrid" },
    { value: "arcgis-imagery-standard", label: "Imagery" },
    { value: "arcgis-light-gray", label: "Light Gray" },
    { value: "arcgis-dark-gray", label: "Dark Gray Canvas" },
    { value: "arcgis-navigation", label: "Navigation" },
    { value: "arcgis-navigation-night", label: "Navigation (Night)" },
    { value: "arcgis-streets", label: "Streets" },
    { value: "arcgis-streets-night", label: "Streets (Night)" },
    { value: "arcgis-streets-relief", label: "Streets (with Relief)" },
    { value: "arcgis-topographic", label: "Topographic" },
    { value: "arcgis-oceans", label: "Oceans" },
    { value: "osm-standard", label: "OpenStreetMap" },
    { value: "osm-streets", label: "OpenStreetMap (Streets)" },
    { value: "osm-streets-relief", label: "OpenStreetMap (Streets with Relief)" },
    { value: "osm-light-gray", label: "OpenStreetMap (Light Gray Canvas)" },
    { value: "osm-dark-gray", label: "OpenStreetMap (Dark Gray Canvas)" },
    { value: "arcgis-terrain", label: "Terrain with Labels" },
    { value: "arcgis-community", label: "Community Map" },
    { value: "arcgis-charted-territory", label: "Charted Territory Map" },
    { value: "arcgis-newspaper", label: "Newspaper Map" },
];

const SYSTEMS = [
    {
        label: "System Code",
        value: "field_system"
    }
];

const TOWNSHIPS = [
    {
        label: "Township",
        value: "field_township"
    }
];

const SECTIONS = [
    {
        label: "Range",
        value: "field_range"
    },
    {
        label: "Section",
        value: "field_section"
    },
    {
        label: "Twp",
        value: "field_twp"
    }
];

const COMMISSIONERS = [
    {
        label: "District",
        value: "field_commissioner"
    }
];

const QUARTERS = [
    {
        label: "Quarter",
        value: "field_quarter"
    }
];

const PARCELS = [
    {
        label: "Parcel Number",
        value: "field_parcelnum"
    },
    {
        label: "Address 1",
        value: "field_add_one"
    },
    {
        label: "City",
        value: "field_city"
    },
    {
        label: "Zip",
        value: "field_zip"
    },
    {
        label: "Name",
        value: "field_name"
    },
    {
        label: "Address 2",
        value: "field_add_two"
    },
    {
        label: "State",
        value: "field_state"
    },
];

const IMG_FORMAATS = [
    {
        value: "image/png",
        label: "image/png"
    },
    {
        value: "image/jpeg",
        label: "image/jpeg"
    }
];

class MapSettings extends Component {
    state = {
        activeLayer: { on_by_default: true },
        baseMap: false,
        deleteDialogOpen: false,
        layerDialogOpen: false,
        referenceLayerDialogOpen: false,
        webMapLoading: false,
        webMapError: false,
        webMapLayers: [],
        webMapTitle: null,
        webMapOwner: null,
        baseOrder: "asc",
        baseOrderBy: "order",
        referenceOrderBy: "order",
        referenceOrder: "asc",
        showLayerType: null,

        //auto
        system_options: [],
        township_options: [],
        section_options: [],
        commissioner_options: [],
        quarter_options: [],
        parcel_options: [],


        reset_system_key: false,
        reset_township_key: false,
        reset_section_key: false,
        reset_commissioner_key: false,
        reset_quarter_key: false,
        reset_parcel_key: false,
    };
    MapFormref = React.createRef();
    PicFormref = React.createRef();

    componentWillUnmount = () => {
        const { ormMapSettingUpdateLocalOnly, ormPictometrySettingUpdateLocalOnly } = this.props;
        const mapState = this.MapFormref.current.store.getState();
        const picState = this.PicFormref.current.store.getState();

        if (mapState.values.id) {
            ormMapSettingUpdateLocalOnly({
                ...mapState.values
            });
        }

        if (picState.values.id) {
            ormPictometrySettingUpdateLocalOnly({
                ...picState.values
            });
        }
    }

    componentDidMount = () => {
        const { autopopulate } = this.props;

        this.mounted = true;

        if (autopopulate && autopopulate.system) {
            this.grabFields(autopopulate.system, "system", SYSTEMS)
        }
        if (autopopulate && autopopulate.township) {
            this.grabFields(autopopulate.township, "township", TOWNSHIPS)
        }
        if (autopopulate && autopopulate.section) {
            this.grabFields(autopopulate.section, "section", SECTIONS)
        }
        if (autopopulate && autopopulate.commissioner) {
            this.grabFields(autopopulate.commissioner, "commissioner", COMMISSIONERS)
        }
        if (autopopulate && autopopulate.quarter) {
            this.grabFields(autopopulate.quarter, "quarter", QUARTERS)
        }
        if (autopopulate && autopopulate.parcel) {
            this.grabFields(autopopulate.parcel, "parcel", PARCELS)
        }
    }

    componentWillUnmount = () => {
        this.mounted = false;
    }

    getOptions = (bln) => {
        const { baseLayers } = this.props;
        var temp = baseLayers.filter(x => x.is_basemap === bln).map(x => ({ label: x.name, value: x.name }));
        return temp;
    }

    showLayerType = value => {
        this.setState({ showLayerType: value });
    }

    handleWebMap = value => {
        const _this = this;
        _this.setState({ webMapLoading: true });

        loadModules(
            [
                'esri/WebMap',
            ], { css: true })
            .then(([
                WebMap
            ]) => {
                const webmap = new WebMap({
                    portalItem: {
                        id: value
                    }
                });
                webmap.load()
                    .then(function () {
                        // grab all the layers and load them
                        const allLayers = webmap.allLayers;
                        const promises = allLayers.map(function (layer) {
                            return layer.load();
                        });
                        return Promise.all(promises.toArray());
                    }).then(function (temp_layers) {
                        const layers = temp_layers.reverse().map(x => x.title + "(" + x.type + (x.popupEnabled ? ", Pop-up" : ", No Pop-up") + ")");
                        _this.setState({
                            webMapLayers: layers,
                            webMapLoading: false,
                            webMapError: false,
                            webMapTitle: webmap.portalItem.title,
                            webMapOwner: webmap.portalItem.owner
                        });
                    }).catch(function (error) {
                        _this.setState({ webMapError: true });
                    });
            });
    }

    baseMap = () => {
        const { baseMap } = this.state;
        this.setState({ baseMap: !baseMap });
    }

    ormHandler = (values, create, update, api) => {
        const { saveBad, saveGood } = this.props;

        if (values.id) {
            update({
                id: values.id,
                ...values
            }).then(id => {
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            create({
                ...values
            }).then(id => {
                if (!id) {
                    saveBad();
                } else {
                    if (api) {
                        this[api].setValue("id", id);
                    }
                }
            });
        }
    }

    mapSettingForm = values => {
        const { ormMapSettingCreate, ormMapSettingUpdatePromise } = this.props;
        this.ormHandler(values, ormMapSettingCreate, ormMapSettingUpdatePromise, "settingApi");
    };

    mapLayerForm = values => {
        const { ormMapLayerCreate, ormMapLayerUpdatePromise } = this.props;
        this.setState({ layerDialogOpen: false, referenceLayerDialogOpen: false });

        if (values.layer_type === "Web Map") {
            values.is_basemap = false;
        }

        this.ormHandler(values, ormMapLayerCreate, ormMapLayerUpdatePromise, null);
    };

    pictometryForm = values => {
        const { ormPictometrySettingCreate, ormPictometrySettingUpdatePromise } = this.props;
        this.ormHandler(values, ormPictometrySettingCreate, ormPictometrySettingUpdatePromise, "formApi");
    };

    errorValidator = values => {
        var obj = {};

        ["map_level", "basemap_dashboard", "basemap_other_map", "jurisdiction"].forEach(x => {
            obj[x] = !values[x] ? "Required": null;
        });

        obj.counties = (values.jurisdiction === 'Watershed District' && !values.counties) ? "Required": null;

        return obj;
    }

    handleRequestSort = (event, property, propName) => {
        var columnData = [];
        var newOrder = "";
        var newBy = "";

        if (propName === "baseLayers") {
            newOrder = "baseOrder";
            newBy = "baseOrderBy";
            columnData = baseColumnData;
        } else {
            newOrder = "referenceOrder";
            newBy = "referenceOrderBy";
            columnData = refenceColumnData;
        }

        const orderBy = property;
        let order = "desc";
        if (this.state[newBy] === property && this.state[newOrder] === "desc") {
            order = "asc";
        }


        this.props[propName].sort(function (a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ [newOrder]: order, [newBy]: orderBy });
    }

    errorValidatorLayer = (values) => {
        const checkInt = value => {
            if (isNaN(value)) return "Must be valid number";
            else return null;
        };

        const validateVectorStyle = (value) => {
            if (!value || value === "") {
                return "required";
            }

            try {
                JSON.parse(value);
                return null;
            } catch (error) {
                return "json could not be parsed";
            }
        }

        const checkNumber = (values, value) => {
            if (values.layer_type === "WMS" && !values.is_basemap) {
                if (isNaN(value)) {
                    return "Must be valid number";
                } else if (value > 1 || value < 0) {
                    return "Must be number 0-1";
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

        return {
            order: checkInt(values.order),
            opacity: checkNumber(values, values.opacity),
            style: values.use_style ? validateVectorStyle(values.style) : null
        }
    }

    agolValidator = (values) => {
        const checkEmpty = value => {
            if (value === null || value === "") {
                return "Required";
            } else {
                return null;
            }
        };

        return {
            enterprise_id: values.is_enterprise === "Yes" ? checkEmpty(values.enterprise_id) : null,
            enterprise_secret: values.is_enterprise === "Yes" ? checkEmpty(values.enterprise_secret) : null
        };
    }

    agolForm = (values) => {
        const {
            ormAgolCreate,
            ormAgolUpdatePromise,
            saveBad,
            saveGood
        } = this.props;

        if (values.id) {
            ormAgolUpdatePromise({
                id: values.id,
                ...values
            }).then(id => {
                this.setState({ agolDialog: false });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormAgolCreate({
                ...values
            }).then(id => {
                this.setState({ agolDialog: false });
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
    }

    gisAutoPopulate = (values) => {
        const {
            ormGISAutoPopulateCreate,
            ormGISAutoPopulateUpdatePromise,
            saveBad,
            saveGood
        } = this.props;

        if (!values.id) {
            ormGISAutoPopulateCreate({
                ...values
            }).then(id => {
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                    this.autoApi.setValue('id', id);
                }
            });
        } else {
            ormGISAutoPopulateUpdatePromise({
                id: values.id,
                ...values
            }).then(id => {
                if (!id) {
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
    }

    openReferenceEdit = (l) => {
        this.handleWebMap(l.webmap_id)
        this.setState({
            webMapError: false,
            webMapLoading: true,
            webMapLayers: [],
            referenceLayerDialogOpen: true,
            activeLayer: l,
            showLayerType: l.layer_type
        })
    }


    grabFields = (url, key, fields) => {
        if (!url || (url && url === "")) {
            return;
        }

        fetch(`${url}?f=json`)
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            }).then(data => {
                var arr = [];
                if (data && data.fields) {
                    arr = [];
                    data.fields.forEach(x => {
                        arr.push(x.name);
                    });
                }
                if (this.mounted) {
                    this.setState({
                        [`${key}_options`]: arr,
                        [`reset_${key}_key`]: true
                    });
                }

                if (arr.length === 0 && this.autoApi) {
                    fields.forEach(x => {
                        this.autoApi.setValue(x.value, null);
                    });
                }
            })
            .catch(err => {
                if (this.autoApi) {
                    fields.forEach(x => {
                        this.autoApi.setValue(x.value, null);
                    });
                }

                if (this.mounted) {
                    this.setState({
                        [`${key}_options`]: [],
                        [`reset_${key}_key`]: true
                    });
                }
            });
    }

    resetFieldSelect = (key) => {
        this.setState({ [`reset_${key}`]: true });
    }

    componentDidUpdate = (e) => {
        const keys = [
            "system",
            "township",
            "section",
            "commissioner",
            "quarter",
            "parcel",
        ];
        keys.forEach(x => {
            if (this.state[`reset_${x}_key`] === true) {
                this.setState({ [`reset_${x}_key`]: false });
            }
        });
    }

    tableSelect = ({ x, field, field_options, labe_case }) => {
        if (labe_case === 0) {
            return (
                <Select
                    eventHandle={(val) => this.resetFieldSelect(`${field}_key`)}
                    field={x.value}
                    label={x.label}
                    fullWidth
                    options={MAKE_OPTIONS(field_options)}
                />
            );
        }

        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell padding="checkbox" style={{ borderBottom: "none" }}>
                            <CheckBox
                                style={{ marginTop: 21 }}
                                field={`${x.value}_check`}
                            />
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                            <Select
                                eventHandle={(val) => this.resetFieldSelect(`${field}_key`)}
                                field={x.value}
                                label={x.label}
                                fullWidth
                                options={MAKE_OPTIONS(field_options)}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    featureLayer = ({ formApi, field, label, options, field_options, labe_case }) => {
        const length = options.length;
        return (
            <Grid item xs={12}>
                <TextField
                    eventHandle={(val) => this.grabFields(val, field, options)}
                    field={field}
                    fullWidth
                >
                    {labe_case === 0 && (
                        <HelpLabel
                            inputLabel={label}
                            helpText={<div>This is used for zooming on the map.</div>}
                        />
                    )}
                    {labe_case === 1 && (
                        <HelpLabel
                            inputLabel={label}
                            helpText={<div>This is used for zooming on the map and auto-populating fields in Activity forms.</div>}
                        />
                    )}
                    {labe_case === 2 && (
                        <HelpLabel
                            inputLabel={label}
                            helpText={<div>This is used to auto-populate fields in Activity forms.</div>}
                        />
                    )}
                </TextField>
                {formApi.values[field] && formApi.values[field] !== "" && (
                    <Subquestion
                        component={
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        {options.slice(0, Math.ceil(length / 2)).map(x => (
                                            <this.tableSelect
                                                x={x}
                                                field={field}
                                                field_options={field_options}
                                                labe_case={labe_case}
                                            />
                                        ))}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {options.slice(Math.ceil(length / 2), length).map(x => (
                                            <this.tableSelect
                                                x={x}
                                                field={field}
                                                field_options={field_options}
                                                labe_case={labe_case}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    />
                )}
            </Grid>
        );
    }

    handleStyleChange = (val, formApi) => {
        formApi.setValue("style", null);
        formApi.setValue("layer_url", null);
    }

    getDefault = (l) => {
        var defaultValues = { ...l };
        if (l && l.layer_type === "Vector Tile") {
            if (!l.layer_url || (l.layer_url && l.layer_url === "")) {
                defaultValues.use_style = true;
            }
        }
        return defaultValues;
    }

    checkVector = (t, values) => {
        if (t !== "Vector Tile") {
            return true;
        }

        return !values.use_style;
    }

    render() {
        const {
            classes,
            baseLayers,
            referenceLayers,
            settings,
            ormAgolDeletePromise,
            ormMapLayerDeletePromise,
            pictometry,
            autopopulate,
            services,
            saveBad,
            saveGood
        } = this.props;

        const {
            activeLayer,
            activeAgol,
            agolDeleteDialogOpen,
            agolDialog,
            baseMap,
            deleteDialogOpen,
            layerDialogOpen,
            referenceLayerDialogOpen,
            baseOrder,
            baseOrderBy,
            referenceOrderBy,
            referenceOrder,
            showLayerType,
            webMapLoading,
            webMapLayers,
            webMapError,
            webMapTitle,
            webMapOwner
        } = this.state;

        const baseMapOptions = this.getOptions(true);
        //const identifyOptions = this.getOptions(false);

        const feature_list = services.map(x => x.type);
        const filteredFeatureTypes = FeatureTypes.filter(x => !feature_list.includes(x));

        return (
            <div className={classes.space}>
                <ExpansionPanel defaultExpanded style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>General Map Settings</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Form
                            ref={this.MapFormref}
                            validateError={this.errorValidator}
                            getApi={el => (this.settingApi = el)}
                            defaultValues={settings}
                            dontValidateOnMount={true}
                            onSubmit={this.mapSettingForm}>
                            {formApi => (
                                <form style={{ flex: 1 }} onSubmit={formApi.submitForm}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <TextField field="map_level" name="map_level" fullWidth>
                                                <HelpLabel
                                                    inputLabel="Default Map Level"
                                                    helpText={
                                                        <div>
                                                            The default zoom level your city will appear at.  The recommended range is between 8-16 with 8 being zoomed out for larger cities and 16 being zoomed in for smaller cities.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                            <Select
                                                field="basemap_dashboard"
                                                label="Default Dashboard"
                                                fullWidth
                                                options={baseMapOptions}
                                            />
                                            <Select
                                                field="basemap_other_map"
                                                label="Default Basemap Other Maps"
                                                fullWidth
                                                options={baseMapOptions}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="portal_map_url" name="portal_map_url" fullWidth>
                                                <HelpLabel
                                                    inputLabel="Default Portal Map URL"
                                                    helpText={
                                                        <div>
                                                            The name that will appear in the legend.
                                                        </div>
                                                    }
                                                />
                                            </TextField>
                                            <Select
                                                field="jurisdiction"
                                                label="Account Type"
                                                fullWidth
                                                required={true}
                                                options={[{
                                                    value: "Watershed District",
                                                    label: "Watershed District"
                                                }, {value: "County", label: "County"}]}
                                            />
                                            {formApi.values.jurisdiction === "Watershed District" &&
                                                <TextField
                                                    field="counties"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Counties"
                                                            helpText={<div>Add a list of counties separated by a semi-colon ";".</div>}
                                                        />
                                                    }
                                                    fullWidth
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Pictometry Settings</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Form
                            ref={this.PicFormref}
                            getApi={el => (this.formApi = el)}
                            id="pictometryForm"
                            defaultValues={pictometry}
                            dontValidateOnMount={true}
                            onSubmit={this.pictometryForm}>
                            {formApi => (
                                <form style={{ flex: 1 }} onSubmit={formApi.submitForm}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <CheckBox
                                                field="picometry"
                                                label="Enable Pictometry"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label="API Key" field="api_key" name="api_key" fullWidth />
                                            <TextField label="Secret Key" field="secret_key" name="secret_key" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label="Integrated Pictometry Application Load URL" field="integrated_pictometry_url" name="integrated_pictometry_url" fullWidth />
                                            <TextField label="Integrated Pictometry Application Library" field="integrated_pictometry_library" name="integrated_pictometry_library" fullWidth />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Base Layer Management</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => this.setState({ layerDialogOpen: true, activeLayer: { on_by_default: true, layer_type: "Tiled", is_basemap: true }, showLayerType: "Tiled" })}
                                    variant="contained"
                                    color="primary"
                                    fullWidth>
                                    <AddCircleOutlineIcon />
                                    &nbsp;&nbsp;&nbsp;Add New Layer</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.noOverFlow}>
                                <Table className={classes.minWidth}>
                                    <EnhancedTableHead propName="baseLayers" columnData={baseColumnData} order={baseOrder} orderBy={baseOrderBy} onRequestSort={this.handleRequestSort} />
                                    <TableBody>
                                        {baseLayers.map(l => {
                                            return (
                                                <TableRow hover>
                                                    <CustomTableCell className={classes.noWrap}>
                                                        <Tooltip title="Edit">
                                                            <Button
                                                                onClick={() =>
                                                                    this.setState({ layerDialogOpen: true, activeLayer: this.getDefault(l), showLayerType: l.layer_type })
                                                                }
                                                                className={classes.deleteWidth}>
                                                                <EditIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <Button
                                                                onClick={() => this.setState({ deleteDialogOpen: true, activeLayer: l })}
                                                                className={classes.deleteWidth}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>{l.name}</CustomTableCell>
                                                    <CustomTableCell>{l.order}</CustomTableCell>
                                                    <CustomTableCell>{l.layer_type}</CustomTableCell>
                                                    <CustomTableCell>{l.date_modified}</CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {baseLayers.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                                    No Map Layers Defined
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Reference Layer Management</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => this.setState({
                                        webMapError: false,
                                        webMapLoading: false,
                                        webMapLayers: [],
                                        referenceLayerDialogOpen: true,
                                        activeLayer: { on_by_default: true, layer_type: "Tiled", is_basemap: false }, showLayerType: "Tiled"
                                    })
                                    }
                                    variant="contained"
                                    color="primary"
                                    fullWidth>
                                    <AddCircleOutlineIcon />
                                    &nbsp;&nbsp;&nbsp;Add New Layer</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.noOverFlow}>
                                <Table className={classes.minWidth}>
                                    <EnhancedTableHead propName="referenceLayers" columnData={refenceColumnData} order={referenceOrder} orderBy={referenceOrderBy} onRequestSort={this.handleRequestSort} />
                                    <TableBody>
                                        {referenceLayers.map(l => {
                                            return (
                                                <TableRow hover>
                                                    <CustomTableCell className={classes.noWrap}>
                                                        <Tooltip title="Edit">
                                                            <Button
                                                                onClick={() =>
                                                                    this.openReferenceEdit(l)
                                                                }
                                                                className={classes.deleteWidth}>
                                                                <EditIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <Button
                                                                onClick={() => this.setState({ deleteDialogOpen: true, activeLayer: this.getDefault(l) })}
                                                                className={classes.deleteWidth}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>{l.name}</CustomTableCell>
                                                    <CustomTableCell>{l.order}</CustomTableCell>
                                                    <CustomTableCell>{l.layer_type}</CustomTableCell>
                                                    <CustomTableCell>{l.layer_type === "Web Map" ? "NA" : (l.on_by_default ? "Yes" : "No")}</CustomTableCell>
                                                    <CustomTableCell>{l.date_modified}</CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {referenceLayers.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={6} className={classes.centerAlign}>
                                                    No Map Layers Defined
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>GIS Auto-Populate from GIS Service Configuration</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Form
                            getApi={el => (this.autoApi = el)}
                            id="autopopulate"
                            defaultValues={{
                                ...autopopulate,
                                system_key: "field_system",
                                township_key: "field_township",
                                section_key: "field_range",
                                commissioner_key: "field_commissioner",
                                quarter_key: "field_quarter",
                                parcel_key: "field_parcelnum",
                            }}
                            dontValidateOnMount={true}
                            onSubmit={this.gisAutoPopulate}>
                            {formApi => (
                                <form style={{ flex: 1 }} onSubmit={formApi.submitForm}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                Note: At this time only ESRI Feature Service's are supported.
                                                All feature services must be polygons and shared with public.
                                                All feature service also must be in a Web Mercator projection (Spatial Reference/EPSG: 3857).
                                                If you do not want to auto-populate a field, then leave the service box unchecked.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <this.featureLayer
                                            field="system"
                                            reset_system_key={this.state.reset_system_key}
                                            label="Drainage System"
                                            field_options={this.state.system_options}
                                            options={SYSTEMS}
                                            formApi={formApi}
                                            labe_case={0}
                                        />
                                        <this.featureLayer
                                            field="township"
                                            reset_township_key={this.state.reset_township_key}
                                            label="Township Names"
                                            field_options={this.state.township_options}
                                            options={TOWNSHIPS}
                                            formApi={formApi}
                                            labe_case={1}
                                        />
                                        <this.featureLayer
                                            field="section"
                                            reset_section_key={this.state.reset_section_key}
                                            label="Range, Section, and Twp, and (PLSS)"
                                            field_options={this.state.section_options}
                                            options={SECTIONS}
                                            formApi={formApi}
                                            labe_case={2}
                                        />
                                        {settings["jurisdiction"]==="County" && (
                                            <this.featureLayer
                                                field="commissioner"
                                                reset_commissioner_key={this.state.reset_commissioner_key}
                                                label="Commissioner Districts"
                                                field_options={this.state.commissioner_options}
                                                options={COMMISSIONERS}
                                                formApi={formApi}
                                                labe_case={2}
                                            />
                                        )}
                                        {settings["jurisdiction"]==="Watershed District" && (
                                            <Grid item xs={12}>
                                                <Select
                                                    field="county"
                                                    label="County"
                                                    options={MAKE_OPTIONS(settings["counties"].split(";"))}
                                                    fullWidth
                                                />
                                            </Grid>
                                        )}
                                        <this.featureLayer
                                            field="quarter"
                                            reset_quarter_key={this.state.reset_quarter_key}
                                            label="Quarters"
                                            field_options={this.state.quarter_options}
                                            options={QUARTERS}
                                            formApi={formApi}
                                            labe_case={2}
                                        />
                                        <this.featureLayer
                                            field="parcel"
                                            reset_parcel_key={this.state.reset_parcel_key}
                                            label="Parcels"
                                            field_options={this.state.parcel_options}
                                            options={PARCELS}
                                            formApi={formApi}
                                            labe_case={2}
                                        />
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" fullWidth>Save</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{ marginTop: "16px" }}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>AGOL Feature Service Sync</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => this.setState({ agolDialog: true, activeAgol: null })}
                                    variant="contained"
                                    color="primary"
                                    fullWidth>
                                    <AddCircleOutlineIcon />
                                    &nbsp;&nbsp;&nbsp;Add New ArcGIS Online Service</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.noOverFlow}>
                                <Table className={classes.minWidth}>
                                    <EnhancedTableHead columnData={agolColumnData} onRequestSort={this.handleRequestSortAgol} />
                                    <TableBody>
                                        {services.map(l => {
                                            return (
                                                <TableRow hover>
                                                    <CustomTableCell className={classes.noWrap}>
                                                        <Tooltip title="Edit">
                                                            <Button
                                                                onClick={() =>
                                                                    this.setState({ agolDialog: true, activeAgol: l })
                                                                }
                                                                className={classes.deleteWidth}>
                                                                <EditIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <Button
                                                                onClick={() => this.setState({ agolDeleteDialogOpen: true, activeAgol: l })}
                                                                className={classes.deleteWidth}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>{l.type}</CustomTableCell>
                                                    <CustomTableCell>{l.geometry_type}</CustomTableCell>
                                                    <CustomTableCell>{l.agol_service}</CustomTableCell>
                                                    <CustomTableCell>{l.agol_username}</CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {services.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={5} className={classes.centerAlign}>
                                                    No ArcGIS Online Services Defined
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Dialog open={layerDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Map Layer
                        </Typography>
                        <IconButton onClick={() => this.setState({ layerDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidatorLayer}
                            defaultValues={activeLayer}
                            onSubmit={values => this.mapLayerForm(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="name" fullWidth required={true}>
                                                <HelpLabel
                                                    inputLabel="Layer Name"
                                                    helpText={<div>The name for this layer that will appear in the map legend.</div>}
                                                />
                                            </TextField>
                                            <TextField field="order" fullWidth required={true}>
                                                <HelpLabel
                                                    inputLabel="Map Order"
                                                    helpText={<div>The order in which this layer will appear in the map legend.</div>}
                                                />
                                            </TextField>
                                            <Select
                                                field="layer_type"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Layer Type"
                                                        helpText={
                                                            <div>
                                                                ArcGIS: You have access to an ESRI ArcGIS Server you want to display on the map.
                                                                <br />
                                                                Tiled: There is an external tiled web map you want displayed on the map.
                                                                <br />
                                                                WMS: There is an external WMS service you want displayed on the map.
                                                            </div>
                                                        }
                                                    />
                                                }
                                                fullWidth
                                                eventHandle={value => this.showLayerType(value)}
                                                options={baseLayerData}
                                            />
                                            {showLayerType === "Web Map" && (
                                                <TextField field="webmap_id" fullWidth required={true}>
                                                    <HelpLabel
                                                        inputLabel="Web Map ID"
                                                        helpText={<div>Web Map ID for layers to be pulled from.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {showLayerType === "Vector Tile" && (
                                                <CheckBox
                                                    field="use_style"
                                                    label="Use Style JSON"
                                                    eventHandle={(val) => this.handleStyleChange(val, formApi)}
                                                />
                                            )}
                                            {showLayerType !== "Web Map" && (["WMS", "Tiled", "WMTS"].includes(showLayerType) || !(showLayerType === "ArcGIS" && !baseMap)) &&
                                                this.checkVector(showLayerType, formApi.values)
                                                && (
                                                    <>
                                                        <TextField field="layer_url" fullWidth required={true}>
                                                            <HelpLabel
                                                                inputLabel="Layer URL"
                                                                helpText={<div>Map Services must be in Web Mercator projection (WKID/ESPG 3857).</div>}
                                                            />
                                                        </TextField>
                                                    </>
                                                )}
                                            {(showLayerType === "Vector Tile" && formApi.values.use_style) && (
                                                <TextField
                                                    required={true}
                                                    multiline
                                                    field="style"
                                                    fullWidth
                                                >
                                                    <HelpLabel
                                                        inputLabel="Style JSON"
                                                        helpText={
                                                            <div>
                                                                A style JSON object of vector tiles that will be used to render the layer.
                                                                For more information visit{" "}
                                                                <a
                                                                    target="_blank noopener noreferrer"
                                                                    href="https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-VectorTileLayer.html#currentStyleInfo">
                                                                    Style JSON object
                                                                </a>.
                                                            </div>
                                                        }
                                                    />
                                                </TextField>
                                            )}
                                            {["WMS", "WMTS"].includes(showLayerType) && (
                                                <TextField field="wms_layers" fullWidth required={true}>
                                                    <HelpLabel
                                                        inputLabel={showLayerType === "WMS" ? "WMS Layers" : "Active sublayer"}
                                                        helpText={<div>{showLayerType === "WMS" ? "Comma-separated list of WMS layers to show." : "Active Sublayer."}</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {(showLayerType === "WMS") && (
                                                <Select
                                                    field="wms_format"
                                                    fullWidth
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="WMS Format"
                                                            helpText={<div>Image format to return.  Ex: image/png or image/jpeg</div>}
                                                        />
                                                    }
                                                    options={IMG_FORMAATS}
                                                />
                                            )}
                                            {(showLayerType === "Tiled") && (<>
                                                <TextField field="subdomains" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Subdomains"
                                                        helpText={<div>Subdomains of the tile service - passed through the &#123;s&#125; placeholder in the url.  Leave blank if not sure what this means.</div>}
                                                    />
                                                </TextField>
                                            </>)}
                                            {["WMS", "Tiled", "WMTS"].includes(showLayerType) && (<>
                                                <TextField field="wms_version" fullWidth>
                                                    <HelpLabel
                                                        inputLabel={`${showLayerType} Version`}
                                                        helpText={<div>Version of the {showLayerType} service to use. Use default if not sure.</div>}
                                                    />
                                                </TextField>
                                            </>)}
                                            {(showLayerType === "ArcGIS") && (<>
                                                <Select
                                                    field="arcgis_type"
                                                    label={!baseMap ? "ArcGIS Basemap Type?" : "ArcGIS Service Type"}
                                                    fullWidth
                                                    options={!baseMap ? ArcGISBaseTypeApiKey : ArcGISType} />
                                            </>)}
                                            {(showLayerType === "ArcGIS" && !baseMap === false) && (<>
                                                <TextField field="opacity" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Opacity"
                                                        helpText={<div>The opacity of layer between 0-1.</div>}
                                                    />
                                                </TextField>
                                            </>)}
                                            {false && (
                                                <TextField field="attribution" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Attribution"
                                                        helpText={<div>(Optional) Who this layer should be attributed to. Will display at the bottom of the map when this layer is activiated.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            <Grid item xs={12}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={referenceLayerDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Map Layer
                        </Typography>
                        <IconButton onClick={() => this.setState({ referenceLayerDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidatorLayer}
                            defaultValues={activeLayer}
                            onSubmit={values => this.mapLayerForm(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="name" fullWidth required={true}>
                                                <HelpLabel
                                                    inputLabel="Layer Name"
                                                    helpText={<div>The name for this layer that will appear in the map legend.</div>}
                                                />
                                            </TextField>
                                            <TextField field="order" fullWidth required={true}>
                                                <HelpLabel
                                                    inputLabel="Map Order"
                                                    helpText={<div>The order in which this layer will appear in the map legend.</div>}
                                                />
                                            </TextField>
                                            <Select
                                                field="layer_type"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Layer Type"
                                                        helpText={
                                                            <div>
                                                                ArcGIS: You have access to an ESRI ArcGIS Server you want to display on the map.
                                                                <br />
                                                                Tiled: There is an external tiled web map you want displayed on the map.
                                                                <br />
                                                                WMS: There is an external WMS service you want displayed on the map.
                                                            </div>
                                                        }
                                                    />
                                                }
                                                fullWidth
                                                eventHandle={value => this.showLayerType(value)}
                                                options={layerData}
                                            />
                                            {showLayerType === "Web Map" && (
                                                <TextField
                                                    eventHandle={(value) => this.handleWebMap(value)}
                                                    field="webmap_id"
                                                    fullWidth
                                                    required={true}>
                                                    <HelpLabel
                                                        inputLabel="Web Map ID"
                                                        helpText={<div>Web Map ID for layers to be pulled from.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {showLayerType === "Vector Tile" && (
                                                <CheckBox
                                                    field="use_style"
                                                    label="Use Style JSON"
                                                    eventHandle={(val) => this.handleStyleChange(val, formApi)}
                                                />
                                            )}
                                            {showLayerType !== "Web Map" && (showLayerType === "WMS" || showLayerType === "Tiled" || !(showLayerType === "ArcGIS" && !baseMap)) &&
                                                this.checkVector(showLayerType, formApi.values) && (
                                                    <TextField field="layer_url" fullWidth required={true}>
                                                        <HelpLabel
                                                            inputLabel="Layer URL"
                                                            helpText={<div>Map Services must be in Web Mercator projection (WKID/ESPG 3857).</div>}
                                                        />
                                                    </TextField>
                                                )}
                                            {(showLayerType === "Vector Tile" && formApi.values.use_style) && (
                                                <TextField
                                                    required={true}
                                                    multiline
                                                    field="style"
                                                    fullWidth
                                                >
                                                    <HelpLabel
                                                        inputLabel="Style JSON"
                                                        helpText={
                                                            <div>
                                                                A style JSON object of vector tiles that will be used to render the layer.
                                                                For more information visit{" "}
                                                                <a
                                                                    target="_blank noopener noreferrer"
                                                                    href="https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-VectorTileLayer.html#currentStyleInfo">
                                                                    Style JSON object
                                                                </a>.
                                                            </div>
                                                        }
                                                    />
                                                </TextField>
                                            )}
                                            {showLayerType === "WMS" && (<>
                                                <TextField field="wms_layers" fullWidth required={true}>
                                                    <HelpLabel
                                                        inputLabel="WMS Layers"
                                                        helpText={<div>Comma-separated list of WMS layers to show.</div>}
                                                    />
                                                </TextField>
                                                <Select
                                                    field="wms_format"
                                                    fullWidth
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="WMS Format"
                                                            helpText={<div>Image format to return.  Ex: image/png or image/jpeg</div>}
                                                        />
                                                    }
                                                    options={IMG_FORMAATS}
                                                />
                                            </>)}
                                            {showLayerType === "Tiled" && (
                                                <TextField field="subdomains" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Subdomains"
                                                        helpText={<div>Subdomains of the tile service - passed through the &#123;s&#125; placeholder in the url.  Leave blank if not sure what this means.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {showLayerType === "WMS" && (
                                                <TextField field="wms_version" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="WMS Version"
                                                        helpText={<div>Version of the WMS service to use. Use default if not sure.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {(showLayerType === "ArcGIS") && (<>
                                                <Select
                                                    field="arcgis_type"
                                                    label={!baseMap ? "ArcGIS Basemap Type?" : "ArcGIS Service Type"}
                                                    fullWidth
                                                    options={!baseMap ? ArcGISBaseTypeApiKey : ArcGISType} />
                                            </>)}
                                            {((showLayerType === "ArcGIS" || showLayerType === "WMS") && baseMap === false) && (
                                                <TextField field="opacity" fullWidth required={true}>
                                                    <HelpLabel
                                                        inputLabel="Opacity"
                                                        helpText={<div>The opacity of layer between 0-1.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            {showLayerType !== "Web Map" && (
                                                <Switch
                                                    field="on_by_default"
                                                    name="on_by_default"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="On by Default?"
                                                            helpText={<div>Show this layer right away after the page loads?</div>}
                                                        />
                                                    }
                                                />
                                            )}
                                            {webMapError && (
                                                <div style={{ textAlign: "center" }}>
                                                    <ErrorOutlineIcon color="secondary" />
                                                    <Typography style={{ color: "#990033" }} variant="subtitle1">Error Loading Layers From Web Map Id</Typography>
                                                </div>
                                            )}
                                            {!webMapError && showLayerType === "Web Map" && webMapLoading && (
                                                <div style={{ textAlign: "center" }}>
                                                    <CircularProgress color="primary" />
                                                </div>
                                            )}
                                            {!webMapError && showLayerType === "Web Map" && !webMapLoading && (
                                                <Typography variant="subtitle1">
                                                    <b>Web Map Title:</b> {webMapTitle}
                                                    <br />
                                                    <b>Web Map Owner:</b> {webMapOwner}
                                                    <br />
                                                    <b>Web Map Operation Layers:</b>
                                                    <ul>
                                                        {
                                                            webMapLayers.map(x => (
                                                                <li>{x}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </Typography>
                                            )}
                                            {false && (
                                                <TextField field="attribution" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Attribution"
                                                        helpText={<div>(Optional) Who this layer should be attributed to. Will display at the bottom of the map when this layer is activiated.</div>}
                                                    />
                                                </TextField>
                                            )}
                                            <Grid item xs={12}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={agolDialog} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            ArcGIS Online Service
                        </Typography>
                        <IconButton onClick={() => this.setState({ agolDialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.agolValidator}
                            defaultValues={activeAgol}
                            onSubmit={values => this.agolForm(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select
                                        field="type"
                                        label="Feature Type"
                                        fullWidth
                                        required={true}
                                        disabled={activeAgol}
                                        options={filteredFeatureTypes}
                                    />
                                    <RadioGroup
                                        field="geometry_type"
                                        name="geometry_type"
                                        label="Geometry Type"
                                        options={[
                                            {
                                                label: "Point",
                                                value: "Point"
                                            },
                                            {
                                                label: "Line",
                                                value: "Line"
                                            }
                                        ]}
                                        disabled={activeAgol}
                                        required={true}
                                        alignment={true}
                                    />
                                    <TextField field="agol_service" fullWidth required={true}>
                                        <HelpLabel
                                            inputLabel="Sync Data with ArcGIS Online Service"
                                            helpText={<div>When you add, edit, or delete data within MS4Front have it sync directly with your ArcGIS Online feature service. Ex: https://services2.arcgis.com/rwqARsO7kmPlpMQS/ArcGIS/rest/services/service_e765080ef4ca4aa0937cf8122551a902/FeatureServer/0/</div>}
                                        />
                                    </TextField>
                                    <TextField
                                        field="agol_username"
                                        label="ArcGIS Username"
                                        fullWidth
                                        required={true} />
                                    <TextField
                                        field="agol_password"
                                        label="ArcGIS Password"
                                        fullWidth
                                        required={true} />
                                    <TextField
                                        field="agol_referer"
                                        label="ArcGIS Referrer"
                                        fullWidth
                                        required={true} />
                                    <TextField
                                        field="token_url"
                                        label="Custom Token URL"
                                        fullWidth />
                                    <Switch
                                        field="is_enterprise"
                                        name="is_enterprise"
                                        label="Is ArcGIS Enterprise" />
                                    <TextField
                                        field="enterprise_id"
                                        label="Enterprise ID"
                                        fullWidth />
                                    <TextField
                                        field="enterprise_secret"
                                        label="Enterprise Secret"
                                        fullWidth />
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormMapLayerDeletePromise(activeLayer.id).then(id => {
                            this.setState({ deleteDialogOpen: false });
                            if (!id) {
                                saveBad();
                            } else {
                                saveGood();
                            }
                        });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Layer"
                    text={"Are you sure you wish to permanently delete " + activeLayer.name + "?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormAgolDeletePromise(activeAgol.id).then(id => {
                            this.setState({ agolDeleteDialogOpen: false });
                            if (!id) {
                                saveBad();
                            } else {
                                saveGood();
                            }
                        });
                    }}
                    cancelAction={() => this.setState({ agolDeleteDialogOpen: false })}
                    open={agolDeleteDialogOpen}
                    title="ArcGIS Online Service"
                    text={"Are you sure you wish to permanently delete service?"}
                />
            </div>
        );
    }
}

MapSettings = connect(
    state => ({
        authState: state.auth,
        baseLayers: getBaseMapLayer(state),
        referenceLayers: getRefenceMapLayer(state),
        settings: getMapSetting(state),
        pictometry: getPictometrySetting(state),
        services: getServices(state),
        autopopulate: getGISAutoPopulate(state)
    }),
    {
        ...MapLayer.actions,
        ...MapSetting.actions,
        ...PictometrySetting.actions,
        ...Agol.actions,
        ...GISAutoPopulate.actions
    }
)(MapSettings);

export default withStyles(styles)(withRouter(MapSettings));
