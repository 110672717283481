import React, {Component} from 'react';
import {Form} from "react-form";
import { withStyles, Divider } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getToday, addToForm } from "../../../api/utils";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SaveIcon from '@material-ui/icons/Save';

import HelpLabel from "../../common/HelpLabel";
import WarningDialog from "../../common/WarningDialog";
import Checkbox from "../../common/Checkbox";
import CommonTable from "../../admin/Table";
import DatePicker from "../../common/DatePicker";
import FileFormInput from "../../common/FileFormInput";
import Select from "../../common/Select";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import SnackBarError from '../../common/SnackBarError';
import {ProceedingStatus} from '../../common/SelectOptions';
import {LegalLinks} from '../../common/KeyLinkPair';
import LegalRows from './Rows';
import {DrainageLegalAttach, DrainageLegalDetail} from "../../mypage/models";
import {createSelector} from "../../common/orm"
import { ProceedType} from "../../common/SelectOptions";


// get details by drainage system id and legal id of proceeding
const getDrainageLegalDetail =  createSelector(
    (state, ownProps) => ownProps.system,
    (state, ownProps) => ownProps.id,
    (session, system, id) => {
        return session.DrainageLegalDetail.filter(sys => sys.drainage === system.id && sys.legalid === id).toRefArray();
    }
);

const getDrainageLegalAttach = createSelector(
    (state, ownProps) => ownProps.system,
    (state, ownProps) => ownProps.id,
    (session, SystemName, id) => {
        return session.DrainageLegalAttach.filter(sys => sys.drainage === SystemName.id && sys.legallink_id === id).toRefArray();
    }
);

const header = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "public", bool: true, label: "Public", allowSort: false, type: "check" },
    {id: "caption", numeric: false, label: "Document Title", allowSort: true, type:"text" },
    {id: "type", numeric: false, label: "Legal Type", allowSort: true, type:"select", options: ProceedType },
    {id: "creationdate", numeric: false, label: "Document Date", allowSort: false, type: "date" },
    {id: "year", numeric: false, label: "Year", allowSort: true, type: "text", format: "int" },
    {id: "notes", numeric: false, label: "Notes", allowSort: false, type: "text" },
    //{id: "proceedtype", numeric: false, label: "Legal Proceeding", allowSort: true, type:"text", noEdit: true},
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file" },
    {id: "uploaddate", numeric: false, label: "Upload Date", allowSort: true, type: "date", noEdit: true },
];
const keyData = header.slice(1);


const styles = theme => ({
    space: {
        marginTop: 88
    },
    m: {
        marginTop: 40
    },
    mb: {
        marginBottom: 20
    },
    min: {
        paddingLeft: 20,
        minWidth: 200,
        marginBottom: 20
    },
    flex: {
        display: "inline-flex"
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    highlight: {
        marginRight: 5,
        backgroundColor: "#f2dbdb !important",
        width: 40,
        minHeight: 16,
        border: "1px solid black"
    },
    head: {
        backgroundColor: "#76C0F0 !important",
        border: '1px solid rgb(0 0 0 / 55%)',
        color: "black"
    },
    important: {
        backgroundColor: "#f2dbdb",
    },
    container: {
        overflowX: "auto",
        marginBottom: 20
    },
    right: {
        float:"right"
    },
    tableContainer: {
        maxHeight: 600,
    }
});


class LegalTable extends Component {
    state = {
        active: {},
        fileDialog: false,
        loading: false,
        title: "",
        filetask: 0, 
        delete_id: null,
        snackOpen: false,
        deleteDialog: false,
        confirmUpload: false,
        calculated: ""
    }

    closeFileDialog = id => {
        const {fileDialog} = this.state;
        this.setState({fileDialog: !fileDialog, filetask: id});
    }

    submitForm = (values) => {
        const { 
            back,
            id, 
            ormDrainageLegalUpdatePromise, 
            ormDrainageLegalDetailUpdateBatch,
            saveBad,
            saveGood,
            system
        } = this.props;
        
        /*const obj = proceedings.find(
            x => x.id === id
        );*/
        
        var date_list = [];
        var recentdate = null;
        var orderdate = null;
        values.task.forEach((x, index) => {
            if(values.task[index] && values.task[index].completedate) {
                date_list.push(values.task[index].completedate);
            }   
        });

        date_list.sort((a, b) => {
            return new Date(a) < new Date(b) ? -1 : 1;
        });

        if(date_list.length === 1){
            recentdate = date_list[0];
            orderdate = date_list[0];
        } else if (date_list.length === 2){
            recentdate = date_list[0];
            orderdate = date_list[1];
        } else if (date_list.length > 2){
            recentdate = date_list[0];
            orderdate = date_list[date_list.length - 1];
        }

        ormDrainageLegalUpdatePromise({
            id: id,
            ...values,
            recentdate,
            orderdate
        }).then(id => {
            if(!id){
                saveBad();
            } else {    
                saveGood();
            }
        });

        this.props.updateSystem();

        /*
        values.task.forEach((x, index) => {
            if(values.task[index] && values.task[index].id) {
                ormDrainageLegalDetailUpdatePromise({
                    ...x
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {    
                        saveGood();
                    }
                });
            } else {
                ormDrainageLegalDetailCreate({
                    drainage_id: system.id,
                    legalid_id: id,
                    task: index,
                    ...x
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {    
                        saveGood();
                    }
                });
            }
        });
        */
        var final_list = [];
        values.task.forEach((x, index) => {
            if(values.task[index] && values.task[index].id) {
                final_list.push({
                    ...x,
                    legalid_id: x.legalid,
                    drainage_id: x.drainage
                });
            } else {
                final_list.push({
                    drainage_id: system.id,
                    legalid_id: id,
                    task: index,
                    ...x
                });
            }
        });


        ormDrainageLegalDetailUpdateBatch("api/db/drainagelegals", id, final_list).then(result => {
            if(!!result){
                saveGood();
            } else {    
                saveBad();
            }
            back();
        });
    }

    open = (e,l) => {
        this.setState({fileDialog: true, filetask: l});
    }

    defaultvalues = () => {
        const {details, id, proceedings} = this.props;

        const obj = proceedings.find(
            x => x.id === id
        );

        var defaultValues = {};
        defaultValues["status"] = obj.status;
        defaultValues["proceed_title"] = obj.proceed_title;
        defaultValues["proceed_notes"] = obj.proceed_notes;

        var arr = [];
        details.forEach(x => {
            arr[x.task] = x;
        });

        defaultValues.task = arr;
        return defaultValues;
    }

    attachments = (id) => {
        const {files} = this.props;
        return files.filter(x => x.legallink_id === id);
    }

    handleFile = e => {
        const { 
            id, 
            system, 
            ormDrainageLegalAttachCreateForm, 
            ormDrainageLegalAttachUpdatePromise, 
            proceedings, 
            saveBad, 
            saveGood,
        } = this.props;
        const { edit, filetask, file } = this.state;

        const obj = proceedings.find(
            x => x.id === id
        );

        if(edit){
            delete e["link"];
            ormDrainageLegalAttachUpdatePromise({
                ...e
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({open: false, edit: false});
            });
        } else {
           var form_data = new FormData();
           form_data.append('drainage_id', system.id);
           form_data.append('attachid', filetask);
           form_data.append('legallink_id', id);
           form_data.append('proceedtype', obj.proceedtype);
           form_data.append('uploaddate', getToday());
           form_data.append('link', file, file.name);
           form_data.append('caption', e.caption);

           addToForm(form_data, 'public', e.public)
           addToForm(form_data, 'year', e.year)
           addToForm(form_data, 'type', e.type)
           addToForm(form_data, 'creationdate', e.creationdate)
           addToForm(form_data, 'notes', e.notes)
           this.setState({loading: true});

           ormDrainageLegalAttachCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({open: false, loading: false});
           });
        }

        this.props.updateSystem();
    }

    annualOnClickEdit = (e,l) => {
        this.setState({active: l, edit: true, open: true});
    }

    annualClickDelete  = l => {
        this.setState({deleteDialog: true, delete_id: l.id });
        //const { ormDrainageLegalAttachDelete } = this.props;
        //ormDrainageLegalAttachDelete(l.id);
        //this.props.updateSystem();
    }

    errorValidator = values => {
        const {edit} = this.state;
        const validateFile = link => {
            if(!edit && !link) return "Please select a File."
            else return null
        }

        return {
            link: validateFile(values.link)
        }
    }

    close = () => {
        const { open } = this.state;
        var obj = {};

        obj["edit"] = false;
        obj["open"] = false;

        if(!open){
            obj["fileDialog"] = false;
        }

        this.setState({ ...obj });
    }

    handleFiles = (val, formApi) => {
        const { fileSize } = this.props;

        if(val && (val.size / 1000000 > 250)){
            formApi.setValue("link", null);
            formApi.setValue("lock_submit", false);
            this.setState({file: null, confirmUpload: false});
            fileSize();
        } else if(val && (val.size / 1000000 > 100)){
            formApi.setValue("lock_submit", false);
            this.setState({file: val, confirmUpload: true, calculated: `${Math.round((val.size / 1000000) / 40)} Minutes`})
        } else {
            formApi.setValue("lock_submit", false);
            this.setState({file: val, confirmUpload: false});
        }
    }

    render(){
        const { 
            back, 
            classes, 
            files, 
            id, 
            proceedings, 
            ormDrainageLegalAttachDeletePromise, 
            updateSystem, 
            saveBad, 
            saveGood,
        } = this.props;
        const { 
            active, 
            edit, 
            fileDialog, 
            filetask, 
            loading, 
            open, 
            delete_id, 
            snackOpen, 
            deleteDialog,
            confirmUpload,
            calculated
        } = this.state;

        const obj = proceedings.find(
            x => x.id === id
        );

        var visibleFiles = files ? files.filter( x => x.attachid === filetask) : [];

        return(
            <>
            {obj ?
                <div>
                    <Form
                        dontValidateOnMount={true}
                        defaultValues={this.defaultvalues()}
                        onSubmit={this.submitForm}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                    <ToolBar disableGutters>
                                        <Typography variant="h5" className={classes.grow}>Legal Proceedings</Typography>
                                        <Button 
                                            style={{marginRight: 10}}
                                            type="submit" 
                                            variant="contained" 
                                            color="primary" 
                                            ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                        <Button 
                                            onClick={() => back()}
                                            variant="contained" 
                                            color="secondary" 
                                            ><ExitToAppIcon />&nbsp;&nbsp;Back To Proceedings List</Button>
                                    </ToolBar>
                                <Divider className={classes.mb}/>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Typography><b>Proceeding Type:</b> {obj.proceedtype}</Typography>
                                        <div>
                                            <TextField field="proceed_title" label="Proceeding Title" style={{marginRight: 20}}/>
                                            <Select field="status" options={ProceedingStatus} label="Status" style={{width: 200}}/>
                                        </div>
                                        <TextField field="proceed_notes" label="Proceeding Notes" fullWidth multiline rows={3}/>
                                        <Typography>
                                            More Information on Statue: 
                                            <Link target="_blank" href={LegalLinks[obj.proceedtype].mn}>
                                                ({LegalLinks[obj.proceedtype].label})
                                            </Link>
                                        </Typography>
                                        <Typography>
                                            Public Drainage Manual:
                                            <Link target="_blank" href={LegalLinks[obj.proceedtype].drainage}>
                                                ({obj.proceedtype})
                                            </Link>
                                        </Typography>
                                        <div className={classes.flex}>
                                            <div className={classes.highlight}></div>
                                            <Typography>Highlighted rows should be considered critical or important step and documents needed to adequately document the proceeding.</Typography>   
                                        </div>            
                                    </Grid>
                                    <Grid item xs={12} className={classes.container}>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table stickyHeader aria-label="legal table">
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell className={classes.head} style={{maxWidth: 240}}>Task / Step</TableCell>
                                                        <TableCell className={classes.head} style={{minWidth: 170, maxWidth: 240}}>Statutory / D.M. Reference</TableCell>
                                                        <TableCell className={classes.head} style={{maxWidth: 240}}>Timeline to Complete</TableCell>
                                                        <TableCell className={classes.head} style={{minWidth: 180}}>Date Compeleted</TableCell>
                                                        <TableCell className={classes.head} style={{maxWidth: 240}}>Template Doc</TableCell>
                                                        <TableCell className={classes.head}>Documents Showing Compliance / <span style={{color:"red"}}>Responsible Party</span></TableCell>
                                                        <TableCell className={classes.head}>Upload / View Documents</TableCell>
                                                        <TableCell className={classes.head} style={{minWidth: 200, maxWidth: 400}}>Notes / Missing Documents</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <LegalRows obj={obj.proceedtype} api={formApi} close={this.closeFileDialog} fileViewer={this.open} files={this.attachments(id)}/>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                        </Form>
                        <Dialog open={fileDialog} maxWidth="md" fullWidth>
                            <DialogTitle disableTypography>
                                <Typography variant="h5" className={classes.grow}>Files</Typography>
                                <IconButton disabled={loading} aria-label="close" className={classes.right} onClick={this.close}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent className={classes.dialogMin}>
                                {
                                    open ?
                                    <Form 
                                        validateOnSubmit="true"
                                        dontValidateOnMount={true}
                                        defaultValues={{...active, lock_submit: false}}
                                        validateError={this.errorValidator}
                                        onSubmit={this.handleFile}>
                                        {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Checkbox field="public" label="Public" />
                                                    <TextField field="caption" label="Document Title" fullWidth required={true}/>
                                                    <Select field="type" label="Type" options={ProceedType} fullWidth />
                                                    <DatePicker field="creationdate" label="Document Date" autoPopulate={true} fullWidth/>
                                                    <TextField field="year" label="Year" fullWidth/>
                                                    <TextField field="notes" label="Notes" fullWidth/>
                                                    {!edit && (
                                                        <FileFormInput 
                                                            disabled={loading}
                                                            eventHandle={val => this.handleFiles(val, formApi)} 
                                                            field="link" 
                                                            label="Upload File" 
                                                            id="proceedfileupload"
                                                        />
                                                    )}
                                                    {confirmUpload && (
                                                        <Subquestion
                                                            component={
                                                                <RadioGroup
                                                                    field="lock_submit"
                                                                    name="lock_submit"
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                            title={true}
                                                                            showLabel={true}
                                                                            helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                                        />
                                                                    }
                                                                    fullWidth
                                                                    options={[
                                                                        { label: "Yes", value: "true" },
                                                                        { label: "No", value: "false" }
                                                                    ]}
                                                                    disabled={loading}
                                                                    alignment={true}></RadioGroup>
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                                        Save
                                                        {loading && <CircularProgress size={24} className={classes.progress} />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                        )}
                                        </Form>
                                    :
                                    <>
                                        <>
                                            <Typography variant="h6" style={{color: "red", textAlign: "center", marginBottom: 10}}>
                                                Notice: File Changes are made immediately and require no saving
                                            </Typography>
                                            <Button 
                                                className={classes.mb}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => this.setState({active: {}, link: null, open: true, edit: false, confirmUpload: false})} >
                                                <AddCircleOutlineIcon /> &nbsp;&nbsp;Upload New Document
                                            </Button>
                                            <CommonTable
                                                name="legal_files" 
                                                by="caption"
                                                data={visibleFiles}                
                                                edit
                                                emptyTxt="No Files Defined"
                                                columns={header} 
                                                keys={keyData}
                                                onClickEdit={this.annualOnClickEdit}
                                                onClickDelete={this.annualClickDelete}
                                            />
                                        </>
                                    </>
                                }
                            </DialogContent>
                            </Dialog>
                            <WarningDialog
                                confirmAction={() => {
                                    ormDrainageLegalAttachDeletePromise(delete_id).then(id => {
                                        if(!id){
                                            saveBad();
                                        } else {
                                            saveGood();
                                        }
                                        this.setState({deleteDialog: false});
                                        updateSystem();
                                    });
                                }}
                                cancelAction={() => this.setState({ deleteDialog: false })}
                                open={deleteDialog}
                                title="Delete Legal Attachment"
                                text={"Are you sure you wish to permanently delete?"}
                            />
                            <SnackBarError open={snackOpen} close={e => this.setState({snackOpen: false})} />
                </div>
            :
                <Grid container direction="column" alignItems="center" justify="center">
                    <CircularProgress color="secondary" />
                </Grid> 
            }
            </>
        );
    }
}


LegalTable = connect(
    (state, ownProps) => ({
        authState: state.auth,
        details: getDrainageLegalDetail(state, ownProps),
        files: getDrainageLegalAttach(state, ownProps)
    }),
    {
        ...DrainageLegalDetail.actions,
        ...DrainageLegalAttach.actions
    }
)(LegalTable);

export default withStyles(styles)(withRouter(LegalTable));