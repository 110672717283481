export const options = (data, key) => {
        var arr = data.map(x => { return {"value": x[key], "label": x[key]}});
        return arr;
}

export const handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props["vendors"].sort((a,b) => {
            var aField = a[orderBy];
            var bField = b[orderBy];
    
            if(aField === null){
                return 1;
            } else if(bField === null){
                return -1;
            } else  if(aField === bField){
                return 0;
            }
    
            if (order === "desc"){
                return (bField.toUpperCase() < aField.toUpperCase() ? -1 : 1);
            } else {
                return (aField.toUpperCase() < bField.toUpperCase() ? -1 : 1);
            }
        });
            
        this.setState({ order, orderBy });
};


export const getFormattedDate = () => {
    var d = new Date();
    return [d.getFullYear(), ('0'+(d.getMonth() + 1)).slice(-2), ('0'+(d.getDate())).slice(-2)].join('-');
}