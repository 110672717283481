import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, TableFooter, TablePagination } from "@material-ui/core";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CustomTableCell from './TableCell';
import TablePaginationActionsWrapped from "./Paginator";

import ExpansionPanel from "./ExpansionPanel";
import EnhancedTableHead from "./EnhancedTableHead";

const styles = theme => ({
    minWidth: {
        minWidth: 400,
        marginBottom: 10
    },
    flex:{
        flex: 1
    },
    noOverFlow: {
        overflowX: "auto"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    mB: {
        marginBottom: 10
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    space: {
        marginTop: 88
    },
    centerAlign: {
        textAlign: "center"
    }
});

class RecordTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            order: "asc",
            orderBy: "",
            page: 0,
            rowsPerPage: 25,
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };


    handleRequestSort = (event, property) => {
        const { columnData } = this.props;
        
        // check if _label is in property if so then it formatted field for table 
        // remove _label to get original property name to sort correcty after 
        // determinging what sorting order
        const propertyLabel = property.includes("_label");
        var orderBy = property;

        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const skipLabelReplace = columnData.find(cD => cD.id === property).skipLabelReplace;
        if(!skipLabelReplace){
	        orderBy = orderBy.replace("_label", "");
        }

        this.props.data.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === property).date;
            var numeric = columnData.find(cD => cD.id === property).numeric;
            var bool = columnData.find(cD => cD.id === property).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
            
        if(propertyLabel && !skipLabelReplace){
            orderBy = orderBy + "_label";
        }
        this.setState({ order, orderBy });
    };

    dummyState = () => {
        const { parentStateSet } = this.props;

        // If The Expansion Panel Filters Need be accesed by parent component pass corresponding function
        // to set state in parent.
        if(parentStateSet){
            parentStateSet(this.filters);
        }

        this.setState({ foo: "bar" });
    };

    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    if(x[this.filters.column] && x[this.filters.column].length !== 0)
                        return true;
                    else 
                        return false;
                }
            });
        } else {
            return data;
        }
    }

    renderCell = (x, l) => {
        if(x.link){
            return(
                <>
                    {(l[x.id] ?
                        <a target="_blank noopener noreferrer" href={l[x.id]}>
                            Open File
                        </a>
                        :
                        "-"
                    )}
                </>
            )
        } else if(x.numeric){
            return l[x.id];
        } else {
            return l[x.id] ? l[x.id] : "-";
        }
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            width: 20
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            height: 20
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    RenderDefaultAction = ({ canView, l }) => {
        const { 
            classes, 
            deletable, 
            editable, 
            viewable,
        } = this.props;

        return (
            <>
                {editable && !canView && (
                    <Tooltip title="Edit">
                        <Button
                            onClick={() => { this.props.editRecord(l) } }
                            className={classes.deleteWidth}>
                            <EditIcon color="primary" />
                        </Button>
                    </Tooltip>
                )}
                {deletable && !canView && (
                    <Tooltip title="Delete">
                        <Button
                            onClick={() => { this.props.deleteRecord(l.id) } }
                            className={classes.deleteWidth}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                )}
                {viewable && (
                    <Tooltip title="View">
                        <Button
                            onClick={() => { this.props.viewRecord(l.id) } }
                            className={classes.deleteWidth}>
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                )}
            </>
        );
    }

    RenderCustom = ({ l }) => {
        const { 
            classes,
            role,
            customPermissions,
        } = this.props;

        const editable = customPermissions[role].canEdit(l);
        const deletable = customPermissions[role].canDelete(l);
        const viewable = customPermissions[role].canView(l);

        return (
            <>
                {editable && (
                    <Tooltip title="Edit">
                        <Button
                            onClick={() => { this.props.editRecord(l) } }
                            className={classes.deleteWidth}>
                            <EditIcon color="primary" />
                        </Button>
                    </Tooltip>
                )}
                {deletable && (
                    <Tooltip title="Delete">
                        <Button
                            onClick={() => { this.props.deleteRecord(l.id) } }
                            className={classes.deleteWidth}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                )}
                {viewable && (
                    <Tooltip title="View">
                        <Button
                            onClick={() => { this.props.viewRecord(l.id) } }
                            className={classes.deleteWidth}>
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                )}
            </>
        );
    }

    



    table = ({ canView, tableData }) => {
        const { 
            classes, 
            columnData, 
            customActions, 
            hasCustomPermissions, 
        } = this.props;
        const { order, orderBy, page, rowsPerPage } = this.state;

        return (
            <Table className={classes.minWidth}>
                <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                <TableBody>
                    {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map((l, index) => {
                        return (
                            <TableRow key={l.id + 'Row'}>
                                <CustomTableCell className={classes.noWrap}>
                                    {customActions && (
                                        <>
                                            {customActions.map(x => (
                                                <Tooltip title={x.title}>
                                                    <Button
                                                        onClick={() => { x.event_func(l) } }
                                                        className={classes.deleteWidth}>
                                                        <x.icon color={x.color} />
                                                    </Button>
                                                </Tooltip>
                                            ))}
                                        </>
                                    )}
                                    {!hasCustomPermissions && (<this.RenderDefaultAction canView={canView} l={l} />)}
                                    {hasCustomPermissions && (<this.RenderCustom l={l} />)}
                                </CustomTableCell>
                                {columnData.slice(1).map( x => (
                                    <CustomTableCell className={classes.noWrap} key={l.id + x.id}>
                                        {this.renderCell(x,l)}
                                    </CustomTableCell>
                                ))
                                }
                            </TableRow>
                            );
                        })}
                        {tableData.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                    No Records
                                </CustomTableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        )
    }

    render(){
        const { classes, addName, columnData, creatable, data, noScroll, viewOnly } = this.props;
        const { page, rowsPerPage } = this.state;

        var canView = viewOnly;
        if(typeof(viewOnly) === "undefined"){
            canView = false;
        }

        const tableData = this.filter(data);

        return(
            <Grid container>
                <Grid container spacing={3} className={classes.mB}>
                        <Grid item xs={12}>
                            <ExpansionPanel onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData}/>
                        </Grid>
                        {creatable && !canView && (
                            <Grid item xs={12}>
                                <Button 
                                    onClick={() => this.props.addRecord()}
                                    variant="contained"
                                    color="primary"
                                    fullWidth>
                                    <AddCircleOutlineIcon />
                                    &nbsp;&nbsp;&nbsp;{addName}</Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{overflowX: noScroll && "auto"}}>
                        {!noScroll && (
                            <Scrollbars 
                                renderThumbVertical={this.renderThumb}
                                renderThumbHorizontal={this.renderThumb2}
                                style={{ height: (tableData.length > 8 ? 500 : 300) }}
                                >
                                <this.table canView={canView} tableData={tableData}/>
                            </Scrollbars>
                        )}
                        {noScroll && (
                            <this.table canView={canView} tableData={tableData}/>
                        )}
                </Grid>
                <Grid item xs={12} style={{marginBottom: noScroll && 25}}>
                    <Table>
                        <TableFooter>
                            <TablePagination 
                                colSpan={columnData.length}
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                            />
                        </TableFooter>
                    </Table>
                </Grid>
            </Grid>
        );
    }

    
}


export default withStyles(styles)(withRouter(RecordTable));
