import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';
import Description from "../bmp/Description";
import OverView from "../bmp/OverView";

import { createSelector, reloadByType } from '../common/orm';
import { Bmp, DrainageSystem } from '../mypage/models';

const getBmp = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["bmp"]),
    (session, BmpID) => {
        return session.Bmp.filter(b => b.id === BmpID).toRefArray()[0];
    }
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = theme => ({
  pt: {
    marginTop: '80px',
    backgroundColor: 'white !important',
    color: 'black'
  },
});

class BmpPage extends Component {
    constructor(props){
      super(props);

      this.state = {
        panel: 0,
        fileSize: false,
        mounted: false,
        saveGood: false,
        saveBad: false
      };

      // Load Information For BMP
      if(props.authState && props.authState.user){
        props.reloadByType(props.match.params.bmp, "bmp");
        props.ormDrainageSystemLoadDetail(parseInt(props.match.params.drainage));
        props.ormBmpLoadDetail(props.match.params.bmp);
      }
    }

    handleChange = (event, newValue) => {
      this.setState({panel: newValue});
    };

    componentDidUpdate = () => {
      const { synced } = this.props;
      const { mounted } = this.state;
      if(synced && !mounted){
        this.setState({mounted: true});
      }
    }

    // Successful save
    saveGood = () => {
      this.setState({saveGood: true});
    }
    
    // Bad Save Error
    saveBad = () => {
      this.setState({saveBad: true});
    }

    // Show File To Big Error
    fileSize = () => {
      this.setState({fileSize: true});
    }

    render() {
        const { bmp, classes, synced } = this.props;
        const { fileSize, mounted, panel, saveBad, saveGood } = this.state;

        const system = this.props.match.params["system"];

        return (
            <>
            {bmp &&(
            <AppBar position="fixed" className={classes.pt}>
                    <Tabs value={panel ? panel : 0} onChange={this.handleChange} aria-label="simple tabs example" 
                        scrollButtons="on"
                        variant="scrollable">
                        <Tab label="OverView" {...a11yProps(0)} />
                        <Tab label="Description" {...a11yProps(1)} />
                    </Tabs>
            </AppBar>
            )}
            <AppContainer authenticated adminPage={true}>
                {bmp ?
                  (
                    !synced && !mounted ? 
                      <NotReady />
                      :
                      <>  
                          {panel === 1 && (
                            <Description bmp={bmp} system={system} saveGood={this.saveGood} saveBad={this.saveBad} fileSize={this.fileSize} />
                          )}
                          {/*KEEP EVERY PANEL ABOVE OVERVIEW*/}
                          <OverView panel={panel} style={{display: (panel === 0 ? "": "none")}} bmp={bmp} system={system} saveGood={this.saveGood} saveBad={this.saveBad} />
                          
                          <Snackbar
                            snackbarOpen={saveGood}
                            handleSnackbarClose={() => this.setState({ saveGood: false })}
                            isSuccess
                            operationType="setting"
                        />
                        <SnackBarError open={saveBad} close={e => this.setState({saveBad: false})} />
                        <SnackBarError open={fileSize} close={e => this.setState({fileSize: false})} customMessage="File upload(s) cannot exceed 250mb!" />
                      </>
                  )
                : 
                <>
                  {!synced && (<NotReady />)}
                  {synced && (
                    <>
                      <Typography className={classes.pt} variant="h4" align="center" gutterBottom>The BMP was not found!</Typography>
                      <Grid container direction="column" alignItems="center" justify="center">
                        <Button component={Link} to="/map">Go to DashBoard</Button>
                      </Grid>  
                    </>
                  )}
                </>
                }
            </AppContainer>
            </>
        );
    }
}

BmpPage = connect((state, ownProps) => ({
    authState: state.auth,
    synced: state.sync.ready,
    bmp: getBmp(state, ownProps)
    }),
    {
      ...Bmp.actions,
      ...DrainageSystem.actions,
      reloadByType
    }
)(BmpPage);

export default withStyles(styles)(withRouter(BmpPage));