import React, {Component} from "react";
import { Form } from "react-form";
import { withStyles, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from "@material-ui/icons/Clear";

import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import TextField from '../common/TextField';
import Select from '../common/Select';


const styles = theme => ({
    minWidth: {
        minWidth: 230
    },
    flex: {
        flex: 1
    },
    margin: {
        marginTop: 25
    }
});

class CustomExpansionPanel extends Component{
    constructor(props){
        super(props);

        this.column = "";
        this.filter = "";
    }

    sort = (e, key) => {
        const { dummyState } = this.props;

        //this doesn't work have to select twice
        /*this.setState({
                [key]: e
        });*/

        this[key] = e;
        dummyState();
    }

    componentDidMount = () => {
        this.setState({mounted: true});
        this.props.onRef(this);
    }

    clear = (formApi) => {
        const { dummyState } = this.props;
        formApi.setValue("filter", "")
        formApi.setValue("column", "")
        this.column = "";
        this.filter = "";
        dummyState();
    }

    render(){
        const { classes, columnData, noSlice } = this.props;

        var temp = [...columnData];
        if(!noSlice){
            temp = temp.slice(1);
        }

        const options = temp.map(x => ({
            label: x.label, 
            value: x.id
        }));

        return(
            <ExpansionPanel >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: "#ddd" }}
                    classes={{ root: classes.expandedMargin }}
                    className={classes.titlePanel}>
                        <FilterListIcon />
                        <Typography className={classes.heading}>Filter</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Form
                            className={classes.flex}
                            dontValidateOnMount={true}
                            >
                            {formApi => (
                                 <form style={{flex: 1}} onSubmit={formApi.submitForm}>
                                    <Grid container spacing={6}>
                                        <Grid style={{maxWidth: '100%'}} item xs={2}>
                                            <TextField field="filter" label="Search/Filter" className={classes.minWidth} eventHandle={ (e) => this.sort(e, "filter") }/>
                                        </Grid>
                                        <Grid style={{maxWidth: '100%'}} item xs={2}>
                                            <Select field="column" label="Column" options={options} className={classes.minWidth} eventHandle={ (e) => this.sort(e, "column") }/>
                                        </Grid>
                                        <Grid style={{maxWidth: '100%'}} item xs={2}>
                                            <Tooltip title="Delete Filters" placement="right">
                                                <Fab onClick={ () => this.clear(formApi)} color="secondary" size="medium" className={classes.margin}>
                                                    <DeleteIcon />
                                                </Fab>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                         </Form>
                    </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}


export default withStyles(styles)(CustomExpansionPanel);