import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';
import Attachments from "../page/Attachments";
import Activites from "../page/Activites";
import Description from "../page/Description";
import Financial from "../page/Financial";
import Legal from "../page/LegalProceeding";
import OverView from "../page/OverView";

import { getFormattedDate } from '../common/CommonFunction';
import { createSelector } from '../common/orm';
import { DrainageSystem } from "../mypage/models";

import { reloadByDrainage } from "../common/orm";

const getDrainage = createSelector(
    (state, ownProps) => ownProps.match.params["system"],
    (session, SystemName) => {
        return session.DrainageSystem.filter(sys => sys.drainage === SystemName).toRefArray()[0];
    }
);

const getJointOptions = createSelector(session => {
    return session.JointOptions.all().filter(x => x.allow).orderBy('county').toModelArray().map(x => {
      return x;
    });
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = theme => ({
  pt: {
    marginTop: '80px',
    backgroundColor: 'white !important',
    color: 'black'
  },
});

class Admin extends Component {
    constructor(props){
      super(props);

      this.state = {
        value: new URLSearchParams(props.location.search).get('tab') === 'legalsearch'? 4: 0,
        fileSize: false,
        mounted: false,
        saveGood: false,
        saveBad: false
      };

      if(props.authState && props.authState.user){
        props.ormDrainageSystemLoadDetail(props.match.params.id);
        this.props.reloadByDrainage(props.match.params.id);
      }
    }

    handleChange = (event, newValue) => {
      this.setState({value: newValue});
    };

    updateDrainagSystem = () => {
      const { authState, system, ormDrainageSystemUpdate } = this.props;

      ormDrainageSystemUpdate({
        id: system.id,
        modified_user: authState.user.username,
        modified_date: getFormattedDate()
      })
    }

    componentDidUpdate = () => {
      const { synced } = this.props;
      const { mounted } = this.state;
      if(synced && !mounted){
        this.setState({mounted: true});
      }
    }

    saveGood = () => {
      this.setState({saveGood: true});
    }

    saveBad = () => {
      this.setState({saveBad: true});
    }

    fileSize = () => {
      this.setState({fileSize: true});
    }

    render() {
        const { classes, system, synced, jointOptions } = this.props;
        const { value, fileSize, mounted, saveBad, saveGood } = this.state;
        return (
            <>
            {system && (
            <AppBar position="fixed" className={classes.pt}>
                    <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example" 
                        scrollButtons="on"
                        variant="scrollable">
                        <Tab label="OverView" {...a11yProps(0)} />
                        <Tab label="Description" {...a11yProps(1)} />
                        <Tab label="Financial" {...a11yProps(2)} />
                        <Tab label="Activites" {...a11yProps(3)} />
                        <Tab label="Legal Proceeding" {...a11yProps(7)} />
                        <Tab label="Attachments" {...a11yProps(8)} />
                    </Tabs>
            </AppBar>
            )}
            <AppContainer authenticated adminPage={true}>
                {system ?
                  (
                    !synced && !mounted ? 
                      <NotReady />
                      :
                      <>
                              {value === 1 && (
                                  <Description 
                                    system={system} 
                                    updateSystem={this.updateDrainagSystem} 
                                    saveGood={this.saveGood} 
                                    saveBad={this.saveBad} 
                                    fileSize={this.fileSize}
                                    jointOptions={jointOptions.map(x => ({value: x.ref.id, label: x.ref.county}))}
                                  />
                              )}
                              {value === 2 && (
                                  <Financial 
                                    system={system} 
                                    updateSystem={this.updateDrainagSystem} 
                                    saveGood={this.saveGood} 
                                    saveBad={this.saveBad}
                                    fileSize={this.fileSize}
                                  />
                              )}
                              {value === 3 && (
                                  <Activites 
                                    system={system}
                                     updateSystem={this.updateDrainagSystem} 
                                    saveGood={this.saveGood} 
                                    saveBad={this.saveBad} 
                                    fileSize={this.fileSize}
                                  />
                              )}
                              {value === 4 && (
                                  <Legal system={system} updateSystem={this.updateDrainagSystem} saveGood={this.saveGood} saveBad={this.saveBad} fileSize={this.fileSize}/>
                              )}
                              {value === 5 && (
                                  <Attachments system={system} updateSystem={this.updateDrainagSystem} saveGood={this.saveGood} saveBad={this.saveBad} fileSize={this.fileSize}/>
                              )}
                              {/*KEEP EVERY PANEL ABOVE OVERVIEW*/}
                              <OverView panel={value} style={{display: (value === 0 ? "": "none")}} system={system} updateSystem={this.updateDrainagSystem} saveGood={this.saveGood} saveBad={this.saveBad}/>
                              <Snackbar
                                  snackbarOpen={saveGood}
                                  handleSnackbarClose={() => this.setState({ saveGood: false })}
                                  isSuccess
                                  operationType="setting"
                              />
                              <SnackBarError open={saveBad} close={e => this.setState({saveBad: false})} />
                              <SnackBarError open={fileSize} close={e => this.setState({fileSize: false})} customMessage="File upload(s) cannot exceed 250mb!" />
                      </>
                  )
                : 
                  <>
                    {!synced && (<NotReady />)}
                    {synced && (
                      <>
                        <Typography className={classes.pt} variant="h4" align="center" gutterBottom>The drainage system was not found!</Typography>
                        <Grid container direction="column" alignItems="center" justify="center">
                          <Button component={Link} to="/map">Go to DashBoard</Button>
                        </Grid>  
                      </>
                    )}
                  </>
                }
            </AppContainer>
            </>
        );
    }
}

Admin = connect((state, ownProps) => ({
    authState: state.auth,
    synced: state.sync.ready,
    system: getDrainage(state, ownProps),
    jointOptions: getJointOptions(state, ownProps)
}),
  {
    ...DrainageSystem.actions,
    reloadByDrainage
  }
)(Admin);

export default withStyles(styles)(withRouter(Admin));
