import { BOARD_DATE_UPDATED } from "./manageactions";

export function boardReducer(state = {board_updated: false}, action){
    switch(action.type){
        case BOARD_DATE_UPDATED:
            return{
                ...state,
                board_updated: action.payload
            };
        default:
            return state;
    }
}