import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import { withStyles, IconButton } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

import Collapse from '@material-ui/core/Collapse';
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from '@material-ui/icons/Search';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import GoTo from "./GoTo";
import Select from "../common/Select";
import HelpLabel from '../common/HelpLabel';
import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";
import BaseMapControl from "../common/esrimap/BaseMapControl";
import { DrainageSystemView, Repair } from '../mypage/models'
import { createSelector, reloadAll } from '../common/orm';

import * as authActions from "../auth/actions";
import * as mapActions from "../common/map/actions";

function checkStatusBoard(status, obj, session){
    const temp = session.TrackingBoard.all().filter(x => x.repair === obj.id && x.status === status).toRefArray();
    return temp.length > 0;
}

const getDrainageSystem = DrainageSystemView.selectAllOrder();
const getRepair = createSelector(session => {
    return session.RepairView.all().orderBy('repair').toModelArray().map(repair => {
        return {
            ...repair.ref,
            name: repair.drainage_id ? repair.drainage_id.drainage : "",
            approval: checkStatusBoard("For Approval", repair, session),
            payment: checkStatusBoard("For Payment", repair, session)
       };
    });
}); 
const getViolation = createSelector(session => {
    return session.ViolationView.all().orderBy('vnum').toModelArray().map(violation => {
        return {
            ...violation.ref,
            name: violation.drainage_id ? violation.drainage_id.drainage : "",
            foraction: !violation.inspectiondone && (new Date() < new Date(violation.next_compliance_inspection))
       };
    });
}); 

const getAssignedRepairs = createSelector(session => {
    return session.Contractor.all().toModelArray().map(con => {
        const { ref } = con;
        const repair = session.RepairView.filter(r => r.id === ref.repair).first();
        var r = {};
        if(repair){
            r = {...repair};
            if(repair.drainage_id){
                r.name = repair.drainage_id.drainage;
            }
        }
        return {
            ...ref,
            ...r,
        };
    });
});

const styles = theme => ({
    horizontal: {
        flexDirection: "row"
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    bNone: {
        '& td': {
            border: "none"
        }
    },
    pl: {
        paddingLeft: 8
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(4)
    }, 
    overviewPrjname: {
        cursor: "pointer",
        color: "#76c0f0",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: 14
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        marginTop: 80,
        width: 342,
        height: "calc(100% - 50px)",
        left: 0,
        right: "auto"
    },
    hiddenPaper: {
        marginTop: 80,
        width: 50,
        height: "calc(100% - 50px)",
        left: 0,
        right: "auto",
        overflow: "hidden"
    },
    box: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 10
    },
    list: {
        padding: 0,
        borderBottom: "none"
    },
    list2: {
        padding: 4,
        borderBottom: "none"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    hiddenListItem: {
        height: "100%"
    },
    ListTextInset: {
        paddingLeft: 15
    },
    Chip: {
        minWidth: 23.9,
        textAlign: "center"
    },
    number: {
        [theme.breakpoints.down("sm")]: {
            marginRight: 15
        }
    }
});

class MapPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            snackbarOpen: false,
            drainage: null,
            ready: false,
            hidden: false,

            
            boardNav: true,
            goToNav: true,
            listNav: true,
            reportNav: true,
        }

        this.props.reloadAll();
    }

    componentDidUpdate = () => {
        const { sync } = this.props;
        const { ready } = this.state;

        if(sync && sync.ready && !ready){
            this.setState({ready: true});
        }
    }
    
    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8",
            zIndex: "9999"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    generateReport = (formApi, url) => {
        formApi.setValue("report", null);
        window.open(url);
    }

    navigationClick = () => {
        const { hidden } = this.state;
        if(this.editChild && this.editChild.addCollapse){
            if(!hidden){
                this.editChild.addCollapse();
            }
            this.setState({ hidden: !hidden });
        }
    }
    

    boardNavClick = () => {
        const { boardNav } = this.state;
        this.setState({boardNav: !boardNav});
    }
    goToNavClick = () => {
        const { goToNav } = this.state;
        this.setState({goToNav: !goToNav});
    }
    listsNavClick = () => {
        const { listNav } = this.state;
        this.setState({listNav: !listNav});
    }
    reportNavClick = () => {
        const { reportNav } = this.state;
        this.setState({reportNav: !reportNav});
    }

    render() {
        const { 
            authState,
            classes, 
            repairs, 
            assigned,
            sync, 
            systems, 
            violations 
        } = this.props;
        const { hidden, ready } = this.state;

        if((!sync || sync) && (!sync.ready || !ready)){
            return (
                <AppContainer authenticated adminPage={true}>
                    <NotReady />
                </AppContainer>
            );
        }


        const MUIListItemText = ({...rest}) => {
            const { classes } = this.props;
            return (
                <ListItemText
                    classes={{
                        inset: classes.ListTextInset
                    }}
                    {...rest}
                />
            );
        }

        const MUINumber = ({label, ...rest}) => {
            return (
                <Typography className={classes.number} {...rest}>
                    <b>{label}</b>
                </Typography>
            );
        }

        const contractor = authState && authState.user && authState.user.role === "Contractor";
        if(contractor){
            return (
                <AppContainer authenticated >
                    {!hidden && (
                        <Drawer
                            variant="permanent"
                            anchor="left"
                        >
                            <Paper className={classes.paper} elevation={0} square >
                                <Scrollbars
                                    style={{height: "calc(100%)"}}
                                    renderThumbVertical={this.renderThumb}
                                    renderThumbHorizontal={this.renderThumb2}>
                                    <GoTo 
                                        navigationClick={this.navigationClick}
                                        goToNavClick={this.goToNavClick}
                                        goToNav={this.state.goToNav}
                                    />
                                    <List
                                        dense={true}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className={classes.root}
                                        >
                                            <ListItem
                                                title="Lists"
                                            >
                                                <MUIListItemText
                                                    primary="Lists"
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                component={Link}
                                                to="/list/repair/assigned">
                                                <MUIListItemText
                                                    inset
                                                    primary="All Repairs Assigned to Me"
                                                />
                                                <MUINumber 
                                                    label={repairs.length}
                                                    style={{marginRight: 20}}
                                                />
                                            </ListItem>
                                    </List>
                                </Scrollbars>
                            </Paper>
                        </Drawer>
                    )}
                    <Grid 
                        container 
                        style={{
                            marginLeft: hidden ? 0 : "inherit",
                            marginTop: hidden ? -48 : -80, 
                            height: "calc(100% + 31px)", 
                            width: "calc(100%)"
                        }}
                    >
                        <Grid 
                            xs
                            item 
                            style={{
                                marginLeft: hidden ? 0 : 342,
                            }}
                            id="leaflet-control-noclic"
                        >
                            <BaseMapControl 
                                ref2={r => this.editChild = r} 
                                navigationClick={this.navigationClick}
                                style={{marginTop: 10}} 
                            />
                        </Grid>
                    </Grid>
                </AppContainer>
            )
        }

        const Board = () => (
            <List
                dense={true}
                component="nav"
                className={classes.root}
                >
                    <ListItem
                        onClick={this.boardNavClick}
                        title="Board Approval"
                        button
                    >
                        <MUIListItemText
                            primary="Board Approval"
                        />
                        {this.state.boardNav ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.boardNav} timeout="auto" unmountOnExit>
                        <ListItem
                            button
                            component={Link}
                            to="/list/approval">
                            <MUIListItemText
                                inset
                                primary="Repairs - For Approval"
                            />
                            <MUINumber 
                                style={{marginRight: 20}}
                                label={repairs.filter(x => x.approval).length}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="Repairs - For Approval Report"
                                    target="_blank" 
                                    href="/report/list/project?status=For Approval"
                                    >
                                    <AssignmentIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/list/payment">
                            <MUIListItemText
                                inset
                                primary="Repairs - For Payment"
                            />
                            <MUINumber 
                                style={{marginRight: 20}}
                                label={repairs.filter(x => x.payment).length}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="Repairs - For Payment Report"
                                    target="_blank" 
                                    href="/report/list/project?status=For Payment"
                                    >
                                    <AssignmentIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to="/list/violation">
                            <MUIListItemText
                                inset
                                primary="Violations - For Action"
                            />
                            <MUINumber 
                                style={{marginRight: 20}}
                                label={violations.filter(x => x.foraction).length}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    aria-label="Violations - For Action Report"
                                    target="_blank" 
                                    href="/report/list/violation"
                                    >
                                    <AssignmentIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Collapse>
            </List>
        )

        const Lists = () => (
            <List
                dense={true}
                component="nav"
                className={classes.root}
                >
                <ListItem
                    onClick={this.listsNavClick}
                    title="Lists"
                    button
                >
                    <MUIListItemText
                        primary="Lists"
                    />
                    {this.state.listNav ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.listNav} timeout="auto" unmountOnExit>
                    <ListItem
                        button
                        component={Link}
                        to="/list/all/systems">
                        <MUIListItemText
                            inset
                            primary="All Drainage Systems"
                        />
                        <MUINumber 
                            label={systems.length}
                        />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/list/repair/review">
                        <MUIListItemText
                            inset
                            primary="Repairs - For Review"
                        />
                        <MUINumber 
                            label={repairs.filter(x => x.status === "For Review").length}
                        />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/list/repair/progress">
                        <MUIListItemText
                            inset
                            primary="Repairs - In Progress"
                        />
                        <MUINumber 
                            label={repairs.filter(x => x.status === "In Progress").length}
                        />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/list/repair/assigned">
                        <MUIListItemText
                            inset
                            primary="Repairs - Assigned to a Contractor"
                        />
                        <MUINumber 
                            label={assigned.filter(a => a.status === "For Review" || a.status === "In Progress").length}
                        />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/list/violation/true">
                        <MUIListItemText
                            inset
                            primary="Violations - Open"
                        />
                        <MUINumber 
                            label={violations.filter(x => !x.inspectiondone).length}
                        />
                    </ListItem>
                </Collapse>
            </List>
        )

        const Report = () => (
            <List
                dense={true}
                component="nav"
                className={classes.root}
                >
                <ListItem
                    onClick={this.reportNavClick}
                    title="Reports"
                    button
                >
                    <MUIListItemText
                        primary="Reports"
                    />
                    {this.state.reportNav ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.reportNav} timeout="auto" unmountOnExit>
                    <ListItem button component={Link} to="/activitysearch">
                        <MUIListItemText inset primary="Activity Search/Report" />
                        <ListItemIcon >
                            <SearchIcon />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem button component={Link} to="/annual/inspection">
                        <MUIListItemText inset primary="Annual Inspection Report" />
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem button component={Link} to="/legalsearch">
                        <MUIListItemText inset primary="Legal Proceeding Search" />
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                    </ListItem>
                    <ListItem>
                        <Form>
                            {formApi => (
                                <form style={{flex: 1}}onSubmit={formApi.submitForm}>
                                    <Select
                                        eventHandle={(url) => this.generateReport(formApi, url)}
                                        options={[
                                            {
                                                label: "Active Legal Proceedings",
                                                value: "/reports/drainage/system/active/legal/excel"
                                            },
                                            {
                                                label: "Drainage System Financial Report",
                                                value: "/reports/drainage/system/all/financial/excel"
                                            },
                                            {
                                                label: "Joint Drainage Systems",
                                                value: "/reports/drainage/system/joint/excel"
                                            }
                                        ]} 
                                        field="report" 
                                        label = {
                                            <HelpLabel
                                                inputLabel="Choose a report"
                                                helpText={<div>The report will be generated on selection.</div>}
                                            />
                                        } 
                                    fullWidth/>
                                </form>
                            )}
                        </Form>
                    </ListItem>
                </Collapse>
            </List>
        )

        return (
            <AppContainer authenticated>
                {!hidden && (
                    <Drawer
                        variant="permanent"
                        anchor="left"
                    >
                        <Paper className={classes.paper} elevation={0} square >
                        <Scrollbars
                            style={{height: "calc(100%)"}}
                            renderThumbVertical={this.renderThumb}
                            renderThumbHorizontal={this.renderThumb2}>
                            <GoTo 
                                navigationClick={this.navigationClick}
                                goToNavClick={this.goToNavClick}
                                goToNav={this.state.goToNav}
                            />
                            <Lists />
                            <Board />
                            <Report />
                            </Scrollbars>
                        </Paper>
                    </Drawer>
                )}
                <Grid 
                    container 
                    style={{
                        marginLeft: hidden ? 0 : "inherit",
                        marginTop: hidden ? -48 : -80, 
                        height: "calc(100% + 31px)", 
                        width: "calc(100%)"
                    }}
                >
                    <Grid 
                        xs
                        item 
                        style={{
                            marginLeft: hidden ? 0 : 342,
                        }}
                        id="leaflet-control-noclic"
                    >
                        <BaseMapControl 
                            ref2={r => this.editChild = r} 
                            navigationClick={this.navigationClick}
                            style={{marginTop: 10}} 
                        />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

MapPage = connect(
    (state, ownProps) => ({ 
        sync: state.sync,
        authState: state.auth,
        systems: getDrainageSystem(state, ownProps),
        repairs: getRepair(state, ownProps),
        assigned: getAssignedRepairs(state, ownProps),
        violations: getViolation(state, ownProps),
    }),
    {
        ...mapActions,
        ...Repair.actions,
        ...authActions,
        reloadAll
    }
)(MapPage);

export default withStyles(styles)(withRouter(MapPage));
