/******************************************************************************
 * Person requesting repair and landowner documentation.
 *
 * Author: Mitchell Lane
 * Date: 1/7/20
 *******************************************************************************/
import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import {Form} from "react-form";
import { connect } from "react-redux";

import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import Select from "../common/Select";
import SnackBarError from '../common/SnackBarError';

import {PersonRequestingType} from "../common/SelectOptions";

import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';

import { createSelector } from '../common/orm';
import PageHeader from '../common/PageHeader';
import {Peoplelandowner, PeopleRequest, Repair} from "../mypage/models";
import { panelChange } from "../common/actions";

const getOwner = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.Peoplelandowner.filter(sys => sys.repair === repair).orderBy("id").toRefArray()[0];
    }
);

const getRequest = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.PeopleRequest.filter(sys => sys.repair === repair).orderBy("id").toRefArray()[0];
    }
);

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
});

const FieldData = ["name", "organization", "add1", "add2", "city","state", "zip", "comments", "phone", "fax", "mobile", "email"];


class People extends Component {
    state = { 
        invalid: false,
        submit: false,
        saveClicked: false
    }
    Formref = React.createRef();

    componentWillUnmount = (panel) => {
        const { ormPeoplelandownerCreateLocalOnly, ormPeopleRequestCreateLocalOnly, ormPeoplelandownerUpdateLocalOnly, ormPeopleRequestUpdateLocalOnly, repair } = this.props;
        this.props.panelChange(panel);
        const values = this.Formref.current.store.getState().values;

        var owner = {};
        var person = {};

        FieldData.forEach(x => {
            person[x] = values["person-" + x];
            owner[x] = values["owner-" + x];
        });

	    person["id"] = values["person-id"];
	    owner["id"] = values["owner-id"];
        person["type"] = values["person-type"];
        owner["typesame"] = values["owner-typesame"] ? true : false;

        // Using negative ID's works becuase it is cleared every time navigation to repair

        if(values["owner-id"]){
            ormPeoplelandownerUpdateLocalOnly({
		        repair: repair.id,
                ...owner
            });
        }
        else {
            ormPeoplelandownerCreateLocalOnly({
		        id: -1,
                repair: repair.id,
                drainage_id: repair.drainage,
                ...owner
            });
        }

        if(values["person-id"]){
            ormPeopleRequestUpdateLocalOnly({
		        repair: repair.id,
                ...person,
            });
        }
        else {
            ormPeopleRequestCreateLocalOnly({
                id: -2,
                repair: repair.id,
                drainage_id: repair.drainage,
                ...person,
            });
        }

    }

    save = values => {
        const { fullRepairUpdate, repair } = this.props;
        var owner = {};
        var person = {};

        FieldData.forEach(x => {
            person[x] = values["person-" + x];
            owner[x] = values["owner-" + x];
        });
	    person["type"] = values["person-type"];
        person["id"] = values["person-id"];
        owner["id"] = values["owner-id"];
        owner["typesame"] = values["owner-typesame"] ? true : false;
        this.setState({saveClicked: true});
        fullRepairUpdate(repair, owner, person);
    }

    validateError = values => {
        const { submit } = this.state;
        var triggeredDialog = false;

        const validateEmail = value => {
            if (value) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(value)){
                    //if submit button was clicked and it hasn't been triggered do so. This prevents constant dialog with always validating.
                    if(submit && !triggeredDialog){
                        this.setState({invalid: true});
                    } else {
                        triggeredDialog = true;
                    }
                    return "Invalid email address";
                } else {
                    return null;
                }
            }
            return null;
        }

        const validatePhoneAndFax = value => {
            if(value){
                var re = /^\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/;
                if(!re.test(value)){
                    //if submit button was clicked and it hasn't been triggered do so. This prevents constant dialog with always validating.
                    if(submit && !triggeredDialog){
                        this.setState({invalid: true});
                    } else {
                        triggeredDialog = true;
                    }
                    return "Invalid phone number format (10 numbers no slashes)";
                } else {
                    return null;
                }
            }
            return null;
        }

        const temp = {
            "person-email": validateEmail(values["person-email"]),
            "owner-email": validateEmail(values["owner-email"]),
            "person-fax": validatePhoneAndFax(values["person-fax"]),
            "owner-fax": validatePhoneAndFax(values["owner-fax"]),
            "person-phone": validatePhoneAndFax(values["person-phone"]),
            "owner-phone": validatePhoneAndFax(values["owner-phone"]),
            "person-mobile": validatePhoneAndFax(values["person-mobile"]),
            "owner-mobile": validatePhoneAndFax(values["owner-mobile"]),
        }

        if(submit){
            this.setState({submit: false});
        }
        return temp;
    }

    //This allows checkbox to copy values from landowner to person requesting
    change = (t, e) => {
        if(typeof(e) !== "undefined")
        {
            if(!this.state.d){
                FieldData.forEach(function(x){
                    var temp = e.getValue("owner-" + x);
                    e.setValue("person-" + x, temp);
                });
            } else {
                FieldData.forEach(function(x){
                    e.setValue("person-" + x, "");
                });
            }
        }
        var temp = this.state.d
        this.setState({d: !temp});
    }

    defaultValues = () => {
        const {owner, person} = this.props;
        var defaultValues = {}
        
        if(owner){
            Object.keys(owner).forEach( x => {
                defaultValues["owner-" + x] = owner[x];
            });
            defaultValues["owner-typesame"] = owner.typesame;
        }

        if(person)
        {
            Object.keys(person).forEach( x => {
                defaultValues["person-" + x] = person[x];
            });
            defaultValues["person-type"] = person.type;
        } else {
            defaultValues["person-type"] = "Landowner";
        }
        return defaultValues;

    }

    componentDidUpdate(prevProps, prevState) {
        const { dont, saveClicked } = this.state;

        if(dont){
            this.setState({dont: false});
        }

        var changed = {};
        Object.entries(this.props).forEach(([key, val]) => {
            if(prevProps[key] !== val) {
                console.log(`Prop '${key}' changed`)
                changed[key] = true;
            }
        });

        //form doesn't upate if prop owner changes after componentDidMount so don't render to force reset of default values.  
        if(changed["owner"] && !saveClicked){
            this.setState({dont: true});
        } else if (changed["owner"] && saveClicked){
            this.setState({saveClicked: false});
        }

      }

    render(){
        const { authState, classes, repair, system } = this.props;
        const { dont, invalid } = this.state;

        const viewOnly = authState.user.role === "View Only";
        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                {!dont && (
                <Form 
                    validateError={this.validateError}
                    defaultValues={this.defaultValues()}
                    onSubmit={(values) => this.save(values)}
                    ref={this.Formref}
                    getApi={el => (this.formApi = el)}>
                    {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <ToolBar disableGutters>
                            <Typography variant="h5" className={classes.grow}>Person Requesting Repair</Typography>
                            <Button
                                onClick={() => this.setState({submit: true})}
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                style = {{float: "right", display: viewOnly && "none "}}
                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                        </ToolBar>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={3} className={classes.mb}>
                            <Grid item xs={6}>
                                <Select options={PersonRequestingType} field="person-type" label="Person Requesting Repair" fullWidth disabled={viewOnly}/>
                                <TextField maxWords={255} field="person-name" label="Name" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-organization" label="Organization" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-add1" label="Address 1" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-add2" label="Address 2" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-city" label="City" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-state" label="State" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="person-zip" label="Zip" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.mt}>
                                    <Checkbox field="owner-typesame" label="Same as Landowner Below" eventHandle={ e => this.change(e, this.formApi)} disabled={viewOnly}/>
                                    <TextField field="person-phone" label="Telephone" fullWidth disabled={viewOnly}></TextField>
                                    <TextField field="person-fax" label="Fax" fullWidth disabled={viewOnly}></TextField>
                                    <TextField field="person-mobile" label="Mobile" fullWidth disabled={viewOnly}></TextField>
                                    <TextField field="person-email" label="E-Mail" fullWidth disabled={viewOnly}></TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField field="person-comments" label="Notes" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" className={classes.grow}>Landowner</Typography>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField maxWords={255} field="owner-name" label="Name" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-organization" label="Organization" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-add1" label="Address 1" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-add2" label="Address 2" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-city" label="City" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-state" label="State" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-zip" label="Zip" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField field="owner-phone" label="Telephone" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-fax" label="Fax" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-mobile" label="Mobile" fullWidth disabled={viewOnly}></TextField>
                                <TextField field="owner-email" label="E-Mail" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField field="owner-comments" label="Notes" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                        </Grid>
                    </form>
                    )}
                </Form>
                )}
                <SnackBarError open={invalid} close={e => this.setState({invalid: false})} customMessage="Please fix values with red!" />
            </div>
        );
    }
}

People = connect(
    (state, ownProps) => ({
        authState: state.auth,
        owner: getOwner(state, ownProps),
        person: getRequest(state, ownProps)
    }),
    {
        ...Peoplelandowner.actions,
        ...PeopleRequest.actions,
        ...Repair.actions,
        panelChange
    }
)(People);

export default withStyles(styles)(withRouter(People));
