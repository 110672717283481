export const Status = [
    {value: "Active", label: "Active"},
    {value: "Abandoned", label: "Abandoned"},
    {value: "Consolidated", label: "Consolidated"},
    {value: "Dismissed", label: "Dismissed"},
    {value: "Transferred", label: "Transferred"},
    {value: "Vacated", label: "Vacated"}
];

export const DocumentTypes = [
    {label: "Memo", value: "Memo"},
    {label: "Design Specification", value: "Design Specification"},
    {label: "Letter", value: "Letter"},
    {label: "Other", value: "Other"}
];

export const LegalType = [
    {value: null, label: "-- Empty --"},
    {value: "County", label: "County"},
    {value: "Judicial", label: "Judicial"},
    {value: "Joint", label: "Joint"}
];

export const DrainageSystem = [
    {value: null, label: "-- Empty --"},
    {value: "Open", label: "Open"},
    {value: "Tile", label: "Tile"},
    {value: "Both", label: "Both"}
];

export const Units = [
    {value: "Miles", label: "Miles"},
    {value: "Feet", label: "Feet"},
];

export const InpsectionType = [
    {value: "Annual", label: "Annual"},
    {value: "Detailed", label: "Detailed"},
];

export const FeatureTypes = [
    {value: "Repair", label: "Repair"},
    {value: "Violation", label: "Violation"},
    {value: "Condition", label: "Condition"},
    {value: "Bmp", label: "Bmp"},
];

export const ProceedType = [
    {value: "Legal documents", label: "Legal documents"},
    {value: "Viewers report - benefit redetermination", label: "Viewers report - benefit redetermination"},
    {value: "Viewers report - Establishment", label: "Viewers report - Establishment"},
    {value: "Commissioner preliminary advisory report", label: "Commissioner preliminary advisory report"},
    {value: "Commissioner final advisory report", label: "Commissioner final advisory report"},
    {value: "Benefitted parcels", label: "Benefitted parcels"},
    {value: "Petition - Abandonment", label: "Petition - Abandonment"},
    {value: "Petition - Establishment", label: "Petition - Establishment"},
    {value: "Petition - Repair", label: "Petition - Repair"},
    {value: "Petition - Request - Maintenance", label: "Petition - Request - Maintenance"},
    {value: "Petition - Improvement", label: "Petition - Improvement"},
    {value: "Petition - Extension", label: "Petition - Extension"},
    {value: "Petition - Laterals", label: "Petition - Laterals"},
    {value: "Petition - Realignment", label: "Petition - Realignment"},
    {value: "Order - Establishment", label: "Order - Establishment"},
    {value: "Order - Repair", label: "Order - Repair"},
    {value: "Order - Improvement", label: "Order - Improvement"},
    {value: "Order - Abandonment and Partial Abandonment", label: "Order - Abandonment and Partial Abandonment"},
    {value: "Order - Court - Legal Proceedings", label: "Order - Court - Legal Proceedings"},
    {value: "Order - Extension", label: "Order - Extension"},
    {value: "Order - Laterals", label: "Order - Laterals"},
    {value: "Order - Diversion and Impoundments", label: "Order - Diversion and Impoundments"},
    {value: "Other Order", label: "Other Order"},
    {value: "Report - Inspection", label: "Report - Inspection"},
    {value: "Report - Engineers", label: "Report - Engineers"},
    {value: "Report - Repair", label: "Report - Repair"},
    {value: "Report - Survey", label: "Report - Survey"},
    {value: "Report - Viewers", label: "Report - Viewers"},
    {value: "Resolution", label: "Resolution"},
    {value: "Action Summary Forms", label: "Action Summary Forms"},
    {value: "Plans - Drawing - Establishment - Location Maps - Plan View", label: "Plans - Drawing - Establishment - Location Maps - Plan View"},
    {value: "Plans - Drawing - Establishment - Cut Sheets", label: "Plans - Drawing - Establishment - Cut Sheets"},
    {value: "Plans - Drawing - Establishment - Cross Section", label: "Plans - Drawing - Establishment - Cross Section"},
    {value: "Plans - Drawing - Establishment - Profile", label: "Plans - Drawing - Establishment - Profile"},
    {value: "Plans - Drawing - Establishment - Other", label: "Plans - Drawing - Establishment - Other"},
    {value: "Plans - Drawing - improvement", label: "Plans - Drawing - improvement"},
    {value: "Plans - Drawing - Repair", label: "Plans - Drawing - Repair"},
    {value: "Plans - Drawing - Maintenance", label: "Plans - Drawing - Maintenance"},
    {value: "Bid Documents - Specifications", label: "Bid Documents - Specifications"},
    {value: "Contracts", label: "Contracts"},
    {value: "Memos and Transmittals", label: "Memos and Transmittals"},
    {value: "Notifications", label: "Notifications"},
    {value: "Bonds", label: "Bonds"},
    {value: "Original TIF Files", label: "Original TIF Files"},
    {value: "All Drainage Systems",label: "All Drainage Systems"},
    {value: "Invoice", label: "Invoice"},
    {value: "Report-DNR", label: "Report-DNR"},
    {value: "Affidavit", label: "Affidavit"},
    {value: "Splits - Combinations", label: "Splits - Combinations"},
    {value: "Tabular Lien Statement", label: "Tabular Lien Statement"},
    {value: "Order-Redetermination", label: "Order-Redetermination"},
    {value: "Property Owner Reports", label:" Property Owner Reports"},
    {value: "Order-Dismissal", label: "Order-Dismissal"},
    {value: "Petition-Removal Of Property", label: "Petition-Removal Of Property"},
    {value: "Account Register", label: "Account Register"},
    {value: "Other", label: "Other"}
];

export const LegalProcceding = [
    {value: "Abandonment of Drainage System", label: "Abandonment of Drainage System"},
    {value: "Consolidation or Division of Drainage Systems", label: "Consolidation or Division of Drainage Systems"},
    {value: "Impounding, Rerouting, And Diverting Drainage System Waters", label: "Impounding, Rerouting, And Diverting Drainage System Waters"},
    {value: "Improvement of Drainage System", label: "Improvement of Drainage System"},
    {value: "Improvement of Outlets", label: "Improvement of Outlets"},
    {value: "Lateral", label: "Lateral"},
    {value: "New Drainage System", label: "New Drainage System"},
    {value: "Partial Abandonment of Drainage System", label: "Partial Abandonment of Drainage System"},
    {value: "Petitioned Repair", label: "Petitioned Repair"},
    {value: "Petition to Use Established Drainage System as Outlet", label: "Petition to Use Established Drainage System as Outlet"},
    {value: "Reestablishment of Drainage System Records", label: "Reestablishment of Drainage System Records"},
    {value: "Removal of Property from the Drainage System", label: "Removal of Property from the Drainage System"},
    {value: "Redetermination of Benefits", label: "Redetermination of Benefits"},
    {value: "Transfer All or Part of a Drainage System", label: "Transfer All or Part of a Drainage System"},
];

export const ProceedingStatus = [
    {value: "In Progress", label: "In Progress"},
    {value: "Completed", label: "Completed"},
    {value: "Dismissed", label: "Dismissed"},
];

export const PersonRequestingType = [
    {value: "Landowner", label: "Landowner"},
    {value: "Renter", label: "Renter"},
    {value: "Comissioner", label: "Comissioner"},
    {value: "Contractor", label: "Contractor"},
    {value: "Staff", label: "Staff"},
    {value: "Other", label: "Other"},
]

export const RepairStatus = [
    {value: "For Review", label: "For Review"},
    {value: "In Progress", label: "In Progress"},
    {value: "Closed", label: "Closed"},
]

export const BoardApproval = [
    {value: "For Approval", label: "For Approval"},
    {value: "For Payment", label: "For Payment"},
]

export const Priority = [
    {value: "High", label: "High"},
    {value: "Medium", label: "Medium"},
    {value: "Low", label: "Low"}
]

export const Quarter = [
    "NWNW",
    "NENW",
    "NWNE",
    "NENE",
    "SWNW",
    "SENW",
    "SWNE",
    "SENE",
    "NWSW",
    "NESW",
    "NWSE",
    "NESE",
    "SWSW",
    "SESW",
    "SWSE",
    "SESE"
]

export const BMP_TYPES = [
    "Alternative Tile Inlets",
    "Culvert Sizing",
    "Filter Strip",
    "Grade Stabilization Structure",
    "Grass Waterway",
    "Side Inlet Controls",
    "Water/Sediment Control Bish",
    "Saturated Buffer",
    "Wood Chip Bioreactor",
    "Wetland Restoration/Creation",
    "Other"
];

export const Violationstatus = [
    "Compliant",
    "Non-Compliant"
]

export const Inspectiontype = [
    "Verification",
    "Compliance",
    "Continued Compliance"
]

export const CheckTypes = [
    "Assessment Collection",
    "Disbursement",
    "Journal Entry"
]

export const TransactionTypes = [
    "Transferred In",
    "Transferred Out"
]

export const FundTypes = [
    "Revenue",
    "Drainage",
    "Other"
]

export const RegisterOptions = [
    "Payroll Expense",
    "St/Fed Disaster Funds",
    "Interest",
    "Maintenance",
    "Mileage",
    "Other"
]
