import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import {Form} from "react-form";
import { connect } from "react-redux";

import Checkbox from "../common/Checkbox";
import DatePicker from "../common/DatePicker";
import PrimaryLocation from "../common/drainage/PrimaryLocation";
import TextField from "../common/TextField";
import Subquestion from "../common/Subquestion";
import Select from "../common/Select";
import { Priority } from "../common/SelectOptions";

import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import {Local_commish, Local_Twpshipxy, Local_twp, Local_Range, Sections, Repair} from "../mypage/models";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";
import { createSelector } from '../common/orm';

const getOwner = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.Peoplelandowner.filter(sys => sys.repair === repair).orderBy("id").toRefArray()[0];
    }
);

const getRequest = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.PeopleRequest.filter(sys => sys.repair === repair).orderBy("id").toRefArray()[0];
    }
);

const getCommish = Local_commish.selectAll();
const getTowns = Local_Twpshipxy.selectAll();
const getSections = Sections.selectAll();

const getTwp = Local_twp.selectAll();
const getLocalRange = Local_Range.selectAll();

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 10
    },
    tb: {
        paddingTop: 30,
        marginBottom: 20
    },
    dt: {
        marginTop: 16
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    flex: {
        flex: 1
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    firstdiv: {
        "& > div:first-child": {
	    paddingTop: 20     
        }
    },
});

class Description extends Component {
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { ormRepairUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormRepairUpdateLocalOnly({
            ...state.values
        });
    }

    componentDidUpdate(prevProps, prevState) {
        var updateForm = false;
        Object.entries(this.props).forEach(([key, val]) => {
          if(prevProps[key] !== val && key === "repair"){
            updateForm = true;
          }
        });

        if(updateForm && this.formApi){
            const base_keys = ["township", "twp", "range", "commissioner", "section", "quarter", "parcelnum"];
            base_keys.forEach(x => {
                if(x in this.props.repair){
                    this.formApi.setValue(x, this.props.repair[x]);
                }
            });
        }
    }

    save = values => {
        const { fullRepairUpdate, repair, owner, person } = this.props;
        values['thegeom'] = repair.thegeom;
        fullRepairUpdate(values, owner, person);
    }

    savea = values => {
        const { repair, ormRepairUpdatePromise, map, saveBad, saveGood } = this.props;

        if(map.geometry_center){
            ormRepairUpdatePromise({
                ...values,
                ...map.geometry_center,
                thegeom: repair.thegeom
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormRepairUpdatePromise({
                ...values,
                thegeom: repair.thegeom
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
    }

    setParent = (val, key, formApi) => {
        if(val){
            formApi.setValue(key, true);
        }
    }

    render(){
        const { authState, classes, repair, system} = this.props;
        const viewOnly = authState.user.role === "View Only";

        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Form 
                    ref={this.Formref}
                    getApi={el => (this.formApi = el)}
                    id="descriptionform"
                    defaultValues={repair}
                    onSubmit={this.save}>
                    {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <ToolBar disableGutters>
                            <Typography variant="h5" className={classes.grow}>Description</Typography>
                            <Button
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                style = {{float: "right", display: viewOnly && "none "}}
                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                        </ToolBar>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField maxWords={255} field="branch" label="Branch" fullWidth disabled={viewOnly}></TextField>
                                <TextField maxWords={2000} field="comments" label="Problem/Proposed Work" fullWidth disabled={viewOnly}></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <Select field="priority" label="Priority" options={Priority} fullWidth disabled={viewOnly}/>
                                <DatePicker 
                                    field="datereceived" 
                                    id="description_date" 
                                    label="Date Created" 
                                    disabled={viewOnly} 
                                    className={classes.dt}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{fontWeight: "bold"}}>Repair Flags</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4} className={classes.firstdiv}>
                                <Checkbox field="femaevent" label="FEMA EVENT" disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker field="femaeventdate" id="femaeventdate_date" label="FEMA Date" disabled={viewOnly}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{fontWeight: "bold"}}>Repair Type</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Checkbox field="ditch_repair" label="Ditch Repair" disabled={viewOnly}/>
                            </Grid>
                        </Grid>
                        {formApi.values.ditch_repair && (
                            <Subquestion
                                component={
                                    <Grid container spacing={3} style={{backgroundColor: "#e2e1e1", borderRadius: 6}}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="ditch_beaver"
                                                            label="Beaver/Beaver Dam - #"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="ditch_beaver_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="ditch_clean"
                                                            label="Clean Out - Length in Feet"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="ditch_clean_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="ditch_culvert"
                                                            label="Culvert/Crossing"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="ditch_culvert_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="ditch_slough"
                                                            label="Slough"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="ditch_slough_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="ditch_other"
                                                            label="Other"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="ditch_other_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Checkbox field="tile_repair" label="Tile Repair" disabled={viewOnly}/>
                            </Grid>
                        </Grid>
                        {formApi.values.tile_repair && (
                            <Subquestion
                                component={
                                    <Grid container spacing={3} style={{backgroundColor: "#e2e1e1", borderRadius: 6}}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="tile_blowout"
                                                            label="Blowout - Tile Size and Type"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="tile_blowout_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="tile_replacement"
                                                            label="Replacement - Length in Feet"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="tile_replacement_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="tile_surface"
                                                            label="Surface Intake - Size"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="tile_surface_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="tile_outlet"
                                                            label="Tile Outlet - Size"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="tile_outlet_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.tb}>
                                                        <Checkbox
                                                            field="tile_other"
                                                            label="Other"
                                                            disabled={viewOnly}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField maxWords={2000} field="tile_other_notes" label="Notes" disabled={viewOnly} fullWidth multiline />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        )}
                        <PrimaryLocation record={repair} formApi={formApi} type="Repair"/>
                    </form>
                    )}
                </Form>
            </div>
        )
    }
}

Description = connect(
    (state, ownProps) => ({
        map: state.map,
        authState: state.auth,
        twp: getTwp(state),
        localRange: getLocalRange(state),
        commish: getCommish(state),
        towns: getTowns(state),
        sections: getSections(state),
        owner: getOwner(state, ownProps),
        person: getRequest(state, ownProps)
    }),
    {
        ...Repair.actions,
        ...Local_twp.actions,
        ...Local_Range.actions,
        panelChange
    }
)(Description);

export default withStyles(styles)(withRouter(Description));
