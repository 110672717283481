import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { loadModules } from "esri-loader";

import Checkbox from "../Checkbox";
import MultiSelect from "../MultiSelect";
import TextField from "../TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import WarningDialog from "../WarningDialog";

import { MAKE_OPTIONS } from "../../../api/constants";
import { Quarter } from "../SelectOptions";
//import {Local_commish, Local_Twpshipxy, Local_twp, Local_Range, Peoplelandowner } from "../../mypage/models";
import { GISAutoPopulate, Peoplelandowner } from "../../mypage/models";
import { createSelector } from '../orm';
import { withRouter } from 'react-router-dom';

import {
    _PerformLookUp
} from "../esrimap/BaseMapControl";

//const getCommish = Local_commish.selectAll();
//const getTowns = Local_Twpshipxy.selectAll();
//const getTwp = Local_twp.selectAll();
//const getLocalRange = Local_Range.selectAll();

const getGISAutoPopulate = GISAutoPopulate.selectFirst();

const getLandOwner = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["repair"]),
    (session, id) => {
        return session.Peoplelandowner.filter(x => x.repair === id).first()
    });

function getOptions(list) {
    return list.map(x => x.toString());
}

function getOptionsKey(list, key) {
    return list.map(x => x[key].toString());
}
const getMapSetting = createSelector(schema => {
    return schema.MapSetting.first();
});

const styles = theme => ({
    mb: {
        marginBottom: 10
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    }
});

class PrimaryLocation extends Component {
    constructor(props) {
        super(props);

        var temp = [];
        for (var i = 1; i < 37; i++) {
            temp.push(i.toString());
        }

        this.state = {
            loading: false,
            repairDialog: false,
            sections: temp
        }
    }

    handleEvent = (val) => {
        const { formApi, type } = this.props;
        const _this = this;
        if (val) {
            if (_this.props.autoPopulate) {
                _this.setState({ loading: true });
                _this._getGeometryAndCenter(function (data) {

                    const base_keys = ["township", "twp", "range", "commissioner", "section", "quarter", "parcelnum"];
                    base_keys.forEach(x => {
                        if (x in data) {
                            formApi.setValue(x, data[x]);
                        }
                    });

                    if (type === "Repair") {
                        setTimeout(_this.setState({ repairDialog: true, savedInformation: data, loading: false }), 2000);
                    } else if (type === "Violation") {
                        formApi.setValue("landowner", data["name"]);
                        formApi.setValue("address", data["address"]);
                        formApi.setValue("city", data["city"]);
                        formApi.setValue("state", data["state"]);
                        formApi.setValue("zip", data["zip"]);
                    } else if (type === "Bmp" || type === "Condition") {
                        formApi.setValue("landowner", data["name"]);
                    }
                    setTimeout(_this.setState({ loading: false }), 2000);
                });
            }
        }
        formApi.setValue("updategeo", val);
    }

    _getGeometryAndCenter = (_callBack) => {
        const { saved, autoPopulate } = this.props;
        loadModules(
            [
                'esri/layers/FeatureLayer',
                'esri/geometry/Point'
            ], { css: true })
            .then(([
                FeatureLayer,
                Point
            ]) => {

                const point = saved && saved.saved_center ? Point.fromJSON(saved.saved_center) : null;

                _PerformLookUp(autoPopulate, FeatureLayer, point, function (locationData) {
                    _callBack(locationData);
                });
            });
    }


    comfirmedUpdate = () => {
        const { authState, landOwner, ormPeoplelandownerCreate, ormPeoplelandownerUpdate } = this.props;
        const { savedInformation } = this.state;

        const base_keys = ["add1", "add2", "city", "state", "zip", "name"];

        if (landOwner) {
            var values = landOwner;

            base_keys.forEach(x => {
                if (x in savedInformation) {
                    values[x] = savedInformation[x];
                }
            });

            ormPeoplelandownerUpdate({
                ...values,
                modified_user: authState.user.username
            });
        } else {
            var newValues = {};

            base_keys.forEach(x => {
                if (x in savedInformation) {
                    newValues[x] = savedInformation[x];
                }
            });

            ormPeoplelandownerCreate({
                repair: this.props.match.params["repair"],
                drainage_id: this.props.match.params["drainage"],
                modified_user: authState.user.username,
                ...newValues
            });
        }
    }

    render() {
        const { loading, repairDialog, sections } = this.state;
        const {
            authState,
            classes,
            commish,
            localRange,
            towns,
            twp,
            settings,
            record
        } = this.props;
        const viewOnly = authState.user.role === "View Only";

        return (
            <>
                <ToolBar disableGutters>
                    <Typography variant="h5" className={classes.grow}>Primary Location</Typography>
                </ToolBar>
                <Divider className={classes.mb} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Checkbox
                                eventHandle={this.handleEvent.bind(this)}
                                field="updategeo"
                                label="Update fields from the centroid of the points or lines drawn on the map"
                                disabled={viewOnly}
                            />
                            {loading && (<CircularProgress />)}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <MultiSelect field="township" label="Township" options={MAKE_OPTIONS(towns)} fullWidth disabled={viewOnly} />
                        <MultiSelect field="twp" label="Twp" options={MAKE_OPTIONS(twp)} fullWidth disabled={viewOnly} />
                        <MultiSelect field="range" label="Range" options={MAKE_OPTIONS(localRange)} fullWidth disabled={viewOnly} />
                    </Grid>
                    <Grid item xs={6}>
                        {settings.jurisdiction === "County" && (
                            <MultiSelect field="commissioner" label="Commissioner District" options={MAKE_OPTIONS(commish)} fullWidth disabled={viewOnly} />
                        )}
                        {settings.jurisdiction === "Watershed District" && (
                            <MultiSelect field="county" label="County" options={MAKE_OPTIONS(settings["counties"].split(";"))} fullWidth disabled={viewOnly} />
                        )}
                        <MultiSelect field="section" label="Section" options={MAKE_OPTIONS(sections)} fullWidth disabled={viewOnly} />
                        <MultiSelect field="quarter" label="Qtr-Qtr" options={MAKE_OPTIONS(Quarter)} fullWidth disabled={viewOnly} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField record={record} field="parcelnum" label="Parcel Number" disabled={viewOnly} fullWidth multiline></TextField>
                        <TextField field="details" rows={3} label="Location Details" disabled={viewOnly} fullWidth multiline></TextField>
                    </Grid>
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        this.comfirmedUpdate();
                        this.setState({ repairDialog: false });
                    }}
                    cancelAction={
                        () => this.setState({
                            repairDialog: false
                        })
                    }
                    confirmText="Update"
                    cancelText="Do Not Update Landowner"
                    open={repairDialog}
                    title={"Update Landowner Location Information"}
                    text={"Are you sure you wish to update?"}
                />
            </>
        );
    }
}

PrimaryLocation = connect(
    (state, ownProps) => ({
        authState: state.auth,
        twp: getOptions(state.map.twps),
        localRange: getOptions(state.map.ranges),
        commish: getOptionsKey(state.map.commissioners, "name"),
        towns: getOptionsKey(state.map.townships, "name"),
        landOwner: getLandOwner(state, ownProps),
        autoPopulate: getGISAutoPopulate(state),
        settings: getMapSetting(state, ownProps),
        saved: state.map.payload
    }),
    {
        ...Peoplelandowner.actions
    }
)(PrimaryLocation);

export default withStyles(styles)(withRouter(PrimaryLocation));
