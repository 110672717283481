import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { withStyles, Grid, TableBody, Table, TableRow, Tooltip, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { getValue } from "../../api/utils";
import { MAKE_OPTIONS_VID } from "../../api/constants";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import TextField from "../common/TextField";
import Select from "../common/Select";
import DatePicker from "..//common/DatePicker";
import FileFormInput from "../common/FileFormInput";
import WarningDialog from "../common/WarningDialog";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import HelpLabel from "../common/HelpLabel";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import {
    Inspection, 
    PhotoInspection, 
    Repair
} from "../mypage/models";
import { createSelector } from '../common/orm';
import { getToday } from '../../api/utils';
import { panelChange } from "../common/actions";

const getInspections = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.Inspection.filter(x => x.repair === repair.id).orderBy("inspectiondate").toRefArray();
    }
);

const getPhotos = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.PhotoInspection.filter(x => x.repair === repair.id).orderBy("uploaddate").toModelArray();
    }
);

const getUsers = createSelector(session => {
    return session.User.all().orderBy('name').toModelArray();
});

const styles = theme => ({
    table: {
        minWidth: 500,
        marginTop: 10
    },
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    date: {
        minWidth: 200
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        grow: 1,
        display: "inline-flex",
    },
    right: {
        float: "right"
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
    photoList: {
        marginLeft: "8.333333%",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0% !important"
        },
    }
});

const inspectionColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "inspectiondate", numeric: false, label: "Date", allowSort: true, date: true},
    {id: "user_label", numeric: false, label: "Modified User", allowSort: true },
    {id: "comments", numeric: false, label: "Notes", allowSort: true, type: "text" },
];

const inspectionPhoto = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: true, date: true},
    {id: "caption", numeric: false, label: "Caption", allowSort: true},
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false },
];

class Inpsections extends Component {
    state = {
        confirmUpload: false, 
        loading: false,
        activeId: null,
        activePhoto: null,
        inspectionDialog: false,
        photoDialog: false,
        selectedInspection: null,
        inspectionDelete: false,
        inspectionID: null,
        phototDelete: false,
        photoID: null,
        calculated: "",
        reportLoading: false,
        reportId: null
    }

    saveInspection = values => {
        const { ormInspectionUpdatePromise, saveBad, saveGood } = this.props;
        ormInspectionUpdatePromise({
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        
        this.setState({inspectionDialog: false, selectedInspection: values.id});

    }

    createInspection = values => {
        const { authState, ormInspectionCreate, repair, saveBad } = this.props;

        var once = ormInspectionCreate({
            drainage_id: repair.drainage,
            repair_id: repair.id,
            user: authState.user.id
        });

        
        once.then(id => {
            if(!id){
                saveBad();
            } else {
                this.setState({activeId: id, inspectionDialog: true, rowSelected: id});
            }
        });
    }

    deleteInspection(id) {
        const { ormInspectionDeletePromise, saveBad, saveGood } = this.props;
        ormInspectionDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    errorValidator = values => {
        const checkIfEmpty = value => {
            if (!value) {
                return "Required";
            }
            return null;
        }

        return {
            files: checkIfEmpty(values.files)
        };
    }

    handleFiles = (val, formApi) => {
        const { fileSize } = this.props;
        this.setState({ files: val });

        if(val !== null){
            const file_list = [...val];
            var total_size = 0;

            file_list.forEach(x => {
                total_size += x.size;
            });

            total_size = total_size / 1000000;

            if(total_size > 250){
                formApi.setValue("files", null);
                this.setState({confirmUpload: false});
                fileSize();
            } else if(total_size > 100){
                this.setState({confirmUpload: true, calculated: `${Math.round(total_size / 40)} Minutes`});
            } else {
                this.setState({confirmUpload: false});
                formApi.setValue("lock_submit", false);
            }
        } else {
            this.setState({confirmUpload: false});
            formApi.setValue("lock_submit", false);
        }
    }

    uploadPhotos = values => {
        const { ormPhotoInspectionCreateForm, repair, saveBad, saveGood } = this.props;
        const { activeId, files } = this.state;

        const files_list = [...files];
        var count = 0;

        this.setState({loading: true});
        
        files_list.forEach(x => {
            var form_data = new FormData();
            form_data.append('inspection_id', activeId);
            form_data.append('drainage_id', repair.drainage);
            form_data.append('repair_id', repair.id);
            form_data.append('caption', x.name);
            form_data.append('uploaddate', getToday());
            form_data.append('link', x, x.name);
            ormPhotoInspectionCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                }

                count += 1;
                if(count === files_list.length){
                    this.setState({loading: false});
                    saveGood();
                }
            });
        });
    }

    deletePhoto = id => {
        const { ormPhotoInspectionDeletePromise, saveBad, saveGood } = this.props;
        ormPhotoInspectionDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    savePhoto = values => {
        const { ormPhotoInspectionUpdatePromise, saveBad, saveGood } = this.props;
        delete values.link;
        ormPhotoInspectionUpdatePromise({
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
        
        this.setState({photoDialog: false});
    }

    handleRequestSort = (event, property, propName) => {
        const orderBy = property;
        let order = "desc";
        if (this.state[`${propName}OrderBy`] === property && this.state[`${propName}Order`] === "desc") {
            order = "asc";
        }

        const columns = propName === "inspections" ? inspectionColumnData : inspectionPhoto;

        this.props[propName].sort((a,b) => {
            var date = columns.find(cD => cD.id === orderBy).date;
            var numeric = columns.find(cD => cD.id === orderBy).numeric;
            var bool = columns.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ [`${propName}Order`]: order, [`${propName}OrderBy`]: orderBy });
    };

    inspectionReport = (inspectionId) => {
        const { authState, mapScreenShot } = this.props;
        this.setState({reportLoading: true, reportId: inspectionId});
        fetch(`/reports/drainagerepair/${inspectionId}/inspection?zoom=10&lat=5&lng=5&baselyr=a`, {
            method: 'POST',
            body: JSON.stringify({img: mapScreenShot}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        }).then( res => res.blob())
        .then( blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, "RepairInpsectionReport.docx");
            } else {
                var file = window.URL.createObjectURL(blob);
                //window.location.assign(file);
                var a = document.createElement('a');
                a.href = file;
                a.download = "RepairInpsectionReport.docx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again     
                URL.revokeObjectURL(file); //release to avoid memeory leak
            }
            this.setState({reportLoading: false, reportId: null});
        });
    }

    render(){
        const { 
            authState, 
            classes, 
            system, 
            repair, 
            inspections,
            photos,
            users,
        } = this.props;
        const { 
            loading,
            confirmUpload,
            activeId, 
            activePhoto, 
            inspectionDelete, 
            inspectionID, 
            photoID, 
            phototDelete, 
            inspectionDialog, 
            photoDialog, 
            selectedInspection, 
            rowSelected,
            calculated,
            reportLoading,
            reportId
        } = this.state;

        const activeInspection = inspections.find(x => x.id === activeId);
        const viewOnly = authState.user.role === "View Only";
        const contractor = authState && authState.user && authState.user.role === "Contractor";
        const username = getValue(authState, "user.username");
        const userID = getValue(authState, "user.id");

        //if it doesn't exist the delete button was clicked
        const inspectionPhotos = inspections.find(x => x.id === selectedInspection) ? photos.filter(x => x.ref.inspection === selectedInspection) : [];

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Typography variant="h5" className={classes.grow}>Inspection Log</Typography>
                <Divider className={classes.mb}/>
                <Grid container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                onClick={this.createInspection}
                                disabled={viewOnly}
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                >
                                <AddCircleOutlineIcon /> 
                                &nbsp;&nbsp;Add
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{overflowX: "auto"}}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={this.state.inspectionsOrder}
                                orderBy={this.state.inspectionsOrderBy}
                                propName="inspections"
                                onRequestSort={this.handleRequestSort}
                                columnData={inspectionColumnData}
                            />
                            <TableBody>
                                {inspections.map((n, index) => { return (
                                    <TableRow
                                        selected={n.id === rowSelected} 
                                        hover 
                                        key={n.id} 
                                        onClick={() => {this.setState({selectedInspection: n.id, rowSelected: n.id})}}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            <Tooltip title="Report">
                                                {reportLoading && reportId === n.id ? 
                                                    <CircularProgress color="primary" size="1.5em"/>
                                                    :
                                                    <Button
                                                        color="primary"
                                                        target="_blank" 
                                                        onClick={() => {this.inspectionReport(n.id)}}
                                                        className={classes.deleteWidth}
                                                    >
                                                        <AssignmentIcon /> 
                                                    </Button>
                                                }
                                            </Tooltip>
                                            {!viewOnly && (
                                                <>
                                                    {(!contractor || (contractor && (username === n.modified_user || userID === n.user))) &&  (
                                                        <Tooltip title="Edit">
                                                            <Button
                                                                onClick={() => {this.setState({activeId: n.id, inspectionDialog: true})}} 
                                                                className={classes.deleteWidth}
                                                            >
                                                                <EditIcon color="primary"/> 
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                    {!contractor && (
                                                        <Tooltip title="Delete">
                                                            <Button
                                                                onClick={() => this.setState({inspectionDelete: true, inspectionID: n.id})}
                                                                className={classes.deleteWidth}
                                                            >
                                                                <DeleteIcon /> 
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.inspectiondate}</CustomTableCell>
                                        <CustomTableCell>{n.user_label}</CustomTableCell>
                                        <CustomTableCell>{n.comments}</CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {inspections.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={inspectionColumnData.length} className={classes.centerAlign}>
                                            No Inspections
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={12} className={classes.photoList}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" className={classes.grow}>Photo List For Selected Inspection</Typography>
                                <Divider className={classes.mb}/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} style={{overflowX: "auto"}}>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        order={this.state.photosOrder}
                                        orderBy={this.state.photosOrderBy}
                                        propName="photos"
                                        onRequestSort={this.handleRequestSort}
                                        columnData={inspectionPhoto}
                                    />
                                    <TableBody>
                                        {inspectionPhotos.map(n => { return (
                                            
                                            <TableRow hover key={n.id}>
                                                <CustomTableCell className={classes.actionWidth}>
                                                    {!viewOnly && (
                                                        <>
                                                            {(!contractor || (contractor && (username === n.inspection.modified_user || userID === n.inspection.user))) &&  (
                                                            <Tooltip title="Edit">
                                                                <Button
                                                                    onClick={() => this.setState({activePhoto: n, photoDialog: n})}
                                                                    className={classes.deleteWidth}
                                                                >
                                                                    <EditIcon color="primary"/> 
                                                                </Button>
                                                            </Tooltip>
                                                            )}
                                                            {!contractor && (
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        onClick={() => this.setState({phototDelete: true, photoID: n.id})}
                                                                        className={classes.deleteWidth}
                                                                    >
                                                                        <DeleteIcon /> 
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell>{n.uploaddate}</CustomTableCell>
                                                <CustomTableCell>{n.caption}</CustomTableCell>
                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                <CustomTableCell>
                                                    <Tooltip title="View Attachment">
                                                        <a target="_blank" rel="noopener noreferrer" href={n.link} className={classes.linkColor}>
                                                            Open File
                                                        </a>
                                                    </Tooltip>
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                        })}
                                        {inspectionPhotos.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={inspectionPhoto.length} className={classes.centerAlign}>
                                                    No Inspection Photos
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={inspectionDialog} maxWidth="md" fullWidth> 
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Inspection</Typography>
                        <IconButton disabled={loading} aria-label="close" className={classes.right} onClick={() => {this.setState({inspectionDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            defaultValues={activeInspection}
                            onSubmit={this.saveInspection}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={true} 
                                            field="modified_user" 
                                            label="Old Inspector" 
                                            fullWidth
                                        />
                                        <Select 
                                            disabled={true} 
                                            field="user" 
                                            label="Inspector" 
                                            fullWidth 
                                            options={MAKE_OPTIONS_VID(users, "name")} 
                                        />
                                        <DatePicker 
                                            className={classes.date} 
                                            field="inspectiondate" 
                                            label="Date" 
                                            autoPopulate={true} 
                                        />
                                        <TextField 
                                            field="comments" 
                                            label="Inspection Notes" 
                                            fullWidth
                                        />
                                        <Button 
                                            disabled={loading} 
                                            type="submit" 
                                            variant="contained" 
                                            color="primary">
                                                Save
                                        </Button>
                                        &nbsp;
                                    </form>
                                )}
                        </Form>
                        <div style={{marginTop: 20}}>
                            <Divider />
                            <Form 
                                id="photos"
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                defaultValues={{lock_submit: false}}
                                onSubmit={values => this.uploadPhotos(values)}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <FileFormInput
                                                eventHandle={val => this.handleFiles(val, formApi)}
                                                fullWidth 
                                                disabled={loading}
                                                label="Upload Files"
                                                id="photoUpload"
                                                field="files"
                                                accept="image/*"
                                                multiFile={true} 
                                            />
                                            <br />
                                            {confirmUpload && (
                                                <Subquestion
                                                    component={
                                                        <RadioGroup
                                                            field="lock_submit"
                                                            name="lock_submit"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                    title={true}
                                                                    showLabel={true}
                                                                    helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                                />
                                                            }
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "true" },
                                                                { label: "No", value: "false" }
                                                            ]}
                                                            disabled={loading}
                                                            alignment={true}></RadioGroup>
                                                    }
                                                />
                                            )}
                                            <br />
                                            <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                                Upload Files
                                                {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </form>
                                    )}
                            </Form>
                        </div>
                    
                    </DialogContent>
                </Dialog>
                <Dialog open={photoDialog}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Photo</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({photoDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            id="photoedit"
                            defaultValues={activePhoto}
                            onSubmit={values => this.savePhoto(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <TextField 
                                        field="caption" 
                                        label="Caption" 
                                        fullWidth
                                    />
                                    <DatePicker 
                                        className={classes.date} 
                                        field="uploaddate" 
                                        label="Date" 
                                        autoPopulate={true} 
                                        fullWidth
                                    />
                                    <TextField 
                                        field="notes" 
                                        label="Notes" 
                                        fullWidth
                                    />
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.deleteInspection(inspectionID);
                        this.setState({ inspectionDelete: false });
                    }}
                    cancelAction={() => this.setState({ inspectionDelete: false })}
                    open={inspectionDelete}
                    title="Delete Inspection"
                    text={"Are you sure you wish to permanently delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.deletePhoto(photoID);
                        this.setState({ phototDelete: false });
                    }}
                    cancelAction={() => this.setState({ phototDelete: false })}
                    open={phototDelete}
                    title="Delete Photo"
                    text={"Are you sure you wish to permanently delete?"}
                />
            </Grid>
        );
    }
}


Inpsections = connect(
    (state, ownProps) => ({
        authState: state.auth,
        map: state.map.map,
        inspections: getInspections(state, ownProps),
        photos: getPhotos(state, ownProps),
        users: getUsers(state)
    }),
    {
        ...Repair.actions,
        ...Inspection.actions,
        ...PhotoInspection.actions,
        panelChange
    }
)(Inpsections);


export default withStyles(styles)(withRouter(Inpsections));
