export const NAV_TOGGLE_NAVIGATION = "NAV_TOGGLE_NAVIGATION";
export const NAV_TOGGLE_FOLDER= "NAV_TOGGLE_FOLDER";
export const NAV_TOGGLE_ADMIN="NAV_TOGGLE_ADMIN";
export const NAV_TOGGLE_CONFIG="NAV_TOGGLE_CONFIG";
export const NAV_SELECT_PAGE = "NAV_SELECT_PAGE";
export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";
export const SEARCH_INPUT = "SEARCH_INPUT";
export const SEARCH_INPUT_CLEAR = "SEARCH_INPUT_CLEAR";
export const PANEL_INIT = "PANEL_INIT";
export const PANEL_CHANGE = "PANEL_CHANGE";

export function navToggleNavigation() {
    return {
        type: NAV_TOGGLE_NAVIGATION
    };
}

export function navToggleFolder() {
    return {
        type: NAV_TOGGLE_FOLDER
    };
}

export function navToggleAdmin() {
    return {
        type: NAV_TOGGLE_ADMIN
    };
}

export function navToggleConfig() {
    return {
        type: NAV_TOGGLE_CONFIG
    };
}

export function navSelectPage(page) {
    return {
        type: NAV_SELECT_PAGE,
        page: page
    };
}

export function searchInput(input, key){
    return {
        type: SEARCH_INPUT,
        input: input,
        key: key
    }
}

export function searchInputClear(){
    return {
        type: SEARCH_INPUT_CLEAR
    }
}

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}

export function panelInit() {
    return { type: PANEL_INIT };
}

export function panelChange(panel){
    return {
        type: PANEL_CHANGE,
        panel: panel
    }
}
