import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBox from "../common/Checkbox";
import SaveIcon from '@material-ui/icons/Save';

import WarningDialog from "../common/WarningDialog";

const styles = theme => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 450,
        textAlign: "center"
    },
    list: {
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    summaryView: {
        display: "flex",
        flexGrow: 1,
        padding: 8,
        alignItems: "center",
        justifyContent: "space-between"
    },
    header: {
        backgroundColor: "#333333",
        color: "#ffffff"
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class TransferList extends Component {
    state = {
        expanded: false,
        dialog: false
    }

    updateRedux = (formApi) => {
        const { ormParentUpdateLocalOnly } = this.props;
        if(formApi && formApi.values){
            const keys = Object.keys(formApi.values);
            keys.forEach(x => {
                var values = formApi.values[x];
                values.id = parseInt(values.id);
                delete values.checked;
                ormParentUpdateLocalOnly({
                    ...values
                });
            })
        }
    }

    handleAllRight = (formApi) => {
        if(formApi && formApi.values){
            const keys = Object.keys(formApi.values);
            keys.forEach(x => {
                var values = formApi.getValue(x);
                values.allow = true;
                values.checked = false;
                formApi.setValue(x, values);
            });
            this.updateRedux(formApi);
        }
    };
    
    handleCheckedRight = (formApi) => {
        if(formApi && formApi.values){
            const keys = Object.keys(formApi.values);
            keys.forEach(x => {
                var values = formApi.getValue(x);
                if(values.checked && !values.allow){
                    values.allow = true;
                    values.checked = false;
                    formApi.setValue(x, values);
                }
            });
            this.updateRedux(formApi);
        }
    };
    
    handleCheckedLeft = (formApi) => {
        if(formApi && formApi.values){
            const keys = Object.keys(formApi.values);
            keys.forEach(x => {
                var values = formApi.getValue(x);
                if(values.checked && values.allow){
                    values.checked = false;
                    values.allow = false;
                    formApi.setValue(x, values);
                }
            });
            this.updateRedux(formApi);
        }
    };
    
    handleAllLeft = (formApi) => {
        if(formApi && formApi.values){
            const keys = Object.keys(formApi.values);
            keys.forEach(x => {
                var values = formApi.getValue(x);
                values.allow = false;
                values.checked = false;
                formApi.setValue(x, values);
            });
            this.updateRedux(formApi);
        }
    };

    handleToggle = (event, formApi, id) => {
        if(formApi && formApi.values){
            var values = formApi.getValue(id);
            values.checked = !values.checked;
            formApi.setValue(id, values);
        }
    }

    inOrder = (items, keys) => {
        var test = [...keys];
        test.sort((a,b) => {
            return (items[a.toString()].label) < (items[b.toString()].label);
        });
        return test;
    }

    customList = (classes, formApi, items, allow) => (
        <Paper className={classes.paper}>
            <Typography className={classes.header}>{!allow ? "Not Selected" : "Selected"}</Typography>
            <List className={classes.list} dense component="div" role="list">
                {this.inOrder(items, Object.keys(items)).filter(x => items[x].allow === allow).map(x => {
                    const obj = items[x];
                    const labelId = `transfer-list-item-${obj.id}-label`;
                    return (
                        <ListItem key={obj.id} role="listitem" button onClick={(e) => this.handleToggle(e, formApi, obj.id.toString())}>
                        <ListItemIcon>
                            <CheckBox
                                onClick={(e) => e.preventDefault()}
                                field={`${obj.id.toString()}.checked`}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${obj.label}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    getDefaultValues = (data) => {
        const { foreignKey } = this.props;
        var values = {};

        data.forEach(x => {
            values[x.id] = {
                id: x.id.toString(), 
                [foreignKey]: x[foreignKey],
                allow: x.allow,
                label: x.label,
                checked: false
            };
        });
        return values;
    }

    getLeftLength = (formApi) => {
        if(formApi && formApi.values){
            var count = 0;
            Object.keys(formApi.values).forEach(x => {
                if(!formApi.values[x].allow){
                    count++;
                }
            });
            return count;
        } else {
            return 0;
        }
    }

    getLeftLengthChecked = (formApi) => {
        if(formApi && formApi.values){
            var count = 0;
            Object.keys(formApi.values).forEach(x => {
                if(formApi.values[x].checked && !formApi.values[x].allow){
                    count++;
                }
            });
            return count;
        } else {
            return 0;
        }
    }

    getRightLength = (formApi) => {
        if(formApi && formApi.values){
            var count = 0;
            Object.keys(formApi.values).forEach(x => {
                if(formApi.values[x].allow){
                    count++;
                }
            });
            return count;
        } else {
            return 0;
        }
    }

    getRightLengthChecked = (formApi) => {
        if(formApi && formApi.values){
            var count = 0;
            Object.keys(formApi.values).forEach(x => {
                if(formApi.values[x].checked && formApi.values[x].allow){
                    count++;
                }
            });
            return count;
        } else {
            return 0;
        }
    }

    handleClick = (e) => {
        const { warning } = this.props;
        e.stopPropagation();
        if(warning){
            e.preventDefault();
            this.setState({dialog: true});
        }
    }

    componentDidUpdate = () => {
        if(this.props.hide && this.state.dialog){
            this.setState({dialog: false});
        }
    }

    render() {
        const { classes, data, hide, panelTitle, save } = this.props;
        const { dialog, expanded } = this.state;

        if(hide){
            return(
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary 
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <div className={classes.summaryView}>
                                <div>
                                    <Typography>
                                        {panelTitle}
                                    </Typography>
                                </div>
                                <div className={classes.wrapper}>
                                    <Button 
                                        disabled={true}
                                        onClick={e => { e.stopPropagation();}}
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                    <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails></ExpansionPanelDetails>
                </ExpansionPanel>
            );
        }

        return (
            <Form
                defaultValues={this.getDefaultValues(data)}
                onSubmit={values => save(values)}
                dontValidateOnMount={true}
                >
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <ExpansionPanel
                            expanded={expanded}
                            onChange={(e, expanded) => {
                                this.setState({expanded})
                            }}
                            >
                            <ExpansionPanelSummary 
                                className={classes.summary}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                    <div className={classes.summaryView}>
                                        <div>
                                            <Typography>
                                                {panelTitle}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Button 
                                                onClick={this.handleClick}
                                                type="submit"
                                                variant="contained" 
                                                color="primary" 
                                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                        </div>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                        <Grid item>{this.customList(classes, formApi, formApi.values, false)}</Grid>
                                        <Grid item>
                                            <Grid container direction="column" alignItems="center">
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={() => this.handleAllRight(formApi)}
                                                    disabled={this.getLeftLength(formApi) === 0}
                                                    aria-label="move all right"
                                                >
                                                    ≫
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={() => this.handleCheckedRight(formApi)}
                                                    disabled={this.getLeftLengthChecked(formApi) === 0}
                                                    aria-label="move selected right"
                                                >
                                                    &gt;
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={() => this.handleCheckedLeft(formApi)}
                                                    disabled={this.getRightLengthChecked(formApi) === 0}
                                                    aria-label="move selected left"
                                                >
                                                    &lt;
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={() => this.handleAllLeft(formApi)}
                                                    disabled={this.getRightLength(formApi) === 0}
                                                    aria-label="move all left"
                                                >
                                                    ≪
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item>{this.customList(classes, formApi, formApi.values, true)}</Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <WarningDialog
                            confirmAction={() => {
                                formApi.submitForm();
                            }}
                            cancelAction={() => this.setState({ dialog: false })}
                            open={dialog}
                            title={this.props.title}
                            text={this.props.text}
                            confirmText={this.props.confirmText}
                            cancelText={this.props.cancelText}
                        />
                    </form>
                )}
            </Form>
        );
    }
}

TransferList = connect(
    (state, ownProps) => ({ 
        authState: state.auth
    }),
    null
)(TransferList);

export default withStyles(styles)(withRouter(TransferList));
