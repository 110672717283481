import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";

import MapSettings from "../config/MapSettings";
import ManageBoardDate from "../config/ManageBoardDate";

import DropDowns from "../admin/DropDowns";
import PublicPortalConfiguration from "../admin/PortalConfig";
import ManageVendors from "../admin/ManageVendors";
import ManageUsers from "../admin/ManageUsers";
import ManageSystems from "../admin/ManageSystems";
import ManageActivities from "../admin/ManageActivities";
import Template from "../admin/Template";
import Account from "../admin/Account";
import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NotReady from "../common/NotReady";

import { reloadAdmin } from "../common/orm";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  pt: {
    marginTop: '80px',
    backgroundColor: 'white !important',
    color: 'black'
  },
  space: {
      marginTop: 88,
      textAlign: "center"
  },
  top: {
      marginTop: 20
  }
});

class Admin extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: 0,
            mounted: false,
            saveGood: false,
            saveBad: false
        };
    }

    componentDidUpdate = () => {
        const { sync } = this.props;
        const { mounted } = this.state;
        if(sync && !mounted){
          this.setState({mounted: true});
        }
    }

    componentDidMount() {
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Admin") {
            history.push("/");
        }
        
        if (authState && authState.user){
            this.props.reloadAdmin();
        }
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    handleReload = () => {
        this.setState({ mounted: false });
    }

    saveGood = () => {
        this.setState({saveGood: true});
      }
  
      saveBad = () => {
        this.setState({saveBad: true});
      }

    render() {
        const { classes, sync } = this.props;
        const { value, mounted, saveBad, saveGood } = this.state;

        return (
            <>
                <AppBar position="fixed" className={classes.pt}>
                        <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example" 
                            scrollButtons="on"
                            variant="scrollable">
                            <Tab label="Manage Users" {...a11yProps(0)} />
                            <Tab label="System Configuration" {...a11yProps(1)} />
                            <Tab label="Public Portal Config" {...a11yProps(2)} />
                            <Tab label="Map Settings" {...a11yProps(3)} />
                            <Tab label="Vendors" {...a11yProps(4)} />
                            <Tab label="Manage Board Date" {...a11yProps(5)} />
                            <Tab label="Manage Systems" {...a11yProps(6)} />
                            <Tab label="Manage Activities" {...a11yProps(7)} />
                            <Tab label="Templates" {...a11yProps(8)} />
                            <Tab label="Batch update" {...a11yProps(9)} />
                        </Tabs>
                </AppBar>
                <AppContainer authenticated onlyAdmin={true} adminPage={true}>
                    {!sync && !mounted && (
                        <NotReady />
                    )}
                    {mounted && (
                        <div className={classes.root}>
                            <div>
                                {value === 0 && (
                                    <ManageUsers saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 1 && (
                                    <DropDowns saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 2 && (
                                    <PublicPortalConfiguration saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 3 && (
                                    <MapSettings saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 4 && (
                                    <ManageVendors saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 5 && (
                                    <ManageBoardDate saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 6 && (
                                    <ManageSystems saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 7 && (
                                    <ManageActivities saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 8 && (
                                    <Template saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                {value === 9 && (
                                    <Account saveGood={this.saveGood} saveBad={this.saveBad}/>
                                )}
                                <Snackbar
                                    snackbarOpen={saveGood}
                                    handleSnackbarClose={() => this.setState({ saveGood: false })}
                                    isSuccess
                                    operationType="setting"
                                />
                                <SnackBarError open={saveBad} close={e => this.setState({saveBad: false})} />
                            </div>
                        </div>
                    )}
                </AppContainer>
            </>
        );
    }
}

Admin = connect(state => ({
    sync: state.sync.ready,
    navState: state.nav,
    authState: state.auth
}),{
    reloadAdmin
})(Admin);

export default withStyles(styles)(withRouter(Admin));