import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';

import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CheckBox from "../../common/Checkbox";
import Select from '../../common/Select';
import TextField from "../TextField";
import Snackbar from "../Snackbar";

import { withStyles } from "@material-ui/core";


import {MAKE_OPTIONS, MAKE_OPTIONS_VID} from "../../../api/constants";


const styles = theme => ({
    mB: {
        marginBottom: 25
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
});


class ChangeNumber extends Component {
    state = {
        snack: false,
        message: "",
        good: false,
    }

    setMessage = (data, status) => {
        var good = false;
        if(status === 200){
            good = true;
        }
        this.setState({message: data.message ? data.message : "Error!", good: good, snack: true});
    }

    go = values => {
        const { authState, type, loadDetail } = this.props;
        var self = this;
        var id = values['existing'];
        var status = null;

        if (type==='Repair') {
            if(!values["auto"]){
                var theyear = parseInt(values["new"].substring(0,2));
                if(theyear < 70){
                    values["year"] =  '20' + theyear.toString();
                } else {
                    values["year"] =  '19' + theyear.toString();
                }
            }
        }

        fetch(`/manage/${type.toLowerCase()}/number/${values["existing"]}/${values["new"]}${type==='Repair'? `/${values["year"]}/${values["auto"]}`: ''}`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + authState.user.auth_token
            }
        })
            .then(response  => {
                status = response.status;
                return response;
            })
            .then(result => result.json())
            .then( data => {
                if(data.error_message){
                    this.setState({message: data.error_message, good: false, snack: true});
                } else {
                    loadDetail(id, function(d){
                        self.setMessage(data, status);
                        self.formApi.resetAll();
                    });
                }
            }).catch(e => {
            var message = e.message;
            if(message && message === `{"detail":"Token has expired."}`){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({message: "Error!", good: false, snack: true});
            }
        });

    }

    validator = values => {
        const {type} = this.props;
        const checkPermit = value => {
            const len = type==='Repair'? 6:7;
            const re = /^\d+$/;
            const n = type==='Bmp'? 'B': type==='Violation'? 'V': type==='Condition'? 'C': undefined;

            if(!value || value.length !== len || !value.includes('-') || !re.test(value.substring(0,2)) || !re.test(value.substring(3,6)) || value[6]!==n){
                return `${type} number Invalid`;
            }
            return null;
        }

        const checkYear = value => {
            var re = /^\d+$/;
            if(!value || value.length !== 4 || !re.test(value.substring(0,4))){
                return "Year is Invalid";
            }
            return null;
        }

        let valObj = {
            new: (type==='Repair' && values.auto) ? null : checkPermit(values.new),
            year: (type==='Repair' && values.auto ? checkYear(values.year) : null),
            existing: values.existing ? null : `Existing ${type} Required`
        };


        return valObj
    }

    getUnique = (data) => {
        const temp = [...new Set(data.map(repair => repair.year))];
        return temp.sort((a,b) => {return parseInt(a) - parseInt(b);});
    }

    render() {
        const { classes, type, data } = this.props;
        const { good, message, snack } = this.state;
        const key = type==='Violation'? 'vnum': type==='Bmp'? 'bmpnum': type==='Condition'? 'cnum': 'repair';

        return (
            <>
                <Typography variant="h5" className={classes.grow}>Change {type} Number</Typography>
                <Divider className={classes.mB}/>
                <Form
                    id="test"
                    defaultValues={{"auto": false}}
                    dontValidateOnMount={true}
                    validateError={this.validator}
                    validateOnSubmit={true}
                    onSubmit={values => this.go(values)}
                    getApi={el => (this.formApi = el)}
                >
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={3}>
                                {type==='Repair' && (
                                    <Grid item xs={12}>
                                        <CheckBox field="auto" label={`Automatically use next available ${type.toLowerCase()} number`} />
                                    </Grid>
                                )}
                                <Grid item xs>
                                    <Select field="existing" label={`Select ${type} Number to Change`} options={MAKE_OPTIONS_VID(data, key)} fullWidth/>
                                    <TextField field="new" label={`Enter New ${type} Number`} disabled={formApi.values["auto"] ? true : false} fullWidth/>
                                </Grid>
                                {type==='Repair' && (
                                    <Grid item xs>
                                        <Select field="year" label="Year" options={MAKE_OPTIONS(this.getUnique(data))} disabled={formApi.values["auto"] ? false : true} fullWidth/>
                                    </Grid>
                                )}
                            </Grid>
                            <Button type="submit" variant="contained" color="secondary">Apply Change</Button>
                        </form>
                    )}
                </Form>
                <Snackbar
                    time={5000}
                    customMessage={message}
                    snackbarOpen={snack}
                    handleSnackbarClose={() => this.setState({ snack: false })}
                    isSuccess={good}
                    operationType="setting"
                />
            </>
        );
    }
}

ChangeNumber = connect(
    (state, ownProps) => ({
        authState: state.auth,
    })
)(ChangeNumber)

export default withStyles(styles)(withRouter(ChangeNumber));
