import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import { Form } from 'react-form';
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import RecordTable from "../common/RecordTable";
import { createSelector } from '../common/orm';
import { BoardApproval, RepairStatus } from "../common/SelectOptions";
import PageHeader from '../common/PageHeader';
import { 
    BoardDate, 
    Tracking, 
    TrackingBoard, 
    Repair,
    Contractor,
    Correspondence,
} from "../mypage/models";
import CorrespondenceForm from './CorrespondenceForm';
import DatePicker from '../common/DatePicker';
import TextField from '../common/TextField';
import Select from '../common/Select';
import DialogForm from "../common/DialogForm";
import WarningDialog from '../common/WarningDialog';
import { panelChange } from "../common/actions";
import { MAKE_OPTIONS_VID } from "../../api/constants";

const getBoardDate = BoardDate.selectAll();

const getTracking = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.Tracking.filter(sys => sys.repair === repair).orderBy("trackingdate").toRefArray();
    }
);

const getTrackingBoard = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.TrackingBoard.filter(sys => sys.repair === repair).orderBy("trackingdate").toRefArray();
    }
);

const getContractor = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        const c = session.Contractor.filter(con => con.repair === repair).first();
        if(c){
            return c.ref;
        } else {
            return null;
        }
    }
);

const getCorrespondence = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.Correspondence.filter(c => c.repair === repair).orderBy('date', 'desc').toModelArray();
    }
);

const getUsers = createSelector(session => {
    return session.User.filter(u => u.role === "Contractor").toRefArray();
});

const trackColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "status", numeric: false, label: "Status", allowSort: true },
    {id: "trackingdate", numeric: false, label: "Date", allowSort: true },
    {id: "initials", numeric: false, label: "Commenter Initials", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
]

const trackBoardColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "status", numeric: false, label: "Status", allowSort: true, type: "select" },
    {id: "trackingdate", numeric: false, label: "Date", allowSort: true, type: "date" },
    {id: "boarddate", numeric: false, label: "Board Action Date", allowSort: true},
    {id: "initials", numeric: false, label: "Commenter Initials", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
]

const correspondenceColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "user_label", numeric: false, label: "Username", allowSort: true, skipLabelReplace: true },
    {id: "recipient_label", numeric: false, label: "Recipient", allowSort: true, skipLabelReplace: true },
    {id: "date", numeric: false, label: "Date", allowSort: true },
    {id: "email_label", numeric: false, label: "Email Sent", allowSort: true, skipLabelReplace: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
];

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    flex: {
        flex: 1
    }
});

class Status extends Component {
    state = {
        dialog: false,
        deleteDialog: false,
        boardID: null,
        deleteAprrovalDialog: false,
        boardDialog: false,
        correspondenceDialog: false,
        correspondenceDelete: false,
        correspondenceID: null,
        snackOpen: false,
    }

    saveBoard = values => {
        const{ authState, boardDate, ormTrackingBoardCreate, repair, saveBad, saveGood } = this.props;

        delete values["deleteProp"];
        ormTrackingBoardCreate({
            repair: repair.id,
            drainage_id: repair.drainage,
            initials: authState.user.username,
            boarddate: boardDate[0] ? boardDate[0].date : "",
            ...values,
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
        this.props.repairUpdate();
        this.setState({boardDialog: false});
    }

    updateByLatest = (obj, included) => {
        const { status, ormRepairUpdatePromise, repair, saveBad, saveGood }  = this.props;
        var arr = [];

        if(included){
            arr = [...status, obj]
        } else {
            arr = status;
            var i = arr.findIndex(x => x.id === obj);

            if( i > -1){
                arr.splice(i,1);
            }
            if(arr.length === 0){
                ormRepairUpdatePromise({
                    id: repair.id,
                    status: "Unknown"
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                });
                return;
            }
        }

        var temp = arr.reduce((a,b) => {
            return new Date(a.trackingdate) > new Date(b.trackingdate) ? a : b;
        });

        ormRepairUpdatePromise({
            id: repair.id,
            status: temp.status,
            statusdate: temp.trackingdate
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });

    }

    addRecord = () => {
        this.setState({ active: {},dialog: true });
    }
    deleteRecord = (id) => {
        this.setState({ id: id, deleteDialog: true });
    }

    deleteBoardRecord = (id) => {
        this.setState({ boardID: id, deleteAprrovalDialog: true});
    }

    save = (values) => {
        const{ authState, boardDate, ormTrackingCreate, repair, saveBad, saveGood } = this.props;
        ormTrackingCreate({
            repair: repair.id,
            drainage_id: repair.drainage,
            initials: authState.user.username,
            boardactiondate: boardDate[0] ? boardDate[0].date : "",
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        
        this.updateByLatest(values, true);
        this.setState({dialog: false});
    }

    validator = (values) => ({ status: values.status ? null : "Status is required", trackingdate: values.trackingdate ? null : "Date is required" });

    boardValidator = (values) => ({ status: values.status ? null : "Status is required" });
    addBoardRecord = () => {
        this.setState({ active: {}, boardDialog: true }); 
    }

    saveContractor = (values) => {
        const { 
            ormContractorCreate, 
            ormContractorUpdatePromise,
            repair,
            saveBad, 
            saveGood
        } = this.props;
        if(values.id){
            ormContractorUpdatePromise({
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormContractorCreate({
                ...values,
                repair: repair.id
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    if(this.formApi){
                        this.formApi.setValue("id", id);
                    }
                    saveGood();
                }
            });
        }
    }

    render(){
        const { 
            authState, 
            board, 
            classes, 
            repair, 
            contractor, 
            correspondences,
            users,
            system, 
            ormTrackingDeletePromise, 
            ormTrackingBoardDeletePromise, 
            ormCorrespondenceDeletePromise,
            status
        } = this.props;
        const { 
            boardDialog, 
            correspondenceDialog,
            dialog, 
            deleteDialog, 
            boardID, 
            deleteAprrovalDialog, 
            correspondenceID,
            correspondenceDelete,
            id 
        } = this.state;

        const viewOnly = authState.user.role === "View Only";
        const admin = authState.user.role === "Admin";
        const contractorDisabled = users.length === 0;

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Typography variant="h5" className={classes.grow}>Add Status</Typography>
                <Divider className={classes.mb}/>
                <RecordTable 
                    noScroll
                    addRecord={this.addRecord}
                    addName="Add Status"
                    columnData={trackColumnData}
                    creatable={true}
                    editable={false}
                    deletable={true}
                    data={status}
                    viewOnly={viewOnly}
                    deleteRecord={this.deleteRecord}
                />
                <Typography variant="h5" className={classes.grow}>Add Board Approval</Typography>
                <Divider className={classes.mb}/>
                <RecordTable 
                    noScroll
                    addRecord={this.addBoardRecord}
                    addName="Add Board Approval"
                    columnData={trackBoardColumnData}
                    creatable={true}
                    editable={false}
                    deletable={true}
                    data={board}
                    viewOnly={viewOnly}
                    deleteRecord={this.deleteBoardRecord}
                />
                {admin && (
                    <>
                        <Typography variant="h5" className={classes.grow}>Contractor Portal - Assignment and Correspondence</Typography>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={2} style={{marginBottom: 20}}>
                            <Grid item xs={12}>
                                <Form
                                    getApi={el => (this.formApi = el)}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    defaultValues={contractor}
                                    onSubmit={this.saveContractor}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Select 
                                                        disabled={contractorDisabled}
                                                        field="user" 
                                                        label="Contractor Username"
                                                        options={MAKE_OPTIONS_VID([{name: "-- No Selection --", id: null}, ...users], "name")} 
                                                        fullWidth 
                                                    />                     
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DatePicker 
                                                        disabled={contractorDisabled}
                                                        field="date" 
                                                        label="Date" 
                                                        fullWidth 
                                                        autoPopulate={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        disabled={contractorDisabled}
                                                        type="submit" 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Form>
                            </Grid>
                        </Grid>
                        <RecordTable 
                            noScroll
                            viewOnly={false}
                            editable={true} 
                            deletable={true}
                            creatable={true}
                            columnData={correspondenceColumnData}
                            data={correspondences}
                            addName="Add Correspondence"
                            addRecord={() => this.setState({ correspondenceDialog: true, id: null })}
                            editRecord={(values) => this.setState({correspondenceDialog: true, id: values.id})}
                            deleteRecord={(id) => this.setState({correspondenceID: id, correspondenceDelete: true})}
                        />
                    </>
                )}
                <DialogForm dialog={dialog} header="Status" onClose={() => this.setState({ dialog: false })}>
                    <Form
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        validateError={this.validator}
                        defaultValues={{status: "For Review"}}
                        onSubmit={this.save}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Select field="status" label="Status" options={RepairStatus} fullWidth required={true} />                     
                                        <DatePicker field="trackingdate" label="Date" fullWidth autoPopulate={true}/>
                                        <TextField field="notes" label="Notes" fullWidth/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogForm>  
                <DialogForm dialog={boardDialog} header="Board Approval" onClose={() => this.setState({ boardDialog: false })}>
                    <Form
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        validateError={this.boardValidator}
                        defaultValues={{status: "For Approval"}}
                        onSubmit={this.saveBoard}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Select field="status" label="Status" options={BoardApproval} fullWidth required={true} />                     
                                        <DatePicker field="trackingdate" label="Date" fullWidth autoPopulate={true} />
                                        <TextField field="notes" label="Notes" fullWidth/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogForm>  
                <CorrespondenceForm
                    open={correspondenceDialog}
                    onClose={() => this.setState({ correspondenceDialog: false })}
                    edit={true}
                    selected={id}
                    repair={repair}
                    saveBad={this.props.saveBad}
                    saveGood={this.props.saveGood}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormTrackingDeletePromise(id).then(id => {
                            if(!id){
                                this.props.saveBad();
                            } else {
                                this.props.saveGood();
                            }
                        });
                        //this.props.repairUpdate();
                        this.updateByLatest(id, false);
                        this.setState({ deleteDialog: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialog: false })}
                    open={deleteDialog}
                    title="Delete Status"
                    text={"Are you sure you wish to permanently delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormTrackingBoardDeletePromise(boardID).then(id => {
                            if(!id){
                                this.props.saveBad();
                            } else {
                                this.props.saveGood();
                            }
                        });
                        this.props.repairUpdate();
                        this.setState({ deleteAprrovalDialog: false });
                    }}
                    cancelAction={() => this.setState({ deleteAprrovalDialog: false })}
                    open={deleteAprrovalDialog}
                    title="Delete Board Approval"
                    text={"Are you sure you wish to permanently delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormCorrespondenceDeletePromise(correspondenceID).then(id => {
                            if(!id){
                                this.props.saveBad();
                            } else {
                                this.props.saveGood();
                            }
                            this.setState({correspondenceDelete: false});
                        });
                    }}
                    cancelAction={() => {
                        this.setState({correspondenceDelete: false});
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={correspondenceDelete}
                    title={"Delete Correspondence"}
                    text={"Are you sure you wish to delete?"}
                />
            </Grid>
        );
    }
}

Status = connect(
    (state, ownProps) => ({
        authState: state.auth,
        status: getTracking(state, ownProps),
        board: getTrackingBoard(state, ownProps),
        contractor: getContractor(state, ownProps),
        correspondences: getCorrespondence(state, ownProps),
        boardDate: getBoardDate(state),
        users: getUsers(state)
    }),
    {
        ...Repair.actions,
        ...Tracking.actions,
        ...TrackingBoard.actions,
        ...Contractor.actions,
        ...Correspondence.actions,
        panelChange
    }
)(Status);

export default withStyles(styles)(withRouter(Status));
