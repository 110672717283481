/******************************************************************************
 * Brings together all the tabs for Violation.
 *
 * Author: Mitchell Lane
 * Date: 1/7/20
 *******************************************************************************/
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';
import Attachments from "../violation/Attachments";
import AuthorityAction from "../violation/AuthorityActions";
import Description from "../violation/Description";
import Inspections from "../violation/Inspections";
import Notification from "../violation/Notification";
import OverView from "../violation/OverView";

import { getFormattedDate } from '../common/CommonFunction';
import { createSelector, reloadByType } from '../common/orm';
import { DrainageSystem, Violation } from "../mypage/models";

const getViolation = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["violation"]),
    (session, violationID) => {
        return session.Violation.filter(v => v.id === violationID).toRefArray()[0];
    }
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = theme => ({
  pt: {
    marginTop: '80px',
    backgroundColor: 'white !important',
    color: 'black'
  },
});

class ViolationPage extends Component {

    constructor(props){
      super(props);

      this.state = {
        panel: 0,
        fileSize: false, 
        mounted: false
      }

      // Load Information For Violation
      if(props.authState && props.authState.user){
        props.reloadByType(props.match.params.violation, "violation");
        props.ormDrainageSystemLoadDetail(parseInt(props.match.params.drainage));
        props.ormViolationLoadDetail(props.match.params.violation);
      }
    }

    handleChange = (event, newValue) => {
      this.setState({panel: newValue});
    };
    
    //update modified date and user 
    updateViolation= () => {
      const { authState, violation, ormViolationUpdate } = this.props;

      ormViolationUpdate({
        id: violation.id,
        modified_user: authState.user.username,
        modified_date: getFormattedDate()
      })
    }

    componentDidUpdate = () => {
      const { synced } = this.props;
      const { mounted } = this.state;
      if(synced && !mounted){
        this.setState({mounted: true});
      }
    }

    // Successful save
    saveGood = () => {
      this.setState({saveGood: true});
    }
    
    // Bad Save Error
    saveBad = () => {
      this.setState({saveBad: true});
    }

    // Show File To Big Error
    fileSize = () => {
      this.setState({fileSize: true});
    }

    render() {
        const { classes, violation, synced } = this.props;
        const { fileSize, mounted, panel, saveBad, saveGood } = this.state;

        const system = this.props.match.params["system"];

        return (
            <>
            {violation &&(
            <AppBar position="fixed" className={classes.pt}>
                    <Tabs value={panel ? panel : 0} onChange={this.handleChange} aria-label="simple tabs example" 
                        scrollButtons="on"
                        variant="scrollable">
                        <Tab label="OverView" {...a11yProps(0)} />
                        <Tab label="Description" {...a11yProps(1)} />
                        <Tab label="Notification" {...a11yProps(2)} />
                        <Tab label="Inspections" {...a11yProps(3)} />
                        <Tab label="Authority Action" {...a11yProps(4)} />
                        <Tab label="Attachments" {...a11yProps(5)} />
                    </Tabs>
            </AppBar>
            )}
            <AppContainer authenticated adminPage={true}>
                {violation ?
                  (
                    !synced && !mounted ? 
                      <NotReady />
                      :
                      <>
                          {panel === 1 && (
                              <Description 
                                violation={violation} 
                                system={system} 
                                updateViolation={this.updateViolation} 
                                saveGood={this.saveGood} 
                                saveBad={this.saveBad} 
                                fileSize={this.fileSize}
                              />
                          )}
                          {panel === 2 && (
                              <Notification 
                                violation={violation} 
                                system={system} 
                                updateViolation={this.updateViolation} 
                                saveGood={this.saveGood} 
                                saveBad={this.saveBad} 
                                fileSize={this.fileSize}
                              />
                          )}
                          {panel === 3 && (
                              <Inspections 
                                violation={violation} 
                                system={system} 
                                updateViolation={this.updateViolation} 
                                saveGood={this.saveGood} 
                                saveBad={this.saveBad} 
                                fileSize={this.fileSize}
                              />
                          )}
                          {panel === 4 && (
                              <AuthorityAction 
                                violation={violation} 
                                system={system} 
                                updateViolation={this.updateViolation} 
                                saveGood={this.saveGood} 
                                saveBad={this.saveBad} 
                                fileSize={this.fileSize}
                              />
                          )}
                          {panel === 5 && (
                              <Attachments 
                                violation={violation} 
                                system={system} 
                                updateViolation={this.updateViolation} 
                                saveGood={this.saveGood} 
                                saveBad={this.saveBad} 
                                fileSize={this.fileSize}
                              />
                          )}
                          {/*KEEP EVERY PANEL ABOVE OVERVIEW*/}
                          <OverView 
                            panel={panel} 
                            style={{display: (panel === 0 ? "": "none")}} 
                            violation={violation} 
                            system={system} 
                            saveGood={this.saveGood} 
                            saveBad={this.saveBad}
                          />
                          <Snackbar
                            snackbarOpen={saveGood}
                            handleSnackbarClose={() => this.setState({ saveGood: false })}
                            isSuccess
                            operationType="setting"
                          />
                          <SnackBarError open={saveBad} close={e => this.setState({saveBad: false})} />
                          <SnackBarError open={fileSize} close={e => this.setState({fileSize: false})} customMessage="File upload(s) cannot exceed 250mb!" />
                      </>
                  )
                : 
                  <>
                  {!synced && (<NotReady />)}
                  {synced && (
                    <>
                      <Typography className={classes.pt} variant="h4" align="center" gutterBottom>The Violation was not found!</Typography>
                      <Grid container direction="column" alignItems="center" justify="center">
                        <Button component={Link} to="/map">Go to DashBoard</Button>
                      </Grid>  
                    </>
                  )}
                  </>
                }
            </AppContainer>
            </>
        );
    }
}

ViolationPage = connect((state, ownProps) => ({
      authState: state.auth,
      synced: state.sync.ready,
      violation: getViolation(state, ownProps),
      lookup_loading: state.map.lookup_loading
    }),
    {
      ...Violation.actions,
      ...DrainageSystem.actions,
      reloadByType
    }
)(ViolationPage);

export default withStyles(styles)(withRouter(ViolationPage));