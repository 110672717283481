import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';

import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import RecordTable from "../common/RecordTable";
import CheckBox from "../common/Checkbox";
import { createSelector, reloadByType } from "../common/orm";
import Select from '../common/Select';
import Snackbar from '../common/Snackbar';
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";
import { Repair, Vendor } from '../mypage/models'

import { MAKE_OPTIONS_KEY, MAKE_OPTIONS, MAKE_OPTIONS_VID } from "../../api/constants";

import * as authActions from "../auth/actions";
import Delete from "./Delete";
import ChangeNumber from "../common/manage/ChangeNumber";

const getVendors = Vendor.selectAll();
const getSystems = createSelector(session => {
    return session.DrainageSystem.all().orderBy('drainage').toModelArray();
});
const getRepairs = createSelector(session => {
    return session.Repair.all().orderBy('repair').toModelArray().map( record => {
        return {
            systemname: record.drainage.drainage ? record.drainage.drainage : "",
            ...record.ref,
        };
    });
});

const styles = theme => ({
    minWidth: {
        minWidth: 400
    },
    noOverFlow: {
        overflowX: "auto"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 25
    },
    mT: {
        marginTop: 30
    },
    space: {
        marginTop: 10
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
});


class ManageRepairs extends Component {
    state = {
        snack: false,
        message: "",
        good: false,
    }

    setMessage = (data, status) => {
        var good = false;
        if(status === 200){
            good = true;
        }
        this.setState({message: data.message ? data.message : "Error!", good: good, snack: true});
    }

    go = values => {
        const { authState, ormRepairLoadDetail } = this.props;
        var self = this;
        var id = values['existing'];
        var status = null;

        if(!values["auto"]){
            var theyear = parseInt(values["new"].substring(0,2));
            if(theyear < 70){
                values["year"] =  '20' + theyear.toString();
            } else {
                values["year"] =  '19' + theyear.toString();
            }
        }

        fetch(`/manage/repair/number/${values["existing"]}/${values["new"]}/${values["year"]}/${values["auto"]}`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(response  => {
            status = response.status;
            return response;
        })
        .then(result => result.json())
        .then( data => {
            if(data.error_message){
                this.setState({message: data.error_message, good: false, snack: true});
            } else {
                ormRepairLoadDetail(id, function(d){
                    self.setMessage(data, status);
                    self.formApi.resetAll();
                });
            }
        }).catch(e => {
            var message = e.message;
            if(message && message === `{"detail":"Token has expired."}`){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({message: "Error!", good: false, snack: true});
            }
        });

    }

    add = values => {
        const { authState, ormRepairCreateLocalOnly } = this.props;
        var self = this;
        var status = null;

        if(!values["auto"]){
            var theyear = parseInt(values["new"].substring(0,2));
            if(theyear < 70){
                values["year"] =  '20' + theyear.toString();
            } else {
                values["year"] =  '19' + theyear.toString();
            }
        }

        fetch(`/manage/repair/historic/${values["existing"]}/${values["new"]}/${values["year"]}/${values["auto"]}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(response  => {
                status = response.status;
                return response;
        })
        .then(result => result.json())
        .then( data => {
            if(data.error_message){
                this.setState({message: data.error_message, good: false, snack: true});
            } else {
                var temp = JSON.parse(data.repair)
                temp['drainage'] = temp['drainage_id'];
                ormRepairCreateLocalOnly(temp);
                self.setMessage(data, status);
                self.historicFormApi.resetAll();
            }
        }).catch(e => {
            var message = e.message;
            if(message && message === `{"detail":"Token has expired."}`){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({message: "Error!", good: false, snack: true});
            }
        });
    }

    system = values => {
        const { authState, ormRepairLoadDetail } = this.props;
        var status = null;
        var self = this;
        fetch(`/manage/repair/system/${values["repair"]}/${values["new"]}` , {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(response  => {
                status = response.status;
                return response;
        })
        .then(result => result.json())
        .then( data => {
            if(data.error_message){
                this.setState({message: data.error_message, good: false, snack: true});
            } else {
                ormRepairLoadDetail(data.id, function(d){
                    self.setMessage(data, status);
                    self.systemApi.resetAll();
                    //this.props.reloadByType(data.id, "repair");
                });
            }
        }).catch(e => {
            var message = e.message;
            if(message && message === `{"detail":"Token has expired."}`){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({message: "Error!", good: false, snack: true});
            }
        });
    }

    changeDisplay = (e, formApi) => {
        const { repairs } = this.props;
        var temp = repairs.find(x => x.repair === e);
        formApi.setValue("current", temp.systemname);
    }

    order = () => {
        let order = "desc";
        order = "asc";

        order === "desc"
            ? this.props.repairs.sort((a, b) => (b["systemname"].toUpperCase() < a["systemname"].toUpperCase() ? -1 : 1))
            : this.props.repairs.sort((a, b) => (a["systemname"].toUpperCase() < b["systemname"].toUpperCase() ? -1 : 1));
    }

    genericValidator = values => {
        const checkEmpty = value => {
            if(!value && value.length === 0){
                return "Required field";
            }
            return null;
        }

        var validated = {};
        Object.keys(values).forEach(x => {
            validated[x] = checkEmpty(values[x]);
        });

        return validated;
    }

    repairValidator = values => {
        const checkPermit = value => {
            var re = /^\d+$/;
            if(!value || value.length !== 6 || !value.includes('-') || !re.test(value.substring(0,2)) || !re.test(value.substring(3,6))){
                return "Repair number Invalid";
            }
            return null;
        }

        const checkYear = value => {
            var re = /^\d+$/;
            if(!value || value.length !== 4 || !re.test(value.substring(0,4))){
                return "Year is Invalid";
            }
            return null;
        }

        return {
            new: values.auto ? null : checkPermit(values.new),
            year: (values.auto ? checkYear(values.year) : null),
            existing: values.existing ? null : "Existing Repair Required"
        };
    }

    historicValidator = values => {
        const checkPermit = value => {
            var re = /^\d+$/;
            if(!value || value.length !== 6 || !value.includes('-') || !re.test(value.substring(0,2)) || !re.test(value.substring(3,6))){
                return "Repair number Invalid";
            }
            return null;
        }

        const checkYear = value => {
            var re = /^\d+$/;
            if(!value || value.length !== 4 || !re.test(value.substring(0,4))){
                return "Year is Invalid";
            }
            return null;
        }

        return {
            new: values.auto ? null : checkPermit(values.new),
            year: (values.auto ? checkYear(values.year) : null),
            existing: values.existing ? null : "System Required"
        };
    }

    render() {
        const { classes, repairs, systems, saveBad, saveGood, ormRepairLoadDetail } = this.props;
        const { good, message, snack } = this.state;

        const temp = [...new Set(repairs.map(repair => repair.year))];
        const unique = temp.sort((a,b) => {return parseInt(a) - parseInt(b);});

        return (
            <div className={classes.space}>
                <Typography variant="h5" className={classes.grow}>Add Historic Repairs</Typography>
                <Divider className={classes.mB}/>
                <Form
                    id="test1"
                    defaultValues={{"auto": false}}
                    dontValidateOnMount={true}
                    validateError={this.historicValidator}
                    validateOnSubmit={true}
                    onSubmit={values => this.add(values)}
                    getApi={el => (this.historicFormApi = el)}
                    >
                    {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <CheckBox field="auto" label="Automatically use next available repair number" />
                                    </Grid>
                                    <Grid item xs>
                                        <Select field="existing" label="Drainage System" options={MAKE_OPTIONS_KEY(systems, "drainage")} fullWidth/>
                                        <TextField field="new" label="Enter New Repair Number" disabled={formApi.values["auto"] ? true : false} fullWidth/>
                                    </Grid>
                                    <Grid item xs>
                                        <Select field="year" label="Year" options={MAKE_OPTIONS(unique)} disabled={formApi.values["auto"] ? false : true} fullWidth/>
                                    </Grid>
                                </Grid>
                                <Button type="submit" variant="contained" color="secondary">Create Repair</Button>
                            </form>
                    )}
                </Form>
                <ChangeNumber data={repairs} loadDetail={ormRepairLoadDetail} type={'Repair'} />

                <Typography variant="h5" className={classes.grow}>Apply Repair to Different System</Typography>
                <Divider className={classes.mB}/>
                <Form
                    id="test2"
                    defaultValues={{"repair": "", "new": ""}}
                    dontValidateOnMount={true}
                    validateError={this.genericValidator}
                    validateOnSubmit={true}
                    onSubmit={values => this.system(values)}
                    getApi={el => (this.systemApi = el)}>
                    {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Select field="repair" label="Select Repair Number" options={MAKE_OPTIONS_KEY(repairs, "repair")} fullWidth eventHandle={ (e) => this.changeDisplay(e, formApi)}/>
                                        <Select field="new" label="Select new Drainage System For Repair" options={MAKE_OPTIONS_KEY(systems, "drainage")} fullWidth/>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField field="current" label="Existing System" disabled/>
                                    </Grid>
                                </Grid>
                                <Button type="submit" variant="contained" color="secondary">Apply Change</Button>
                            </form>
                    )}
                </Form>
                <Delete saveGood={saveGood} saveBad={saveBad} type={'Repair'} />
                <Snackbar
                    time={5000}
                    customMessage={message}
                    snackbarOpen={snack}
                    handleSnackbarClose={() => this.setState({ snack: false })}
                    isSuccess={good}
                    operationType="setting"
                />
            </div>
        );
    }
}

ManageRepairs = connect(
    (state, ownProps) => ({
        authState: state.auth,
        systems: getSystems(state, ownProps),
        repairs: getRepairs(state, ownProps),
        vendors: getVendors(state)
    }),
    {
        ...Repair.actions,
        ...authActions,
        reloadByType
    }
)(ManageRepairs);

export default withStyles(styles)(withRouter(ManageRepairs));