import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { getToday } from "../../api/utils";

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";

import Attachments from "../repair/Attachments";
import Description from "../repair/Description";
import Estimate from "../repair/Estimates";
import Inpsection from "../repair/Inspections";
import Invoices from "../repair/Invoices";
import People from "../repair/People";
import OverView from "../repair/OverView";
import Status from "../repair/Status";
import Correspondence from "../repair/Correspondence";

import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";
import Snackbar from "../common/Snackbar";
import SnackBarError from '../common/SnackBarError';
import { createSelector, reloadByType } from '../common/orm';
import { DrainageSystem, Repair, Peoplelandowner, PeopleRequest } from "../mypage/models";

function getLatest(list){
  var mostRecentDate = new Date(Math.max.apply(null, list.map( e => {
    return new Date(e.trackingdate);
  })));

  return list.filter( e => { 
    var d = new Date( e.trackingdate ); 
    return d.getTime() === mostRecentDate.getTime();
  })[0];
}

const getRepair = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["repair"]),
    (session, repairID) => {
        return session.Repair.filter(r => r.id === repairID).toModelArray().map(repair => {
          return {
            latestTracking: getLatest(repair.repair_tracking.all().toRefArray()),
            ...repair.ref
          }
        })[0];
    }
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  pl: {
    overflow: 'auto'
  },
  pt: {
    marginTop: '80px',
    backgroundColor: 'white !important',
    color: 'black'
  },
});

class RepairPage extends Component {

    constructor(props){
      super(props);

      this.state = {
        panel: 0,
        fileSize: false,
        mounted: false,
        saveGood: false,
        saveBad: false,
        pageInvalid: false,
        mapScreenShot: null,
        lockInspectionTab: true
      };

      if(props.authState && props.authState.user){
        props.ormRepairLoadDetail(parseInt(props.match.params.repair));
        props.ormDrainageSystemLoadDetail(parseInt(props.match.params.drainage));
        props.reloadByType(parseInt(props.match.params.repair), "repair");
      }
    }

    validateEmail = (invalid, value) => {
      if(invalid) return true;
      
      if (value) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !re.test(value) ? true : false;
      }
      return false;
    }

    validatePhoneAndFax = (invalid, value) => {
      if(invalid) return true;
      
      if(value){
          var re = /^\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/;
          return !re.test(value) ? true : false;
      }
      return false;
    }

    fullRepairUpdate = (values, landowner, request) => {
      const { authState,  ormRepairUpdateLocalOnly, ormPeoplelandownerUpdateLocalOnly, ormPeopleRequestUpdateLocalOnly } = this.props;

      var payload = {...values};

      var peoplePageInvalid = false;
      if(landowner.id && landowner.id > 0){
        payload["peoplelandowner"] = {...landowner, modified_user: authState.user.username, modified_date: getToday()};
        peoplePageInvalid = this.validateEmail(peoplePageInvalid, payload["peoplelandowner"]["email"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplelandowner"]["fax"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplelandowner"]["phone"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplelandowner"]["mobile"]);
      } else {
        delete landowner.id;
        payload["createpeoplelandowner"] = {...landowner, modified_user: authState.user.username, modified_date: getToday()};
        peoplePageInvalid = this.validateEmail(peoplePageInvalid, payload["createpeoplelandowner"]["email"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["createpeoplelandowner"]["fax"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["createpeoplelandowner"]["phone"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["createpeoplelandowner"]["mobile"]);
      }

      if(request.id && request.id > 0){
        payload["peoplerequest"] = {...request, modified_user: authState.user.username, modified_date: getToday()};
        peoplePageInvalid = this.validateEmail(peoplePageInvalid, payload["peoplerequest"]["email"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplerequest"]["fax"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplerequest"]["phone"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["peoplerequest"]["mobile"]);
      } else {
        delete request.id;
        payload["creatpeoplerequest"] = {...request, modified_user: authState.user.username, modified_date: getToday()};
        peoplePageInvalid = this.validateEmail(peoplePageInvalid, payload["creatpeoplerequest"]["email"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["creatpeoplerequest"]["fax"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["creatpeoplerequest"]["phone"]);
        peoplePageInvalid = this.validatePhoneAndFax(peoplePageInvalid, payload["creatpeoplerequest"]["mobile"]);
      }


      if(peoplePageInvalid){
        this.setState({pageInvalid: true});
        return;
      }
        


      fetch(`api/db/repairs/${payload.id}?t=${Date.now()}`, {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + authState.user.auth_token
        }
      })
      .then(result => {
        if (!result.ok) {
            return result.text().then(text => {
                throw new Error(text);
            });
        } else {
            return result.json();
        }
      })
      .then(data => {

          if(data.owner.id){
            ormPeoplelandownerUpdateLocalOnly({
              ...data.owner,
              repair: data.owner.repair_id,
              drainage: data.owner.drainage_id
            });
          }

          if(data.person.id){
            ormPeopleRequestUpdateLocalOnly({
              ...data.person,
              repair: data.owner.repair_id,
              drainage: data.owner.drainage_id
            });
          }

          ormRepairUpdateLocalOnly({
            ...data.repair
          });
          this.saveGood();
      })
      .catch(e => {
          this.saveBad();
      });

    }

    handleChange = (event, newValue) => {
      if(this.overViewChild){
        this.overViewChild.screenShot().then(data => {
          this.setState({mapScreenShot: (data && data.dataUrl ? data.dataUrl : null), panel: newValue});
        });
      } else {
        this.setState({panel: newValue});
      }
    };

    repairUpdate = () => {
      const { ormRepairUpdate, repair } = this.props;
      ormRepairUpdate({id: repair.id});
    };

    repairLocalUpadte = () => {
      const { authState, ormRepairUpdateLocalOnly, repair } = this.props;
      ormRepairUpdateLocalOnly({
        id: repair.id,
        modified_user: authState.user ? authState.user.username : "",
        modified_date: getToday()
      });
    };

    componentDidUpdate = () => {
      const { synced } = this.props;
      const { mounted } = this.state;
      if(synced && !mounted){
        this.setState({mounted: true});
      }
    }

    // Successful save
    saveGood = () => {
      this.setState({saveGood: true});
    }
    
    // Bad Save Error
    saveBad = () => {
      this.setState({saveBad: true});
    }

    // Show File To Big Error
    fileSize = () => {
      this.setState({fileSize: true});
    }

    openInspectionTab = () => {
      this.setState({lockInspectionTab: false});
    }

    render() {
        const { 
          classes, 
          repair, 
          synced,
          authState
        } = this.props;
        const { 
          lockInspectionTab, 
          fileSize, 
          mapScreenShot, 
          mounted, 
          pageInvalid, 
          panel, 
          saveBad, 
          saveGood 
        } = this.state;

        const system = this.props.match.params["system"];
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        return (
            <>
            {repair &&(
            <AppBar position="fixed" className={classes.pt}>
                    {!contractor && (
                      <Tabs value={panel ? panel : 0} onChange={this.handleChange} aria-label="simple tabs example" 
                          scrollButtons="on"
                          variant="scrollable">
                          <Tab disabled={!synced && !mounted} label="OverView" {...a11yProps(0)} />                        
                          <Tab disabled={!synced && !mounted} label="Description" {...a11yProps(1)} />
                          <Tab disabled={!synced && !mounted} label="People" {...a11yProps(2)} />
                          <Tab disabled={!synced && !mounted} label="Status/Tracking" {...a11yProps(3)} />
                          <Tab disabled={(!synced && !mounted) || lockInspectionTab} label="Inspections" {...a11yProps(4)} />
                          <Tab disabled={!synced && !mounted} label="Estimates" {...a11yProps(5)} />
                          <Tab disabled={!synced && !mounted} label="Invoices" {...a11yProps(6)} />
                          <Tab disabled={!synced && !mounted} label="Attachments" {...a11yProps(7)} />
                      </Tabs>
                    )}
                    {contractor && (
                      <Tabs value={panel ? panel : 0} onChange={this.handleChange} aria-label="simple tabs example" 
                      scrollButtons="on"
                      variant="scrollable">
                        <Tab disabled={!synced && !mounted} label="OverView" {...a11yProps(0)} />
                        <Tab disabled={(!synced && !mounted) || lockInspectionTab} label="Correspondence" {...a11yProps(1)} />
                        <Tab disabled={(!synced && !mounted) || lockInspectionTab} label="Inspections" {...a11yProps(2)} />
                        <Tab disabled={!synced && !mounted} label="Invoices" {...a11yProps(3)} />
                        <Tab disabled={!synced && !mounted} label="Attachments" {...a11yProps(4)} />
                      </Tabs>
                    )}
            </AppBar>
            )}
            <AppContainer authenticated adminPage={true}>
                {repair ?
                  (
                    !synced && !mounted ? 
                      <NotReady />
                      :
                      <>
                          {!contractor && (
                            <>
                              {panel === 1 && (
                                <Description 
                                  fullRepairUpdate={this.fullRepairUpdate} 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad}
                                />
                              )}
                              {panel === 2 && (
                                <People 
                                  repair={repair} 
                                  system={system} 
                                  fullRepairUpdate={this.fullRepairUpdate}
                                   repairUpdate={this.repairUpdate} 
                                   repairLocalUpadte={this.repairLocalUpadte} 
                                   saveGood={this.saveGood} 
                                   saveBad={this.saveBad}
                                  />
                              )}
                              {panel === 3 && (
                                <Status 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad}
                                />
                              )}
                              {panel === 4 && (
                                <Inpsection 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  mapScreenShot={mapScreenShot} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                              {panel === 5 && (
                                <Estimate 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  repairLocalUpadte={this.repairLocalUpadte} 
                                  saveGood={this.saveGood} saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                              {panel === 6 && (
                                <Invoices 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  repairLocalUpadte={this.repairLocalUpadte} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                              {panel === 7 && (
                                <Attachments 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  saveGood={this.saveGood}
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                            </>
                          )}
                          {contractor && (
                            <>
                              {panel === 1 && (
                                <Correspondence
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                />
                              )}
                              {panel === 2 && (
                                <Inpsection 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  mapScreenShot={mapScreenShot} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                              {panel === 3 && (
                                <Invoices 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  repairLocalUpadte={this.repairLocalUpadte} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                              {panel === 4 && (
                                <Attachments 
                                  repair={repair} 
                                  system={system} 
                                  repairUpdate={this.repairUpdate} 
                                  saveGood={this.saveGood} 
                                  saveBad={this.saveBad} 
                                  fileSize={this.fileSize}
                                />
                              )}
                            </>
                          )}
                          {/*KEEP EVERY PANEL ABOVE OVERVIEW*/}
                          <OverView 
                            ref1={r => this.overViewChild = r} 
                            openInspectionTab={this.openInspectionTab} 
                            panel={panel} 
                            style={{display: (panel === 0 ? "": "none")}} 
                            fullRepairUpdate={this.fullRepairUpdate} 
                            repair={repair} 
                            system={system} 
                            repairUpdate={this.repairUpdate} 
                            saveGood={this.saveGood} 
                            saveBad={this.saveBad}
                          />
                          
                          <Snackbar
                            snackbarOpen={saveGood}
                            handleSnackbarClose={() => this.setState({ saveGood: false })}
                            isSuccess
                            operationType="setting"
                          />
                          <SnackBarError open={saveBad} close={e => this.setState({saveBad: false})} />
                          <SnackBarError open={pageInvalid} close={e => this.setState({pageInvalid: false})} customMessage="Please fix values with red on People tab!" />
                          <SnackBarError open={fileSize} close={e => this.setState({fileSize: false})} customMessage="File upload(s) cannot exceed 250mb!" />
                      </>
                  )
                : 
                  <>
                    {!synced && (<NotReady />)}
                    {synced && (
                      <>
                        <Typography className={classes.pt} variant="h4" align="center" gutterBottom>The Repair was not found!</Typography>
                        <Grid container direction="column" alignItems="center" justify="center">
                          <Button component={Link} to="/map">Go to DashBoard</Button>
                        </Grid>  
                      </>
                    )}
                  </>
                }
            </AppContainer>
            </>
        );
    }
}

RepairPage = connect((state, ownProps) => ({
      map: state.map,
      authState: state.auth,
      synced: state.sync.ready,
      repair: getRepair(state, ownProps),
      panel: state.nav.panel
    }),
    {
      ...Peoplelandowner.actions,
      ...PeopleRequest.actions,
      ...DrainageSystem.actions,
      ...Repair.actions,
      reloadByType
    }
)(RepairPage);

export default withStyles(styles)(withRouter(RepairPage));
