import {
    NAV_TOGGLE_NAVIGATION,
    NAV_TOGGLE_FOLDER,
    NAV_TOGGLE_ADMIN,
    NAV_TOGGLE_CONFIG,
    NAV_SELECT_PAGE,
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY,
    SEARCH_INPUT,
    SEARCH_INPUT_CLEAR,
    PANEL_INIT,
    PANEL_CHANGE
} from "./actions";

function navReducer(state = {}, action) {
    switch (action.type) {
        case NAV_TOGGLE_NAVIGATION:
            // If closing the navigation panel then also collapse the main menus
            // Don't worry about sub-menus - it is better to retain their status
            if (state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    folderOpen: false,
                    adminOpen: false,
                    configOpen: false,
                    adminOpenPrevious: state.adminOpen,
                    configOpenPrevious: state.configOpen,
                    folderOpenPrevious: state.folderOpen,
                };
            } else {
                // If they have a previous state re-open to that
                if ("folderOpenPrevious" in state) {
                    return {
                        ...state,
                        navigationOpen: !state.navigationOpen,
                        adminOpen: state.adminOpenPrevious,
                        configOpen: state.configOpenPrevious,
                        folderOpen: state.folderOpenPrevious,
                    };
                } else {
                    return {
                        ...state,
                        proposalsOpen: true,
                        navigationOpen: !state.navigationOpen
                    };
                }
            }

        case NAV_TOGGLE_FOLDER:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    folderOpen: true
                };
            } else {
                return {
                    ...state,
                    folderOpen: !state.folderOpen
                };
            }

        case NAV_TOGGLE_ADMIN:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    adminOpen: true
                };
            } else {
                return {
                    ...state,
                    adminOpen: !state.adminOpen
                };
            }

        case NAV_TOGGLE_CONFIG:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    configOpen: true
                };
            } else {
                return {
                    ...state,
                    configOpen: !state.configOpen
                };
            }
            
        case NAV_SELECT_PAGE:
            return {
                ...state,
                selectedPage: action.page
            };

        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true }
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true }
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true }
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true }
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true }
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true
            };

        case SEARCH_INPUT:
            return {
                ...state,
                searchInput: action.input,
                searchKey: action.key
            };
        
        case SEARCH_INPUT_CLEAR:
            return {
                ...state,
                searchInput: null,
                key: null
            };
        
        case PANEL_INIT:
            return {
                ...state,
                panel: 0
            };

        case PANEL_CHANGE:
            return {
                ...state,
                panel: action.panel
            };

        default:
            return state;
    }
}

export default navReducer;
