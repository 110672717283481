import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import InputBase from '@material-ui/core/InputBase';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Map from "@material-ui/icons/Map";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HelpIcon from "@material-ui/icons/Help";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import Hidden from '@material-ui/core/Hidden';

import pack from "../../../package.json";
import County from './counties.json';

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAdmin, clearAll, ormLogOutSync } from "./orm";

const image = require('../../images/drainagedbBanner.png');

const styles = theme => ({
    topListText: {
        textAlign: "center",
        padding: 0
    },
    versionPadding: {
        paddingLeft: "24px !important"
    },
    updateReady: {
        color: "#fff",
        backgroundColor: "#4e4e4e",
        position: "absolute",
        width: "100%",
        height: 88
    },
    noHover: {
        marginLeft: -16,
        '@media(max-width: 500px)': {
            width: 150,
            paddingTop: 15
        }
    },
    rm: {
        "&:hover": {
            cursor: 'pointer'
        },
        marginLeft: -16,
        '@media(max-width: 500px)': {
            width: 150,
            paddingTop: 15
        }
    },
    appLogo: {
        width: 115
    },
    zIndex: {
        zIndex: 1300,
    },
    listItem: {
        borderBottom: "1px solid #666666"
    },
    alertText: {
        color: "#666666"
    },
    topListItem: {
        borderBottom: "1px solid #666666",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    headerButton: {
        verticalAlign: "top",
        margin: "16px 8px 8px 8px",
        color: "#fff",
        "& svg": {
            fontSize: 32
        }
    },
    flex: {
        display: "flex",
        flexFlow: "row wrap",
        height: 80
    },
    flexCenter: {
        color: "white",
        textAlign: "right",
        marginTop: 24,
        flex: "1 auto"
    },
    flexRight: {
        textAlign: "right",
        flex: "1 auto",
        marginTop: -4,
    },
    hideSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    hideLarge: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    userLogo: {
        maxHeight: 64
    },
    minWidth: {
        minWidth: 400
    },
    flexButton: {
        flex: 1
    },
    centerAlign: {
        textAlign: "center"
    },
    search: {
        display: "inline-flex"
    },
    searchIcon: {
        marginRight: 5,
        "&:hover": {
            cursor: 'pointer'
        },
    }
});

class Header extends Component {
    constructor(props) {
        super();

        var subdomain = "Dev";
        var temp = window.location.hostname.split('.')[0].replace("new", "");
        if(window.location.hostname.split('.').length === 3){
            County.forEach(x => {
                if(x.id === temp){
                    subdomain = x.label;
                }
            })
        } 

        this.state = {
            userMenuAnchor: null,
            smallAnchor: null,
            searchMenuAnchor: null,
            openDialog: false,
            search: props && props.navState && props.navState.searchInput ? props.navState.searchInput : null,
            subdomain
        };
    }

    search = (e) => {
        const { searchInput } = this.props;
        const { search } = this.state;
        e.preventDefault();
        searchInput(search, window.performance.now());
        this.props.history.push("/search");
    }

    goToAdmin = (e) => {
        if(this.props.location.pathname === "/admin"){
            //this.props.history.push("/");
            //this.props.history.goBack();
        } else {
            this.props.history.push("/admin");
        }
    }
    updateuser = (e) => {
        this.props.history.push("/updateuser");
    }
    goToDashBoard = (e) => {
        if(this.props.location.pathname !== "/portal"){
            this.props.history.push("/map");
        } else {
            window.location.reload();
        }
    }

    handleUserMenu = event => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };
    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };
    handleSmallMenu = event => {
        this.setState({ smallAnchor: event.currentTarget });
    };
    handleSmallMenuClose = () => {
        this.setState({ smallAnchor: null });
    };
    handleSearchMenu = event => {
        this.setState({ searchMenuAnchor: event.currentTarget });
    }
    handleSearchMenuClose = event => {
        this.setState({ searchMenuAnchor: null });
    }

    logOut = () => {
        const { clearAll, ormLogOutSync } = this.props;
        this.props.authLogout();
        this.props.history.push("/");
        clearAll();
        ormLogOutSync();
    };

    render() {
        const { classes, authenticated, authState, navState, hideToolbar } = this.props;
        const { search, subdomain, userMenuAnchor, searchMenuAnchor, smallAnchor } = this.state;
        const userMenuOpen = Boolean(userMenuAnchor);
        const smallMenuOpen = Boolean(smallAnchor);
        const searchMenuOpen = Boolean(searchMenuAnchor);

        if (!authState) return "...";

        var is_admin = false;
        if(authState && authState.user && authState.user.role === "Admin") is_admin = true;

        const contractor = authState && authState.user && authState.user.role === "Contractor";
        const updateReady = navState.sw && navState.sw.update;

        var { user } = authState;
        if (!user) {
            user = {};
            if (authenticated) {
                // Page requires authentication
                if (!navState.storeReady) {
                    // Give store a chance to reload
                    user.city_name = "...";
                } else {
                    user.city_name = "Not Logged In!";
                }
            }
        }

        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the application is available. Please save your work and refresh your
                            browser. &nbsp;
                        </Typography>
                        <Button color="default" variant="contained" onClick={() => window.location.reload()}>
                            Refresh Now
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return authenticated ? ( // Logged In
            <AppBar position="fixed" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                {!updateReady && (
                <Toolbar>
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <div className={classes.flex}>
                                <div>
                                    <Tooltip title="Dashboard" placement="bottom" enterDelay={300}>
                                        <IconButton style={{padding: 0}} onClick={this.goToDashBoard}>
                                            <img src={image} className={classes.rm} alt="DrainageDB Logo"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Hidden xsDown>
                                    <div className={classes.flexCenter}>
                                        <Typography variant="h5">
                                            {subdomain}
                                        </Typography>
                                    </div>
                                </Hidden>
                                {!hideToolbar && (
                                    <div className={classes.flexRight}>
                                            <Tooltip className={classes.hideLarge} title="Menu" placement="bottom-start" enterDelay={300}>
                                                <IconButton className={classes.headerButton} onClick={this.handleSmallMenu}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Hidden xsDown>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        color: "#FFFFFF",
                                                        fontSize: "large",
                                                        display: "inline-block",
                                                        marginTop: 32
                                                    }}>
                                                    {user.name && "Hi " + user.name + ` (${user.role})`}
                                                </Typography>
                                            </Hidden>
                                            <Tooltip className={classes.hideSmall} title="Map" placement="bottom-start" enterDelay={300}>
                                                <IconButton className={classes.headerButton} component={Link} to="/map">
                                                    <Map />
                                                </IconButton>
                                            </Tooltip>
                                            {!contractor && (
                                                <Tooltip className={classes.hideSmall} title="Quick Search For Repairs" placement="bottom-start" enterDelay={300}>
                                                    <IconButton aria-label="Quick Search For Repairs" className={classes.headerButton} onClick={this.handleSearchMenu}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip className={classes.hideSmall} title="Contact Support" placement="bottom-start" enterDelay={300}>
                                                <IconButton aria-label="Contact Support" className={classes.headerButton} component="a" href="mailto:DrainageDB@houstoneng.com">
                                                    <HelpIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip className={classes.hideSmall} title="Account Options" placement="bottom-start" enterDelay={300}>
                                                <IconButton aria-label="Account Options" className={classes.headerButton} onClick={this.handleUserMenu}>
                                                    <AccountCircle />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Menu
                            id="menu-appbar"
                            anchorEl={userMenuAnchor}
                            classes={{
                                paper: classes.list
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={userMenuOpen}
                            onClose={this.handleUserMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Account Options</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText
                                    }}
                                />
                            </ListItem>
                            {is_admin && (
                            <ListItem button onClick={this.goToAdmin} className={classes.listItem}>
                                <ListItemIcon>
                                    <SupervisorAccountIcon />
                                </ListItemIcon>
                                <ListItemText primary="Admin" />
                            </ListItem>
                            )}
                            <ListItem button onClick={this.updateuser} className={classes.listItem}>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Update Profile" />
                            </ListItem>
                            <ListItem button onClick={this.logOut} className={classes.listItem}>
                                <ListItemIcon>
                                    <ExitIcon />
                                </ListItemIcon>
                                <ListItemText primary="Log Out" />
                            </ListItem>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText inset primary={"Version " + pack.version}/>
                            </ListItem>
                        </Menu>

                        <Menu
                            id="menu-appbar"
                            anchorEl={searchMenuAnchor}
                            classes={{
                                paper: classes.list
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={searchMenuOpen}
                            onClose={this.handleSearchMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Quick search for repairs</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText
                                    }}
                                />
                            </ListItem>
                            <ListItem button className={classes.topListItem}>
                                <form onSubmit={this.search} className={classes.search}>
                                    <div className={classes.searchIcon} onClick={this.search}>
                                        <SearchIcon/>
                                    </div>
                                    <InputBase
                                        value={search}
                                        onChange={e => this.setState({search: e.target.value})}
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </form>
                            </ListItem>
                        </Menu>

                        <Menu
                            id="menu-small"
                            anchorEl={smallAnchor}
                            classes={{
                                paper: classes.list
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={smallMenuOpen}
                            onClose={this.handleSmallMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Navigation Menu</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText
                                    }}
                                />
                            </ListItem>
                            <ListItem button component={Link} to="/map" className={classes.listItem}>
                                <ListItemIcon>
                                    <Map />
                                </ListItemIcon>
                                <ListItemText primary="Map" />
                            </ListItem>
                            {!contractor && (
                                <ListItem button onClick={this.handleSearchMenu} className={classes.listItem}>
                                    <ListItemIcon>
                                        <SearchIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Search" />
                                </ListItem>
                            )}
                            <ListItem button component="a" href="mailto:gis@houstoneng.com" className={classes.listItem}>
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact Support" />
                            </ListItem>
                            <ListItem button onClick={this.handleUserMenu} className={classes.listItem}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary="Account Options" />
                            </ListItem>
                        </Menu>
                </Toolbar>)}
            </AppBar>
        ) : (
            <>
                {!user.auth_token && (
                    <AppBar position="absolute" className={classes.zIndex}>
                        {updateReady && <UpdateHeader />}
                        {!updateReady && (
                            <Toolbar>
                                <img src={image} className={classes.noHover} alt="DrainageDB Logo"/>
                            </Toolbar>
                        )}
                    </AppBar>
                )}
                {user.auth_token && (
                    <AppBar position="absolute" className={classes.zIndex}>
                        {updateReady && <UpdateHeader />}
                        {!updateReady && (
                            <Toolbar>
                                <Tooltip title={this.props.location.pathname !== "/portal" ? "Dashboard" : "Portal"} placement="bottom" enterDelay={300}>
                                    <IconButton style={{padding: 0}} onClick={this.goToDashBoard}>
                                        <img src={image} style={{cursor: "pointer"}} alt="DrainageDB Logo" />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        )}
                    </AppBar>
                )}
            </>
        );
    }
}

Header = connect(
    state => ({ authState: state.auth, navState: state.nav }),
    {
        ...authActions,
        ...navActions,
        reloadAdmin,
        clearAll,
        ormLogOutSync
    }
)(Header);

export default withStyles(styles)(withRouter(Header));
