import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Delete from "./Delete";
import {createSelector, reloadByType} from "../common/orm";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Form} from "react-form";
import Grid from "@material-ui/core/Grid";
import Select from "../common/Select";
import {MAKE_OPTIONS_VID} from "../../api/constants";
import TextField from "../common/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "../common/Snackbar";
import * as authActions from "../auth/actions";
import {Condition} from "../mypage/models";
import ChangeNumber from "../common/manage/ChangeNumber";

const getConditions = createSelector(session => {
    return session.Condition.all().orderBy('cnum').toRefArray();
});

const styles = theme => ({
    mB: {
        marginBottom: 25
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
});

class ManageConditions extends Component {

    render() {
        const { classes, conditions, saveGood, saveBad, ormConditionLoadDetail } = this.props;

        return (
            <div className={classes.space}>
                <ChangeNumber data={conditions} loadDetail={ormConditionLoadDetail} type={'Condition'} />
                <Delete saveGood={saveGood} saveBad={saveBad} type={'Condition'} />
            </div>
        );
    }
}

ManageConditions = connect(
    (state, ownProps) => ({
        authState: state.auth,
        conditions: getConditions(state, ownProps),
    }),
    {
        ...Condition.actions,
        ...authActions,
        reloadByType
    }
)(ManageConditions);

export default withStyles(styles)(withRouter(ManageConditions));
