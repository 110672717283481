import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Delete from "./Delete";
import {createSelector, reloadByType} from "../common/orm";
import * as authActions from "../auth/actions";
import {Violation} from "../mypage/models";
import ChangeNumber from "../common/manage/ChangeNumber";

const getViolations = createSelector(session => {
    return session.Violation.all().orderBy('vnum').toModelArray();
});

const styles = theme => ({
});

class ManageViolations extends Component {

    render() {
        const { classes, violations, saveGood, saveBad, ormViolationLoadDetail } = this.props;

        return (
            <div className={classes.space}>
                <ChangeNumber data={violations} loadDetail={ormViolationLoadDetail} type={'Violation'} />
                <Delete saveGood={saveGood} saveBad={saveBad} type={'Violation'} />
            </div>
        );
    }
}

ManageViolations = connect(
    (state, ownProps) => ({
        authState: state.auth,
        violations: getViolations(state, ownProps),
    }),
    {
        ...Violation.actions,
        ...authActions,
        reloadByType
    }
)(ManageViolations);

export default withStyles(styles)(withRouter(ManageViolations));
