import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {Form} from "react-form";
import { addToForm } from "../../api/utils";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToolBar from "@material-ui/core/Toolbar";
import SaveIcon from '@material-ui/icons/Save';

import Checkbox from "../common/Checkbox";
import DatePicker from "../common/DatePicker";
import PrimaryLocation from "../common/drainage/PrimaryLocation";
import TextField from "../common/TextField";
import LargeFileTable from "../common/LargeFileTable";

import { createSelector } from '../common/orm';

import { Condition, PhotosCondition } from "../mypage/models";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";

const getConditionPhotos = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["condition"]),
    (session, condition) => {
        var temp = session.PhotosCondition.filter(x => x.condition=== condition).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "doc";
        });
        return temp;
    }
)

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    dt: {
        paddingTop: 22
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
});

const columnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: true, type: "date" },
    {id: "caption", numeric: false, label: "Document Title", allowSort: false, required: true, type: "text" },
    {id: "notes", numeric: false, label: "Notes", allowSort: false, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, accept: "image/*", type: "file", required:true },
]
const keyData = columnData.slice(1);

class Description extends Component {
    state = {
        forceReset: performance.now()
    }
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { condition, ormConditionUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormConditionUpdateLocalOnly({
            ...state.values,
            thegeom: condition.thegeom
        });
    }

    componentDidUpdate(prevProps, prevState) {
        var updateForm = false;
        Object.entries(this.props).forEach(([key, val]) => {
          if(prevProps[key] !== val && key === "condition"){
            updateForm = true;
          }
        });

        if(updateForm && this.formApi){
            const base_keys = ["township", "twp", "range", "commissioner", "section", "quarter", "parcelnum", "landowner"];
            base_keys.forEach(x => {
                if(x in this.props.condition){
                    this.formApi.setValue(x, this.props.condition[x]);
                }
            });
        }
    }

    save = values => {
        const { condition, ormConditionUpdatePromise, saveBad, saveGood } = this.props;
        ormConditionUpdatePromise({
            ...values,
            thegeom: condition.thegeom
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    submitPhoto = (values, file_interface) => {
        const { condition, ormConditionUpdate, ormPhotosConditionUpdatePromise, ormPhotosConditionCreateForm, saveBad, saveGood } = this.props;
        delete values["deleteProp"];
        if(values.id){
            ormPhotosConditionUpdatePromise({
                drainage_id: condition.drainage_id,
                condition_id: condition.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        } else {
            var form_data = new FormData();
            form_data.append('drainage_id', condition.drainage_id);
            form_data.append('condition_id', condition.id);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'notes', values.notes);
            addToForm(form_data, 'uploaddate', values.uploaddate);
            form_data.append('link', file_interface, file_interface.name);
            
            ormPhotosConditionCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }
        ormConditionUpdate({
            id: condition.id, 
            drainage_id: condition.drainage_id
        });
    }

    deleteDoc = (id) => {
        const { ormConditionUpdate, condition, saveBad, saveGood } = this.props;
        this.props.ormPhotosConditionDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        ormConditionUpdate({
            id: condition.id, 
            drainage_id: condition.drainage_id
        });
    }

    render(){
        const { authState, classes, condition, fileSize, system, photos } = this.props;
        const { forceReset } = this.state;
        const viewOnly = authState.user.role === "View Only";

        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system} rightTitle={"Condition #: " + condition.cnum} />
                <Form 
                    ref={this.Formref}
                    getApi={el => (this.formApi = el)}
                    id="descriptionform"
                    defaultValues={condition}
                    onSubmit={this.save}>
                    {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <ToolBar disableGutters>
                            <Typography variant="h5" className={classes.grow}>Description</Typography>
                            <Button
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                style = {{float: "right", display: viewOnly && "none "}}
                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                        </ToolBar>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid xs>
                                        <Checkbox field="monitor" label="Monitor" disabled={viewOnly}/>
                                    </Grid>
                                    <Grid xs>
                                        <DatePicker field="conditiondate" label="Date Created" autoPopulate={true} disabled={viewOnly}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField maxWords={255} condition={condition} field="landowner" label="Landowner Name" fullWidth disabled={viewOnly}/>
                                <TextField maxWords={2000} field="notes" label="Notes" multiline rows={2} fullWidth disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField maxWords={255} field="branch" label="Branch" disabled={viewOnly}/>
                            </Grid>
                        </Grid>
                        <PrimaryLocation record={condition} formApi={formApi} type="Condition"/>
                    </form>
                    )}
                </Form>
                <Typography variant="h5" className={classes.grow}>Photos</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable
                    name="condition_photo" 
                    id="condition-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly}
                    by="uploaddate"
                    data={photos}
                    noHelp
                    title="Photo"
                    emptyTxt="No Photos"
                    columns={columnData} 
                    keys={keyData}
                    parentSubmitForm={this.submitPhoto}
                    addFiles={true}
                    forceReset={forceReset}
                    fileSize={fileSize}
                    deleteProp={{"doc": this.deleteDoc}}
                />
            </div>
        );
    }
}

Description = connect(
    (state, ownProps) => ({
        authState: state.auth,
        photos: getConditionPhotos(state, ownProps)
    }),
    {
        ...Condition.actions,
        ...PhotosCondition.actions,
        panelChange
    }
)(Description);

export default withStyles(styles)(withRouter(Description));