import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { withStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import RecordTable from "../common/RecordTable";
import SnackBarError from '../common/SnackBarError';
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import HelpLabel from "../common/HelpLabel";
import WarningFormDialog from "../common/WarningFormDialog";
import Submit from "../common/Submit";
import { DrainageSystem } from '../mypage/models'
import { createSelector } from "../common/orm";

const getDrainageSystems = createSelector(session => {
        return session.DrainageSystem.all().orderBy('drainage').toRefArray();
});

const styles = theme => ({
    minWidth: {
        minWidth: 400,
    },
    noOverFlow: {
        overflowX: "auto"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    space: {
        marginTop: 88
    },
    italic: {
        fontStyle: "italic"
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: "#74A94C",
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#74A94C",
        fontWeight: 500
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    primaryButton: {
        backgroundColor: "#74A94C",
        color: "#fff"
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "drainage", numeric: false, label: "System Name", allowSort: true },
    { id: "year", numeric: true, label: "Year", allowSort: true },
    { id: "ifsnumber", numeric: true, label: "IFS Number", allowSort: true },
    { id: "status", numeric: false, label: "Status", allowSort: true }
];

export function AGOLCredenitials({lock}){
    return(
        <>
            <TextField 
                field="username" 
                name="username"
                fullWidth 
                required={true} 
                disabled={lock}
            >
                <HelpLabel
                    inputLabel="ArcGIS Online Admin Username"
                    helpText={
                        <div>
                            This information is used to manage Drainage system options in ArcGIS Online when
                            creating, deleting, or unselecting a system. This information will not be saved.
                        </div>
                    }
                />
            </TextField>
            <TextField 
                field="password" 
                name="password"
                fullWidth 
                required={true} 
                disabled={lock}
            >
                <HelpLabel
                    inputLabel="ArcGIS Online Admin Password"
                    helpText={
                        <div>
                            This information is used to manage Drainage system options in ArcGIS Online when
                            creating, deleting, or unselecting a system. This information will not be saved.
                        </div>
                    }
                />
            </TextField>
        </>
    )
}

class ManageSystems extends Component {
    state = {
        activeLayer: {},
        dialog: false,
        deleteDialog: false,
        id: null,
        lock: false,
        snack: false
    };

    save = (values, formApi) => {
        const { 
            authState,
            ormDrainageSystemCreateLocalOnly, 
            systems, 
            saveBad,
            saveGood
        } = this.props;

        const existing = systems.find(x => x.drainage === values.drainage);
        
        if(existing){
            this.setState({ snack: false });
            return;
        }
	    this.setState({ lock: true });

        fetch(`${DrainageSystem.source}?format=json&t=${Date.now()}`, {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
            if (!result.ok) {
                return result.text().then(text => {
                    throw new Error(text);
                });
            } else {
                result.json().then(data => {
                    if(data && data.message){
                        formApi.setError("username", data.message);
                        formApi.setError("password", data.message);
                        this.setState({ lock: false });
                    } else {
                        ormDrainageSystemCreateLocalOnly({
                            ...data
                        });
                        this.setState({ lock: false, dialog: false });
                        saveGood();
			            this.props.history.push("drainage/" + data.id + "/"+ data.drainage);
                    }
                });
            }
        })
        .catch(e => {
            saveBad();
	    this.setState({ lock: false });
        });
    } 

    validator = values => {
        const { systems } = this.props;
        
        const checkExists = name => {
            var test = systems.find(x => x.drainage === name);
            if(test){
                return "System Name exists already";
            }
            if(values.drainage.indexOf('#') !== -1 || values.drainage.indexOf('&') !== -1 || values.drainage.indexOf('+') !== -1 || values.drainage.indexOf("'") !== -1 || values.drainage.indexOf(',') !== -1
            || values.drainage.indexOf("\\") !== -1 || values.drainage.indexOf('{') !== -1 || values.drainage.indexOf('}') !== -1)
            {
                return "Unsupporrted characters in the drainage systme name";
            }
            return null;
        };

        const isEmpty = value => {
            if(value === undefined || value === null){
                return "Required";
            }
            return null;
        }

        if(values.id){
            return {
                username: isEmpty(values.username),
                password: isEmpty(values.password),
            };
        } else {
            return {
                drainage: checkExists(values.drainage),
                username: values.update_domains ? isEmpty(values.username): null,
                password: values.update_domains ? isEmpty(values.password): null
            };
        }
    }

    addRecord = () => {
        this.setState({
            active: {
		    update_domains: false
	    },
            dialog: true
        });
    }

    deleteRecord = (id) => {
        this.setState({
            id: id,
            deleteDialog: true
        });
    }

    deleteSystem = (values, formApi) => {
        const {
	        ormDrainageSystemDeleteLocalOnly,
            authState, 
            saveBad, 
            saveGood 
        } = this.props;
        this.setState({lock: true});

        fetch(`${DrainageSystem.source}/${values.id}?&t=${Date.now()}`, {
            method: "DELETE",
            body: JSON.stringify(values),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
            if (!result.ok) {
                return result.text().then(text => {
                    throw new Error(text);
                });
            } else {
                if(result.status === 204){
                    ormDrainageSystemDeleteLocalOnly(values.id);
                    this.setState({ lock: false, deleteDialog: false });
                    saveGood();
                } else {
                    result.json().then(data => {
                        if(data && data.message){
                            formApi.setError("username", data.message);
                            formApi.setError("password", data.message);
                            this.setState({ lock: false });
                        }
                    });
                }
            }
        })
        .catch(e => {
            saveBad();
	        this.setState({ lock: false });
        });
    }

    render() {
        const { 
            classes, 
            systems, 
        } = this.props;
        const { 
            active, 
            dialog, 
            deleteDialog, 
            id, 
            lock,
            snack 
        } = this.state;

        return (
            <div className={classes.space}>
                <Typography variant="h5" className={classes.grow}>Manage Systems</Typography>
                <Typography variant="body1" className={classes.italic}>Use the table below, the filter tool or the map to identify and delete an existing system or create a new system.</Typography>
                <Divider className={classes.mB}/>
                <RecordTable 
                    noScroll
                    addRecord={this.addRecord}
                    addName="Create Drainage System"
                    columnData={columnData}
                    creatable={true}
                    deletable={true}
                    editable={false}
                    data={systems}
                    deleteRecord={this.deleteRecord}
                />
                <Dialog 
                    open={dialog} 
                    maxWidth="sm"
                    fullWidth
                >
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            System
                        </Typography>
                        <IconButton disabled={lock} onClick={() => this.setState({ dialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.validator}
                            defaultValues={active}
                            onSubmit={(values, e, formApi) => this.save(values, formApi)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="drainage" fullWidth required={true} label="System Name"/>
                                            <Checkbox 
                                                field="update_domains" 
                                            >
                                                <HelpLabel
                                                    inputLabel="Add as Drainage option in ArcGIS Online"
                                                    helpText={
                                                        <div>
                                                            This new system will be added as an option for Drainage Systme in ArcGIS Online and allow activities to be synced to ArcGIS.
                                                        </div>
                                                    }
                                                />
                                            </Checkbox>
                                            {formApi.values.update_domains === true && (
                                                <AGOLCredenitials />
                                            )}
                                        </Grid>
                                        <div className={classes.mB}>
                                            <Submit label="Save" loading={lock} regular/>
                                        </div>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <SnackBarError open={snack} close={e => this.setState({snack: false})} customMessage="Drainage System Exists"/>
                <WarningFormDialog
                    lock={lock}
                    cancelAction={() => this.setState({ deleteDialog: false })}
                    open={deleteDialog}
                    form={
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.validator}
                            defaultValues={{id}}
                            onSubmit={(values, e, formApi) => this.deleteSystem(values, formApi)}
                        >
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <DialogContent>
                                        <Typography variant="title" className={classes.questionTitle} gutterBottom>
                                         Delete System
                                        </Typography>
                                        <DialogContentText>Are you sure you wish to permanently delete? Note this action may take a few seconds and dialog will lock!</DialogContentText>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <AGOLCredenitials lock={lock} />
                                            </Grid>
                                        </Grid>
                                        <DialogActions className={classes.centerButtons}>
                                            <div className={classes.wrapper}>
                                                <Button 
                                                    disabled={lock}
                                                    type="submit" 
                                                    color="primary" 
                                                    variant="outlined">
                                                    Delete Entry
                                                </Button>
                                                {lock && (<CircularProgress color="primary" size={24} className={classes.buttonProgress} />)}
                                            </div>
                                            <div>
                                                <Button 
                                                    disabled={lock}
                                                    onClick={() => this.setState({ deleteDialog: false })}
                                                    variant="outlined" 
                                                    className={classes.greyButton} 
                                                    autoFocus>
                                                    Do Not Delete
                                                </Button>
                                            </div>
                                        </DialogActions>
                                    </DialogContent>
                                </form>
                            )}
                        </Form>
                    }
                />
            </div>
        );
    }
}

ManageSystems = connect(
    (state, ownprops) => ({ 
        authState: state.auth,
        systems: getDrainageSystems(state, ownprops)
    }),
    {
        ...DrainageSystem.actions
    }
)(ManageSystems);

export default withStyles(styles)(withRouter(ManageSystems));
