import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withStyles } from "@material-ui/core";
import { 
    addToForm, 
    addtoFormFile,
    formateDate, 
    formatMoney, 
    formatPercent 
} from "../../api/utils";
import { MAKE_OPTIONS } from "../../api/constants";
import { getFormattedDate } from "../common/CommonFunction";
import { ExcelIcon } from '../common/IconFunctions';
import { 
    CheckTypes, 
    TransactionTypes, 
    FundTypes, 
    RegisterOptions 
} from "../common/SelectOptions";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from '@material-ui/core/Button';
import Close from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CommonTable from "../admin/Table";
import DatePicker from "../common/DatePicker";
import HelpLabel from '../common/HelpLabel';
import TextField from "../common/TextField";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import RecordTable from "../common/RecordTable";
import { createSelector } from "../common/orm";
import { 
    CheckBook, 
    DrainageAssessment, 
    DrainageExpenditure,
    DrainageSystem, 
    Transfer 
} from "../mypage/models";
import FileFormInput from "../common/FileFormInput";


const getChecks = createSelector(
    (state, props) => props.system.id,
    (session, id) => {
        return session.CheckBook.filter(x => x.drainage === id).toModelArray().map(x => ({
            ...x.ref,
            checkdate_label: formateDate(x.checkdate),
            withdrawal_label: formatMoney(x.withdrawal),
            deposit_label: formatMoney(x.deposit)
        }));
    }
);

const getTransfers = createSelector(
    (state, props) => props.system.id,
    (session, id) => {
        return session.Transfer.filter(x => x.drainage === id).toModelArray().map(x => ({
 	    ...x.ref, 
	    amount_borrowed_label: formatMoney(x.amount_borrowed),
	    board_authorized_label: formateDate(x.board_authorized),
	    payment_anticipated_label: formateDate(x.payment_anticipated)
	}));
    }
);

const getDrainageAssessments = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageAssessment.filter(assessment => assessment.drainage_id === system.id).toRefArray();
    }
);

const getDrainageExpenditures = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageExpenditure.filter(expenditure => expenditure.drainage_id === system.id).toRefArray();
    }
);

const getSystems = createSelector(session => {
    return session.DrainageSystemView.all().orderBy('drainage').toModelArray().map(x => ({label: x.drainage, value: x.drainage}));
});

const styles = theme => ({
    view: {
        display: "flex",
        padding: 8,
        alignItems: "center",
        justifyContent: "space-between"
    },
    summaryView: {
        display: "flex",
        flexGrow: 1,
        padding: 8,
        alignItems: "center",
        justifyContent: "space-between"
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    container: {
        width: "100%",
        border: "1px solid black",
        borderRadius: 6,
        marginLeft: 6,
        marginBottom: 15,
        marginTop: 5
    },
    flex: {
        flex: 1
    },
    width: {
        width: 100,
        marginRight: 3,
        marginBottom: 10,
        backgroundColor: "#82d4f3",
        color: "white",
    },
    content: {
        paddingBottom: "16px !important"
    },
    submitWrapper: {
        position: "relative"
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 10
    },
});

const checkColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "code", numeric: false, label: "Code", allowSort: true },
    {id: "checkdate_label", bool: false, date: true, label: "Date", allowSort: true },
    {id: "transaction", bool: false, label: "Transaction", allowSort: true },
    {id: "category", numeric: false, label: "Category", allowSort: true },
    {id: "description", numeric: false, label: "Description", allowSort: true },
    {id: "withdrawal_label", numeric: true, label: "Withdrawal", allowSort: true },
    {id: "deposit_label", numeric: true, label: "Deposit", allowSort: true },
];

const transferColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "transaction_type", numeric: false, label: "Transaction Type", allowSort: true},
    {id: "fund_type", numeric: false, label: "Type", allowSort: true},
    {id: "lender", numeric: false, label: "Funds Borrowed From", allowSort: true},
    {id: "amount_borrowed_label", numeric: true, label: "Amount Borrowed", allowSort: true},
    {id: "board_authorized_label", bool: false, date: true, label: "Board Authorized Transfer Date", allowSort: true },
    {id: "payment_anticipated_label", numeric: false, date: true, label: "Final Payment Anticipated", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true }
];

const columnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "year", numeric: false, label: "Year", allowSort: false },
    {id: "assessment", numeric: false, label: "Assessment", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Date Approved", allowSort: false },
    {id: "attachment", numeric: false, label: "Attachment", allowSort: false, openWindow: true },
];
const keyData = columnData.slice(1);

const columnExpendituresData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "year", numeric: false, label: "Year", allowSort: false },
    {id: "expand", numeric: false, label: "Expenditure", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: false },
];
const keyExpendituresData = columnExpendituresData.slice(1);

class Financial extends Component {
    state = {
        loading: false,
        activeYear: {},
        activeCheck: {},
        activeTransfer: {},
        activeExpend: {},
        yearOpen: false, 
        expendOpen: false,
        checkOpen: false,
        transferOpen: false,
        assessmentID: null,
        expenditureID: null,
        checkbookID: null,
        transferID: null,
        confirmUpload: false, 
        calculated: ""
    }

    saveDrainge = values => {
        const { authState, ormDrainageSystemUpdatePromise, saveBad, saveGood } = this.props;

        values["modified_user"] = authState.user.username;
        values["modified_date"] = getFormattedDate();

        if (values["acctfundbalance"] === null || values["acctfundbalance"] === ""){
            values["acctfundbalance"] = "0";
        }

        ["updateacctbalance", "avglandowner", "largelandowner"].forEach(x => {
            if(values[x] !== null && values[x] === ""){
                values[x] = null;
            }
        });

        ormDrainageSystemUpdatePromise({
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    handleFile = (val, formApi) => {
        const { fileSize } = this.props;
        
        if(val && (val.size / 1000000 > 250)){
            formApi.setValue("attachment", null);
            formApi.setValue("lock_submit", false);
            this.setState({file: null, confirmUpload: false});
            fileSize();
        } else if(val && (val.size / 1000000 > 100)){
            this.setState({confirmUpload: true, calculated: `${Math.round((val.size / 1000000) / 40)} Minutes`})
        } else {
            formApi.setValue("lock_submit", false);
            this.setState({file: val, confirmUpload: false});
        }
    }

    addAssessment = values => {
        const { 
            ormDrainageAssessmentCreateForm, 
            ormDrainageAssessmentUpdateForm,
            ormDrainageAssessmentUpdatePromise, 
            updateSystem, 
            system, 
            saveGood, 
            saveBad 
        } = this.props;

        const { file } = this.state;

        var form_data = new FormData();
        form_data.append('drainage_id', system.id);
        addToForm(form_data, 'year', values.year);
        addToForm(form_data, 'assessment', values.assessment);
        addToForm(form_data, 'uploaddate', values.uploaddate);
        addtoFormFile(form_data, 'attachment', file);
        this.setState({loading: true});

        if(values.id === undefined){
            ormDrainageAssessmentCreateForm(form_data).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({yearOpen: false, activeYear: null});
                    saveGood();
                }
            });
        } else if(file){
            form_data.append('id', values.id);
            ormDrainageAssessmentUpdateForm(form_data).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({yearOpen: false, activeYear: null});
                    saveGood();
                }
            });
        } else {
            delete values.attachment;
            ormDrainageAssessmentUpdatePromise({
                ...values
            }).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({yearOpen: false, activeYear: null});
                    saveGood();
                }
            });
        }
        updateSystem();
    }

    addExpenditure= values => {
        const { ormDrainageExpenditureCreate, ormDrainageExpenditureUpdatePromise, updateSystem, system, saveGood, saveBad } = this.props;
        
        if(values.id){
            ormDrainageExpenditureUpdatePromise({...values}).then(id => {
                this.setState({expendOpen: false, activeExpend: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormDrainageExpenditureCreate({
                ...values,
                drainage_id: system.id
            }).then(id => {
                this.setState({expendOpen: false, activeExpend: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        updateSystem();
    }

    onClickEdit = (e,l) => {
        this.setState({activeYear: l, yearOpen: true, edit: true});
    }
    onClickEditExpend = (e,l)=> {
        this.setState({activeExpend: l, expendOpen: true, edit: true});
    }

    addCheck = values => {
        const { ormCheckBookCreate, ormCheckBookUpdatePromise, updateSystem, system, saveGood, saveBad } = this.props;

        if(values.deposit !== null && values.deposit === ""){
            values.deposit = null;
        }

        if(values.withdrawal !== null && values.withdrawal === ""){
            values.withdrawal = null;
        }
        
        if(values.id){
            ormCheckBookUpdatePromise({...values}).then(id => {
                this.setState({checkOpen: false, activeCheck: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormCheckBookCreate({
                ...values,
                drainage_id: system.id
            }).then(id => {
                this.setState({checkOpen: false, activeCheck: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        updateSystem();
    }

    errorValidator = values => {
        const isEmpty = value => {
            if (!value || value === "") {
                return "Required";
            }
            return null;
        };
        const singularCheck = (a,b) => {
            const a_new = typeof a !== "undefined" && a !== null && a !== "" ? true : false;
            const b_new = typeof b !== "undefined" && b !== null && b !== "" ? true : false;


            console.log((a_new && b_new));
            console.log((!a_new && !b_new));
            if((a_new && b_new) || (!a_new && !b_new)){
                return "Must enter deposit or withdrawal only"
            }
            return null;
        }
        return {
            code: isEmpty(values.code),
            deposit: singularCheck(values.deposit, values.withdrawal),
            withdrawal: singularCheck(values.deposit, values.withdrawal)
        };
    };

    addTransfer = values => {
        const { ormTransferCreate, ormTransferUpdatePromise, updateSystem, system, saveGood, saveBad } = this.props;

        if(values.amount_borrowed !== null && values.amount_borrowed === ""){
            values.amount_borrowed = null;
        }
        
        if(values.id){
            ormTransferUpdatePromise({...values}).then(id => {
                this.setState({transferOpen: false, activeTransfer: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormTransferCreate({
                ...values,
                drainage_id: system.id
            }).then(id => {
                this.setState({transferOpen: false, activeTransfer: null});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        updateSystem();
    }

    transferErrorValidator = (values) => {
        const isEmpty = value => {
            if (!value || value === "") {
                return "Required";
            }
            return null;
        };
	const isDrainageType = value => {
            if (values.fund_type && values.fund_type === "Drainage" && (values.lender === null || values.lender === undefined)) {
                return "Required";
            }
            return null;
        };

        return {
	    'transaction_type': isEmpty(values.transaction_type),
	    'fund_type': isEmpty(values.fund_type),
            'lender': isDrainageType(values)
        }
    }

    handleType = (val, formApi) => {
	if(val !== "Drainage"){
	    formApi.setValue("lender", null);
	}
    }

    parentStateSet = (key, filters) => {
        this.setState({[key]: filters});
    }

    getSearchStr = (key) => {
        const filters = this.state[key];

        if(filters && filters.column !== "" && filters.filter !== ""){
            return `?column=${filters.column.replace("_label","")}&searchstring=${filters.filter}`;
        } else {
            return ``;
        }
    } 


    getAverage = (arr, key) => {
        var temp = 0; 
	    var count = 0;

        arr.forEach(x => {
            if(x[key] !== null){
                count++;
            }
            temp += parseFloat(x[key]);
        });
        var avg =  temp / count;
        avg = !isNaN(avg) ? avg : null;
        return avg;
    }

    render(){
        const {
            saveBad, 
            saveGood,
            ormDrainageAssessmentDeletePromise,
            ormDrainageExpenditureDeletePromise,
            ormCheckBookDeletePromise,
            ormTransferDeletePromise,
            updateSystem,
            classes,
            authState,
            system,
            assessments,
            expenditures,
            checks,
            transfers,
            systems
        } = this.props;
        const {
            loading,
            assessmentID,
            expenditureID,
            checkbookID,
            transferID,
            assessmentDelete,
            expenditureDelete,
            checkBookDelete,
            transferDelete,
            activeCheck,
            activeYear,
            activeExpend,
            activeTransfer,
            checkOpen,
            yearOpen,
            expendOpen,
            transferOpen,
            confirmUpload,
            calculated
        } = this.state;

        const viewOnly = authState.user.role === "View Only";
        var avgAssessment = this.getAverage(assessments, "assessment");
        var avgExpen = this.getAverage(expenditures, "expand");

	    var negativeBalance = system && system.acctfundbalance && !isNaN(parseFloat(system.acctfundbalance)) && parseFloat(system.acctfundbalance) < 0;

        var total = 0.0;
        checks.forEach(x => {
            if(x.withdrawal){
                total -= parseFloat(x.withdrawal);
            } else if(x.deposit){
                total += parseFloat(x.deposit);
            }
        });

        const currentBalance = !isNaN(parseFloat(total)) ? formatMoney(total) : "";

        return (
            <Grid container className={classes.space}>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system.drainage} />   
                <Grid container spacing={3} className={classes.container}>
                    <Grid item xs={12}>
                        <div className={classes.view}>
                            <div><Typography variant="h4">Financial Summary Information</Typography></div>
                            <div>
                                <Button
                                    variant="contained"
                                    target="_blank" 
                                    href={`reports/drainage/system/financial/${system.id}/excel`}
                                >
                                    <ExcelIcon /> 
                                    &nbsp;&nbsp;Export to Excel
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Internal Account #:</b></Typography>
                        <Typography>{system.internalacct}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>IFS Account #:</b></Typography>
                        <Typography>{system.ifsnumber}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Largest Landowner % Benefits:</b></Typography>
                        <Typography>{formatPercent(system.largelandowner)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Avg of Landowner % Benefits:</b></Typography>
                        <Typography>{formatPercent(system.avglandowner)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Account Balance:</b></Typography>
                        {negativeBalance && (
                            <Typography style={{color: "red"}}><b>{formatMoney(system.acctfundbalance)}</b></Typography>
                        )}
                        {!negativeBalance && (
                            <Typography>{formatMoney(system.acctfundbalance)}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Date Account Balance Last Updated:</b></Typography>
                        <Typography>{formateDate(system.rob)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography><b>Average Annual Expenditures:</b></Typography>
                        <Typography>{formatMoney(avgExpen)}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            Annual Assessments
                                        </Typography>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Button 
                                            style = {{display: viewOnly && "none"}} 
                                            onClick={() => {this.setState({file: null, yearOpen: true, edit: false, activeYear: {} })}}
                                            variant="contained"
                                            color="primary"
                                            fullWidth>
                                            <AddCircleOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;Add Year
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CommonTable
                                            name="assessments"
                                            viewOnly={viewOnly} 
                                            by="year"
                                            data={assessments} 
                                            edit
                                            emptyTxt="No Assessments Defined"
                                            columns={columnData} 
                                            keys={keyData}
                                            onClickEdit={this.onClickEdit}
                                            onClickDelete={(l) => this.setState({assessmentID: l.id, assessmentDelete: true})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Average Annual Assessment: {formatMoney(avgAssessment)}</Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            Annual Expenditures
                                        </Typography>    
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Button 
                                            style = {{display: viewOnly && "none"}} 
                                            onClick={() => {this.setState({expendOpen: true, edit: false, activeExpend: {} })}}
                                            variant="contained"
                                            color="primary"
                                            fullWidth>
                                            <AddCircleOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;Add Year
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CommonTable
                                            name="expenditures"
                                            viewOnly={viewOnly} 
                                            by="year"
                                            data={expenditures} 
                                            edit
                                            emptyTxt="No Expenditures Defined"
                                            columns={columnExpendituresData} 
                                            keys={keyExpendituresData}
                                            onClickEdit={this.onClickEditExpend}
                                            onClickDelete={(l) => this.setState({expenditureID: l.id, expenditureDelete: true})}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Average Annual Expenditures: {formatMoney(avgExpen)}</Typography>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            Account Register
                                        </Typography>
                                    </div>
                                    <div style={{display: "inline-flex"}}>
                                        <div style={{marginRight: 30}}>
                                            <Typography variant="h5">Current Balance: {currentBalance}</Typography>
                                        </div>
                                        <div>
                                            <Button
                                                onClick={e => { 
                        e.stopPropagation();
                        window.open(`reports/drainage/system/register/${system.id}/excel${this.getSearchStr("register")}`);
                        }}
                                                color="primary"
                                                variant="contained"
                                            >
                                                <ExcelIcon /> 
                                                &nbsp;&nbsp;Export to Excel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Form
                                            id="checkbook_custom"
                                            dontValidateOnMount={true}
                                            validateOnSubmit={true}
                                            validateError={this.errorValidator}
                                            defaultValues={{}}
                                            onSubmit={values => this.addCheck(values)}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Select field="code" label="Code" options={MAKE_OPTIONS(CheckTypes)} fullWidth required={true} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select field="category" label="Category" options={MAKE_OPTIONS(RegisterOptions)} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField field="transaction" label="Transaction" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField field="description" label="Description" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField field="withdrawal" label="Withdrawal" useTextFormat fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField field="deposit" label="Deposit" useTextFormat fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker field="checkdate" label="Date" autoPopulate={true} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button 
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth>
                                                                <AddCircleOutlineIcon />
                                                                &nbsp;&nbsp;&nbsp;Add Transaction
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RecordTable 
                                            noScroll
                                            viewOnly={viewOnly} 
                                            columnData={checkColumnData}
                                            data={checks}
                                            deletable={true}
                                            deleteRecord={(id) => this.setState({checkbookID: id, checkBookDelete: true})}
                                            editable={true}
                                            editRecord={(values) => this.setState({checkOpen: true, activeCheck: values})}
                                            parentStateSet={(filters) => this.parentStateSet("register", filters)}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            Financial Information
                                        </Typography>
                                    </div>
                                    <div>
                                        {!viewOnly && (
                                            <Button 
                                                onClick={e => { e.stopPropagation();this.formApi.submitForm()}}
                                                disabled={loading}
                                                type="submit" 
                                                variant="contained" 
                                                color="primary" 
                                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                        )}
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Form
                                            getApi={el => (this.formApi = el)}
                                            defaultValues={system}
                                            onSubmit={(values) => this.saveDrainge(values)}>
                                            {formApi => (
                                                <form style={{flex: 1}} onSubmit={formApi.submitForm}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <TextField field="internalacct" label="Internal Account #" useIntegerFormat fullWidth disabled={viewOnly}></TextField>
                                                            <TextField field="ifsnumber" label="IFS Account #" useIntegerFormat fullWidth disabled={viewOnly}></TextField>
                                                            <TextField field="acctfundbalance" label="Account Balance" useTextFormat fullWidth disabled={viewOnly}></TextField>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField field="largelandowner" userPercentageFormat fullWidth disabled={viewOnly}>
                                                                <HelpLabel
                                                                    inputLabel="Largest Landowner % Benefits"
                                                                    helpText={<div>Use this field to enter the % of largest landowner benefits. Useful for calculating largest individual assessment.</div>}
                                                                />
                                                            </TextField>
                                                            <TextField field="avglandowner" userPercentageFormat fullWidth disabled={viewOnly}>
                                                                <HelpLabel
                                                                    inputLabel="Avg of Landowner % Benefits"
                                                                    helpText={<div>Use this field to enter the % of largest landowner benefits. Useful for calculating largest individual assessment.</div>}
                                                                />
                                                            </TextField>
                                                            <DatePicker 
                                                                disabled={viewOnly}
                                                                field="updateacctbalance" label="Date Account Balance Last Updated" 
                                                                style={{ marginTop: 16}} 
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            Transfer of Funds
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                window.open(`reports/drainage/system/transfer/${system.id}/excel${this.getSearchStr("transfer")}`);
                                            }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            <ExcelIcon />
                                            &nbsp;&nbsp;Export to Excel
                                        </Button>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <RecordTable 
                                    noScroll
                                    viewOnly={viewOnly} 
                                    columnData={transferColumnData}
                                    data={transfers}
                                    deletable={true}
                                    deleteRecord={(id) => this.setState({transferID: id, transferDelete: true})}
                                    editable={true}
                                    editRecord={(values) => this.setState({transferOpen: true, activeTransfer: values})}
                                    creatable={true}
                                    addName="Add Transfer"
                                    addRecord={() => this.setState({ transferOpen: true, activeTransfer: {} })}
                                    parentStateSet={(filters) => this.parentStateSet("transfer", filters)}
                                />
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <div style={{height:20, width: "100%"}}></div>
                <Dialog 
                    open={yearOpen} 
                    classes={{ paper: classes.minWidth }} 
                    maxWidth="sm" 
                    fullWidth
                >
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Assessment
                        </Typography>
                        <IconButton onClick={() => this.setState({ yearOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="assessment"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={activeYear}
                            onSubmit={values => this.addAssessment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="year" label="Year" useIntegerFormat fullWidth required={true} />
                                            <TextField field="assessment" label="Assessment" useTextFormat fullWidth required={true} />
                                            <DatePicker field="uploaddate" label="Date Approved" autoPopulate={true}/>
                                            <>
                                                <FileFormInput 
                                                    id="attachment" 
                                                    field="attachment" 
                                                    label="Attachment" 
                                                    eventHandle={val => this.handleFile(val, formApi)}
                                                />
                                                <br />
                                                {confirmUpload && (
                                                    <Subquestion
                                                        component={
                                                            <RadioGroup
                                                                field="lock_submit"
                                                                name="lock_submit"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                        title={true}
                                                                        showLabel={true}
                                                                        helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                                    />
                                                                }
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "true" },
                                                                    { label: "No", value: "false" }
                                                                ]}
                                                                disabled={loading}
                                                                alignment={true}></RadioGroup>
                                                        }
                                                    />
                                                )}
                                            </>
                                            <br />
                                            <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                                Save
                                                {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open={expendOpen} 
                    classes={{ paper: classes.minWidth }} 
                    maxWidth="sm" 
                    fullWidth
                >
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Expenditure
                        </Typography>
                        <IconButton onClick={() => this.setState({ expendOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="expenditure"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={activeExpend}
                            onSubmit={values => this.addExpenditure(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="year" label="Year" useIntegerFormat fullWidth required={true} />
                                            <TextField field="expand" label="Expenditure" useTextFormat fullWidth required={true} />
                                            <DatePicker field="uploaddate" label="Date" autoPopulate={true}/>
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open={checkOpen} 
                    classes={{ paper: classes.minWidth }} 
                    maxWidth="sm" 
                    fullWidth
                >
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Item
                        </Typography>
                        <IconButton onClick={() => this.setState({ checkOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="checkbook"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={activeCheck}
                            onSubmit={values => this.addCheck(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select field="code" label="Code" options={MAKE_OPTIONS(CheckTypes)} fullWidth required={true} />
                                            <DatePicker field="checkdate" label="Date" autoPopulate={true} />
                                            <Select field="category" label="Category" options={MAKE_OPTIONS(RegisterOptions)} fullWidth />
                                            <TextField field="transaction" label="Transaction" fullWidth />
                                            <TextField field="description" label="Description" fullWidth />
                                            <TextField field="withdrawal" label="Withdrawal" useTextFormat fullWidth />
                                            <TextField field="deposit" label="Deposit" useTextFormat fullWidth />
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open={transferOpen} 
                    classes={{ paper: classes.minWidth }} 
                    maxWidth="sm" 
                    fullWidth
                >
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Transfer
                        </Typography>
                        <IconButton onClick={() => this.setState({ transferOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="transfer"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.transferErrorValidator}
                            defaultValues={activeTransfer}
                            onSubmit={values => this.addTransfer(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select field="transaction_type" label="Transaction Type" options={MAKE_OPTIONS(TransactionTypes)} fullWidth required={true}/>
                                            <Select 
				    		field="fund_type" 
				    		label="Type" 
				    		options={MAKE_OPTIONS(FundTypes)} 
				    		fullWidth 
				    		required={true}
				    	    	eventHandle={(val) => this.handleType(val, formApi)}
				    	    />
                                            {formApi && formApi.values["fund_type"] === "Drainage" && (
                                                <Select field="lender" label="Funds Borrowed From" options={systems} fullWidth required={true}/>
                                            )}
                                            <TextField field="amount_borrowed" label="Amount Borrowed" useTextFormat fullWidth />
                                            <DatePicker field="board_authorized" label="Board Authorized Transfer Date" />
                                            <DatePicker field="payment_anticipated" label="Final Payment Anticipated" />
                                            <TextField field="notes" label="Notes" multiline rows={3} fullWidth/>
                                            <Grid item xs={12} className={classes.mt}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormDrainageAssessmentDeletePromise(assessmentID).then(id => {
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({assessmentDelete: false});
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({assessmentDelete: false});
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={assessmentDelete}
                    title={"Delete Assessment"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormDrainageExpenditureDeletePromise(expenditureID).then(id => {
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({expenditureDelete: false});
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({expenditureDelete: false});
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={expenditureDelete}
                    title={"Delete Expenditure"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormCheckBookDeletePromise(checkbookID).then(id => {
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({checkBookDelete: false});
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({checkBookDelete: false});
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={checkBookDelete}
                    title={"Delete Check"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormTransferDeletePromise(transferID).then(id => {
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                            this.setState({transferDelete: false});
                        });
                        updateSystem();
                    }}
                    cancelAction={() => {
                        this.setState({transferDelete: false});
                    }}
                    confirmText="Delete"
                    cancelText="Cancel"
                    open={transferDelete}
                    title={"Delete Transfer"}
                    text={"Are you sure you wish to delete?"}
                />
            </Grid>
        );
    }
}


Financial = connect((state, ownProps) => ({
    authState: state.auth,
    synced: state.sync.ready,
    assessments: getDrainageAssessments(state, ownProps),
    expenditures: getDrainageExpenditures(state, ownProps),
    checks: getChecks(state, ownProps),
    transfers: getTransfers(state, ownProps),
    systems: getSystems(state, ownProps)
}),
  {
    ...DrainageSystem.actions,
    ...DrainageAssessment.actions,
    ...DrainageExpenditure.actions,
    ...CheckBook.actions,
    ...Transfer.actions
  }
)(Financial);

export default withStyles(styles)(withRouter(Financial));
