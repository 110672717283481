import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { Form } from 'react-form';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createSelector } from '../common/orm';
import CheckBox from '../common/Checkbox';
import DatePicker from '../common/DatePicker';
import TextField from '../common/TextField';
import Select from '../common/Select';
import DialogForm from "../common/DialogForm";
import { MAKE_OPTIONS_VID } from "../../api/constants";
import { Correspondence } from '../mypage/models';
import { getValue } from "../../api/utils";

const getDefaultValues = createSelector(
    (state, ownProps) => ownProps.selected,
    (state, ownProps) => state,
    (session, selected, state) => {
        if(selected){
            return session.Correspondence.filter(x => x.id === selected).toModelArray().map(c => {
                return c.ref;
            })[0] || null;
        } else {
            return {
                user: getValue(state, "auth.user.id")
            };
        }
    }
);

const getUsers = createSelector(session => {
    return session.User.filter(u => u.role === "Contractor").toRefArray();
});

const getAllUsers = createSelector(session => {
    return session.User.all().toRefArray();
});

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    flex: {
        flex: 1
    }
});

class CorrespondenceForm extends Component {

    correspondenceValidator = (values) => {
        return {
            user: !values.user ? "Required" : null,
            recipient: values.email && !values.recipient ? "Required" : null
        }
    }

    saveCorrespondence = (values) => {
        const { 
            ormCorrespondenceCreate, 
            ormCorrespondenceUpdatePromise,
            repair,
            saveBad, 
            saveGood,
            onClose
        } = this.props;
        if(values.id){
            ormCorrespondenceUpdatePromise({
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                    onClose();
                }
            });
        } else {
            ormCorrespondenceCreate({
                ...values,
                repair: repair.id
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                    onClose();
                }
            });
        }
    }

    render(){
        const { 
            authState, 
            open, 
            onClose,
            users,
            allUsers,
            defaultValues,
        } = this.props;
        const admin = authState.user.role === "Admin";



        return(
            <DialogForm dialog={open} header="Correspondence" onClose={onClose}>
                <Form
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.correspondenceValidator}
                    defaultValues={defaultValues}
                    onSubmit={this.saveCorrespondence}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>               
                                    <DatePicker field="date" label="Date" fullWidth autoPopulate={true} />
                                    <CheckBox field="email" label="Send Email of this note to a User" />
                                    {formApi.values.email && (
                                        <Select field="recipient" label="Username" options={MAKE_OPTIONS_VID(allUsers, "username")} fullWidth required={true} />      
                                    )}
                                    <Select 
                                        field="user" 
                                        label="Person Recording Notes" 
                                        options={admin ? MAKE_OPTIONS_VID(allUsers, "username") : MAKE_OPTIONS_VID(users, "username")} 
                                        fullWidth 
                                        required={true} 
                                        disabled={!admin}
                                    />      
                                    <TextField 
                                        field="notes" 
                                        label="Notes" 
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </DialogForm> 
        );
    }
}

CorrespondenceForm = connect(
    (state, ownProps) => ({
        authState: state.auth,
        users: getUsers(state),
        allUsers: getAllUsers(state),
        defaultValues: getDefaultValues(state, ownProps)
    }),
    {
        ...Correspondence.actions,
    }
)(CorrespondenceForm);

export default withStyles(styles)(withRouter(CorrespondenceForm));


