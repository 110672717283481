import React from 'react';
import utils from "./react-form-utils";
import DateFnsUtils from "@date-io/date-fns";

import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";


export const setValue = utils.set;
export const getValue = (obj, path, def) => {
    const val = utils.get(obj, path, def);
    if (val === null && def) {
        return def;
    }
    return val;
}

export const formateDateSlash = (date) => {
    const temp = new Date(date + "T12:00:00");
    const dateFns = new DateFnsUtils();
    return dateFns.format(temp, "MM/dd/yyyy");
} 

export const formateDate = (date) => {
    if(date === "" || date === null){
        return "";
    }

    if(Object.prototype.toString.call(date) === "[object Date]"){
        const dateFns = new DateFnsUtils();
        return dateFns.format(date, "MM/dd/yyyy");
    } else {
        const temp = new Date(date + "T12:00:00");
        const dateFns = new DateFnsUtils();
        return dateFns.format(temp, "MM/dd/yyyy");
    }
} 

export const formatPercent = (value) => {
    if(value === "" || value === null){
        return "";
    }
    return value + "%";
}

export const formatMoney = (value) => {
    if(value === null || value === ""){
        return "";
    }
    if(!isNaN(parseFloat(value)) && parseFloat(value) < 0){
        return "-$" + parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('-', '');
    }
    return "$" + parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const formatNumber = (value) => {
    if(value === null || value === ""){
        return "";
    }
    return parseFloat(value).toLocaleString()
}

export const getToday = () => {
    const date = new Date();
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}

// FormData every value is converted to string so null turns into "null".
// With that just don't include it in form
export const addToForm = (form, key, value) => {
    if(value !== null && typeof(value) !== "undefined"){
        form.append(key, value);
    }
}

export const addtoFormFile = (form, key, value) => {
    if(value !== null && typeof(value) !== "undefined"){
        form.append(key, value, value.name);
    }
} 

export const renderThumb = ({ style }) => {
    const thumbStyle = {
        backgroundColor: "#e1d4d8",
        zIndex: "9999",
        cursor: "pointer",
        width: 20
    };
    return <div style={{ ...style, ...thumbStyle }} />;
}

export const renderThumb2 = ({ style }) => {
    const thumbStyle = {
        backgroundColor: "#e1d4d8",
        zIndex: "999",
        cursor: "pointer",
        height: 20
    };
    return <div style={{ ...style, ...thumbStyle }} />;
}

export const prepareHtmlToDraft = (html) => {
    const contentBlock = htmlToDraft(html? html: '')
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
}

export const prepareDraftToHtml = (draft) => {
    return draftToHtml(convertToRaw(draft.getCurrentContent()))
}

