import React, { Component } from "react";

import AssignmentIcon from '@material-ui/icons/Assignment';
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

import CustomTableCell from '../common/TableCell';
import EnhancedTableHead from "../common/EnhancedTableHead";

const styles = theme => ({
    minWidth: {
        minWidth: 400,
        marginBottom: 10
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
    centerAlign: {
        textAlign: "center"
    }
});

class CommonTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            order: "asc",
            orderBy: props.by? props.by : "name"
        }
    }

    handleRequestSort = (event, property) => {
        const { columns, data } = this.props; 

        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        data.sort(function(a, b) {
            var date = columns.find(cD => cD.id === orderBy).date;
            var numeric = columns.find(cD => cD.id === orderBy).numeric;
            var bool = columns.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy });
    }

    displayBool = (e) => {
        if(e === null){
            return "";
        } else {
            return e ? "True" : "False";
        }
    }

    render(){
        const {canView, classes, customEdit, edit, editProp, data, emptyTxt, columns, keys, name, noDelete, report, viewOnly} = this.props;
        const {order, orderBy} = this.state;

        return(
        <Table className={classes.minWidth}>
            <EnhancedTableHead columnData={columns ? columns : []} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} name={name} />
            <TableBody>
                {data.map((l,index) => {
                    return (
                        <TableRow hover key={name + "_" + index}>
                            <CustomTableCell className={classes.noWrap}>
                                {report && !viewOnly && (
                                    <Tooltip title="Report">
                                        <Button
                                            onClick={() => this.props.onReport(l, editProp)}
                                            className={classes.deleteWidth}>
                                            <AssignmentIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                )}
                                {edit && !viewOnly && (
                                    customEdit ? 
                                    <Tooltip title="Edit">
                                        <Button
                                            onClick={(e) => this.props.customEdit(e, l)}
                                            className={classes.deleteWidth}>
                                            <EditIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Edit">
                                        <Button
                                            onClick={(e) => this.props.onClickEdit(e,l)}
                                            className={classes.deleteWidth}>
                                            <EditIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                )}
                                {!viewOnly && canView &&(<Tooltip title="View">
                                    <Button
                                        onClick={() => this.props.onClickView(l)}
                                        className={classes.deleteWidth}>
                                        <VisibilityIcon />
                                    </Button>
                                </Tooltip>)
                                }
                                {!viewOnly && !noDelete &&(<Tooltip title="Delete">
                                    <Button
                                        onClick={() => this.props.onClickDelete(l)}
                                        className={classes.deleteWidth}>
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>)
                                }
                            </CustomTableCell>
                            {keys.map((x,index) => {
                                if(x.openWindow){
                                    if(l[x.id]){
                                        return (
                                                <CustomTableCell key={name + "_cell_" + index}>
                                                    <Tooltip title="View Attachment">
                                                        <a target="_blank" rel="noopener noreferrer" href={l[x.id]} className={classes.linkColor}>
                                                            Open File
                                                        </a>
                                                        </Tooltip>        
                                                </CustomTableCell>
                                        )
                                    } else {
                                        return (
                                            <CustomTableCell key={name + "_cell_" + index}>None</CustomTableCell>
                                        )
                                    }
                                } else if(x.bool) {
                                    return <CustomTableCell key={index}>{this.displayBool(l[x.id])}</CustomTableCell>
                                } else {
                                    return <CustomTableCell key={index}>{l[x.id]}</CustomTableCell>
                                }
                            })
                            }
                        </TableRow>
                    );
                })}
                {data.length < 1 && (
                    <TableRow>
                        <CustomTableCell colSpan={columns.length} className={classes.centerAlign}>
                            {emptyTxt}
                        </CustomTableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
        )
    }
}

export default withStyles(styles)(CommonTable);
