import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { formateDateSlash, getValue } from "../../api/utils";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';

import Divider from "@material-ui/core/Divider";
import ToolBar from "@material-ui/core/Toolbar";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { ExcelIcon } from '../common/IconFunctions';

import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import DatePicker from "..//common/DatePicker";
import SnackBarError from '../common/SnackBarError';

import { LegalProcceding } from "../common/SelectOptions";

import { createSelector } from "../common/orm";

import * as authActions from "../auth/actions";

const getDrainageSystem = createSelector(session => {
    return session.DrainageSystemView.all().orderBy("drainage", "asc").toRefArray();
});
const getUsers = createSelector(session => {
    return session.User.filter(x => x.role === "Contractor").toRefArray();
});


const styles = theme => ({
    space: {
        marginTop: 48
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    mr: {
        marginRight: 5
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px"
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
    },
    submitWrapper: {
        position: "relative"
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    },
    centerAlign: {
        textAlign: "center"
    },
});

const columnData = [
    { pos: 0, label: "System Name", id: "drainage__drainage" },
    { pos: 1, label: "Proceeding Title", id: "proceed_title" },
    { pos: 2, label: "Proceeding Type", id: "proceedtype" },
    { pos: 3, label: "Date of First Activity" , id: "recentdate" },
    { pos: 4, label: "Date of Last Activity", id: "orderdate" },
    { pos: 5, label: "Status", id: "status" },
    { pos: 6, label: "Proceeding Notes", id: "proceed_notes" },
];

const includeColumnData = [
    {id: "key", numeric: false, label: "X", allowSort: false, bool: true },
    {id: "label", numeric: false, label: "Include Column", allowSort: true },
];

function FormatDate(date){
    return date.getFullYear() + 
    "-" + 
    (date.getMonth() + 1 < 10 ? "0" : "") +
    (date.getMonth() + 1) +
    "-" +
    ((date.getDate() < 10 ? "0" : "") + date.getDate());
}

function GetColumnInitial(){
    var data = {};
    columnData.forEach(x => {
        data[x.id] = true;
    });
    return data;
}

class LegalSearch extends Component {
    state = {
        noResults: false,
        snackbarOpen: false,
        date: "None",
        range: "Current",

        page: 0,
        rowsPerPage: 100,
        data: [],
	    loading: false,

        dateError: false,
        dateMessage: "One or more dates are invalid",
        start: FormatDate(new Date()),
        end: FormatDate(new Date()),

        drainage: false,
        active: false,
        type_check: false,
        ...GetColumnInitial()
    }

    handleChangeDrainage = (e, key) => {
        const { systems } = this.props;
        const index = systems.findIndex(x => x.drainage === e.target.value);

        if(index > -1){
            this.setState({[key]: e.target.value, drainage_id: systems[index].id});
        } else {
            this.setState({[key]: e.target.value});
        }
    }
    handleChange = (e, key) => {
        this.setState({[key]: e.target.value});
    }
    handleCheckBox = (e, key) => {
        this.setState({[key]: e.target.checked});
    }
    handleRange = (e) => {
        this.setState({range: e.target.value});
    }
    handleDate = (e) => {
        this.setState({date: e.target.value});
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    getQueryString = () => {
        var arr = [];

        arr.push(`date=${this.state.date}`);
        arr.push(`range=${this.state.range}&start=${this.state.start}&end=${this.state.end}`);
        arr.push(`drainage=${this.state.drainage}&drainage_str=${this.state.drainage_str}`);
        arr.push(`type=${this.state.type_check}&type_str=${this.state.type_str}`);
        arr.push(`active=${this.state.active}`);

        return arr.join('&');
    }

    search = () => {
        const { authState } = this.props;

        if(this.state.date !== "None" && this.state.range === "Date Range"){
            const start_error = this.state.start && (this.state.start === "Invalid Date" || this.state.start === "Invalid Year");
            const end_error = this.state.end && (this.state.end === "Invalid Date" || this.state.end === "Invalid Year");
            const start_required = this.state.start && (this.state.start === "Required");
            const end_required = this.state.end && (this.state.end === "Required");

            if(start_error || end_error){
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates are invalid"
                });  
                return;
            } if(start_required || end_required){
                this.setState({
                    dateError: true,
                    data: [],
                    dateMessage: "One or more dates require input"
                }); 
                return;
            } else {
		        this.setState({loading: true});	
	        }
        } else {
            this.setState({loading: true, data: []});
        }

        var querystring = this.getQueryString()

        fetch(`/legalsearch?${querystring}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            this.setState({data: data, loading: false, noResults: data && data.length === 0});
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {
                this.setState({data: [], loading: false});
            }
        });
    }

    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb1({ style, ...props }) {
        const thumbStyle = {
            zIndex: "9999",
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            width: 20
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb12({ style, ...props }) {
        const thumbStyle = {
            zIndex: "999",
            backgroundColor: "#e1d4d8",
            cursor: "pointer",
            height: 20
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }


    goDrainage = (x) => {
        const { history } = this.props;
        history.push(`/drainage/${x.drainage__id}/${x.drainage__drainage}?tab=legalsearch`);
    }

    setDate = (val, key) => {
        const date = new Date(val);
        var formatDate = FormatDate(date);
        if(formatDate !== "NaN-NaN-NaN"){
            this.setState({[key]: formatDate});
        } else {
            this.setState({[key]: val});
        }
    }

    display = (x, z) => {
        if(z.id === "recentdate" || z.id === "orderdate"){
            return x[z.id] ? formateDateSlash(x[z.id]) : "-";
        } else {
            return x[z.id] ? x[z.id] : "-";
        }
    }

    render(){
        const { 
            authState,
            classes, 
            systems,
        } = this.props;
        const { 
            noResults,
            data,
	        loading,

            range,
            date,
            dateError,
            dateMessage,

            drainage_str,
            drainage,
            type_str,
            type_check,
            active,

            order, 
            orderBy,
            rowsPerPage,
            page,
            tableOrder,
            tableOrderBy
        } = this.state;

        const includedColumns = columnData.filter(x => this.state[x.id.toString()]);
        const include = includedColumns.map(x => x.id + "1" + x.label);
        var list = this.getQueryString() + "&list=" + include.join(',').replace('#', '%23');  //  need to encode #

        const token = getValue(authState, 'user.auth_token');
        
        return (
            <AppContainer authenticated adminPage={true}>
                <div className={classes.space}>
                    <ToolBar>
                        <Typography variant="h5" className={classes.grow}>Legal Proceeding Search</Typography>
                    </ToolBar>
                    <Divider className={classes.mb}/>
                    <ExpansionPanel defaultExpanded={true} style={{marginTop: 10}}> 
                        <ExpansionPanelSummary
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="h5">
                                <AssessmentIcon className={classes.expansionIcon} /> Search Filters
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={9} lg={9} xl={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Typography id="include-date-label">Date Search:</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="master"
                                                    name="master1"
                                                    value={date}
                                                    onChange={this.handleDate}>
                                                    <FormControlLabel
                                                        value="None"
                                                        control={<Radio color="primary" />}
                                                        label="None"
                                                    />
                                                    <FormControlLabel
                                                        value="First Date"
                                                        control={<Radio color="primary" />}
                                                        label="First Date"
                                                    />
                                                    <FormControlLabel
                                                        value="Last Date"
                                                        control={<Radio color="primary" />}
                                                        label="Last Date"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid 
                                            style={{display: date === "None" ? "none" : ""}}
                                            item xs={12} 
                                            md={6}
                                        >
                                            <Typography id="date-range-label">Select Year(s) :</Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="master"
                                                    name="master1"
                                                    value={range}
                                                    onChange={this.handleRange}>
                                                    <FormControlLabel
                                                        value="Current"
                                                        control={<Radio color="primary" />}
                                                        label="Current"
                                                    />
                                                    <FormControlLabel
                                                        value="Date Range"
                                                        control={<Radio color="primary" />}
                                                        label="Date Range"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            <Form>
                                                {formApi => (
                                                    <form>
                                                        <div>
                                                            <DatePicker 
                                                                disabled={range === "Current"} 
                                                                field="start"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "start")} 
                                                            />
                                                        </div>
                                                        <div>
                                                            <DatePicker 
                                                                disabled={range === "Current"} 
                                                                field="end"
                                                                autoPopulate
                                                                eventHandle={(val) => this.setDate(val, "end")} 
                                                            />
                                                        </div>
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid xs={12} md={9} lg={9} xl={8}>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': 'Drainage System search' }}
                                                            onChange={(e) => this.handleCheckBox(e, "drainage")}
                                                            color="primary"
                                                            checked={drainage}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Drainage System"
                                                    />
                                                        <Select
                                                            className={classes.listselectbox}
                                                            inputProps={{ 'aria-label': 'Drainage System' }}
                                                            value={drainage_str}
                                                            onChange={(e) => this.handleChangeDrainage(e, "drainage_str")}
                                                            input={<Input />}>
                                                            {systems.map(x => (
                                                                <MenuItem key={x.drainage + "drainage"} value={x.drainage}>
                                                                    <ListItemText primary={x.drainage} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': 'Proceeding type search' }}
                                                            onChange={(e) => this.handleCheckBox(e, "type_check")}
                                                            color="primary"
                                                            checked={type_check}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary="Proceeding Type"
                                                    />
                                                        <Select
                                                            className={classes.listselectbox}
                                                            inputProps={{ 'aria-label': 'Proceeding Type' }}
                                                            value={type_str}
                                                            onChange={(e) => this.handleChange(e, "type_str")}
                                                            input={<Input />}>
                                                            {LegalProcceding.map(x => (
                                                                <MenuItem key={x.value} value={x.value}>
                                                                    <ListItemText primary={x.label} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': 'Active search' }}
                                                            onChange={(e) => this.handleCheckBox(e, "active")}
                                                            color="primary"
                                                            checked={active}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Active" />
                                                </ListItem>
                                            </List>
                                            <Button
                                                onClick={this.search}
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<SearchIcon />}
                                            >
                                            Search
                                            {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} xl={4}>
                                    <Table style={{width: "auto"}}>
                                        <Scrollbars
                                            style={{minWidth: 200, height: 400}}
                                            renderThumbVertical={this.renderThumb}
                                            renderThumbHorizontal={this.renderThumb2}>
                                            <EnhancedTableHead 
                                                columnData={includeColumnData}
                                                order={tableOrder} 
                                                orderBy={tableOrderBy} 
                                                onRequestSort={this.handleRequestSort} 
                                            />
                                            <TableBody>
                                            {columnData.map(x => (
                                                <TableRow key={x.id}>
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': `Include Column ${x.label}` }}
                                                            onChange={(e) => this.handleCheckBox(e, x.id)}
                                                            color="primary"
                                                            checked={this.state[x.id.toString()]}
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        {x.label}
                                                    </CustomTableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        </Scrollbars>
                                    </Table>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10}}>
                            <div className={classes.root}>
                                <Button 
                                    variant="contained"
                                    disabled={include.length < 1 || data.length < 1}
                                    target="_blank" 
                                    href={`/report/legalsearch?token=${token}&${list}`}
                                    className={classes.mr}>
                                    <ExcelIcon /> 
                                        &nbsp;&nbsp;Export to Excel
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{overflowX: "auto"}}>
                            <Table>
                                <EnhancedTableHead columnData={includedColumns} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                                <TableBody>
                                    {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(x => (
                                        <TableRow hover onClick={(e) => this.goDrainage(x)} key={x.id}>
                                            {includedColumns.map(z => (
                                                <CustomTableCell key={x.id + "_" + z.id + "_cell"}>{this.display(x,z)}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    {data.length === 0 && (
                                        <CustomTableCell
                                            colSpan={includedColumns.length}
                                            className={classes.centerAlign}>
                                            No Records
                                        </CustomTableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={includedColumns.length}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                            rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Grid>
                    </Grid>    
                </div>
                <SnackBarError 
                    open={noResults} 
                    close={e => this.setState({noResults: false})} 
                    customMessage="No records match your query"
                />
                <SnackBarError 
                    open={dateError} 
                    close={e => this.setState({dateError: false})} 
                    customMessage={dateMessage}
                />
            </AppContainer>
        );
    }
}

LegalSearch = connect(
    (state, ownProps) => ({
        authState: state.auth,
        systems: getDrainageSystem(state, ownProps),
        users: getUsers(state, ownProps),
    }),
    {
        ...authActions
    }
)(LegalSearch);

export default withStyles(styles)(withRouter(LegalSearch));
