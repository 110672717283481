import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {Form} from "react-form";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToolBar from "@material-ui/core/Toolbar";
import SaveIcon from '@material-ui/icons/Save';

import DatePicker from "../common/DatePicker";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";

import { MAKE_OPTIONS_KEY_WTH_NONE } from "../../api/constants";
import { Vendor, Violation } from "../mypage/models";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";

const getVendors = Vendor.selectAll();

const styles = theme => ({
    mb: {
        marginBottom: 10
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    pad_2: {
        marginRight: 150,
    }
});

class Description extends Component {
    state = {
        snackOpen: false
    }
    Formref = React.createRef();

    componentWillUnmount = (panel) => {
        const { ormViolationUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormViolationUpdateLocalOnly({
            ...state.values
        });
    }

    save = values => {
        const { violation, ormViolationUpdatePromise, saveGood, saveBad } = this.props;
        ormViolationUpdatePromise({
            ...values,
            thegeom: violation.thegeom
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    render(){
        const { authState, classes, system, vendors, violation} = this.props;
        const viewOnly = authState.user.role === "View Only";

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Violation #: " + violation.vnum} />
                <Grid item xs={12}> 
                    <Form 
                        ref={this.Formref}
                        getApi={el => (this.formApi = el)}
                        id="authorityform"
                        defaultValues={violation}
                        onSubmit={this.save}>
                        {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <ToolBar disableGutters>
                                <Typography variant="h5" className={classes.grow}>Authority Action</Typography>
                                {!viewOnly && (
                                    <Button
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        style = {{float: "right", display: viewOnly && "none "}}
                                        ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                )}
                            </ToolBar>
                            <Divider className={classes.mb}/>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DatePicker field="notify_authority_date" label="Date Drainage Authority was Notified:" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        options={[{label: "Yes", value: "true"},{label: "No", value: "false"}]}
                                        field="remedy_violation"
                                        label="Ordered Contractor to Remedy Violation"
                                        className={classes.pad_2}
                                        disabled={viewOnly}
                                    />
                                    <TextField field="remedy_violation_reason" label="If No, Reason:" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select style={{width: 221}} field="contractor" label="Contractor" options={MAKE_OPTIONS_KEY_WTH_NONE(vendors, "name")} disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="work_completed" label="Date Work was Completed" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="invoice_date" label="Invoice Date" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField field="invoice_amount" label="Invoice Amount" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup
                                        row
                                        options={[{label: "Yes", value: "true"},{label: "No", value: "false"}]}
                                        field="invoice_sent"
                                        label="Invoice Sent to the Auditor & Landowner"
                                        className={classes.pad_2}
                                        disabled={viewOnly}
                                    />
                                    <TextField field="invoice_reason" label="If No, Reason:" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="datesent" label="Date Sent" disabled={viewOnly}/>
                                </Grid>
                            </Grid>
                        </form>
                        )}
                    </Form>
                </Grid>
            </Grid>
        );
    }
}

Description = connect(
    (state, ownProps) => ({
        authState: state.auth,
        vendors: getVendors(state)
    }),
    {
        ...Violation.actions,
        panelChange
    }
)(Description);

export default withStyles(styles)(withRouter(Description));