import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";

import FileInput from "../common/FileInput";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";

import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import CircularProgress from "@material-ui/core/CircularProgress";


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';
import ErrorIcon from "@material-ui/icons/Error";

import { createSelector } from '../common/orm';
import { DrainageSystem } from '../mypage/models';
import * as authActions from "../auth/actions";

const image = require('../../images/BalanceExample.png');


const getBoardDate = createSelector(schema => {
    return schema.BoardDate.first()
});

const styles = theme => ({
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    mB5: {
        marginBottom: 5,
        '& > *': {
            margin: theme.spacing(0.5),
          },
    },
    space: {
        marginTop: 88
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
    info: {
        position: "absolute", 
        right: 8,
        "& p": {
            padding: 0,
            margin: 0
        }
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    submitWrapper: {
        position: "relative"
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    }
});

const columnData = [
    { id: "drainage", label: "System"},
    { id: "balance", label: "Balance"},
    { id: "date", label: "Date"},
    { id: "notes", label: "Notes"},
];

const UpdateTypes = [
    {
        type: "assessment",
        title: "Annual Asssessment",
        plural: "Asssessments",
        url: "manage/update/system/annuals?type=assessments",
        keys: [
            "system",
            "year",
            "assessment",
            "uploaddate",
            "notes"
        ],
        data: [
            { id: "drainage", label: "System"},
            { id: "year", label: "Year"},
            { id: "asessment", label: "Assessment"},
            { id: "uploaddate", label: "Date"},
            { id: "notes", label: "Notes"}
        ],
        instructions: function(classes){
            return(
                <div style={{float: "right"}}>
                    <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>System names must match exactly with what is in DraingeDB<br />including spaces and capitalization.</Typography>
                    <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>The upload will use the first worksheet found in the Excel file.</Typography>
                    <Typography style={{marginTop: 0, marginBottom: 3, outline: "none !important"}}>For each system only one assessment per year can be upload/updated.</Typography> 
                    <Typography style={{marginTop: 0, marginBottom: 3, outline: "none !important"}}>New assessment will be create if there isn't existing one for that year.</Typography> 
                    <img src="/static/Assessment.jpg" className={classes.rm} alt="Assessment Example"/>
                </div>
            )
        }
    },
    {
        type: "expenditure",
        title: "Annual Expenditure",
        plural: "Expenditures",
        url: "manage/update/system/annuals?type=expenditures",
        keys: [
            "system",
            "year",
            "expand",
            "uploaddate",
            "notes"
        ],
        data: [
            { id: "drainage", label: "System"},
            { id: "year", label: "Year"},
            { id: "exppenditure", label: "Expenditure"},
            { id: "uploaddate", label: "Date"},
            { id: "notes", label: "Notes"}
        ],
        instructions: function(classes){
            return(
                <div style={{float: "right"}}>
                    <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>System names must match exactly with what is in DraingeDB<br />including spaces and capitalization.</Typography>
                    <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>The upload will use the first worksheet found in the Excel file.</Typography>
                    <Typography style={{marginTop: 0, marginBottom: 3, outline: "none !important"}}>For each system only one expenditure per year can be upload/updated.</Typography> 
                    <Typography style={{marginTop: 0, marginBottom: 3, outline: "none !important"}}>New expenditure will be create if there isn't existing one for that year.</Typography> 
                    <img src="/static/Expenditure.jpg" className={classes.rm} alt="Expenditure Example"/>
                </div>
            )
        }
    }
];

class Template extends Component {
    constructor(props){
        super(props);

        var keys = {};
        UpdateTypes.forEach(x => {
            keys[`${x.type}s`] = [];
            keys[`${x.type}Fail`] = 0;
            keys[`${x.type}Success`] = 0;
            keys[`${x.type}Loading`] = false;
        });

        this.state = {
            systems: [],
            order: "",
            orderBy: "",
            fail: 0,
            success: 0,
            fetchError: false,
            message: "",
            loading: false,
            ...keys
        }
    }

    updateBalance = (values) => {
        const  { authState, ormDrainageSystemReloadAdmin } = this.props;

        if(!values.file){
            this.setState({fetchError: true, message: "Empty"});
            console.log("Empty");
        } else {
            this.setState({ 
                systems: [], 
                fail: 0, 
                success: 0,
                loading: true
            });

            fetch('manage/update/system/balances', {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values),
            })
            .then(result => {
                if (!result.ok) {
                    return result.json().then(res => {
                        throw new Error(res);
                    });
                } else {
                    return result.json().then(res => {
                        this.setState({
                            systems: res.list, 
                            fail: res.fail, 
                            success: res.success,
                            loading: false
                        });
                        ormDrainageSystemReloadAdmin();
                    });
                }
            }).catch(e => {
                
                if(e === '{"detail":"Token has expired."}'){
                    this.props.authLogout();
                    this.props.history.push("/");
                } else {                
                    this.setState({
                        fetchError: true,
                        message: "Error",
                        systems: [],
                        loading: false
                    });
                }
            });
        }
    }

    update = (type, url, values) => {
        const  { authState } = this.props;

        if(!values.file){
            this.setState({fetchError: true, message: "Empty"});
            console.log("Empty");
        } else {
            this.setState({ 
                [`${type}s`]: [], 
                [`${type}Fail`]: 0, 
                [`${type}Success`]: 0,
                [`${type}Loading`]: true
            });

            fetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values),
            })
            .then(result => {
                if (!result.ok) {
                    return result.json().then(res => {
                        throw new Error(res);
                    });
                } else {
                    return result.json().then(res => {
                        this.setState({
                            [`${type}s`]: res.list, 
                            [`${type}Fail`]: res.fail,  
                            [`${type}Success`]: res.success,
                            [`${type}Loading`]: false
                        });
                    });
                }
            }).catch(e => {
                
                if(e === '{"detail":"Token has expired."}'){
                    this.props.authLogout();
                    this.props.history.push("/");
                } else {                
                    this.setState({
                        fetchError: true,
                        message: "Error",
                        [`${type}Success`]: [],
                        [`${type}Loading`]: false
                    });
                }
            });
        }
    }

    render(){
        const { classes } = this.props;
        const { 
            loading, 
            fail, 
            fetchError, 
            message, 
            systems, 
            success
        } = this.state;

        return (
            <div className={classes.space}>
                <ExpansionPanel>
                    <ExpansionPanelSummary 
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <div className={classes.summaryView}>
                                <div>
                                    <Typography>
                                        Account Balance Updates
                                    </Typography>
                                </div>
                                <div></div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Form 
                                        onSubmit={this.updateBalance}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <FileInput
                                                        accept=".xls,.xlsx"
                                                        canDelete
                                                        fullWidth 
                                                        view={true}
                                                        label="Upload File"
                                                        id="accountAttachmentUpload"
                                                        field="file"
                                                    />
                                                    <br />
                                                    <br />
                                                    <div className={classes.submitWrapper}>
                                                        <Button type="submit" variant="contained" color="primary" startIcon={<PublishIcon />}>
                                                            Update Edited Records
                                                            {loading && <CircularProgress size={24} className={classes.progress} />}
                                                        </Button>
                                                    </div>
                                                </form>
                                            )}
                                    </Form>
                                </Grid>
                                <Grid item xs={6}>{
                                    <div style={{float: "right"}}>
                                        <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>System names must match exactly with what is in DraingeDB<br />including spaces and capitalization.</Typography>
                                        <Typography style={{marginTop: 0, marginBottom: 0, outline: "none !important"}}>The upload will use the first worksheet found in the Excel file.</Typography>
                                        <Typography style={{marginTop: 0, marginBottom: 3, outline: "none !important"}}>This section will only updated existing drainage systems.</Typography> 
                                        <Typography>To add or delete a system use the sections below.</Typography>
                                        <img src={image} className={classes.rm} alt="Example"/>
                                    </div>
                                }</Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" className={classes.grow}>Error List</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.mB5}>
                                        <Chip
                                            label={`${success} updated.`}
                                            color="primary"
                                        />
                                        <Chip
                                            label={`${fail} Systems not found or have value errors.`}
                                            color="secondary"
                                        />
                                    </div>
                                    <Table>
                                        <EnhancedTableHead columnData={columnData} />
                                        <TableBody>
                                            {systems.map(x => { return (
                                                <TableRow hover key={x.drainage}>
                                                    <CustomTableCell>{x.drainage}</CustomTableCell>
                                                    <CustomTableCell>{parseFloat(x.balance).toLocaleString(undefined, {minimumFractionDigits: 2})}</CustomTableCell>
                                                    <CustomTableCell>{x.date}</CustomTableCell>
                                                    <CustomTableCell>{x.notes}</CustomTableCell>
						</TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                </ExpansionPanel>
                {UpdateTypes.map(x => (
                    <ExpansionPanel>
                        <ExpansionPanelSummary 
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                                <div className={classes.summaryView}>
                                    <div>
                                        <Typography>
                                            {x.title}
                                        </Typography>
                                    </div>
                                    <div></div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <Form 
                                            onSubmit={(values) => this.update(x.type, x.url, values)}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <FileInput
                                                            accept=".xls,.xlsx"
                                                            canDelete
                                                            fullWidth 
                                                            view={true}
                                                            label="Upload File"
                                                            id={`${x.type}AttachmentUpload`}
                                                            field="file"
                                                        />
                                                        <br />
                                                        <br />
                                                        <div className={classes.submitWrapper}>
                                                            <Button type="submit" variant="contained" color="primary" startIcon={<PublishIcon />}>
                                                                Update Edited Records
                                                                {this.state[`${x.type}Loading`] && <CircularProgress size={24} className={classes.progress} />}
                                                            </Button>
                                                        </div>
                                                    </form>
                                                )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={6}>{x.instructions(classes)} </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" className={classes.grow}>Error List</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.mB5}>
                                            <Chip
                                                label={`${this.state[`${x.type}Success`]} ${x.plural} updated or created.`}
                                                color="primary"
                                            />
                                            <Chip
                                                label={`${this.state[`${x.type}Fail`]} Systems not found or have value errors.`}
                                                color="secondary"
                                            />
                                        </div>
                                        <Table>
                                            <EnhancedTableHead columnData={x.data} />
                                            <TableBody>
                                                {this.state[`${x.type}s`].map(z => (
                                                    <TableRow hover key={z.drainage}>
                                                        {x.keys.map(j => (
                                                            <CustomTableCell>{z[j]}</CustomTableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    className={classes.snackbar}
                    open={fetchError}
                    autoHideDuration={3000}
                    onClose={() => this.setState({fetchError: false})}>
                    <SnackbarContent
                        className={classes.snackbarContainer}
                        message={ 
                            <span className={classes.snackbarMessage}>
                                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {message}
                            </span>
                        }
                    />
                </Snackbar>
                </div>
            );
    }
}


Template = connect(
    (state, ownProps) => ({
        authState: state.auth,
        boardDate: getBoardDate(state)
    }),
    {
        ...authActions,
        ...DrainageSystem.actions
    }
)(Template);


export default withStyles(styles)(withRouter(Template));
