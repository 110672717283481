import React, {Component} from "react";
import {FormField} from "react-form";

import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {InputLabel, withStyles} from "@material-ui/core";
import {prepareDraftToHtml} from "../../api/utils";

const editorStyles = {
    wrapper: {
        display: "flex",
        flexDirection: "column-reverse",
        border: "1px solid #979797",
        borderRadius: "4px",
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        margin: "5px 0px",
    },
    editor: {
        padding: "0px 8px",
        minHeight: "170px",
        height: "100%",
        background: "#FFFFFF"
    },
    toolbar: {
        marginBottom: "0px"
    }
}

const editorToolbar = {
    options: ['inline',  'list',  'link'],
}

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        },
        backgroundColor: "#fff",
        fontSize: "16px",
        fontWeight: 500,
        color: "#666666 !important",
        font: "Sans-Serif"
    },
    labelSelected: {
        //border: '1px solid ' + theme.palette.primary.main,
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #f44336"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8
        //color: theme.palette.primary.dark,
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    wordCountSmall: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -10,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
});

class EditorFieldWrapper extends Component {

    handleChange = value => {
        const { onChange } = this.props;
        onChange(value)
    };

    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            help,
            classes,
            label,
            disabled,
            children,
            required,
            maxWords,
            value,
            ...rest
        } = this.props;

        return (
            <>
                {label && <InputLabel>{`${label}${required ? ' *' : ''}`}</InputLabel>}
                <Editor
                    editorState={value}
                    onEditorStateChange={this.handleChange}
                    wrapperStyle={editorStyles.wrapper}
                    editorStyle={editorStyles.editor}
                    toolbarStyle={editorStyles.toolbar}
                    toolbar={editorToolbar}
                >
                    <textarea
                        disabled
                        value={prepareDraftToHtml(value)}
                    />
                </Editor>
            </>
        );
    }
}

const EditorField = FormField(EditorFieldWrapper);
export default withStyles(styles)(EditorField);
