import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { formateDate } from "../../api/utils";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";


import BaseMapControl from "../common/esrimap/BaseMapControl";
import { getStartType } from "../common/esrimap/Helpers";


import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { Violation } from '../mypage/models'
import { createSelector } from '../common/orm';

import * as mapActions from "../common/map/actions";
import * as authActions from "../auth/actions";
import { panelChange } from "../common/actions";

const getDrainageSystem = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageSystem.filter(sys => sys.drainage === system).toRefArray()[0];
    }
);

const styles = theme => ({
    horizontal: {
        flexDirection: "row"
    },
    formControl: {
        margin: theme.spacing.unit * 3
    },
    deleteWidth: {
        border: "1px solid",
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    bNone: {
        '& td': {
            border: "none"
        }
    },
    pl: {
        paddingLeft: 8
    },
    overviewPrjname: {
        cursor: "pointer",
        color: "#0071bc",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: 14
    }
});

class OverView extends Component {
    constructor(props){
        super(props);

        this.state = {
            load: false,
            value: this.getDefaultDraw(),
            name: "",
            mapReady: true,
            reportLoading: false
        }

        this.getDefaultDraw = this.getDefaultDraw.bind(this);
    }

    // Panel Prop Changed Complete Edit and Update Location Information
    componentDidUpdate(prevProps, prevState) {
        var changed = {};
        Object.entries(this.props).forEach(([key, val]) => {
            if(prevProps[key] !== val) {
                console.log(`Prop '${key}' changed`)
                changed[key] = true;
            }
        });
 
        if(changed["panel"] && prevProps["panel"] === 0){
            this.editChild._drawSave(false);
            this.editChild._updateLocation();
        }
    }

    // Gets Start type for Radio
    getDefaultDraw = () => {
        return getStartType(this.props.violation.thegeom);
    }

    // Create Location Button 
    create = () => {
        const { value } = this.state;
        var type = this.editChild._getType();

        if(type === ""){
            this.editChild._drawCreate(value);
        } else if(value === type){
            this.editChild._drawCreate(value);
        } else {
            this.editChild._setError(`${type === "polyline" ? "Line": "Point"} feature(s) already exists on the map for this violation!`);
        }
    }

    // Delete Locations Button
    delete = () => {
        this.editChild._drawClear();
        this.renderForGraphicUpdate();
    }

    save = () => {
        this.editChild._drawSave(false);
        this.setState({saving: true});
        const interval = setInterval(this.check.bind(this), 1200);
        this.setState({intervalId: interval});
    }

    check = () => {
        const { ormViolationUpdatePromise, violation, saveBad, saveGood } = this.props;

        if(!this.editChild._getSavedStatus()){
            clearInterval(this.state.intervalId);
            const saved_data = this.editChild._getSavedData();
            if(violation.updategeo && saved_data.saved_locationData){
                
                if('name' in saved_data.saved_locationData){
                    violation["landowner"] = saved_data.saved_locationData["name"];
                }
                if('add1' in saved_data.saved_locationData){
                    violation["address"] = saved_data.saved_locationData["add1"];
                }
                
                const base_keys = ["city", "state", "zip", "township", "twp", "range", "commissioner", "section", "quarter", "parcelnum"];
                base_keys.forEach(x => {
                    if(x in saved_data.saved_locationData){
                        violation[x] =  saved_data.saved_locationData[x];
                    }
                });;
            }
            ormViolationUpdatePromise({
                ...violation,
                thegeom: saved_data.saved_geometry
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({saving: false})
            });
        }
    }

    report = () => {
        const { authState, violation } = this.props;
        this.setState({reportLoading: true});
        this.editChild._screenShot().then(data => {
            fetch(`/reports/drainageviolation/${violation.id}/word`, {
                method: 'POST',
                body: JSON.stringify({img: (data && data.dataUrl ? data.dataUrl : null)}),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                }
            }).then( res => {
                if(res.status !== 200 && res.statusText !== "Unauthorized"){
                    throw new Error("error");
                } else if(res.statusText === "Unauthorized"){
                    throw new Error("Unauthorized");
                } else {
                    return res.blob();
                }
            })
            .then( blob => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "ViolationReport.docx");
                } else {
                    var file = window.URL.createObjectURL(blob);
                    //window.location.assign(file);
                    var a = document.createElement('a');
                    a.href = file;
                    a.download = "ViolationReport.docx";
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();    
                    a.remove();  //afterwards we remove the element again     
                    URL.revokeObjectURL(file); //release to avoid memeory leak
                }
                this.setState({reportLoading: false});
            }).catch(e => {
                if(e.message === "Unauthorized"){
                    this.props.authLogout();
                    this.props.history.push("/");
                }
            });
        });
    }

    whenMapReady = () => {
        const _this = this;
        setTimeout(function(){
            _this.setState({mapReady: false});
        }, 500);
    }

    renderForGraphicUpdate = () => {
        this.setState({dummyState: performance.now()})
    }

    render() {
        const { authState, classes, violation, system, style } = this.props;
        const { mapReady, reportLoading, value } = this.state;

        const ViewOnly = authState.user.role !== "View Only";
        const empty = this.editChild && this.editChild._checkForGraphics();

        const date = formateDate(violation.modified_date && violation.modified_date !== "" ? violation.modified_date : new Date());

        return (
            <Grid container style={{...style, height: "calc(100% - 48px)"}}>
                <Grid item style={{marginTop: 48, width: 260}}>
                    <br />
                    <Typography><b>Violation #: </b> {violation.vnum}</Typography>
                    <Typography>
                        <b>System Name: </b>
                        <Link to={"/drainage/" + system.id + "/" + system.drainage} className={classes.overviewPrjname}>
                            {system.drainage}
                        </Link>
                    </Typography>
                    <Typography><b>Source Created: </b>{formateDate(violation.violationdate)}</Typography>
                    <Typography><b>Status: </b>{violation.statuscomply}</Typography>
                    <Typography><b>Township: </b>{violation.township}</Typography>
                    <Typography><b>Section: </b>{violation.section}</Typography>
                    <Typography>Last Updated {date} by {violation.modified_user ? violation.modified_user : authState.user.username}</Typography>
                    {empty && (
                        <Typography style={{color: "#e31c3d"}}>No Geometry Mapped</Typography>
                    )}
                    <br />
                    <br />
                    {ViewOnly && (
                        <>
                            <Typography><b>Map Editing</b></Typography>
                            {empty && (
                                <Typography style={{color: "#e31c3d"}}>No Geometry Mapped</Typography>
                            )}
                            <Table>
                                <TableBody>
                                    <TableRow className={classes.bNone}>
                                        <TableCell padding="checkbox">
                                            <Button 
                                                aria-label="View Edit Instructions"
                                                disabled={empty}
                                                onClick={() => this.editChild._toggleHelp()} 
                                                className={classes.deleteWidth}>
                                                <EditIcon fontSize="small"/> 
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.pl}>
                                            <Typography>Edit Location(s) on map</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.bNone}>
                                        <TableCell padding="checkbox">
                                            <Button 
                                                aria-label="Add Geometry"
                                                onClick={this.create} 
                                                className={classes.deleteWidth}
                                            >
                                                <AddCircleIcon fontSize="small"/> 
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.pl}>
                                            <Typography>Create Location(s) on map</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <FormControl component="fieldset" style={{marginLeft: 10}}>
                                <RadioGroup name="type" value={value} onChange={(e) => {this.setState({value: e.target.value, name: e.target.name})}} row>
                                    <FormControlLabel name="Point" value="point" label="Point" control={<Radio />} labelPlacement="end"/>
                                    <FormControlLabel name="LineString" value="polyline" label="Line" control={<Radio />} labelPlacement="end"/>
                                </RadioGroup>
                            </FormControl>  
                            <Table>
                                <TableBody>
                                    <TableRow className={classes.bNone}>
                                        <TableCell padding="checkbox">
                                            <Button 
                                                aria-label="Delete Mapped Geometry"
                                                disabled={empty}
                                                onClick={this.delete} 
                                                className={classes.deleteWidth}>
                                                <DeleteIcon fontSize="small"/> 
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.pl}>
                                            <Typography>Delete All Location(s) on map</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.bNone}>
                                        <TableCell padding="checkbox">
                                            <Button 
                                                aria-label="Save Violation"
                                                onClick={this.save} 
                                                className={classes.deleteWidth}
                                            >
                                                <SaveIcon fontSize="small"/> 
                                            </Button>
                                        </TableCell>
                                        <TableCell className={classes.pl}>
                                            <Typography>Save Changes</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </>
                    )}
                    <Typography><b>Reports</b></Typography>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.bNone}> 
                                <TableCell padding="checkbox">
                                    {reportLoading ? 
                                        <CircularProgress color="primary" />
                                        :
                                        <Button 
                                            aria-label="Generate Violation Report"
                                            disabled={mapReady}
                                            onClick={this.report} 
                                            className={classes.deleteWidth}>
                                            <AssignmentIcon fontSize="small"/> 
                                        </Button>
                                    }
                            </TableCell>
                            <TableCell className={classes.pl}>
                                <Typography>Printable Violation Report</Typography>
                            </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs style={{marginTop: 58}} id="leaflet-control-noclick">
                    <BaseMapControl 
                        ref2={r => this.editChild = r} 
                        mapEdit={true} 
                        type="Violation"
                        open={violation.inspectiondone}
                        geometry={violation.thegeom}
                        drainage={violation.drainage}
                        centroid={violation.centroid}
                        whenMapReady={this.whenMapReady}
                        renderForGraphicUpdate={this.renderForGraphicUpdate}
                        updategeo={violation.updategeo}
                        localUpdate={this.props.ormViolationUpdateLocalOnly}
                        record_id={violation.id}
                    />
                </Grid>
            </Grid>
        );
    }
}

OverView = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        system: getDrainageSystem(state, ownProps)
    }),
    {
        ...Violation.actions,
        ...mapActions,
	...authActions,
        panelChange
    }
)(OverView);

export default withStyles(styles)(withRouter(OverView));
