import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { addToForm, getToday } from '../../api/utils';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import LargeFileTable from "../common/LargeFileTable";
import PageHeader from "../common/PageHeader";
import { DocumentTypes } from "../common/SelectOptions";
import { panelChange } from "../common/actions";
import { createSelector } from '../common/orm';
import { Documentsrepair, Photos } from "../mypage/models";

const getPhotos = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["repair"]),
    (session, id) => {
        var temp = session.Photos.filter(x => x.repair === id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "photo";
        });
        return temp;
    }
);

const getDocs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["repair"]),
    (session, id) => {
        var temp = session.Documentsrepair.filter(x => x.repair === id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "doc";
        });
        return temp;
    }
);

const photoColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: true, type: "date" },
    {id: "caption", numeric: false, label: "Caption", allowSort: true, required: true, type: "text" },
    {id: "notes", numeric: false, label: "Notes", allowSort: true, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file", required: true, accept: "image/*" }
];
const photokeyData = photoColumnData.slice(1);

const docColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "caption", numeric: false, label: "Document Title", allowSort: true, type:"text", required: true },
    {id: "type", numeric: false, label: "Type", allowSort: true, type: "select", options: DocumentTypes },
    {id: "uploaddate", numeric: false, label: "Date", allowSort: true, type: "date", noEdit: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file", required: true },
];
const dockeyData = docColumnData.slice(1);


const styles = theme => ({
    space: {
        marginTop: 48
    },
    m: {
        marginTop: 40
    },
    mb: {
        marginBottom: 20
    },
});

class Attachments extends Component {
    state = {
        forceReset: performance.now()
    }

    submitPhoto = (values, file_interface) => {
        const { ormPhotosUpdatePromise, ormPhotosCreateForm, repair, saveBad, saveGood } = this.props;
        delete values["deleteProp"];
        if(values.id){
            ormPhotosUpdatePromise({
                drainage_id: repair.drainage_id,
                repair_id: repair.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        } else {
            this.setState({loading: true});
            var form_data = new FormData();
            form_data.append('drainage_id', repair.drainage);
            form_data.append('repair_id', repair.id);
            form_data.append('uploaddate', values.uploaddate);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'notes', values.notes);
            form_data.append('link', file_interface, file_interface.name);
            ormPhotosCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }
        this.props.repairUpdate();
    }

    submitDocument = (values, file_interface) => {
        const { ormDocumentsrepairUpdatePromise, ormDocumentsrepairCreateForm, repair, saveBad, saveGood } = this.props;
        delete values["deleteProp"];
        if(values.id){
            ormDocumentsrepairUpdatePromise({
                drainage_id: repair.drainage_id,
                repair_id: repair.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        } else {
            this.setState({docLoading: true});
            var form_data = new FormData();
            form_data.append('drainage_id', repair.drainage);
            form_data.append('repair_id', repair.id);
            form_data.append('uploaddate', getToday());
            form_data.append('creationdate', values.creationdate);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'type', values.type);
            addToForm(form_data, 'notes', values.notes);
            form_data.append('link', file_interface, file_interface.name);
            ormDocumentsrepairCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }
        this.props.repairUpdate();
    }

    deletePhoto = (id) => {
        const { saveBad, saveGood } = this.props;
        this.props.ormPhotosDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.repairUpdate();
    }

    deleteDoc = (id) => {
        const { saveBad, saveGood } = this.props;
        this.props.ormDocumentsrepairDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.repairUpdate();
    }

    render(){
        const { authState, classes, fileSize, system, repair, docs, photos } = this.props;
        const { forceReset } = this.state;
        const viewOnly = authState.user.role === "View Only";
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Typography variant="h5" style={{marginTop: 20}}>Photos</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable
                    name="repair_photo_attachement" 
                    id="repair-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly && !contractor}
                    data={photos}
                    noHelp       
                    title="Photo"
                    emptyTxt="No Photos"
                    columns={photoColumnData} 
                    keys={photokeyData}
                    parentSubmitForm={this.submitPhoto}
                    addFiles={true}
                    forceReset={forceReset}
                    deleteProp={{"photo": this.deletePhoto}}
                    fileSize={fileSize}
                />  
                <Typography variant="h5" style={{marginTop: 20}}>Documents</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable
                    name="repair_document_attachement" 
                    id="repair-document-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly && !contractor}
                    data={docs}
                    noHelp      
                    title="Document"
                    emptyTxt="No Documents"
                    columns={docColumnData} 
                    keys={dockeyData}
                    parentSubmitForm={this.submitDocument}
                    addFiles={true}
                    forceReset={forceReset}
                    deleteProp={{"doc": this.deleteDoc}}
                    fileSize={fileSize}
                /> 
            </Grid>
        )
    }
}

Attachments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        photos: getPhotos(state, ownProps),
        docs: getDocs(state, ownProps)
    }),
    {
        ...Photos.actions,
        ...Documentsrepair.actions,
        panelChange
    }
)(Attachments);

export default withStyles(styles)(withRouter(Attachments));
