

export function getGeoDjangoFormat(data) {

    if (data.type === "Point") {
        return data;
    }

    if (data.type === "MultiPoint") {
        return data;
    }

    if (data.type === "MultiLineString") {
        return data;
    }

    if (data.type === "FeatureCollection" && data.features.length > 0) {

        //MultiPoint
        if (data.features[0].geometry.type === "Point") {
            var pointArray = [];
            for (var x in data.features) {
                pointArray.push(data.features[x].geometry.coordinates);
            }

            return {
                type: "MultiPoint",
                coordinates: pointArray
            };
        }

        //MultiString
        if (data.features[0].geometry.type === "LineString" && data.features.length === 1) {
            return {
                type: "LineString",
                coordinates: data.features[0].geometry.coordinates
            };
        }

        if (data.features[0].geometry.type === "LineString" && data.features.length > 1) {
            var geometry = [];

            for (var y in data.features) {
                geometry.push(data.features[y].geometry.coordinates);
            }

            return {
                type: "GeometryCollection",
                geometries: [
                    {
                        type: "MultiLineString",
                        coordinates: [...geometry]
                    }
                ]
            };
        }
    }
}

export function getType(data) {

    if (!data || !data.features || data.features.length === 0) {
        return "";
    }

    if (data.features[0].geometry.type === "Point" || data.type === "MultiPoint") {
        return "point";
    }

    if (data.features[0].geometry.type === "LineString" || data.type === "MultiLineString") {
        return "polyline";
    }
}

export function getStartType(data) {
    if (typeof data === "undefined" || data === null || (data && !data.type)) {
        return "point";
    }

    if (data.type === "MultiPoint" || data.type === "Point") {
        return "point";
    }
    if (data.type === "GeometryCollection" || data.type === "LineString" || data.type === "MultiLineString") {
        return "polyline";
    }
}
