import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {Form} from "react-form";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToolBar from "@material-ui/core/Toolbar";
import SaveIcon from '@material-ui/icons/Save';

import DatePicker from "../common/DatePicker";
import TextField from "../common/TextField";
import RadioGroup from "../common/RadioGroup";

import { Violation } from "../mypage/models";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";


const styles = theme => ({
    mb: {
        marginBottom: 10
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    pad_2: {
        marginRight: 150,
    }
});

class Notification extends Component {
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { violation, ormViolationUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormViolationUpdateLocalOnly({
            ...violation,
            ...state.values
        });
    }

    save = values => {
        const { violation, ormViolationUpdatePromise, saveBad, saveGood } = this.props;

        ormViolationUpdatePromise({
            ...violation,
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
    }

    getDefaultValues = (violation) => {
        var defaultValues = {};
        defaultValues.notification_sent = violation.notification_sent;
        defaultValues.notification_other = violation.notification_other;
        defaultValues.mailed_date = violation.mailed_date;
        defaultValues.remedy_date = violation.remedy_date;
        defaultValues.compilance_inspection_date = violation.compilance_inspection_date;
        return defaultValues;
    }

    render(){
        const { authState, classes, system, violation} = this.props;
        const viewOnly = authState.user.role === "View Only";

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Violation #: " + violation.vnum} />
                <Grid item xs={12}> 
                    <Form 
                        ref={this.Formref}
                        getApi={el => (this.formApi = el)}
                        id="descriptionform"
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        defaultValues={this.getDefaultValues(violation)}
                        onSubmit={this.save}>
                        {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <ToolBar disableGutters>
                                <Typography variant="h5" className={classes.grow}>Description</Typography>
                                <Button
                                    type="submit" 
                                    variant="contained" 
                                    color="primary" 
                                    style = {{float: "right", display: viewOnly && "none "}}
                                    ><SaveIcon />&nbsp;&nbsp;Save</Button>
                            </ToolBar>
                            <Divider className={classes.mb}/>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            <RadioGroup
                                                row
                                                options={[{label: "Yes", value: "true"},{label: "No", value: "false"}]}
                                                field="notification_sent"
                                                label="Landowner Notification Letter Sent"
                                                className={classes.pad_2}
                                                disabled={viewOnly}
                                            />
                                        </div>
                                        <div>
                                            <TextField field="notification_other" label="If No, Reason:" disabled={viewOnly}/>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="mailed_date" label="Date Mailed" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="remedy_date" label="Required Remedy Date" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker field="compilance_inspection_date" label="Scheduled Compliance Inspection Date" disabled={viewOnly}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        target="_blank" 
                                        href={`/reports/violation_letter/${violation.id}`}
                                        variant="contained" 
                                        color="primary">
                                        Generate Notification Letter
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        )}
                    </Form>
                </Grid>
            </Grid>
        );
    }
}

Notification = connect(
    (state, ownProps) => ({
        authState: state.auth,
    }),
    {
        ...Violation.actions,
        panelChange
    }
)(Notification);

export default withStyles(styles)(withRouter(Notification));