import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { formateDate } from "../../api/utils";

import Grid from "@material-ui/core/Grid";
import { CircularProgress, withStyles } from "@material-ui/core";

import BaseMapControl from "../common/esrimap/BaseMapControl";
import { getStartType } from "../common/esrimap/Helpers";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import WarningDialog from "../common/WarningDialog";
import CollapsePanel from "../common/CollapsePanel";

import { Peoplelandowner, Repair } from '../mypage/models'
import { createSelector } from '../common/orm';
import * as mapActions from "../common/map/actions";
import * as authActions from "../auth/actions";

const getDrainageSystem = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        return session.DrainageSystem.filter(sys => sys.drainage === system).toRefArray()[0];
    }
);

const getStatus = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.Tracking.filter(status => status.repair === repair.id).toRefArray();
    }
)

const getLandOwner = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["repair"]),
    (session, id) => {
        return session.Peoplelandowner.filter(x => x.repair === id).first() 
});

const getRequest = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.PeopleRequest.filter(sys => sys.repair === repair).orderBy("id").toRefArray()[0];
    }
);

const styles = theme => ({
    deleteWidth: {
        border: "1px solid",
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    bNone: {
        '& td': {
            border: "none"
        }
    },
    pl: {
        paddingLeft: 8
    },
    overviewPrjname: {
        color: "#76c0f0",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: 14
    },
    overviewPrjnameLink: {
        cursor: "pointer",
        color: "#0071bc",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: 14
    }
});

class OverView extends Component {
    constructor(props){
        super(props);

        this.state = {
            repairDialog: false,
            ignore: false,
            load: false,
            value: this.getDefaultDraw(),
            name: "",
            message: "",
            snackbarOpen: false,
            repairCache: {},
            mapReady: true,
            reportLoading: false,
            accepted: false,
            hidden: false,
        }

        this.getDefaultDraw = this.getDefaultDraw.bind(this);
    }

    componentDidMount(){
        if(this.props.ref1){
            this.props.ref1(this);
        }
    }

    screenShot = () => {
        return this.editChild._screenShot();
    }
    
    // Panel Prop Changed Complete Edit and Update Location Information
    componentDidUpdate(prevProps, prevState) {
        var changed = {};
        Object.entries(this.props).forEach(([key, val]) => {
            if(prevProps[key] !== val) {
                changed[key] = true;
            }
        });
 
        if(changed["panel"] && prevProps["panel"] === 0){
            this.editChild.updateMapState();
            this.editChild._drawSave(false);
            this.editChild._updateLocation();
        }
    }

    // Gets Start type for Radio
    getDefaultDraw = () => {
        return getStartType(this.props.repair.thegeom);
    }

    // Create Location Button 
    create = () => {
        const { value } = this.state;
        var type = this.editChild._getType();

        if(type === ""){
            this.editChild._drawCreate(value);
        } else if(value === type){
            this.editChild._drawCreate(value);
        } else {
            this.editChild._setError(`${type === "polyline" ? "Line": "Point"} feature(s) already exists on the map for this repair!`);
        }
    }

    // Delete Locations Button
    delete = () => {
        this.editChild._drawClear();
        this.renderForGraphicUpdate();
    }

    save = (forceSave = true) => {
        const { repair } = this.props;
        if(repair.updategeo && forceSave){
            this.setState({repairDialog: true});
        } else {
            this.comfirmedUpdate(false);
        }
    }

    comfirmedUpdate = (accepted) => {
        this.editChild._drawSave(accepted);
        this.setState({ saving: true, accepted });
        const interval = setInterval(this.check.bind(this), 1200);
        this.setState({intervalId: interval});
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalId);
    }

    check = () => {
        const { fullRepairUpdate, repair, landOwner, person } = this.props;
        const { accepted } = this.state;
        var newLandOwnerObj = {...landOwner};

        if(!this.editChild._getSavedStatus()){
            clearInterval(this.state.intervalId);
            const saved_data = this.editChild._getSavedData();
            if(saved_data.saved_locationData){
                var temp = saved_data.saved_locationData;
                const base_keys = ["township", "twp", "range", "commissioner", "section", "quarter", "parcelnum"];
                
                if(repair.updategeo){
                    base_keys.forEach(x => {
                        if(x in temp){
                            repair[x] = temp[x];
                        }
                    });
                }
                
                if(accepted){
                    const info_keys = ["add1", "add2", "city", "state", "zip", "name"];

                    info_keys.forEach(x => {
                        if(x in temp){
                            newLandOwnerObj[x] = temp[x];
                        }
                    });

                    if(newLandOwnerObj.id){
                        var values = {};
                        
                        newLandOwnerObj = { 
                            ...newLandOwnerObj, 
                            ...values
                        };

                    } else {
                        newLandOwnerObj = {
                            ...newLandOwnerObj,
                            repair: this.props.match.params["repair"],
                            drainage_id: this.props.match.params["drainage"]
                        };
                    }
                }
                fullRepairUpdate({...repair, thegeom: saved_data.saved_geometry}, newLandOwnerObj, person);
            } else {
                fullRepairUpdate({...repair, thegeom: saved_data.saved_geometry}, newLandOwnerObj, person);
            }
        }
    }

    repairReport = () => {
        const { authState, repair } = this.props;
        this.setState({reportLoading: true});
        this.editChild._screenShot().then(data => {
            fetch(`/reports/drainagerepair/${repair.id}/word`, {
                method: 'POST',
                body: JSON.stringify({img: (data && data.dataUrl ? data.dataUrl : null)}),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                }
            }).then( res => {
                if(res.status !== 200 && res.statusText !== "Unauthorized"){
                    throw new Error("error");
                } else if(res.statusText === "Unauthorized"){
                    throw new Error("Unauthorized");
                } else {
                    return res.blob();
                }
            })
            .then( blob => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "RepairReport.docx");
                } else {
                    var file = window.URL.createObjectURL(blob);
                    //window.location.assign(file);
                    var a = document.createElement('a');
                    a.href = file;
                    a.download = "RepairReport.docx";
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();    
                    a.remove();  //afterwards we remove the element again     
                    URL.revokeObjectURL(file); //release to avoid memeory leak
                }
                this.setState({reportLoading: false});
            }).catch(e => {
                if(e.message === "Unauthorized"){
                    this.props.authLogout();
                    this.props.history.push("/");
                }
            });
        });
    }

    whenMapReady = () => {
        const _this = this;
        setTimeout(function(){
            _this.props.openInspectionTab();
            _this.setState({mapReady: false});
        }, 500);
    }

    renderForGraphicUpdate = () => {
        this.setState({dummyState: performance.now()})
    }

    navigationClick = () => {
        const { hidden } = this.state;
        if(this.editChild && this.editChild.addCollapse){
            if(!hidden){
                this.editChild.addCollapse();
            }
            this.setState({ hidden: !hidden });
        }
    }

    render() {
        const { authState, classes, repair, status, system, style } = this.props;
        const { 
            value, 
            mapReady, 
            repairDialog, 
            reportLoading,
            hidden
        } = this.state;

        const ViewOnly = authState.user.role !== "View Only" && authState.user.role !== "Contractor";
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        const trackingdates = status.sort((a,b) => {
            return new Date(b.trackingdate) < new Date(a.trackingdate) ? -1 : 1;
        });

        const empty = this.editChild && this.editChild._checkForGraphics();

        return (
            <>
                <Grid container style={{...style, height: "calc(100% - 48px)"}}>
                    {!hidden && (
                        <Grid item style={{marginTop: 48, width: 260}}>
                            <CollapsePanel 
                                navigationClick={this.navigationClick}
                            />
                            <Typography><b>Repair #: </b> {repair.repair}</Typography>
                            {contractor && (
                                <Typography>
                                    <b>System Name: </b>
                                    <span className={classes.overviewPrjname}>
                                        {system.drainage}
                                    </span>
                                </Typography>
                            )}
                            {!contractor && (
                                <Typography>
                                    <b>System Name: </b>
                                    <Link to={"/drainage/" + system.id + "/" + system.drainage} className={classes.overviewPrjnameLink}>
                                        {system.drainage}
                                    </Link>
                                </Typography>
                            )}
                            <Typography><b>Date Created: </b>{formateDate(repair.datereceived)}</Typography>
                            <Typography><b>Status: </b>{repair.latestTracking ? `${repair.latestTracking.status} on ${formateDate(trackingdates.length > 0 ? trackingdates[0].trackingdate : null)}` : repair.status}</Typography>
                            <Typography><b>Township: </b>{repair.township}</Typography>
                            <Typography><b>Section: </b>{repair.section}</Typography>
                            <Typography>Last Updated {repair.modified_user ? formateDate(repair.modified_date) : formateDate(repair.datereceived)} by {repair.modified_user ? repair.modified_user : authState.user.username}</Typography>
                            {empty && (
                                <Typography style={{color: "#e31c3d"}}>No Geometry Mapped</Typography>
                            )}
                            <br />
                            <br />
                            {ViewOnly && (
                                <>
                                    <Typography><b>Map Editing</b></Typography>
                                    {empty && (
                                        <Typography style={{color: "#e31c3d"}}>No Geometry Mapped</Typography>
                                    )}
                                    <Table>
                                        <TableBody>
                                            <TableRow className={classes.bNone}>
                                                <TableCell padding="checkbox">
                                                    <Button 
                                                        aria-label="View Edit Instructions"
                                                        disabled={empty}
                                                        onClick={() => this.editChild._toggleHelp()} 
                                                        className={classes.deleteWidth}>
                                                        <EditIcon fontSize="small"/> 
                                                    </Button>
                                                </TableCell>
                                                <TableCell className={classes.pl}>
                                                    <Typography>Edit Location(s) on map</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.bNone}>
                                                <TableCell padding="checkbox">
                                                    <Button 
                                                        aria-label="Add Geometry"
                                                        onClick={this.create} 
                                                        className={classes.deleteWidth}
                                                    >
                                                        <AddCircleIcon fontSize="small"/> 
                                                    </Button>
                                                </TableCell>
                                                <TableCell className={classes.pl}>
                                                    <Typography>Create Location(s) on map</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <FormControl component="fieldset" style={{marginLeft: 10}}>
                                        <RadioGroup name="type" value={value} onChange={(e) => {this.setState({value: e.target.value, name: e.target.name})}} row>
                                            <FormControlLabel name="Point" value="point" label="Point" control={<Radio />} labelPlacement="end"/>
                                            <FormControlLabel name="LineString" value="polyline" label="Line" control={<Radio />} labelPlacement="end"/>
                                        </RadioGroup>
                                    </FormControl>  
                                    <Table>
                                        <TableBody>
                                            <TableRow className={classes.bNone}>
                                                <TableCell padding="checkbox">
                                                    <Button 
                                                        aria-label="Delete Mapped Geometry"
                                                        disabled={empty}
                                                        onClick={this.delete} 
                                                        className={classes.deleteWidth}>
                                                        <DeleteIcon fontSize="small"/> 
                                                    </Button>
                                                </TableCell>
                                                <TableCell className={classes.pl}>
                                                    <Typography>Delete All Location(s) on map</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className={classes.bNone}>
                                                <TableCell padding="checkbox">
                                                    <Button 
                                                        aria-label="Save Repair"
                                                        onClick={this.save} 
                                                        className={classes.deleteWidth}
                                                    >
                                                        <SaveIcon fontSize="small"/> 
                                                    </Button>
                                                </TableCell>
                                                <TableCell className={classes.pl}>
                                                    <Typography>Save Changes</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                            <Typography><b>Reports</b></Typography>
                            <Table>
                                <TableBody>
                                    <TableRow className={classes.bNone}> 
                                        <TableCell padding="checkbox">
                                            {reportLoading ? 
                                                <CircularProgress color="primary" />
                                                :
                                                <Button 
                                                    aria-label="Generate Repair Report"
                                                    disabled={mapReady}
                                                    onClick={() => this.repairReport()}
                                                    className={classes.deleteWidth}>
                                                    <AssignmentIcon fontSize="small"/> 
                                                </Button>
                                            }
                                    </TableCell>
                                    <TableCell className={classes.pl}>
                                        <Typography>Printable Repair Report</Typography>
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    )}
                    <Grid item xs style={{marginTop: 58}} id="leaflet-control-noclick">
                        <BaseMapControl 
                            navigationClick={this.navigationClick}
                            ref2={r => this.editChild = r} 
                            mapEdit={true} 
                            type="Repair"
                            drainage={repair.drainage}
                            status={repair.status}
                            geometry={repair.thegeom}
                            centroid={repair.centroid}
                            whenMapReady={this.whenMapReady}
                            renderForGraphicUpdate={this.renderForGraphicUpdate}
                            updategeo={repair.updategeo}
                            localUpdate={this.props.ormRepairUpdateLocalOnly}
                            record_id={repair.id}
                            landOwner={this.props.landOwner}
                            landOwnerUpdate={this.props.ormPeoplelandownerUpdateLocalOnly}
                        />
                    </Grid>
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        this.comfirmedUpdate(true);
                        this.setState({ repairDialog: false });
                    }}
                    cancelAction={() => {
                        this.comfirmedUpdate(false);
                        this.setState({ repairDialog: false });
                    }}
                    confirmText="Update"
                    cancelText="Do Not Update Landowner"
                    open={repairDialog}
                    title={"Update Landowner Location Information"}
                    text={"Are you sure you wish to update?"}
                />
            </>
        );
    }
}

OverView = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        system: getDrainageSystem(state, ownProps),
        status: getStatus(state, ownProps),
        landOwner: getLandOwner(state, ownProps),
        person: getRequest(state, ownProps)
    }),
    {
        ...Repair.actions,
        ...Peoplelandowner.actions,
        ...mapActions,
        ...authActions,
    }
)(OverView);

export default withStyles(styles)(withRouter(OverView));
