import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";

import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from "@material-ui/core/styles";

import { setDefaultOptions } from 'esri-loader';

import Admin from "./components/home/Admin";
import Bmp from "./components/home/Bmp";
import Condition from "./components/home/Condition";
import HomePage from "./components/home/HomePage";
import MapPage from "./components/home/Map";
import PasswordPage from "./components/auth/PasswordPage";
import PasswordRecoverPage from "./components/auth/PasswordRecoverPage";
import UpdatePage from "./components/auth/UpdatePage";
import Repair from "./components/home/Repair";
import Violation from "./components/home/Violation";
import Portal from "./components/public/portal";
import Portal2 from "./components/public/portal2";
import Privacy from "./Privacy";
import RepairBoard from "./components/list/RepairBoard";
import AllSystems from "./components/list/Drainage";
import ViolationList from "./components/list/Violations";
import ViolationOpen from "./components/list/ViolationsOpen";
import RepairList from "./components/list/Repair";
import ActivitySearch from "./components/tools/ActivitySearch";
import Drainage from './components/home/Drainage';
import Delete from './components/admin/Delete';
import ManageRepairs from './components/admin/ManageRepairs';
import ManageSystems from './components/admin/ManageSystems';
import ManageUsers from './components/admin/ManageUsers';
import ManageVendors from './components/admin/ManageVendors';
import ManageBoardDate from './components/config/ManageBoardDate';
import MapSettings from './components/config/MapSettings';

import ErrorBoundary from "./ErrorBoundary";
import registerServiceWorker from "./registerServiceWorker";
import navReducer from "./components/common/reducers";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
// eslint-disable-next-line
import orm, { reloadAll, reloadListOfModels, syncReducer } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";
import mapReducer from "./components/common/map/reducerMap";
import { appReducer } from "./components/common/Navigation/redux";
import { boardReducer } from "./components/common/Navigation/manage";
import { processBoardDate } from "./components/common/Navigation/manageactions";
import AnnaulInspection from "./components/tools/AnnaulInspection";
import Search from "./components/tools/Search";
import LegalSearch from "./components/tools/LegalSearch";

const theme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: false
        }
    },
    typography: {
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    },
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: 14,
                color: "#212529 !important"
            },
            shrink: {
                transform: "translate(0, 0px) scale(1)"
            }
        },
        MuiListItem: {
            root: {
                fontSize: 16,
                fontWeight: 400,
                color: "#212529"
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
                fontWeight: 400,
                color: "#212529"
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: "#333333 !important",
                fontWeight: 500,
                color: "#ffffff"
            },
            body: {
                "& button": {
                    color: "#990033"
                }
            }
        },
        MuiTypography: {
            body1: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.5,
                color: "#212529"
            },
            gutterBottom: {
                marginBottom: ".6em"
            },
            h6: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.5,
                color: "#212529"
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: 15,
                fontWeight: 400,
                color: "#2b6dad",
                "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    textDecoration: "none"
                },
                borderRadius: 4
            },
            raised: {
                border: "none"
            }
        },
        MuiTouchRipple: {
            childPulsate: {
                animation: "none !important"
            }
        }
    },
    palette: {
        primary: {
            light: "#cf445c",
            main: "#3454ae",
            dark: "#233c7f",
            contrastText: "#fff"
        },
        secondary: {
            light: "#cf445c",
            main: "#990033",
            dark: "#64000d",
            contrastText: "#fff"
        }
    }
});

// configure esri-loader to use version 4.20 from the ArcGIS CDN
// NOTE: make sure this is called once before any calls to loadModules()
setDefaultOptions({ version: '4.20' });

const generateClassName = createGenerateClassName({
    productionPrefix: "prj"
});

const logger = createLogger();
const reducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    orm: createReducer(orm),
    sync: syncReducer,
    map: mapReducer,
    nav: navReducer,
    board: boardReducer
});

const initialState = {
    nav: {
        navigationOpen: true,
        folderOpen: true,
        selectedPage: null,
        sw: { checking: true }
    },
    app: {
        mounted: false
    },
    board: {
        board_updated: false
    },
    map: {
        edit: false,
        draw: false,
        loading: false,
        townships: [],
        systems: []
    }
};

const discard = async (error, _action, _retries) => {
    if(error && error.response && (error.response.detail === "Invalid token." || error.response.detail === "Token has expired.")){
        store.dispatch({
            type: "AUTH_LOGOUT"
        });
    }
    return error.status === 401;
}

const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => (retries > 3 ? null : retries * 1000),
            persistOptions: {
                storage: localForage,
                serialize: false
            },
            discard,
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        
                        //Don't carry over 
                        if(inboundState.app && inboundState.app.mounted){
                            inboundState.app.mounted = false;
                        }

                        if(inboundState.app && inboundState.app.board){
                            inboundState.board.board_updated = false;
                        }

                        if(inboundState.map && inboundState.map){
                            inboundState.map.edit = false;
                        }

                        if(inboundState.map && inboundState.map){
                            inboundState.map.draw = false;
                        }

                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw,
                                panel: 0
                            };
                        } else {
                            inboundState.nav = {
                                ...inboundState.nav,
                                panel: 0
                            }
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy
                        };

                        // When registering a brand new model (ex: FarmSurvey)
                        // the new model is not present in inboundState.orm
                        // but it is in state.orm.
                        // Add the empty orm models through state.orm so the new model
                        // is present but overwrite any existing models through inboundState.orm
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm
                        };

                        inboundState.sync = state.sync;

                        return inboundState;
                    }
                }),
            persistCallback: () => {
                store.dispatch(storeReady());
                store.dispatch(processBoardDate())
                //store.dispatch(reloadAll(true));
                store.dispatch(reloadListOfModels(["JointOptions"]))
            }
        })
    )
);

const getConfirmation = (message, callback) => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <WarningDialogWrapper message={message} callback={callback} />
        </MuiThemeProvider>,
        document.getElementById("pp")
    );
};

ReactDOM.render(
    <ErrorBoundary>
        <ReduxProvider store={store}>
            <JssProvider generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                    <HashRouter getUserConfirmation={getConfirmation}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/password/reset" component={PasswordPage} />
                            <Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
                            <Route path="/updateuser" component={UpdatePage} />
                            <Route path="/map" component={MapPage} />
                            <Route path="/drainage/:id/:system" component={Drainage} />
                            <Route path="/bmp/:bmp/:drainage/:system" component={Bmp} />
                            <Route path="/condition/:condition/:drainage/:system" component={Condition} />
                            <Route path="/repair/:repair/:drainage/:system/" component={Repair} />
                            <Route path="/violation/:violation/:drainage/:system" component={Violation} />

                            <Route path="/config/boarddate" component={ManageBoardDate} />
                            <Route path="/config/mapsettings" component={MapSettings} />

                            <Route exact path="/admin" component={Admin} />
                            <Route path="/admin/delete" component={Delete} />
                            <Route path="/admin/managerepairs" component={ManageRepairs} />
                            <Route path="/admin/managesystems" component={ManageSystems} />
                            <Route path="/admin/manageusers" component={ManageUsers} />
                            <Route path="/admin/managevendors" component={ManageVendors} />

                            <Route path="/list/all/systems" component={AllSystems} />
                            <Route path="/list/repair/:status" component={RepairList} />
                            <Route path="/list/approval" component={RepairBoard} />
                            <Route path="/list/payment" component={RepairBoard} />
                            <Route exact strict path="/list/violation" component={ViolationList} /> 
                            <Route exact strict path="/list/violation/:open" component={ViolationOpen} /> 

                            <Route path="/legalsearch" component={LegalSearch} />
                            <Route path="/activitysearch" component={ActivitySearch} />
                            <Route path="/annual/inspection" component={AnnaulInspection} />
                            <Route path="/search" component={Search} />

                            <Route path="/portal" component={Portal} />
                            <Route path="/portal2" component={Portal2} />
                            <Route path="/privacy_policy" component={Privacy} />

                        </Switch>
                    </HashRouter>
                </MuiThemeProvider>
            </JssProvider>
        </ReduxProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);

registerServiceWorker(store.dispatch);
