import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import FileInput from "../common/FileInput";

import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";

import { withStyles, Chip, Grid, TableBody, Table, TableRow, Button, Snackbar, SnackbarContent } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish';
import ErrorIcon from "@material-ui/icons/Error";

import { createSelector } from '../common/orm';

import * as authActions from "../auth/actions";
import { DrainageSystem } from '../mypage/models';


const getBoardDate = createSelector(schema => {
    return schema.BoardDate.first()
});

const styles = theme => ({
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    mB5: {
        marginBottom: 5,
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    space: {
        marginTop: 88
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
    info: {
        position: "absolute",
        right: 8,
        "& p": {
            padding: 0,
            margin: 0
        }
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    submitWrapper: {
        position: "relative"
    },
    progress: {
        marginLeft: 5,
        color: "#4CAF50",
    }
});

const columnData = [
    { id: "drainage", label: "System" },
    { id: "notes", label: "Notes" },
]

class Template extends Component {
    state = {
        systems: [],
        order: "",
        orderBy: "",
        fail: 0,
        success: 0,
        fetchError: false,
        message: "",
        loading: false,
    }

    update = (values) => {
        const { authState, ormDrainageSystemReloadAdmin } = this.props;

        if (!values.file) {
            this.setState({ fetchError: true, message: "Empty" });
            console.log("Empty");
        } else {
            this.setState({
                systems: [],
                fail: 0,
                success: 0,
                loading: true
            });

            fetch('manage/update/systems', {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(values),
            })
                .then(result => {
                    if (!result.ok) {
                        return result.json().then(res => {
                            throw new Error(res);
                        });
                    } else {
                        return result.json().then(res => {
                            this.setState({
                                systems: res.list,
                                fail: res.fail,
                                success: res.success,
                                loading: false
                            });
                            ormDrainageSystemReloadAdmin();
                        });
                    }
                }).catch(e => {
                    if (e === '{"detail":"Token has expired."}') {
                        this.props.authLogout();
                        this.props.history.push("/");
                    } else {
                        this.setState({
                            fetchError: true,
                            message: "Error",
                            systems: [],
                            loading: false
                        });
                    }
                });
        }
    }

    render() {
        const { classes } = this.props;
        const { loading, fail, fetchError, message, systems, success } = this.state;


        return (
            <div className={classes.space}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.grow}>Download Blank Templates</Typography>
                        <Divider className={classes.mB} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_Setup_Checklist.docx"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Setup CheckList
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_Ditch_Import_Template.xlsx"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Ditch Import List Template
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_HistoricRepairRequests_Template.xlsx"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Historic Repair Requests Template
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_Historicdocuments_Template.xlsx"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Historic Documents Template
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_ScannedDocNamingConvention.pdf"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Historic Document Scanning Guidance
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Button
                            href="/static/DrainageDB_GIS_Import_Template.gdb.zip"
                            target="_blank"
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            GIS Map Layers Template
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.grow}>Manage Template Records For Drainage Systems</Typography>
                        <Divider className={classes.mB} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            target="_blank"
                            href={`/manage/all/systems/excel`}
                            startIcon={<GetAppIcon />}
                            color="primary"
                            variant="contained"
                            fullWidth>
                            Current Drainage System Records
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.info}>
                            <Typography>Uploads must only be edited versions of the downloaded system records.</Typography>
                            <Typography>Other Excel documents not in the correct format will be rejected.</Typography>
                            <Typography>This section will only update existing drainage systems.</Typography>
                            <Typography>To add or delete a system reference the Manage Systems tab.</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Form
                            onSubmit={this.update}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <FileInput
                                        accept=".xls,.xlsx"
                                        canDelete
                                        fullWidth
                                        view={true}
                                        label="Upload File"
                                        id="attachmentUpload"
                                        field="file"
                                    />
                                    <br />
                                    <br />
                                    <div className={classes.submitWrapper}>
                                        <Button type="submit" variant="contained" color="primary" startIcon={<PublishIcon />}>
                                            Update
                                            {loading && <CircularProgress size={24} className={classes.progress} />}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.grow}>Error List</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.mB5}>
                            <Chip
                                label={`${success} Systems updated successfully.`}
                                color="primary"
                            />
                            <Chip
                                label={`${fail} Systems not found or have value errors.`}
                                color="secondary"
                            />
                        </div>
                        <Table>
                            <EnhancedTableHead columnData={columnData} />
                            <TableBody>
                                {systems.map(x => {
                                    return (
                                        <TableRow hover key={x.drainage}>
                                            <CustomTableCell>{x.drainage}</CustomTableCell>
                                            <CustomTableCell>{x.notes}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    className={classes.snackbar}
                    open={fetchError}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ fetchError: false })}>
                    <SnackbarContent
                        className={classes.snackbarContainer}
                        message={
                            <span className={classes.snackbarMessage}>
                                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {message}
                            </span>
                        }
                    />
                </Snackbar>
            </div>
        );
    }
}


Template = connect(
    (state, ownProps) => ({
        authState: state.auth,
        boardDate: getBoardDate(state)
    }),
    {
        ...authActions,
        ...DrainageSystem.actions
    }
)(Template);


export default withStyles(styles)(withRouter(Template));
