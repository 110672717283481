import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import HelpDialog from "./HelpDialog";

const styles = theme => ({
    helpGrid: {
        width: "20px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark
        }
    },
    helpInline: {
        width: "20px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark
        }
    },
    absolute: {
        height: theme.spacing(2)
    },
    inline: {
        display: "inline"
    }
});

class HelpLabel extends Component {
    state = {
        helpOpen: false
    };

    iconClick = e => {
        this.setState({ helpOpen: true });
        //FIXME: How to stop the input from being focused
        e.stopPropagation();
    };

    render() {
        const { helpText, classes, inputLabel, question } = this.props;

        const { helpOpen } = this.state;

        return (
            <Typography className={!inputLabel ? classes.absolute : classes.inline}>
                {inputLabel}
                <Tooltip title="Help">
                    <HelpIcon className={inputLabel ? classes.helpInline : classes.helpGrid} onClick={this.iconClick} style={{color: "#1976d2"}}/>
                </Tooltip>
                <HelpDialog
                    question={inputLabel && inputLabel !== true ? inputLabel : question}
                    text={helpText}
                    open={helpOpen}
                    showHelp
                    onClose={() => this.setState({ helpOpen: false })}
                />
            </Typography>
        );
    }
}

export default withStyles(styles)(HelpLabel);
