import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'; 

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const styles = theme => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    listItemAdmin: {
        flex: "none",
        display: "inline-flex"
    }
});


class CollapsePanel extends Component {
    render(){
        const { 
            classes, 
        } = this.props;

        return (
            <List
                className={classes.list}  
            >
                <ListItem
                    title="Collapse Panel"
                    button
                    onClick={this.props.navigationClick}
                    className={classes.listItemAdmin}>
                    <ListItemIcon>
                        <ChevronLeftIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Collapse Panel"
                    />
                </ListItem>
            </List>
        );
    }
}

export default withStyles(styles)(withRouter(CollapsePanel));