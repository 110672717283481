import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { withStyles, Grid, TableBody, Table, TableRow, Tooltip, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { addToForm, addtoFormFile, getValue } from "../../api/utils";

import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import HelpLabel from "../common/HelpLabel";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";


import { Invoice, InvoiceCost } from "../mypage/models";
import Select from "../common/Select";
import TextField from "../common/TextField";
import FileFormInput from "../common/FileFormInput";
import WarningDialog from "../common/WarningDialog";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import { createSelector } from '../common/orm';
import { panelChange } from "../common/actions";

const getInvoices = createSelector(
    (state, ownProps) => ownProps.repair,
    (state, ownProps) => state.auth,
    (session, repair, auth) => {
        let contractor = getValue(auth, "user.role") === "Contractor";
        if(contractor){
            const id = getValue(auth, "user.vendor_id");
            return session.Invoice.filter(x => x.repair === repair.id && x.vendor === id)
            .orderBy("estimatenumb", "acs")
            .toModelArray();
        } else {
            return session.Invoice.filter(x => x.repair === repair.id)
            .orderBy("estimatenumb", "acs")
            .toModelArray();
        }
    }
);

const getInvoiceCosts = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.InvoiceCost.filter(x => x.repair === repair.id).toRefArray();
    }
);

const getBoardDate = createSelector(schema => {
    return schema.BoardDate.first()
});

const getVendors = createSelector(schema => {
    return schema.Vendor.filter(x => !x.inactive).orderBy('name').toModelArray();
});

const styles = theme => ({
    table: {
        minWidth: 500,
        marginTop: 10
    },
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    flex: {
        grow: 1,
        display: "inline-flex",
    },
    right: {
        float: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    width: {
        width: 250,
        marginBottom: 10,
        backgroundColor: "#82d4f3",
        color: "white",
    },
    content: {
        paddingBottom: "16px !important"
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
});

const invoiceColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "estimatenumb", numeric: true, label: "Order #", allowSort: true},
    {id: "boarddate", numeric: false, label: "Board Action Date", allowSort: true, date: true },
    {id: "datecreated", numeric: false, label: "Date Created", allowSort: true, date: true },
    {id: "percentage", numeric: false, label: "Percentage", allowSort: true },
    {id: "get_contractor", numeric: false, label: "Contractor", allowSort: true },
    {id: "get_num", numeric: false, label: "Vendor", allowSort: true },
    {id: "totalcost", numeric: true, label: "Total", allowSort: true },
    {id: "paymenttype", numeric: false, label: "Payment Types", allowSort: true },
    {id: "status", numeric: false, label: "Status", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
    {id: "attachment", numeric: false, label: "Attachment", allowSort: false },
];

const costColumnData = [
    {id: "actions", numeric: false, label: "" },
    {id: "unittype", numeric: false, label: "Cost Type" },
    {id: "quanity", numeric: false, label: "Quantity" },
    {id: "perunit", numeric: false, label: "Per Units Costs" },
    {id: "unittype", numeric: false, label: "Units" },
    {id: "totalcost", numeric: true, label: "Total" },
    {id: "costcomments", numeric: false, label: "Cost Notes" },
];

const paymentTypes = [
    {label: "Partial Payment" , value: "Partial Payment"},
    {label: "Final Payment" , value: "Final Payment"},
];

const costTypes = [
    {label: "Labor" , value: "Labor"},
    {label: "Material" , value: "Material"},
    {label: "Equipment" , value: "Equipment"},
    {label: "Other" , value: "Other"},
];

class Invoices extends Component {
    state = {
        confirmUpload: false, 
        loading: false,
        invoiceDelete: false, 
        invoiceID: null,
        defaultInvoice: null,
        costDelete: false,
        costObj: null,
        activeId: null,
        activeCost: null,
        invoiceDialog: false,
        costDialog: false,
        selectedInvoice: null,
        snackOpen: false,
        order: "asc",
        orderBy: "",
        calculated: "",
    }

    handleFile = (val, formApi) => {
        const { fileSize } = this.props;

        if(val && (val.size / 1000000 > 250)){
            formApi.setValue("attachment", null);
            formApi.setValue("lock_submit", false);
            this.setState({file: null, confirmUpload: false});
            fileSize();
        } else if(val && (val.size / 1000000 > 100)){
            this.setState({confirmUpload: true, calculated: `${Math.round((val.size / 1000000) / 40)} Minutes`})
        } else {
            formApi.setValue("lock_submit", false);
            this.setState({file: val, confirmUpload: false});
        }
    }

    openInvoice = () => {
        const { authState, vendors } = this.props;
        const contractor = authState && authState.user && authState.user.role === "Contractor";
        if(contractor){
            const v_id = getValue(authState, 'user.vendor_id')
            const vendor = vendors.find(x => x.id === v_id);
            this.setState({
                activeId: null, 
                invoiceDialog: true, 
                rowSelected: null, 
                file: null,
                defaultInvoice: {
                    vendor: v_id,
                    new_vendor: getValue(vendor, "number"),
                    status: "Empty"
                },
            });
        } else {
            this.setState({
                activeId: null, 
                invoiceDialog: true, 
                rowSelected: null, 
                file: null,
                defaultInvoice: null,
                status: "Empty"
            });
        }
    }

    saveInvoice = values => {
        const { 
            vendors,
            boardDate,
            repair,
            ormInvoiceUpdatePromise, 
            ormInvoiceUpdateForm, 
            ormInvoiceCreateForm,
            ormInvoiceCreate,
            saveBad,
            saveGood
        } = this.props;
        const { file } = this.state;
        
        var form_data = new FormData();

        values.percentage = values.percentage !== null && values.percentage === "" ? null : values.percentage;
        
        addtoFormFile(form_data, 'attachment', file);
        addToForm(form_data, 'comments', values.comments);
        addToForm(form_data, 'paymenttype', values.paymenttype);
        addToForm(form_data, 'contractor', values.contractor);
        addToForm(form_data, 'percentage', values.percentage);
        addToForm(form_data, 'vendor_id', values.vendor);
        this.setState({loading: true});


        const vendor = vendors.find(x => x.id === values.vendor);
        if(vendor && file !== null){
            addToForm(form_data, 'vendor_orig', vendor.number);
        } else if(vendor){
            values['vendor_orig'] = vendor.number
            values['contractor'] = vendor.name;
        }

        var formAction = ormInvoiceCreateForm;
        var action = ormInvoiceCreate;
        if(values.id){
            form_data.append('id', values.id);
            formAction = ormInvoiceUpdateForm;
            action = ormInvoiceUpdatePromise;
            form_data.append('totalcost', values.totalcost);
            if(values.attachment){
                delete values.attachment
            }
        } else {
            if(file !== null){
                form_data.append('totalcost', "0.00");
                addToForm(form_data, 'boarddate', boardDate ? boardDate.date : null);
            } else {
                values['totalcost'] = "0.00";
                values['boarddate'] = boardDate ? boardDate.date : null;
            }
        }
        
        if(file !== null){
            form_data.append('drainage_id', repair.drainage);
            form_data.append('repair_id', repair.id);
            formAction(form_data).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({invoiceDialog: false, selectedInvoice: values.id});
                    saveGood()
                }
            });
        } else {
            values['drainage_id'] = repair.drainage;
            values['repair_id'] = repair.id;
            action({
                ...values
            }).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({invoiceDialog: false, selectedInvoice: values.id});
                    saveGood();
                }
            });
        }
    }

    invoiceDelete = id => {
        const { ormInvoiceDeletePromise, saveBad, saveGood } = this.props;
        ormInvoiceDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    saveCost = values => {
        const { costs, ormInvoiceCostUpdatePromise, ormInvoiceUpdatePromise, saveBad, saveGood } = this.props;
        const { activeCost } = this.state;
        var totalcost = parseFloat(values["perunit"]) * parseFloat(values["quanity"]);


        totalcost = isNaN(totalcost) ? "" : totalcost;
        values["totalcost"] = totalcost;
        ormInvoiceCostUpdatePromise({
            ...values,
        }).then(id => {
            if(!id){

            } else {
                this.setState({costDialog: false});

                var total = 0;
                costs.forEach(cost => {
                    if(cost.ordernumb === activeCost.ordernumb && cost.id !== values.id && cost.totalcost){
                        total += parseFloat(cost.totalcost);
                    }
                });
                if(typeof(total) === "undefined"){
                    total = totalcost;
                } else {
                    total += totalcost;
                }

                ormInvoiceUpdatePromise({
                    id: values.ordernumb,
                    totalcost: total
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                });
            }
        });
    }

    costDelete = (id, cost, invoiceID) => {
        const { invoices, ormInvoiceCostDeletePromise, ormInvoiceUpdatePromise, saveBad, saveGood } = this.props;
        ormInvoiceCostDeletePromise(id).then(id => {
            if(!id){
                this.setState({snackOpen: true});
            } else {
                var invoice = invoices.find(x => x.id === invoiceID);

                var total = parseFloat(invoice.totalcost) - parseFloat(cost || "0.00");
                ormInvoiceUpdatePromise({
                    id: invoiceID,
                    totalcost: total
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                });
            }
        });
    }

    addCost = id => {
        const { ormInvoiceCostCreate, repair, saveBad } = this.props;
        
        ormInvoiceCostCreate({
            ordernumb_id: id,
            drainage_id: repair.drainage,
            repair_id: repair.id,
            perunit: 0,
            quanity: 0,
            totalcost: "0.00"
        }).then(id => {
            if(!id){
                saveBad();
            }
        });
    }

    handleChange = (e, api) => {
        const { vendors } = this.props;

        if(e){
            var vendor = vendors.find(x => x.id === e);
            api.setValue("new_vendor", vendor.number);
        }
    }

    totalCost = (api) => { 
        const { activeCost } = this.props;
        var perunit = api.values["perunit"];
        var quanity = api.values["quanity"];
        if(typeof(perunit) === undefined){
            perunit = activeCost.perunit;
        } else {
            perunit = parseFloat(perunit);
        }
        if(typeof(perunit) === undefined){
            quanity = activeCost.quanity;
        }

        var total = parseFloat(perunit) * parseFloat(quanity);
        
        return isNaN(total) ? "" : total.toFixed(2);
    }

    handleRequestSort = (event, property, propName) => {
        const orderBy = property;
        let order = "desc";
        if (this.state[`${propName}OrderBy`] === property && this.state[`${propName}Order`] === "desc") {
            order = "asc";
        }

        const columns = propName === "invoices" ? invoiceColumnData : costColumnData;

        this.props[propName].sort((a,b) => {
            var date = columns.find(cD => cD.id === orderBy).date;
            var numeric = columns.find(cD => cD.id === orderBy).numeric;
            var bool = columns.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined" || isNaN(parseFloat(a[orderBy]))) return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined" || isNaN(parseFloat(b[orderBy]))) return -1;

                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ [`${propName}Order`]: order, [`${propName}OrderBy`]: orderBy });
    };

    displayCost = (value) => {
        if(value){
            var parse = parseFloat(value);
            if(parse >= 0){
                return `$${parse.toLocaleString(undefined, {minimumFractionDigits: 2})}`;
            } else {
                return `($${Math.abs(parse).toLocaleString(undefined, {minimumFractionDigits: 2})})`;
            }
        } else {
            return "$0.00";
        }
    }

    validator = (values) => {
        return {
            vendor: values.vendor === undefined || values.vendor === null ? "Required" : null
        };
    }

    editInvoice = (n) => {
        this.setState({
            file: null, 
            activeId: n.id, 
            activeInvoice: n, 
            invoiceDialog: true,
            defaultInvoice: {
                new_vendor: n.get_num,
                vendor: n.ref.vendor
            },
        });
    }

    render(){
        const { authState, classes, costs, invoices, system, repair, vendors } = this.props;
        const { 
            activeCost, 
            activeId, 
            costObj, 
            costDelete, 
            invoiceDelete, 
            invoiceID, 
            costDialog, 
            invoiceDialog, 
            selectedInvoice, 
            rowSelected,
            confirmUpload,
            loading,
            calculated,
            defaultInvoice
        } = this.state;

        const vendorOptions = vendors.map(x => {
            return {
                label: x.name, 
                value: x.id,
            };
        });

        var totalCost = 0; 
        
        invoices.forEach(x => {
            var temp = parseFloat(x.totalcost);
            if(!(isNaN(temp)))
                totalCost += temp;
        });

        const activeInvoice = invoices.find(x => x.id === activeId);
        const viewOnly = authState.user.role === "View Only";
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        //if it doesn't exist the delete button was clicked
        const invoiceCosts = invoices.find(x => x.id === selectedInvoice) ? costs.filter(x => x.ordernumb === selectedInvoice) : [];

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Typography variant="h5" className={classes.grow}>Repair Invoices</Typography>
                <Divider className={classes.mb}/>
                <Grid container>
                    <Grid container>
                        <Grid item xs>
                            <Card className={classes.width}>
                                <CardContent className={classes.content}>
                                    <Typography style={{color: "white"}}>Total Cost for all Invoices</Typography>
                                        <Typography variant="h5">${totalCost.toLocaleString(undefined, {minimumFractionDigits: 2})}</Typography>
                                </CardContent>
                            </Card>
                            <Button
                                onClick={this.openInvoice}
                                disabled={viewOnly}
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                >
                                <AddCircleOutlineIcon /> 
                                &nbsp;&nbsp;Add
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{overflowX: "auto"}}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={this.state.invoicesOrder} 
                                orderBy={this.state.invoicesOrderBy}
                                propName="invoices"
                                onRequestSort={this.handleRequestSort}
                                columnData={invoiceColumnData}
                            />
                            <TableBody>
                                {invoices.map( (n, index) => { return (
                                    
                                    <TableRow
                                        selected={n.id === rowSelected} 
                                        hover 
                                        key={n.id + "invoice"} 
                                        onClick={() => {this.setState({selectedInvoice: n.id, rowSelected: n.id})}}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            <Tooltip title="Report">
                                                <Button
                                                    color="primary"
                                                    target="_blank" 
                                                    href={`/reports/drainagerepair/${repair.id}/${n.boarddate}/${n.id}/${n.vendor_orig || " "}/${n.percentage}/invoice?company=${n.contractor || " "}`}
                                                    className={classes.deleteWidth}
                                                >
                                                    <AssignmentIcon color="primary"/> 
                                                </Button>
                                            </Tooltip>
                                            {!viewOnly && !contractor && (
                                                <>
                                                    <Tooltip title="Add Cost">
                                                        <Button
                                                            onClick={() => {this.addCost(n.id)}}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <AddCircleOutlineIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={() => this.editInvoice(n)}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({invoiceDelete: true, invoiceID: n.id})}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <DeleteIcon /> 
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                             )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.estimatenumb}</CustomTableCell>
                                        <CustomTableCell>{n.boarddate}</CustomTableCell>
                                        <CustomTableCell>{n.datecreated}</CustomTableCell>
                                        <CustomTableCell>{n.percentage ? parseFloat(n.percentage).toFixed(2) : ""}</CustomTableCell>
                                        <CustomTableCell>{n.get_contractor}</CustomTableCell>
                                        <CustomTableCell>{n.get_num}</CustomTableCell>
                                        <CustomTableCell>{this.displayCost(n.totalcost)}</CustomTableCell>
                                        <CustomTableCell>{n.paymenttype ? n.paymenttype : "-"}</CustomTableCell>
                                        <CustomTableCell>{n.status}</CustomTableCell>
                                        <CustomTableCell>{n.comments ? n.comments : "-"}</CustomTableCell>
                                        <CustomTableCell>
                                            {n.attachment && (
                                                <Tooltip title="View Attachment">
                                                    <a target="_blank" rel="noopener noreferrer" href={n.attachment} className={classes.linkColor}>
                                                        Open File
                                                    </a>
                                                </Tooltip>
                                                )
                                            }
                                            {!n.attachment &&("-")}
                                        </CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {invoices.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={invoiceColumnData.length} className={classes.centerAlign}>
                                            No Invoices
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                {!contractor && (
                    <>
                        <Typography variant="h5" className={classes.grow}>Item Costs</Typography>
                        <Divider className={classes.mb}/>
                        <Grid container style={{marginTop: 20, marginBottom: 10}}>
                            <Grid item xs={12} style={{overflowX: "auto"}}>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        order={this.state.costsOrder} 
                                        orderBy={this.state.costsOrderBy}
                                        propName="costs"
                                        columnData={costColumnData}
                                    />
                                    <TableBody>
                                        {invoiceCosts.map( n => { return (
                                            <TableRow hover key={n.id}>
                                                <CustomTableCell className={classes.actionWidth}>
                                                    {!viewOnly && (
                                                        <>
                                                            <Tooltip title="Edit">
                                                                <Button
                                                                    onClick={() => this.setState({activeCost: n, costDialog: true})}
                                                                    className={classes.deleteWidth}
                                                                >
                                                                    <EditIcon /> 
                                                                </Button>
                                                            </Tooltip>
                                                            {!contractor && (
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        onClick={() => this.setState({
                                                                            costObj: {
                                                                                id: n.id, 
                                                                                cost: n.totalcost, 
                                                                                order: n.ordernumb
                                                                            },
                                                                            costDelete: true
                                                                        })}
                                                                        className={classes.deleteWidth}
                                                                    >
                                                                        <DeleteIcon /> 
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    )}
                                                </CustomTableCell>
                                                <CustomTableCell>{n.costtype}</CustomTableCell>
                                                <CustomTableCell>{n.quanity}</CustomTableCell>
                                                <CustomTableCell>{this.displayCost(n.perunit)}</CustomTableCell>
                                                <CustomTableCell>{n.unittype}</CustomTableCell>
                                                <CustomTableCell>{this.displayCost(n.totalcost)}</CustomTableCell>
                                                <CustomTableCell>{n.costcomments ? n.costcomments : "-"}</CustomTableCell>
                                            </TableRow>
                                        );
                                        })}
                                        {invoiceCosts.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={costColumnData.length} className={classes.centerAlign}>
                                                    No Invoice Costs
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Dialog open={invoiceDialog} maxWidth="md" fullWidth> 
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Invoice</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({invoiceDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.validator}
                            defaultValues={{
                                ...activeInvoice, 
                                ...defaultInvoice,
                                lock_submit: false
                            }}
                            onSubmit={this.saveInvoice}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField 
                                            disabled={true} 
                                            field="contractor" 
                                            label="Old Contractor" 
                                            fullWidth 
                                        />
                                        <TextField 
                                            disabled={true} 
                                            field="vendor_orig" 
                                            label="Old Vendor #" 
                                            fullWidth
                                        />
                                        <Select 
                                            disabled={loading || contractor} 
                                            field="vendor" 
                                            label="Contractor" 
                                            fullWidth 
                                            options={vendorOptions} 
                                            eventHandle={(e) => this.handleChange(e, formApi)}
                                        />
                                        <TextField 
                                            disabled={true} 
                                            field="new_vendor" 
                                            label="Vendor #" 
                                            fullWidth
                                        />
                                        <Select 
                                            disabled={loading} 
                                            field="paymenttype" 
                                            label="Payment Type" 
                                            fullWidth 
                                            options={paymentTypes} 
                                        />
                                        <TextField 
                                            disabled={loading} 
                                            field="percentage" 
                                            label="Percentage Complete" 
                                            fullWidth
                                        />
                                        <Select 
                                            disabled={loading || contractor} 
                                            field="status" 
                                            label="Status" 
                                            fullWidth 
                                            options={[
                                                {
                                                    label: "Empty",
                                                    value: "Empty",
                                                },
                                                {
                                                    label: "Submitted",
                                                    value: "Submitted",
                                                },
                                                {
                                                    label: "Paid",
                                                    value: "Paid",
                                                }
                                            ]} 
                                        />
                                        <TextField 
                                            disabled={loading} 
                                            field="comments" 
                                            label="Invoice Notes" 
                                            fullWidth
                                        />
                                        &nbsp;
                                        <FileFormInput
                                            eventHandle={val => this.handleFile(val, formApi)}
                                            canDelete
                                            fullWidth 
                                            view={true}
                                            disabled={loading}
                                            label="Upload File"
                                            id="attachmentUpload"
                                            field="attachment"
                                        />
                                        <br />
                                        {confirmUpload && (
                                            <Subquestion
                                                component={
                                                    <RadioGroup
                                                        field="lock_submit"
                                                        name="lock_submit"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                title={true}
                                                                showLabel={true}
                                                                helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                            />
                                                        }
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        disabled={loading}
                                                        alignment={true}></RadioGroup>
                                                }
                                            />
                                        )}
                                        <br />
                                        <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                            Save
                                            {loading && <CircularProgress size={24} className={classes.progress} />}
                                        </Button>
                                    </form>
                                )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={costDialog}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Cost Item</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({costDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            id="costedit"
                            defaultValues={activeCost}
                            onSubmit={this.saveCost}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select field="costtype" label="Cost Type" fullWidth options={costTypes}/>
                                    <TextField field="quanity" label="Quantity" fullWidth useDecimalFormat></TextField>
                                    <TextField field="perunit" label="Per Unit Cost" fullWidth useTextFormat></TextField>       
                                    <TextField field="unittype" label="Units" fullWidth></TextField>       
                                    &nbsp;
                                    <b>Total Cost: &nbsp;&nbsp;${this.totalCost(formApi)}</b>
                                    <br />
                                    <br />
                                    <TextField field="costcomments" label="Cost Notes" fullWidth></TextField>       
                                    <br />
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.invoiceDelete(invoiceID);
                        this.setState({ invoiceDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            invoiceDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={invoiceDelete}
                    title={"Delete Invoice"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.costDelete(costObj.id, costObj.cost, costObj.order);
                        this.setState({ costDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            costDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={costDelete}
                    title={"Delete Cost"}
                    text={"Are you sure you wish to delete?"}
                />
            </Grid>
        );
    }
}


Invoices = connect(
    (state, ownProps) => ({
        authState: state.auth,
        boardDate: getBoardDate(state),
        costs: getInvoiceCosts(state, ownProps),
        invoices: getInvoices(state, ownProps),
        vendors: getVendors(state)
    }),
    {
        ...Invoice.actions,
        ...InvoiceCost.actions,
        panelChange
    }
)(Invoices);


export default withStyles(styles)(withRouter(Invoices));
