import React, { Component } from "react";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { KeyboardDatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormField } from "react-form";

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        },
        backgroundColor: "#fff",
        fontSize: "16px",
        fontWeight: 500,
        color: "#666666 !important"
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336"
        }
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8,
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    button: {
        "& button": {
            border: 0
        },
        "& button:hover": {
            backgroundColor: "rgba(60, 138, 199, 0.08)"
        }
    }
});

class DatePickerWrapper extends Component {
    constructor(props){
        super(props);
        var value = props.fieldApi.getValue();
        if(props.autoPopulate === true && !value){
            value = new Date();
            this.handleDateChange(value);
        } else if(value === "") {
            this.handleDateChange(null);
        }
    }
    handleDateChange = (date) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, setError, setTouched } = fieldApi;
        if (date) {
            if (isNaN(date)) {
                setError("Invalid Date");
            } else if (date.getFullYear().toString().length < 4) {
                setError("Invalid Year");
            } else {
                setError(null);
                var formatDate =
                    date.getFullYear() + 
                    "-" + 
                    (date.getMonth() + 1 < 10 ? "0" : "") +
                    (date.getMonth() + 1) +
                    "-" +
                    ((date.getDate() < 10 ? "0" : "") + date.getDate())
                setValue(formatDate);
            }
            setTouched(); // This should be set onblur but onblur isnt called when using the button
            if (eventHandle) eventHandle(date.toLocaleDateString());
        } else if (!date) {
            //occurs when date is deleted from form
            setTouched();
            setValue(null);
            if (eventHandle) eventHandle("Required");
        }
    };
    render() {
        const { helperText, fieldApi, classes, disabled, eventHandle, ...rest } = this.props;
        const { getValue, getError, getWarning, getSuccess, setTouched } = fieldApi;
        var value = getValue(),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;
        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, disabled && classes.disabled),
            error: classes.bootstrapInputError,
        };
        if(value){
            value += "T12:00:00";
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    clearable
                    value={value === "None" || value === null || value === undefined || value === "" ? null : value}
                    disabled={disabled}
                    required={rest.required}
                    onChange={this.handleDateChange}
                    onBlur={() => setTouched()}
                    format="MM/dd/yyyy"
                    autoOk
                    InputProps={InputProps}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            shrink: classes.rootLabelShrink,
                        },
                    }}
                    style={{marginTop: 16}}
                    emptyLabel=""
                    okLabel={null}
                    cancelLabel={null}
                    error={hasError}
                    helperText={errorText}
                    DialogProps={{ className: classes.button }}
                    variant={"contained"}
                    {...rest}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
const DatePickerr = FormField(DatePickerWrapper);
export default withStyles(styles)(DatePickerr);