import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import DeleteIcon from "@material-ui/icons/Delete";
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import RecordTable from "../common/RecordTable";
import { createSelector } from "../common/orm";
import WarningDialog from "../common/WarningDialog";
import { Bmp, Condition, Repair, Violation } from '../mypage/models'

const getViolations = createSelector(session => {
    return session.Violation.all().orderBy('vnum').toModelArray();
});
const getConditions = createSelector(session => {
    return session.Condition.all().orderBy('cnum').toRefArray();
});
const getBMPs = createSelector(session => {
    return session.Bmp.all().orderBy('bmpnum').toRefArray();
});
const getRepairSorted = createSelector(session => {
    return session.Repair.all().orderBy(['system_label', 'repair'], ['asc', 'asc']).toModelArray().map( record => {
        var request = record.requesting_view.first();

        return {
            request: request ? request.name : "",
            systemname: record.drainage.drainage ? record.drainage.drainage : "",
            ...record.ref,
        };
    });
});

const styles = theme => ({
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 25
    },
    space: {
        marginTop: 10
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
});


const repairColumnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "systemname", numeric: true, label: "System Name", allowSort: true },
    { id: "repair", numeric: false, label: "Repair #", allowSort: true },
    { id: "status", numeric: false, label: "Status", allowSort: true },
    { id: "datereceived", numeric: false, label: "Date Received", allowSort: true },
    { id: "township", numeric: false, label: "Township", allowSort: true },
    { id: "section", numeric: false, label: "Section", allowSort: true },
    { id: "request", numeric: false, label: "Person Requesting Repair", allowSort: true }
];

const bmpColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "bmpnum", numeric: false, label: "BMP #", allowSort: true },
    {id: "branch", numeric: false, label: "Branch", allowSort: true },
    {id: "bmpdate", numeric: false, label: "Date Created", allowSort: true },
    {id: "status", numeric: false, label: "Status", allowSort: true },
    {id: "bmp_type", numeric: false, label: "BMP Type", allowSort: true },
    {id: "township", numeric: false, label: "Township", allowSort: true },
    {id: "section", numeric: true, label: "Section", allowSort: true },
    {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true }
];

const conditionColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "cnum", numeric: false, label: "Condition #", allowSort: true },
    {id: "branch", numeric: false, label: "Branch", allowSort: true },
    {id: "conditiondate", numeric: false, label: "Date Created", allowSort: true },
    {id: "monitor_label", numeric: false, label: "Monitor", allowSort: true },
    {id: "township", numeric: false, label: "Township", allowSort: true },
    {id: "section", numeric: true, label: "Section", allowSort: true },
    {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true }
];

const violationColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "vnum", numeric: false, label: "Violation #", allowSort: true },
    {id: "branch", numeric: false, label: "Branch", allowSort: true },
    {id: "violationdate", numeric: false, label: "Source Date", allowSort: true },
    {id: "statuscomply", numeric: false, label: "Status", allowSort: true },
    {id: "violation_type", numeric: false, label: "Violation Type", allowSort: true },
    {id: "township", numeric: false, label: "Township", allowSort: true },
    {id: "section", numeric: true, label: "Section", allowSort: true },
    {id: "landowner", numeric: false, label: "Landowner", allowSort: true },
    {id: "next_compliance_inspection", numeric: false, label: "Next Scheduled Inspection Date", allowSort: true },
];

const agolColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "id", numeric: true, label: "Object ID", allowSort: false },
    {id: "activity_id", numeric: false, label: "Activity ID", allowSort: false },
    {id: "activity_type", numeric: false, label: "Activity Type", allowSort: false },
]


class DeleteItems extends Component {
    constructor(props){
        super(props);

        this.state = {
            agol_data: [],
            deleteDialog: false,
            snack: false,
            name: "",
            path: "",
            bmp: false,
            condition: false,
            violation: false
        }
    }

    componentDidMount = () => {
        const { authState } = this.props;
        fetch('api/db/agols/deleted', {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
            if (!result.ok) {
                return result.json().then(res => {
                    throw new Error(res);
                });
            } else {
                return result.json();
            }
        })
        .then(data => {
            var new_list = [];
            data.list.forEach(x => {
                var activity_id = "";
                var activity_type = "Repair";
                if(x.repair){
                    activity_id = x.repair;
                } else if(x.vnum){
                    activity_id = x.vnum;
                    activity_type = "Violation";
                } else  if(x.cnum){
                    activity_id = x.cnum;
                    activity_type = "Condition";
                } else {    
                    activity_id = x.bmpnum;
                    activity_type = "Bmp";
                }
                new_list.push({
                    id: x.id,
                    activity_id,
                    activity_type
                });
            });
            this.setState({agol_data: new_list});
        })
        .catch(e => {
            console.log("Error");
        });
    }

    deleteRepair = (id) => {
        this.setState({
            id: id,
            name: "Repair",
            path: "",
            bmp: false,
            condition: false,
            violation: false,
            repair: true,
            deleteDialog: true
        });
    }

    deleteBbmp = (id) => {
        this.setState({
            id: id,
            name: "BMP",
            path: "",
            bmp: true,
            condition: false,
            violation: false,
            repair: false,
            deleteDialog: true
        });
    }

    deleteCondition = (id) => {
        this.setState({
            id: id,
            name: "Condition",
            path: "",
            bmp: false,
            condition: true,
            violation: false,
            repair: false,
            deleteDialog: true
        });
    }

    deleteViolation = (id) => {
        this.setState({
            id: id,
            name: "Violation",
            path: "",
            bmp: false,
            condition: false,
            violation: true,
            repair: false,
            deleteDialog: true,
            agolDelete: false,
            active: {},
            title: "",
            text: "",
            confirmText: "",
            cancelText: ""
        });
    }

    confirmedDelete = (id) => {
        const {
            ormBmpDeletePromise,
            ormConditionDeletePromise,
            ormViolationDeletePromise,
            ormRepairDeletePromise,
            saveBad,
            saveGood
        } = this.props;
        const { bmp, condition, violation, repair } = this.state;

        if(bmp)
            ormBmpDeletePromise(id).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        else if(condition)
            ormConditionDeletePromise(id).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        else if(violation)
            ormViolationDeletePromise(id).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        else if(repair)
            ormRepairDeletePromise(id).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
    }

    agolSync = (data) => {
        console.log(data);
    }

    confirmAgol = () => {
        const {
            ormBmpUpdatePromise, 
            ormConditionUpdatePromise, 
            ormRepairUpdatePromise, 
            ormViolationUpdatePromise, 
            ormBmpDeletePromise, 
            ormConditionDeletePromise, 
            ormRepairDeletePromise, 
            ormViolationDeletePromise, 
            saveBad, 
            saveGood
        } = this.props;
        const { active, isDeletion, agol_data } = this.state;
        
        var action;
        if(isDeletion){

            if (active.activity_type === "Repair"){
                action = ormRepairDeletePromise;
            } else if(active.activity_type === "BMP"){
                action = ormBmpDeletePromise;
            } else if(active.activity_type === "Condition"){
                action = ormConditionDeletePromise;
            } else if(active.activity_type === "Violation"){
                action = ormViolationDeletePromise;
            }

            action(active.id).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                    const index = agol_data.findIndex(x => x.id === id);
                    if (index > -1){
                        agol_data.splice(index,1);
                        this.setState({agol_data: agol_data});
                    }
                }
            });

        } else {

            if (active.activity_type === "Repair"){
                action = ormRepairUpdatePromise;
            } else if(active.activity_type === "BMP"){
                action = ormBmpUpdatePromise;
            } else if(active.activity_type === "Condition"){
                action = ormConditionUpdatePromise;
            } else if(active.activity_type === "Violation"){
                action = ormViolationUpdatePromise;
            }

            action({
                ...active,
                agol_objectid: null,
                agol: null,
                agol_globalid: null,
                agol_deleted: false,
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                    const index = agol_data.findIndex(x => x.id === id);
                    if (index > -1){
                        agol_data.splice(index,1);
                        this.setState({agol_data: agol_data});
                    }
                }
            });
        }
    }

    openSync = (data) => {
        this.setState({
            agolDialog: true, 
            active: data, 
            isDeletion: false, 
            title: "Reset AGOL Sync", 
            text: "Are you sure you wish to enable AGOL sync for this activity?",
            confirmText: "Reset Sync",
            cancelText: "Do Not Reset"
        });
    }

    openDelete = (data) => {
        this.setState({
            agolDialog: true, 
            active: data, 
            isDeletion: true, 
            title: "Delete Activity", 
            text: "Are you sure you wish to delete this activity",
            confirmText: "Delete Activity",
            cancelText: "Do Not Delete"
        });
    }


    render() {
        const { classes, bmps, conditions, violations, repairs, type } = this.props;
        const { 
            agol_data,
            agolDialog, 
            deleteDialog, 
            confirmText, 
            cancelText, 
            id, 
            name, 
            title, 
            text 
        } = this.state;

        return (
            <div className={classes.space}>
                {type==='Repair' && (
                    <ExpansionPanel style={{marginTop: "16px"}}>
                        <ExpansionPanelSummary
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Delete Repair</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <RecordTable
                                columnData={repairColumnData}
                                data={repairs}
                                deleteRecord={this.deleteRepair}
                                editable={false}
                                creatable={false}
                                deletable={true}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                {type==='Bmp' && (
                    <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Delete BMP</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <RecordTable
                            columnData={bmpColumnData}
                            data={bmps}
                            deleteRecord={this.deleteBbmp}
                            editable={false}
                            creatable={false}
                            deletable={true}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )}
                {type==='Condition' && (
                    <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary 
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                           <Typography>Delete Condition</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <RecordTable 
                                columnData={conditionColumnData}
                                data={conditions}
                                deleteRecord={this.deleteCondition}
                                editable={false}
                                creatable={false}
                                deletable={true}
                            />
                        </ExpansionPanelDetails>
                </ExpansionPanel>
                )}
                {type==='Violation' && (
                    <ExpansionPanel style={{marginTop: "16px"}}>
                        <ExpansionPanelSummary
                            className={classes.summary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Delete Violation</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <RecordTable
                                columnData={violationColumnData}
                                data={violations}
                                deleteRecord={this.deleteViolation}
                                editable={false}
                                creatable={false}
                                deletable={true}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                {type==='agol' && (
                    <ExpansionPanel style={{marginTop: "16px"}}>
                    <ExpansionPanelSummary 
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <Typography>Delete Activities from AGOL</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <RecordTable 
                                columnData={agolColumnData}
                                data={agol_data}
                                customActions={[
                                    {
                                        title: "Reset Sync",
                                        color: "primary",
                                        event_func: this.openSync,
                                        icon: SyncIcon
                                    },
                                    {
                                        title: "Delete Permanently",
                                        color: "secondary",
                                        event_func: this.openDelete,
                                        icon: DeleteIcon
                                    }
                                ]}
                                editable={false}
                                creatable={false}
                                deletable={false}
                            />
                        </ExpansionPanelDetails>
                </ExpansionPanel>
                )}
                <WarningDialog
                    confirmAction={() => {
                        this.confirmedDelete(id);
                        this.setState({ deleteDialog: false, snack: true });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            deleteDialog: false,
                            bmp: false,
                            condition: false,
                            violation: false
                        })
                    }
                    open={deleteDialog}
                    title={"Delete " + name}
                    text={"Are you sure you wish to permanently delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.confirmAgol();
                        this.setState({ agolDialog: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            agolDialog: false,
                            active: {}
                        })
                    }
                    confirmText={confirmText}
                    cancelText={cancelText}
                    open={agolDialog}
                    title={title}
                    text={text}
                />
            </div>
        );
    }
}

DeleteItems = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        repairs: getRepairSorted(state, ownProps),
        bmps: getBMPs(state),
        conditions: getConditions(state),
        violations: getViolations(state)
    }),
    {
        ...Repair.actions,
        ...Bmp.actions,
        ...Condition.actions,
        ...Repair.actions,
        ...Violation.actions,
    }
)(DeleteItems);

export default withStyles(styles)(withRouter(DeleteItems));
