import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import NumberFormat from "react-number-format";
import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import HelpLabel from "./HelpLabel";
const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        },
        backgroundColor: "#fff",
        fontSize: "16px",
        fontWeight: 500,
        color: "#666666 !important",
        font: "Sans-Serif"
    },
    labelSelected: {
        //border: '1px solid ' + theme.palette.primary.main,
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #f44336"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8
        //color: theme.palette.primary.dark,
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    wordCountSmall: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -10,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
});
function NumberFormatCustom(props) {
    const { inputRef, onChange, scale, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            fixedDecimalScale //adds cents to value
            prefix="$"
            allowNegative={other.allowNegative}
            decimalScale={2}
        />
    );
};
function IntegerFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            allowNegative={false}
            decimalScale={0}
        />
    );
};
function PercentageFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            fixedDecimalScale //adds cents to value
            suffix="%"
            allowNegative={false}
            decimalScale={2}
        />
    );
};
// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {

    DecimalFormatCustom = (props) => {
        const scale = this.props.scale;
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                thousandSeparator
                allowNegative={false}
                decimalScale={scale? scale : 2}
            />
        );
    };

    handleChange = e => {
        let value = e.target.value;
        const { fieldApi, eventHandle, maxWords, units } = this.props;
        const { setValue } = fieldApi;

        

        // ArcGIS online every esriFieldTypeString field scans for HTML tags
        // if it finds a "<" and matching ">" later in string it errors
        // if both occur in string replace spelt out version for now 
        const lt = /^(.*[<].*)/;
        const gt = /^(.*[>].*)/;

        if(value && lt.test(value) &&gt.test(value)){
            value = value.replace(/</g, "less than ");
            value = value.replace(/>/g, "greater than ");
        }
        

        if (maxWords) {
            if(value && value.length > maxWords){
                value = value.substring(0, maxWords);
            }
        }


        // Make sure value isn't blank otherwise result returns: " acres"
        // Which the server turns into "1 acres"
        if (value !== "" && units) {
            value += " " + units;
        }
        setValue(value);
        if (eventHandle) eventHandle(value);
    };
    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            units,
            help,
            classes,
            label,
            disabled,
            unitsVisual,
            useDecimalFormat,
            useTextFormat, //for currency
            userPercentageFormat, //for percentages
            useIntegerFormat,
            children,
            isRequired,
            maxWords,
            autoOff,
            ...rest
        } = this.props;
        const { getValue, getError, getWarning, getSuccess, setTouched } = fieldApi;
        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;
        let InputProps = {},
            renderValue;
        //end of field write out measurement_unit
        if (help || units) {
            if (units) {
                //print measurement unit after field
                InputProps["endAdornment"] = (
                    <InputAdornment position="end" className={classes.whiteSpace}>
                        {(units || unitsVisual) && (
                            <Typography color="textSecondary">
                                {units}
                                {unitsVisual}
                                {help && <HelpLabel question={label} helpText={help} inputLabel={true} />}
                            </Typography>
                        )}
                        {help && !units && !unitsVisual && <HelpLabel question={label} helpText={help} />}
                    </InputAdornment>
                );
            }
        }
        if (units) {
            if (value && value.split) {
                renderValue = value.split(" ")[0];
            } else {
                renderValue = value;
            }
        } else {
            renderValue = value;
        }
        // set default value to field
        if (!value && rest.defaultValue) {
            renderValue = rest.defaultValue;
        }
        
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, value && classes.labelSelected, disabled && classes.disabled),
            error: classes.bootstrapInputError
        };
        //number formater case used for currency values--uses above function
        if (useTextFormat) {
            InputProps["inputComponent"] = NumberFormatCustom;
        }
        if(userPercentageFormat){
            InputProps["inputComponent"] = PercentageFormatCustom;
        }
        if(useIntegerFormat){
            InputProps["inputComponent"] = IntegerFormatCustom;
        }
        if(useDecimalFormat){
            InputProps["inputComponent"] = this.DecimalFormatCustom;
        }
        if(useDecimalFormat){
            InputProps["inputComponent"] = this.DecimalFormatCustom;
        }
        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }
        sProps["classes"] = {
            select: hasError ? classes.bootstrapSelectError : ""
        };


        var count = 0;
        var distance = 100;
        if (maxWords) {
            count = renderValue ? renderValue.length : 0;
            distance = maxWords - count;
        }

        var color = "#be0000";

        var inputProps = {};
        if(autoOff){
            inputProps["autocomplete"] = "new-password";
        }   

        return (
            <>
                <MuiTextField
                    value={renderValue.toString()}
                    error={hasError || rest.isRequired || isRequired}
                    label={label || children}
                    disabled={disabled}
                    helperText={errorText}
                    // defaultValue={rest.defaultValue}
                    onChange={this.handleChange}
                    onBlur={() => setTouched()}
                    SelectProps={sProps}
                    InputProps={InputProps}
                    // eslint-disable-next-line
                    inputProps={inputProps}
                    margin="normal"
                    children={children}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.rootLabel,
                            shrink: classes.rootLabelShrink
                        }
                    }}
                    {...rest}
                />
                {(maxWords && distance <= 30) && (
                    <Typography
                        style={{ color: color }}>
                        Character Count: {count} out of {maxWords}
                    </Typography>
                )}
            </>
        );
    }
}
const TextField = FormField(TextFieldWrapper);
export default withStyles(styles)(TextField);
