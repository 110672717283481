/******************************************************************************
 * Violation Description
 *
 * Author: Mitchell Lane
 * Date: 2/20/20
 *******************************************************************************/
import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {Form} from "react-form";
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToolBar from "@material-ui/core/Toolbar";
import SaveIcon from '@material-ui/icons/Save';

import PrimaryLocation from "../common/drainage/PrimaryLocation";
import DatePicker from "../common/DatePicker";
import TextField from "../common/TextField";
import RadioGroup from "../common/RadioGroup";

import { Violation } from "../mypage/models";
import PageHeader from '../common/PageHeader';
import { panelChange } from "../common/actions";


const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 10
    },
    tb: {
        paddingTop: 30,
        marginBottom: 20
    },
    dt: {
        paddingTop: 22
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    flex: {
        flex: 1
    },
    grow: {
        display: "inline-flex",
        flex: 1
    },
    pad: {
        marginRight: 5,
    },
    pad_2: {
        marginRight: 30,
    },
    date: {
        marginTop: 16,
    }
});

const violation_types = [
    {label: "Easement Encroachment", value: "Easement Encroachment"},
    {label: "Noxious Weeds Present", value: "Noxious Weeds Present"},
    {label: "Rock Pile in Easement", value: "Rock Pile in Easement"},
    {label: "Obstruction", value: "Obstruction"},
    {label: "Structure", value: "Structure"},
    {label: "Other", value: "Other"}
];

class Description extends Component {
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { violation, ormViolationUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormViolationUpdateLocalOnly({
            ...violation,
            ...state.values
        });
    }

    componentDidUpdate(prevProps, prevState) {
        var updateForm = false;
        Object.entries(this.props).forEach(([key, val]) => {
          if(prevProps[key] !== val && key === "violation"){
            updateForm = true;
          }
        });

        if(updateForm && this.formApi){
            const base_keys = ["address", "landowner", "city", "state", "zip", "township", "twp", "range", "commissioner", "section", "quarter", "parcelnum"];
            base_keys.forEach(x => {
                if(x in this.props.violation){
                    this.formApi.setValue(x, this.props.violation[x]);
                }
            });
        }
    }

    save = values => {
        const { violation, ormViolationUpdatePromise, saveBad, saveGood } = this.props;
        ormViolationUpdatePromise({
            ...violation,
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
    }

    getDefaultValues = (violation) => {
        var defaultValues = {};
        defaultValues.source = violation.source;
        defaultValues.branch = violation.branch;
        defaultValues.violationdate = violation.violationdate;
        defaultValues.landowner = violation.landowner;
        defaultValues.address = violation.address;
        defaultValues.city = violation.city;
        defaultValues.state = violation.state;
        defaultValues.zip = violation.zip;
        defaultValues.violation_type = violation.violation_type;
        defaultValues.notes = violation.notes;
        defaultValues.updategeo = violation.updategeo;
        defaultValues.township = violation.township;
        defaultValues.twp = violation.twp;
        defaultValues.range = violation.range;
        defaultValues.commissioner = violation.commissioner;
        defaultValues.section = violation.section;
        defaultValues.quarter = violation.quarter;
        defaultValues.parcelnum = violation.parcelnum;
        return defaultValues;
    }

    render(){
        const { authState, classes, system, violation} = this.props;
        const viewOnly = authState.user.role === "View Only";

        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system} rightTitle={"Violation #: " + violation.vnum} />
                <Form 
                    ref={this.Formref}
                    getApi={el => (this.formApi = el)}
                    id="descriptionform"
                    defaultValues={this.getDefaultValues(violation)}
                    onSubmit={this.save}>
                    {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <ToolBar disableGutters>
                            <Typography variant="h5" className={classes.grow}>Description</Typography>
                            <Button
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                style = {{float: "right", display: viewOnly && "none "}}
                                ><SaveIcon />&nbsp;&nbsp;Save</Button>
                        </ToolBar>
                        <Divider className={classes.mb}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div style={{display: "flex"}}>
                                    <div>
                                        <RadioGroup
                                            row
                                            options={[{label: "Annual Inspection", value: "Annual Inspection"},{label: "Reported", value: "Reported"}]}
                                            field="source"
                                            label="Notification Source"
                                            className={classes.pad_2}
                                            disabled={viewOnly}
                                        />
                                    </div>
                                    <div>
                                        <TextField maxWords={255} field="branch" label="Branch" className={classes.pad} disabled={viewOnly}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePicker field="violationdate" label="Source Date" autoPopulate={true} className={classes.date} disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField maxWords={255} violation={violation} field="landowner" label="Landowner Name" fullWidth disabled={viewOnly}/>
                                <TextField field="address" label="Address" fullWidth disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={12} style={{display: "inline"}}>
                                <TextField violation={violation} field="city" label="City" className={classes.pad} disabled={viewOnly}/>
                                <TextField violation={violation} field="state" label="State" className={classes.pad} disabled={viewOnly}/>
                                <TextField violation={violation} field="zip" label="Zip" disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row
                                    options={violation_types}
                                    field="violation_type"
                                    label="Violation Type"
                                    className={classes.pad_2}
                                    disabled={viewOnly}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField maxWords={2000} field="notes" label="Notes" multiline rows={2} fullWidth disabled={viewOnly}/>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                        <PrimaryLocation record={violation} formApi={formApi} type="Violation"/>
                    </form>
                    )}
                </Form>
            </div>
        );
    }
}

Description = connect(
    (state, ownProps) => ({
        authState: state.auth,
    }),
    {
        ...Violation.actions,
        panelChange
    }
)(Description);

export default withStyles(styles)(withRouter(Description));