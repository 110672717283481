import React from 'react';
import { makeStyles  } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import DatePicker from "../../common/DatePicker";
import TextField from "../../common/TextField";


const styles = makeStyles(theme => ({
    cell: {
        "& td": {
            border: '1px solid rgb(0 0 0 / 55%)'
        },
    },
    important: {
        backgroundColor: "#f2dbdb",
        "& td": {
            border: '1px solid rgb(0 0 0 / 55%)'
        },
    },
    c1: {
        maxWidth: 150
    }
}));


const hasFile = (taskNumber, props) => {
    const {files} = props;
    var file = files.find(x => x.attachid === taskNumber);
    return typeof(file) === "undefined" ? "#000000de" : "#008000"
}

//// Could seprate out into json to mayber render. For now it's more readable.
const LegalRows = (props) => {

    const classes = styles();
    const x = props.obj;
    if(x === "Abandonment of Drainage System" ){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell style={{maxWidth: "100px"}}>Petition for the abandonment</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.811#stat.103E.811.2">
                            MS 103E.811
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">
                           Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0" autoPopulate={false}/>
                    </TableCell>
                    <TableCell><Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AL:_Petition_for_Abandonment">Template AL: Petition for Abandonment</Link></TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>/ 51% of landowners or landowners holding 51% of land</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>File Petition with county Auditory or court administrator</TableCell>
                    <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.811#stat.103E.811.4">MS 103E.811</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Board sets hearing date</TableCell>
                    <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.811#stat.103E.811.5">MS 103E.811</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Approved Board Minutes
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority or Court Administrator</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publication of final hearing notice in newspaper or drainage authority website</TableCell>
                    <TableCell><Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">Drainage Manual</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record of publication with dates
                        <br />
                        <span style={{color: "red"}}>/ Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares finding of fact and order</TableCell>
                    <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.811#stat.103E.811.5">MS 103E.811</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Draft Order
                        <br />
                        <span style={{color: "red"}}>/ Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint viewers (only if written objection is made)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Signed Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Filing of Viewers Report</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Reconvene hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board makes Order Abandoning System or Dismisses Petition</TableCell>
                    <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.811#stat.103E.811.6">MS 103E.811</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Signed Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                
            </>
        );
    }
    if(x === "Consolidation or Division of Drainage Systems" ){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition to Consolidate or Divide</TableCell>
                    <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.801">MS 103E.801</Link></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0" autoPopulate={false} />
                    </TableCell>
                    <TableCell><Link target="_blank" href="/static/templatead.docx">Template AD: Petition for Consolidation Division of Drainage System</Link></TableCell>
                    <TableCell>
                        Petition 
                        <br />
                        <span style={{color: "red"}}>Petitioners / Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Establish Public Hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Notice of Hearing-Publication</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Notice 
                        <br /> 
                        <span style={{color: "red"}}> Auditor </span> 
                        / Secretary
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}> 
                    <TableCell>Notice of Hearing–Mailing</TableCell>
                    <TableCell>Optional</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Request for Board Action Consolidation or Division</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Final Order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5" autoPopulate={false} />
                    </TableCell>
                    <TableCell><Link target="_blank" href="/static/templateae.docx">Template AE: Final Order Consolidating Dividing Drainage SystemsAE</Link></TableCell>
                    <TableCell>
                        Findings and Order
                        < br/>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Impounding, Rerouting, And Diverting Drainage System Waters"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition for the impoundment, rerouting, or diversion of drainage system waters</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.227#stat.103E.227.1">
                            MS 103E.227 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters#1._Petition_Requirements">
                           Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_E:_Petition_to_Impound_Reroute_Divert">
                            Template E: Petition to Impound Reroute Divert
                        </Link>
                    </TableCell>
                    <TableCell>
                        Affidavit documenting valid petition, motion/resolution from board accepting the petition
                        <br />
                        <span style={{color: "red"}}>/ Petitioners</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petition filed by Auditor (or Secretary)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petitioner files bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.227#stat.103E.227.2">
                            MS 103E.227
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters#3._Filing_the_Petition_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint Engineer</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.227#stat.103E.227.3">
                            MS 103E.227
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters#4._Appointment_of_Engineer_and_Notice_of_Public_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_F:_Order_Appointing_Engineer">
                            Template F: Order Appointing Engineer
                        </Link>
                    </TableCell>
                    <TableCell>Motion from Board</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer prepares Engineer's Report</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Engineer's Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Board sets hearing date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_G:_Notice_of_Hearing">
                            Template G: Notice of Hearing
                        </Link>
                    </TableCell>
                    <TableCell>Approved Board Minutes</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publication of final hearing notice in newspaper or drainage authority website</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/8/84/Temp_V_Notice_of_Hearing_%28engineer_report_on_record_correction.docx">
                            Template V: From drainage manual
                        </Link>
                    </TableCell>
                    <TableCell>
                        Record of publication with dates
                        <br />
                        <span style={{color: "red"}}>/ Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Mail final hearing notice to:
                        <br />
                        &nbsp;&nbsp;a) petitioner(s)
                        <br />
                        &nbsp;&nbsp;b) owners of property likely affected
                        <br />
                        &nbsp;&nbsp;c) public entities likely affected
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/8/84/Temp_V_Notice_of_Hearing_%28engineer_report_on_record_correction.docx">
                            Template V: From drainage manual
                        </Link>
                    </TableCell>
                    <TableCell>Affidavit of mailing with date mailed and materials</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares finding of fact and order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/f/fc/Temp_W_Final_Order_Reestablishing_Drainage_System_Records.docx">
                            Template W: From drainage manual
                        </Link>
                    </TableCell>
                    <TableCell>
                        Draft Order
                        <br />
                        <span style={{color: "red"}}>/ Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters#5._Conducting_the_Public_Hearing">
                        Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board makes Order dismissing the petition or modifying the drainage system</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.227#stat.103E.227.5">
                        MS 103E.227
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_H:_Final_Order_on_Petition_to_Impound_Reroute_Divert">
                        Template H: Final Order on Petition to Impound Reroute Divert
                        </Link>
                    </TableCell>
                    <TableCell>
                        Signed order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Obtain required easements</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters#6._Permits_and_Flowage_Easements">
                        Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Easement
                        <br />
                        <span style={{color: "red"}}>/ Petitioner or drainage authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell> </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Improvement of Drainage System"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition and bond filed with County Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.215">
                            MS 103E.215
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Improvement_of_Drainage_System#1._Petition_Requirements">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Petition_for_Improvement_of_Drainage_System">
                            Template B: Petition for Improvement
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>Landowners</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petition and bond sent to County Attorney for review</TableCell>
                    <TableCell></TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Review and approval of petitionReferred back to petitioners for correction Petition &nbsp; bond are valid</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/B._Review_and_Approval_of_Petition_by_the_County_Attorney">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.238">
                            MS 103E.238
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>County Attortney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Present petition to DA at board meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.215">
                            MS 103E.215
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition &amp; bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appointment of engineer to make preliminary survey</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition &amp; bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Appointing_Engineer">
                        Template A: Order Appointing Engineer
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set bond amount</TableCell>
                    <TableCell></TableCell>
                    <TableCell>10 days after appointing engineer</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Engineer's oath and bond filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/C._Appointment_of_the_Engineer#1._Engineer.E2.80.99s_Oath_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B-2:_Engineer's_Oath">
                            Template B: Engineer's Bond
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Engineer's_Bond">
                            Template B-2: Engineer's Oath 
                        </Link>
                    </TableCell>
                    <TableCell>
                        Engineer's Oath
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's Preliminary Survey Report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.015">
                            MS 103E.015
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of Preliminary Survey Report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After preliminary report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Notify DA and obtain order for hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261">
                        Drainage Manual MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Setting_Hearing_on_Preliminary_Survey">
                        Template D: Order Setting Hearing on Preliminary Survey Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set preliminary hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Mail notices of preliminary hearing Notice of preliminary hearing published (not required) Notice of preliminary hearing to DNR (not required)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            103E.255
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare preliminary hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Preliminary Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            MS 103E.255
                        </Link>
                    </TableCell>
                    <TableCell>Before preliminary hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        DNR Preliminary Advisory Report
                        <br />
                        <span style={{color: "red"}}>DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Preliminary hearing to review petition</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#2._Preliminary_Hearing_Procedures">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Template D: Order Setting Hearing on Preliminary Survey Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DA, Drainage Manager, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Dismiss petition</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=G._Reasons_for_Dismissal">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_E:_Order_Dismissing_Petition">
                            Template E: Order Dismissing Petition
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DA, Drainage Manager, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Adoption of findings and order</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/H._Adoption_of_Findings_and_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_F:_Preliminary_Hearing_Order">
                            Template F: Findings and Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DA, Drainage Manager, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint viewers</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Part of Adoption of findings and order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's detailed survey report (final report) filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Engineer%27s_Detailed_Survey_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[18].completedate"} id="date-18" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Engineer's Report
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,18)} style={{color: hasFile(18, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[18].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of engineer's final report to DNR</TableCell>
                    <TableCell></TableCell>
                    <TableCell>After final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[19].completedate"} id="date-19" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,19)} style={{color: hasFile(19, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[19].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Order designating first meeting of viewers First viewer meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Within 5 days after final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[20].completedate"} id="date-20" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Auditor's_Order">
                            Template A: Auditor's Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,20)} style={{color: hasFile(20, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[20].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Viewers report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Filing_Viewers%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[21].completedate"} id="date-21" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Viewers Report
                        <br />
                        <span style={{color: "red"}}>Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,21)} style={{color: hasFile(21, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[21].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare owner's report from viewer's report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Property_Owners%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after viewers report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[22].completedate"} id="date-22" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Property_Owners'_Report">
                            Template B: Property Owner's Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,22)} style={{color: hasFile(22, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[22].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Final Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Commissioner%27s_Final_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receipt of engineer's final report	</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[23].completedate"} id="date-23" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        DNR Final Advisory Report
                        <br />
                        <span style={{color: "red"}}>DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,23)} style={{color: hasFile(23, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[23].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule final hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>
                        After receipt of engineer's final report, viewer's report, and DNR report. Not less than 25 days nor more than 50 days after the date of
                        the final hearing notice.
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[24].completedate"} id="date-24" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Setting_Hearing_on_Detailed_Survey">
                            Template A: Order Setting Time, Place, and Location for Final Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,24)} style={{color: hasFile(24, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[24].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Post final hearing notice at front door of courthouse in each county</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>
                        Post at least 3 weeks prior to date of the final hearing
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[25].completedate"} id="date-25" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,25)} style={{color: hasFile(25, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[25].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish final hearing notice in a legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Publish 1/wk for 3 consecutive weeks At least 3 weeks before the final hearing date
                        Mail notices within 1 week after notice is first published
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[26].completedate"} id="date-26" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,26)} style={{color: hasFile(26, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[26].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Mail copy of final hearing notice and property owner's report</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Mail notices within 1 week after notice is first published
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[27].completedate"} id="date-27" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Affidavit_of_Mailing">
                            Template C: Affidavit of Mailing (not required)
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,27)} style={{color: hasFile(27, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[27].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare final hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before final hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[28].completedate"} id="date-28" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,28)} style={{color: hasFile(28, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[28].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Final hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>25-50 days after publication of final hearing notice</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[29].completedate"} id="date-29" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, Drainage Authority, Drainage Manager, Engineer, 1 Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,29)} style={{color: hasFile(29, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[29].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Make changes to final report or viewer's report Dismiss proceedings</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[30].completedate"} id="date-30" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, Drainage Authority, Drainage Manager, Engineer, 1 Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,30)} style={{color: hasFile(30, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[30].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Adoption of final order establishing proposed project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[31].completedate"} id="date-31" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Order_Dismissing_Proceedings">
                            Template C: Findings and Order Dismissing Proceedings and Petition
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>Auditor, Drainage Authority, Drainage Manager, Engineer, 1 Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,31)} style={{color: hasFile(31, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[31].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule bid opening and bid award (letting)</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Allow 30 days for appeal period to expire</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[32].completedate"} id="date-32" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,32)} style={{color: hasFile(32, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[32].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare specs and advertise for construction bids</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[33].completedate"} id="date-33" autoPopulate={false} />
                    </TableCell><TableCell>
                    </TableCell>
                    <TableCell>
                        Construction Plans and Specs
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,33)} style={{color: hasFile(33, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[33].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish advertisement for bids in legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 2 consecutive weeks (not same week as bid opening)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[34].completedate"} id="date-34" autoPopulate={false} />
                    </TableCell><TableCell>
                    </TableCell>
                    <TableCell>
                        Advertisement for Bids
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,34)} style={{color: hasFile(34, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[34].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid opening</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[35].completedate"} id="date-35" autoPopulate={false} />
                    </TableCell><TableCell>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,35)} style={{color: hasFile(35, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[35].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Consider bid award</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Next board meeting following opening</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[36].completedate"} id="date-36" autoPopulate={false} />
                    </TableCell><TableCell>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,36)} style={{color: hasFile(36, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[36].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Award contract to low bidder Forward letters to unsuccessful bidders</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[37].completedate"} id="date-37" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Notice_of_Award">
                            Template A: Notice of Award
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Award
                        <br />
                        <span style={{color: "red"}}>Drainage Authority Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,37)} style={{color: hasFile(37, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[37].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare contract and bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._The_Construction_Contract_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[38].completedate"} id="date-38" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,38)} style={{color: hasFile(38, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[38].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Project construction and oversight</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[39].completedate"} id="date-39" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Contractor, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,39)} style={{color: hasFile(39, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[39].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Report to DA when contract complete</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[40].completedate"} id="date-40" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,40)} style={{color: hasFile(40, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[40].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule hearing for final completion of project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Following engineer's completion report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[41].completedate"} id="date-41" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_G:_Notice_of_Hearing_on_Final_Acceptance">
                            Template G: Notice of Hearing on Final Acceptance of Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Hearing
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,41)} style={{color: hasFile(41, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[41].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Provide notice of hearing to affected landowners by publication or mail</TableCell>
                    <TableCell></TableCell>
                    <TableCell>At least 10 days before completion hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[42].completedate"} id="date-42" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,42)} style={{color: hasFile(42, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[42].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold hearing for final acceptance of project Adjourn hearing to another date Final acceptance order and payment of balance due</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[43].completedate"} id="date-43" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_H:_Findings_and_Order_Accepting_Contract">
                            Template H: Final Acceptance Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        Final Acceptance
                        <br />
                        <span style={{color: "red"}}>Auditor, DA, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,43)} style={{color: hasFile(43, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[43].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's as constructed survey filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[44].completedate"} id="date-44" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record Drawings (as-built plan)
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,44)} style={{color: hasFile(44, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[44].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of revised engineer's report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[45].completedate"} id="date-45" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,45)} style={{color: hasFile(45, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[45].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[46].completedate"} id="date-46" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,46)} style={{color: hasFile(46, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[46].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Improvement of Outlets"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition and bond filed with County Auditor or WD Secretary</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.221#stat.103E.221.2">
                            MS 103E.221
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/D._Improvement_of_Outlets#1._Petition_Requirements">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Petition_for_Improvement_of_Outlet">
                            Template C: Petition for Improvement of Outlet
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition and Bond
                        <span style={{color: "red"}}>/ Landowners</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petition and bond sent to County Attorney for review</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.221#stat.103E.221.3">
                            MS 103E.221
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Review of petition and bond Refer back to petitioners for correction or approve for presentation to DA</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.221#stat.103E.221.2">
                            MS 103E.221
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/D._Improvement_of_Outlets#2._Petition_Signatures">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Present petition to DA at board meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.221#stat.103E.221.3">
                            MS 103E.221
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary	</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appointment of engineer to make preliminary survey</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Appointing_Engineer">
                            Template A: Order Appointing Engineer
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order Appointing Engineer /
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set bond amount</TableCell>
                    <TableCell></TableCell>
                    <TableCell>10 days after appointing engineer</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Engineers oath and bond filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Engineer's_Bond">
                            Template B: Engineer's Bond 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B-2:_Engineer's_Oath">
                            Template B-2: Engineer's Bond
                        </Link>
                    </TableCell>
                    <TableCell>
                        Engineer's Oath and Bond
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineers Preliminary Survey Report filed</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary Engineer's Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of Preliminary Survey Report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            103E.255
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After preliminary report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Notify DA and obtain order for hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                        Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Setting_Hearing_on_Preliminary_Survey">
                            Template D: Order Setting Hearing on Preliminary Survey Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set preliminary hearing date</TableCell>
                    <TableCell></TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                    Mail notices of preliminary hearing 
                    <br />
                    Notice of preliminary hearing published (not required)
                    <br />
                    Notice of preliminary hearing to DNR (not required)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261">
                            103E.261
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#1._Notice">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>/ Auditor or Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare preliminary hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Preliminary Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            MS 103E.255
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Before preliminary hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary Advisory Report
                        <br />
                        <span style={{color: "red"}}>/ DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Preliminary hearing to review petition <br />Dismiss petition Adoption of findings and order</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.2">
                            MS 103E.255 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.4">
                            MS 103E.255 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.5">
                            MS 103E.255 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#2._Preliminary_Hearing_Procedures">
                            Drainage Manual 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=G._Reasons_for_Dismissal">
                            Drainage Manual 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/H._Adoption_of_Findings_and_Order">
                            Drainage Manual 
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Template F: Preliminary Hearing Order
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_E:_Order_Dismissing_Petition">
                            Template E: Order Dismissing Petition
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint viewers</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Part of Adoption of findings and order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's detailed survey report (final report) filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Engineer%27s_Detailed_Survey_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Engineer's Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of engineer's final report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Engineer%27s_Detailed_Survey_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Order designating first meeting of viewers First viewer meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Within 5 days after final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[18].completedate"} id="date-18" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Auditor's_Order">
                            Template A: Auditor's Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary,Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,18)} style={{color: hasFile(18, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[18].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Viewers report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.321#stat.103E.321.4">
                            103E.321
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Filing_Viewers%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[19].completedate"} id="date-19" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Viewers Report
                        <br />
                        <span style={{color: "red"}}>/ Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,19)} style={{color: hasFile(19, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[19].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare owner's report from viewer's report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.323">
                            MS 103E.323
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Property_Owners%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after viewers report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[20].completedate"} id="date-20" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Property_Owners'_Report">
                        Template B: Property Owner's Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,20)} style={{color: hasFile(20, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[20].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Final Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Commissioner%27s_Final_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receipt of engineer's final report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[21].completedate"} id="date-21" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary Advisory Report
                        <br />
                        <span style={{color: "red"}}>/ DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,21)} style={{color: hasFile(21, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[21].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule final hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.325">
                            103E.325 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After receipt of engineer's final report, viewer's report, and DNR report.</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[22].completedate"} id="date-22" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Setting_Hearing_on_Detailed_Survey">
                            Template A: Order Setting Time, Place, and Location for Final Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,22)} style={{color: hasFile(22, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[22].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish final hearing notice in a legal newspaper Provide copy of final hearing notice for posting Mail copy of final hearing notice and property owner's report</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 3 consecutive weeks At least 3 weeks before the final hearing date Mail notices within 1 week after notice is first published</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[23].completedate"} id="date-23" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Affidavit_of_Mailing">
                            Template C: Affidavit of Mailing (not required)
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,23)} style={{color: hasFile(23, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[23].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare final hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before final hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[24].completedate"} id="date-24" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,24)} style={{color: hasFile(24, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[24].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Final hearing Make changes to final report or viewer's report Dismiss proceedings Adoption of final order establishing proposed project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing#2._Final_Hearing_Procedures">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Making_Changes:_Engineer%27s_Detailed_Survey_Report_and_Viewer%27s_Report">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>25-50 days after publication of final hearing notice</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[25].completedate"} id="date-25" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Order_Dismissing_Proceedings">
                            Template C: Findings and Order Dismissing Proceedings and Petition
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Establishing_Drainage_Project">
                            Template D: Order Establishing Drainage Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,25)} style={{color: hasFile(25, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[25].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule bid opening and bid award (letting)</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Allow 30 days for appeal period to expire</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[26].completedate"} id="date-26" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,26)} style={{color: hasFile(26, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[26].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare specs and advertise for construction bids</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[27].completedate"} id="date-27" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Bid Package
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,27)} style={{color: hasFile(27, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[27].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish advertisement for bids in legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 2 consecutive weeks (not same week as bid opening)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[28].completedate"} id="date-28" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,28)} style={{color: hasFile(28, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[28].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid opening</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[29].completedate"} id="date-29" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,29)} style={{color: hasFile(29, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[29].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid award</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract#1._Notice_of_Contract_Awarding">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>	Next board meeting following opening</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[30].completedate"} id="date-30" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,30)} style={{color: hasFile(30, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[30].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Award contract to low bidder Forward letters to unsuccessful bidders</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[31].completedate"} id="date-31" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Notice_of_Award">
                            Template A: Notice of Award
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Award
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,31)} style={{color: hasFile(31, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[31].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare contract and bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.501">
                            MS103E.501
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._The_Construction_Contract_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[32].completedate"} id="date-32" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,32)} style={{color: hasFile(32, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[32].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Project construction and oversight</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[33].completedate"} id="date-33" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Contractor, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,33)} style={{color: hasFile(33, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[33].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Report to DA when contract complete</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[34].completedate"} id="date-34" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,34)} style={{color: hasFile(34, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[34].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule hearing for final completion of project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Following engineer's completion report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[35].completedate"} id="date-35" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_G:_Notice_of_Hearing_on_Final_Acceptance">
                            Template G: Notice of Hearing on Final Acceptance of Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,35)} style={{color: hasFile(35, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[35].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Provide notice of hearing to affected LO by publication or mail</TableCell>
                    <TableCell></TableCell>
                    <TableCell>	At least 10 days before completion hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[36].completedate"} id="date-36" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,36)} style={{color: hasFile(36, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[36].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold hearing for final acceptance of project Adjourn hearing to another date Final acceptance order and payment of balance due</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://www.revisor.mn.gov/statutes/cite/103E.555">
                            MS 103E.555
                        </Link>
                        <br />
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[37].completedate"} id="date-37" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php/Template_H:_Findings_and_Order_Accepting_Contract">
                            Template H: Final Acceptance Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,37)} style={{color: hasFile(37, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[37].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's as constructed survey filed with Auditor</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://www.revisor.mn.gov/statutes/cite/103E.295">
                            MS 103E.295
                        </Link>
                        <br />
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[38].completedate"} id="date-38" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record Drawings
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,38)} style={{color: hasFile(38, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[38].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of revised engineer's report to DNR</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[39].completedate"} id="date-39" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,39)} style={{color: hasFile(39, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[39].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[40].completedate"} id="date-40" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,40)} style={{color: hasFile(40, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[40].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Lateral"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition and bond filed with County Auditor or WD Secretary</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/E._Laterals#E._Laterals">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.225#stat.103E.225.1">
                            MS 103E.225
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Petition_for_Lateral_to_Drainage_System">
                            Template D: Petition for Lateral to Drainage System
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>/ Landowners</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petition and bond sent to County Attorney for review</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/E._Laterals#3._Filing_the_Petition_for_a_Lateral">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petitioner files bond Review and approval of petition Referred back to petitioners for correction <br />Petition &amp; bond are valid</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/B._Review_and_Approval_of_Petition_by_the_County_Attorney">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.238">
                            MS 103E.238
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>County Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Present petition to DA at board meeting</TableCell>
                    <TableCell></TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appointment of engineer to make preliminary survey</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.241#stat.103E.241.1">
                            MS 103E.241
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Appointing_Engineer">
                            Template A: Order Appointing Engineer
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set bond amount</TableCell>
                    <TableCell></TableCell>
                    <TableCell>10 days after appointing engineer</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Drainage Authority
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Engineers oath and bond filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.241#stat.103E.241.1">
                            MS 103E.241
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Engineer's_Bond">
                            Template B: Engineer's Bond 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B-2:_Engineer's_Oath">
                            Template B-2: Engineer's Bond
                        </Link>
                    </TableCell>
                    <TableCell>
                        Oath and Bond
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineers Preliminary Survey Report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.251">
                            MS 103E.251
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary Survey Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of Preliminary Survey Report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After preliminary report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Notify DA and obtain order for hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.1">
                            MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Setting_Hearing_on_Preliminary_Survey">
                            Template D: Order Setting Hearing on Preliminary Survey Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set preliminary hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.2">
                            MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                    Mail notices of preliminary hearing 
                    <br />
                    Notice of preliminary hearing published (not required)
                    <br />
                    Notice of preliminary hearing to DNR (not required)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#1._Notice">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>/ Auditor or Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare preliminary hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Preliminary Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            MS 103E.255
                        </Link>
                    </TableCell>
                    <TableCell>Before preliminary hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Advisory Report
                        <br />
                        <span style={{color: "red"}}>/ DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Preliminary hearing to review petition Dismiss petition Adoption of findings and order</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#2._Preliminary_Hearing_Procedures">
                            Drainage Manual 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=G._Reasons_for_Dismissal">
                            Drainage Manual 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/H._Adoption_of_Findings_and_Order">
                            Drainage Manual 
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_F:_Preliminary_Hearing_Order">
                            Template F: Preliminary Hearing Order
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_E:_Order_Dismissing_Petition">
                            Template E: Order Dismissing Petition
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DA, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint viewers</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Part of Adoption of findings and order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Order appointing viewers
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's detailed survey report (final report) filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.271">
                            MS 103E.271
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Engineer%27s_Detailed_Survey_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Detailed Survey Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of engineer's final report to DNR</TableCell>
                    <TableCell></TableCell>
                    <TableCell>After final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Order designating first meeting of viewers First viewer meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Within 5 days after final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[18].completedate"} id="date-18-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Auditor's_Order">
                            Template A: Auditor's Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ DA, Auditor, Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,18)} style={{color: hasFile(18, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[18].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Viewers report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Filing_Viewers%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[19].completedate"} id="date-19-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Viewers Report
                        <br />
                        <span style={{color: "red"}}>/ Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,19)} style={{color: hasFile(19, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[19].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Prepare owner's report from viewer's report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.323">
                            MS 103E.323
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Property_Owners%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after viewers report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[20].completedate"} id="date-20-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Property_Owners%E2%80%99_Report">
                            Template D: Property Owner's Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        Owners Report
                        <br />
                        <span style={{color: "red"}}>/ Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,20)} style={{color: hasFile(20, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[20].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Final Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Commissioner%27s_Final_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receipt of engineer's final report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[21].completedate"} id="date-21-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Advisory Report
                        <br />
                        <span style={{color: "red"}}>/ DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,21)} style={{color: hasFile(21, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[21].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule final hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After receipt of engineer's final report, viewer's report, and DNR report.</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[22].completedate"} id="date-22-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Setting_Hearing_on_Detailed_Survey">
                            Template A: Order Setting Time, Place, and Location for Final Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ DA, Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,22)} style={{color: hasFile(22, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[22].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish final hearing notice in a legal newspaper Provide copy of final hearing notice for posting Mail copy of final hearing notice and property owner's report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.325">
                            MS 103E.325
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing#1._Notice">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Publish 1/wk for 3 consecutive weeks At least 3 weeks before the final hearing date Mail notices within 1 week after notice is first published</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[23].completedate"} id="date-23-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Affidavit_of_Mailing">
                            Template C: Affidavit of Mailing (not required)
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>/ Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,23)} style={{color: hasFile(23, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[23].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare final hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before final hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[24].completedate"} id="date-24-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Auditor</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,24)} style={{color: hasFile(24, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[24].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Final hearing Make changes to final report or viewer's report Dismiss proceedings Adoption of final order establishing proposed project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Making_Changes:_Engineer%27s_Detailed_Survey_Report_and_Viewer%27s_Report">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.335">
                            MS 103E.335
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order#2._Establishment_of_Proposed_Drainage_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>25-50 days after publication of final hearing notice</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[25].completedate"} id="date-25-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Order_Dismissing_Proceedings">
                            Template C: Findings and Order Dismissing Proceedings and Petition
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Establishing_Drainage_Project">
                            Template D: Order Establishing Drainage Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ Auditor, Drainage Authority, Drainage Manager, Engineer, 1 Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,25)} style={{color: hasFile(25, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[25].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule bid opening and bid award (letting)</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Allow 30 days for appeal period to expire</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[26].completedate"} id="date-26-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>DA, Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,26)} style={{color: hasFile(26, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[26].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare specs and advertise for construction bids</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[27].completedate"} id="date-27-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Bid Package
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,27)} style={{color: hasFile(27, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[27].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish advertisement for bids in legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 2 consecutive weeks (not same week as bid opening)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[28].completedate"} id="date-28-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Advertisement for Bids
                        <br />
                        <span style={{color: "red"}}>/ Engineer, Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,28)} style={{color: hasFile(28, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[28].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid opening</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[29].completedate"} id="date-29-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,29)} style={{color: hasFile(29, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[29].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid award</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.505">
                            MS 103E.505
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Next board meeting following opening</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[30].completedate"} id="date-30-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Recommendation for Award
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,30)} style={{color: hasFile(30, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[30].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Award contract to low bidder Forward letters to unsuccessful bidders</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[31].completedate"} id="date-31-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Notice_of_Award">
                            Template A: Notice of Award
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Award
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,31)} style={{color: hasFile(31, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[31].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare contract and bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.501">
                            MS103E.501
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._The_Construction_Contract_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[32].completedate"} id="date-32-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,32)} style={{color: hasFile(32, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[32].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Project construction and oversight</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[33].completedate"} id="date-33-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Contractor, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,33)} style={{color: hasFile(33, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[33].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Report to DA when contract complete</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[34].completedate"} id="date-34-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,34)} style={{color: hasFile(34, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[34].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule hearing for final completion of project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.555">
                            MS 103E.555
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Following engineer's completion report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[35].completedate"} id="date-35-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_G:_Notice_of_Hearing_on_Final_Acceptance">
                            Template G: Notice of Hearing on Final Acceptance of Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,35)} style={{color: hasFile(35, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[35].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Provide notice of hearing to affected landowners by publication or mail</TableCell>
                    <TableCell></TableCell>
                    <TableCell>At least 10 days before completion hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[36].completedate"} id="date-36-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,36)} style={{color: hasFile(36, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[36].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold hearing for final acceptance of project Adjourn hearing to another date Final acceptance order and payment of balance due</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://www.revisor.mn.gov/statutes/cite/103E.555#stat.103E.555.2">
                            MS 103E.555
                        </Link>
                        <br />
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[37].completedate"} id="date-37-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php/Template_H:_Findings_and_Order_Accepting_Contract">
                            Template H: Final Acceptance Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ Auditor, DA, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,37)} style={{color: hasFile(37, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[37].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's as constructed survey filed with Auditor</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://www.revisor.mn.gov/statutes/cite/103E.295">
                            MS 103E.295
                        </Link>
                        <br />
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[38].completedate"} id="date-38-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record Drawings
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,38)} style={{color: hasFile(38, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[38].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of revised engineer's report to DNR</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[39].completedate"} id="date-39-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,39)} style={{color: hasFile(39, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[39].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[40].completedate"} id="date-40-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,40)} style={{color: hasFile(40, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[40].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "New Drainage System"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition and bond filed with County Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/B._New_Drainage_System_Projects">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.212">
                            MS 103E.212
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.202">
                            MS 103E.202
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Petition_for_Lateral_to_Drainage_System">
                            Template A: Petition for New Drainage System
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition /
                        <span style={{color: "red"}}>51% of landowners or landowners holding 60% of land</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Petition and bond sent to County Attorney for review</TableCell>
                    <TableCell></TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Review and approval of petition Referred back to petitioners for correction <br />Petition and bond are valid</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/B._Review_and_Approval_of_Petition_by_the_County_Attorney">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.238">
                            MS 103E.238
                        </Link>
                    </TableCell>
                    <TableCell>30 days after filing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>County Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Present petition to DA at board meeting</TableCell>
                    <TableCell></TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appointment of engineer to make preliminary survey</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Appointment_of_the_Engineer">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.241">
                            MS 103E.241
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receiving petition and bond from Attorney</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Appointing_Engineer">
                            Template A: Order Appointing Engineer
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order / <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set bond amount</TableCell>
                    <TableCell></TableCell>
                    <TableCell>10 days after appointing engineer</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Engineers oath and bond filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/C._Appointment_of_the_Engineer#1._Engineer.E2.80.99s_Oath_and_Bond">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.241">
                            MS 103E.241
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Engineer's_Bond">
                            Template B: Engineer's Oath and Bond
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineers Preliminary Survey Report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.251">
                            MS 103E.251
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=Minnesota_Public_Drainage_Manual_-_Engineering_and_Environmental_Considerations_-_Preliminary_survey_and_engineer%27s_preliminary_report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary Engineer&apos;s Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of Preliminary Survey Report to DNR</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After preliminary report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Notify DA and obtain order for hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261">
                            MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Setting_Hearing_on_Preliminary_Survey">
                            Template D: Order Setting Hearing on Preliminary Survey Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set preliminary hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261#stat.103E.261.2">
                            MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                    Mail notices of preliminary hearing 
                    <br />
                    Notice of preliminary hearing published (not required)
                    <br />
                    Notice of preliminary hearing to DNR (not required)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare preliminary hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Preliminary Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.255">
                            MS 103E.255
                        </Link>
                    </TableCell>
                    <TableCell>Before preliminary hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        DNR Preliminary Advisory Report
                        <br />
                        <span style={{color: "red"}}>DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Preliminary hearing to review petition</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261">
                            Drainage Manual 
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=G._Reasons_for_Dismissal">
                            MS 103E.261
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order setting preliminary hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Adoption of findings and order to dismiss petition OR appoint Viewers</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/H._Adoption_of_Findings_and_Order">
                            Drainage Manual 
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_F:_Preliminary_Hearing_Order">
                            Template E: Findings and Order Dismissing Petition
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_F:_Preliminary_Hearing_Order">
                            Template F: Preliminary Hearing Order 
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint viewers</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Part of Adoption of findings and order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's detailed survey report (final report) filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Engineer%27s_Detailed_Survey_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Engineer's Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of engineer's final report to DNR</TableCell>
                    <TableCell></TableCell>
                    <TableCell>After final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[18].completedate"} id="date-18-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,18)} style={{color: hasFile(18, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[18].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Order designating first meeting of viewers First viewer meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=A._Procedures_for_Appointing_Viewers">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Within 5 days after final report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[19].completedate"} id="date-19-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Auditor's_Order">
                            Template A: Auditor's Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,19)} style={{color: hasFile(19, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[19].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Viewers report filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Filing_Viewers%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[20].completedate"} id="date-20-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Viewers Report
                        <br />
                        <span style={{color: "red"}}>Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,20)} style={{color: hasFile(20, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[20].ns"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare owner's report from viewer's report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Property_Owners%E2%80%99_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after viewers report filed</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[21].completedate"} id="date-21-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Property_Owners'_Report">
                            Template B: Property Owner's Report
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,21)} style={{color: hasFile(21, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[21].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>DNR Final Advisory Report filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._Commissioner%27s_Final_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>30 days after receipt of engineer's final report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[22].completedate"} id="date-22-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        DNR Final Advisory Report
                        <br />
                        <span style={{color: "red"}}>DNR Commissioner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,22)} style={{color: hasFile(22, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[22].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule final hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._The_Final_Hearing">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>After receipt of engineer's final report, viewer's report, and DNR report.</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[23].completedate"} id="date-23-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Order_Setting_Hearing_on_Detailed_Survey">
                            Template A: Order Setting Time, Place, and Location for Final Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,23)} style={{color: hasFile(23, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[23].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish final hearing notice in a legal newspaper Provide copy of final hearing notice for posting Mail copy of final hearing notice and property owner's report</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 3 consecutive weeks At least 3 weeks before the final hearing date Mail notices within 1 week after notice is first published</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[24].completedate"} id="date-24-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Affidavit_of_Mailing">
                            Template C: Affidavit of Mailing (not required)
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_B:_Notice_of_Final_Hearing">
                            Template B: Notice of Final Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,24)} style={{color: hasFile(24, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[24].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare final hearing agenda</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Before final hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[25].completedate"} id="date-25-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Auditor</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,25)} style={{color: hasFile(25, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[25].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell>25-50 days after publication of final hearing notice</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[26].completedate"} id="date-26-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, Drainage Authority, Drainage Manager, Engineer,Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,26)} style={{color: hasFile(26, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[26].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Make changes to final report or viewer's report, if requested</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Making_Changes:_Engineer%27s_Detailed_Survey_Report_and_Viewer%27s_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[27].completedate"} id="date-27-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer,Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,27)} style={{color: hasFile(27, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[27].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Dismiss proceedings OR Adoption of final order establishing proposed project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._Adoption_of_Final_Order">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[28].completedate"} id="date-28-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_C:_Order_Dismissing_Proceedings">
                            Template C: Findings and Order Dismissing Proceedings and Petition
                        </Link>
                    </TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>Auditor, Drainage Authority, Drainage Manager, Engineer,Viewer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,28)} style={{color: hasFile(28, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[28].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule bid opening and bid award (letting)</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Allow 30 days for appeal period to expire</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[29].completedate"} id="date-29-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,29)} style={{color: hasFile(29, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[29].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare specs and advertise for construction bids</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[30].completedate"} id="date-30-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Construction plans and specifications
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,30)} style={{color: hasFile(30, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[30].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish advertisement for bids in legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 2 consecutive weeks (not same week as bid opening)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[31].completedate"} id="date-31-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Advertisement for Bids
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,31)} style={{color: hasFile(31, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[31].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid opening</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[32].completedate"} id="date-32-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,32)} style={{color: hasFile(32, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[32].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Consider bid award</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Next board meeting following opening</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[33].completedate"} id="date-33-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,33)} style={{color: hasFile(33, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[33].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Award contract to low bidder Forward letters to unsuccessful bidders</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[34].completedate"} id="date-34-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Notice_of_Award">
                            Template A: Notice of Award
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Award
                        <br />
                        <span style={{color: "red"}}>Drainage Authority Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,34)} style={{color: hasFile(34, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[34].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare contract and bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._The_Construction_Contract_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[35].completedate"} id="date-35-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,35)} style={{color: hasFile(35, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[35].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Project construction and oversight</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[36].completedate"} id="date-36-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Contractor, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,36)} style={{color: hasFile(36, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[36].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Report to DA when contract complete</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[37].completedate"} id="date-37-lateral" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,37)} style={{color: hasFile(37, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[37].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule hearing for final completion of project</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Following engineer's completion report</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[38].completedate"} id="date-38-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,38)} style={{color: hasFile(38, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[38].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Provide notice of hearing to affected landowners by publication or mail</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=D._Hearing_for_Final_Acceptance_of_Project">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before completion hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[39].completedate"} id="date-39-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_G:_Notice_of_Hearing_on_Final_Acceptance">
                            Template G: Notice of Hearing on Final Acceptance of Project
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Hearing
                        <br />
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,39)} style={{color: hasFile(39, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[39].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold hearing for final acceptance of project Adjourn hearing to another date Final acceptance order and payment of balance due</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[40].completedate"} id="date-40-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php/Template_H:_Findings_and_Order_Accepting_Contract">
                            Template H: Final Acceptance Order
                        </Link>
                    </TableCell>
                    <TableCell>
                        Final Acceptance
                        <br />
                        <span style={{color: "red"}}>Auditor, DA, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,40)} style={{color: hasFile(40, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[40].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's as constructed survey filed with Auditor</TableCell>
                    <TableCell>
                        <Link target='_blank' href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[41].completedate"} id="date-41-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record Drawings (as-built plan)
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,41)} style={{color: hasFile(41, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[41].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Send copy of revised engineer's report to DNR</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[42].completedate"} id="date-42-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,42)} style={{color: hasFile(42, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[42].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[43].completedate"} id="date-43-ns" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,43)} style={{color: hasFile(43, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[43].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Partial Abandonment of Drainage System"){
        return(
            <>
            <TableRow className={classes.important}>
                <TableCell style={{maxWidth: "100px"}}>Petition for the abandonment</TableCell>
                <TableCell>
                    <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.806#stat.103E.806.1">
                        MS 103E.806
                    </Link>
                    <br />
                    <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">
                       Drainage Manual
                    </Link>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-partial" autoPopulate={false} />
                </TableCell>
                <TableCell><Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AL:_Petition_for_Abandonment">Template AL: Petition for Abandonment</Link></TableCell>
                <TableCell>
                    Petition
                    <br />
                    <span style={{color: "red"}}>/ benefiting landowner or drainage authority</span>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[0].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>File Petition with county Auditor or WD Secretary</TableCell>
                <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.806#stat.103E.806.2">MS 103E.806</Link></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[1].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Board sets hearing date</TableCell>
                <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.806#stat.103E.806.3">MS 103E.806</Link></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    Approved Board Minutes
                    <br />
                    <span style={{color: "red"}}>/ Drainage Authority or Court Administrator</span>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[2].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Publication of final hearing notice in newspaper or drainage authority website</TableCell>
                <TableCell><Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">Drainage Manual</Link></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    Record of publication with dates
                    <br />
                    <span style={{color: "red"}}>/ Auditor</span>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[3].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Provide notice by mail to owners of all property benefited by the system</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[4].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Attorney prepares finding of fact and order</TableCell>
                <TableCell><Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.806#stat.103E.806.3">MS 103E.806</Link></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    Draft Order
                    <br />
                    <span style={{color: "red"}}>/ Attorney</span>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[5].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Public hearing</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[6].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.important}>
                <TableCell>Board makes Order Abandoning System or Dismisses Petition</TableCell>
                <TableCell>
                    <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.806#stat.103E.806.4">
                        MS 103E.806
                    </Link>
                    <br />
                    <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">
                        Drainage Manual
                    </Link>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    Signed Order
                    <br />
                    <span style={{color: "red"}}>/ Drainage Authority</span>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[7].legalnote"}/>
                </TableCell>
            </TableRow>
            <TableRow className={classes.cell}>
                <TableCell>Miscellaneous documents related to proceedings</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-partial" autoPopulate={false} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                    <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                </TableCell>
                <TableCell>
                    <TextField multiline fullWidth field={"task[8].legalnote"}/>
                </TableCell>
            </TableRow>
        </>
        );
    }
    if(x === "Petitioned Repair"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition filed with County Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/G._Drainage_System_Repairs#5._Procedure_for_Repair_by_Petition">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.715">
                            MS 103E.715
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_L:_Petition_for_Repair">
                            Template L: Petition for Repair
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>/ individual or an entity interested in or affected by a drainage system</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Present petition to DA at board meeting</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Next Meeting (County/WD) or 10 days after receiving petition (Joint Board)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appointment of engineer to prepare a repair report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.715#stat.103E.715.2">
                            MS 103E.715
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineers Report Filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=Minnesota_Public_Drainage_Manual_-_Engineering_and_Environmental_Considerations_-_Repair/Maintenance_of_Drainage_Systems">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.715#stat.103E.715.2">
                            MS 103E.715
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Engineer's Report
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Set hearing date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Mail notices of preliminary hearing
                    Notice of preliminary hearing published (not required
                    Notice of preliminary hearing to DNR (not required)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.261">
                            MS 103E.261
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Commissioner%27s_Preliminary_Advisory_Report">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>At least 10 days before hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_D:_Order_Setting_Hearing_on_Preliminary_Survey">
                            Template D: Order Setting Hearing on Preliminary Hearing
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing to consider repair</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=F._The_Preliminary_Hearing#2._Preliminary_Hearing_Procedures">
                            Drainage Manual
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.715#stat.103E.715.4">
                            MS 103E.715
                        </Link>
                    </TableCell>
                    <TableCell>30 days from order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DA, Drainage Manager, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Adoption of findings and order</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Part of Adoption of findings and order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_N:_Final_Order_on_Repair">
                            Template N: Final Order on Repair
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Schedule bid opening and bid award (letting)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare specs and advertise for construction bids</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Construction Plan Set
                        <br />
                        <span style={{color: "red"}}>/ Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publish advertisement for bids in legal newspaper</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publish 1/wk for 2 consecutive weeks (not same week as bid opening)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid opening</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hold bid award</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=B._Procedure_for_Awarding_the_Construction_Contract#1._Notice_of_Contract_Awarding">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell>Next board meeting following opening</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Auditor, DM, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Award contract to low bidder Forward letters to unsuccessful bidders</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_A:_Notice_of_Award">
                            Template A: Notice of Award
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice of Award
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Prepare contract and bond</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=C._The_Construction_Contract_and_Bond">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Project construction and oversight</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Contractor, Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer's as constructed survey filed with Auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.295">
                            MS 103E.295
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=E._Engineer%27s_As-Constructed_Survey_and_Discharge_of_Duties">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record Drawing
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17-pr" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Petition to Use Established Drainage System as Outlet"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition filed</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.401#stat.103E.401.3">
                            MS 103E.401 Subd 3
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/J._Use_of_the_Drainage_System_as_Outlet#1._Petition_to_Use_Established_Drainage_System_as_Outlet">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_Z:_Petition_to_Use_Drainage_System_as_Outlet">
                            Template Z: Petition to Use Drainage System as Outlet
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>Petitioners</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>If applicable consider a teleconference with Joint Authority</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Consider the Hire of an Engineer</TableCell>
                    <TableCell>Optional</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hire viewers</TableCell>
                    <TableCell>Optional</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public hearing set by drainage authority</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.401#stat.103E.401.4">
                            MS 103E.401 Subd 4
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/J._Use_of_the_Drainage_System_as_Outlet#2._Drainage_System_as_Outlet_for_Municipality">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AA:_Order_Setting_Hearing_on_Petition_for_Outlet">
                            Template AA: Order Setting Hearing on Petition for Outlet
                        </Link>
                    </TableCell>
                    <TableCell>
                    Findings and Order
                    <br />
                    <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing notice</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publication - 1x/week for 3 successive weeks, final publication one week preceding public hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Affidavit of publication with publication dates</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing notice</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Posting - At least 3 weeks before the date of the final hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Affidavit of posted notice</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing notice</TableCell>
                    <TableCell></TableCell>
                    <TableCell>	Mail - Landowners need to receive 10 days prior to the hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Affidavit of mailing</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares findings of fact &amp; order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Findings and order
                        <br />
                        <span style={{color: "red"}}>Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Within 30 days of receiving the viewers information</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board considering findings and order to authorize outlet or dismiss petition</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Landowner billed for costs to outlet</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.401#stat.103E.401.5">
                            MS 103E.401 Subd 5
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Benefits assigned to the parcel</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.401#stat.103E.401.6">
                            MS 103E.401 Subd 6
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Record findings for the outlet petition</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-114-pe" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Reestablishment of Drainage System Records"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition for the reestablishment of drainage system records (optional)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.101#stat.103E.101.4a">
                            MS 103E.101
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php?title=H._Reestablishment_of_Drainage_System_Records">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/b/b1/Temp_T_Petition_for_Record_Correction.docx">
                            Template T: Petition for Record Correction
                        </Link>
                    </TableCell>
                    <TableCell>
                        Affidavit documenting valid petition, motion/resolution from board accepting the petition
                        <br />
                        <span style={{color: "red"}}>Petitioners or the Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>1a) Motion from Board to initiate the reestablishment of drainage system records</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.101#stat.103E.101.3">
                            MS 103E.101
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/0/01/Temp_U_Resolution_to_Initiate_Record_Correction.docx">
                            Template U: Resolution to Initiate Record Correction
                        </Link>
                    </TableCell>
                    <TableCell>
                        Motion/resolution from Board
                        <br />
                        <span style={{color: "red"}}>County Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint Engineer</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>	Motion from Board </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Engineer prepares Draft Engineer's Report (optional)</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.101#stat.103E.101.4a">
                            MS 103E.101
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Draft Engineer's Report</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer prepares Final Engineer's Report</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Final Engineer's Report</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public Information Meeting (optional)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Agenda
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Board sets hearing date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Approved Board Minutes</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publication of final hearing notice in newspaper or drainage authority website</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/8/84/Temp_V_Notice_of_Hearing_%28engineer_report_on_record_correction.docx">
                            Template V: Notice of Hearing on Engineer's Report
                        </Link>
                    </TableCell>
                    <TableCell>Record of publication with dates</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                        Mail final hearing notice to:
                        <br/>
                        &nbsp;&nbsp;a) DNR
                        <br/>
                        &nbsp;&nbsp;b) BWSR
                        <br/>
                        &nbsp;&nbsp;c) petitioner(s)
                        <br/>
                        &nbsp;&nbsp;d) all property owners
                        <br/>
                        benefitted/ damaged by the system
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/8/84/Temp_V_Notice_of_Hearing_%28engineer_report_on_record_correction.docx">
                            Template V: Notice of Hearing on Engineer's Report
                        </Link>
                    </TableCell>
                    <TableCell>Affidavit of mailing with date mailed and materials</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares finding of fact and order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/images/f/fc/Temp_W_Final_Order_Reestablishing_Drainage_System_Records.docx">
                            Template W: Final Order Reestablishing Drainage System Records
                        </Link>
                    </TableCell>
                    <TableCell>Draft Order</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board makes Order Reestablishing Drainage System Records</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        signed order
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12-rest" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Removal of Property from the Drainage System"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition filed with County Auditor or WD Secretary</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.805#stat.103E.805.1">
                            MS 103E.805
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/L._Removal_of_Property_from_a_Drainage_System">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AF:_Petition_for_Removal_of_Property_from_a_Drainage_System">
                            Template AF: Petition for Removal Property from a Drainage System
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>/ Benefitting landowner</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public hearing set by drainage authority</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing notice</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Publication - 1x/week for 3 successive weeks, final publication one week preceding public hearing</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AG:_Notice_of_Hearing_on_Property_Removal">
                            Template AG: Notice of Hearing on Removal of Property from the Drainage System
                        </Link>
                    </TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>/ Affidavit of publication with publication dates</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Hearing notice mailed to all benefiting landowners</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Notice
                        <br />
                        <span style={{color: "red"}}>/ Affidavit of mailing</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Public hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.805#stat.103E.805.3">
                            MS 103E.805
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares findings of fact and order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <span style={{color: "red"}}>Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board order to dismiss petition or remove property from the drainage system</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.805#stat.103E.805.4">
                            MS 103E.805
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AH:_Final_Order_Removing_Property_from_Drainage_System">
                            Template AH: Final Order Removing Property from Drainage System
                        </Link>
                    </TableCell>
                    <TableCell>
                        Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Redetermination of Benefits"){
        return(
            <>
                <TableRow className={classes.cell}>
                    <TableCell>Information / meeting notes prior to petition or order</TableCell>
                    <TableCell></TableCell>
                    <TableCell>(OPTIONAL)</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Petition</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.202">
                            MS 103E.202
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Affidavit documenting valid petition, motion/resolution
                        from board accepting the
                        petition
                        <br />
                        <span style={{color: "red"}}>Petitioners or Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                        Orders from the board
                        <br />
                        &nbsp;a. Viewers appointed
                        &nbsp;b. Engineer appointed
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.351">
                            MS 103E.351
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.305">
                            MS 103E.305
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.241">
                            MS 103E.241
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/I._Redetermination_of_Benefits_and_Damages#1._Initiating_a_Redetermination_of_Benefits_and_Damages">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_X:_Order_Initiating_Redetermination_of_Benefits">
                            Template X: Order Initiating Redetermination of Benefits
                        </Link>
                    </TableCell>
                    <TableCell>
                        Finding of fact resolution
                        ordering redetermination
                        <br />
                        <span style={{color: "red"}}>Viewers</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>
                        Viewers oath
                        <br />
                        a. Evidence of insurance
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.305">
                            MS 103E.305
                        </Link>
                        <br />
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/466">
                            M.S.466
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="/static/legalauditor_order.docx">
                            Template A: From drainage manual
                        </Link>
                    </TableCell>
                    <TableCell>Motion of resolution from board</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer prepares Preliminary Engineers Advisory Report and watershed maps</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Preliminary engineers report and maps
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Initial informational meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.043">
                            MS 103E.043
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Agenda</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final informational meeting</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.043">
                            MS 103E.043
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Agenda</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Board sets the final hearing date</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.351">
                            MS 103E.351
                        </Link>
                    </TableCell>
                    <TableCell>
                        Hearing set within 30 days after date of the final hearing notice and within 30 days of mailing property owners report
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Approved board minutes</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Drainage authority receives Preliminary Viewers Report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.321">
                            MS 103E.321
                        </Link>
                    </TableCell>
                    <TableCell>
                        Hearing set within 30 days after date of the final hearing notice and within 30 days of mailing property owners report
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Preliminary Viewers Report</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Drainage authority prepares Property Owners Report</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.323">
                            MS 103E.323
                        </Link>
                    </TableCell>
                    <TableCell>
                        Within 30 days after the viewers report is filed
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Property Owners Report
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing notice</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.325">
                            MS 103E.325
                        </Link>
                    </TableCell>
                    <TableCell>
                    Publication - 1X/week for 3 successive weeks, final publication 1 week preceding the final hearing
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[10].completedate"} id="date-10-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Affidavit of publication with publication dates</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,10)} style={{color: hasFile(10, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[10].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing notice</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.325">
                            MS 103E.325
                        </Link>
                    </TableCell>
                    <TableCell>
                    Posting At least 3 weeks before the date of the final hearing
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[11].completedate"} id="date-11-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Posted copy with dates posted</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,11)} style={{color: hasFile(11, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[11].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Final hearing notice</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.325">
                            MS 103E.325
                        </Link>
                    </TableCell>
                    <TableCell>
                        Mail within 1 week after first publication of notice
                    </TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[12].completedate"} id="date-12-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Affidavit of mailing with date mailed and materials</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,12)} style={{color: hasFile(12, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[12].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares findings of fact and order</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/I._Redetermination_of_Benefits_and_Damages#2._Legal_Procedures">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[13].completedate"} id="date-13-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="/static/legaly_finalorder.docx">
                            Template Y: Final Order Confirming Redetermined Benefits
                        </Link>
                    </TableCell>
                    <TableCell>Order and notes</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,13)} style={{color: hasFile(13, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[13].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Public hearing and Drainage authority considers final order</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.341">
                            MS 103E.341
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[14].completedate"} id="date-14-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Findings and Order
                        <br />
                        <span style={{color: "red"}}>Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,14)} style={{color: hasFile(14, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[14].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Engineer prepares final Engineers Advisory Report, watershed map and GIS layer.</TableCell>
                    <TableCell>Optional</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[15].completedate"} id="date-15-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Final Engineers Advisory Report
                        <br />
                        <span style={{color: "red"}}>Engineer</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,15)} style={{color: hasFile(15, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[15].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Final Approved Viewers Report</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[16].completedate"} id="date-16-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Final Viewers Report</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,16)} style={{color: hasFile(16, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[16].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appeal served to auditor</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.091#stat.103E.091.2">
                            MS 103E.091 Subd 2 (b)
                        </Link>
                    </TableCell>
                    <TableCell>Within 30 days of order</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[17].completedate"} id="date-17-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Appeal</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,17)} style={{color: hasFile(17, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[17].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Auditor files appeal with court administrator</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.091#stat.103E.091.2">
                            MS 103E.091 Subd 2 (b)
                        </Link>
                    </TableCell>
                    <TableCell>Within 30 days appeal notice filed with auditor</TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[18].completedate"} id="date-18-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>Court entry, outcome of appeals notes</TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,18)} style={{color: hasFile(18, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[18].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Damages paid</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[19].completedate"} id="date-19-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Receipt of Damages Paid
                        <br />
                        <span style={{color: "red"}}>Auditor/Secretary</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,19)} style={{color: hasFile(19, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[19].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[20].completedate"} id="date-20-rb" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,20)} style={{color: hasFile(20, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[20].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    if(x === "Transfer All or Part of a Drainage System"){
        return(
            <>
                <TableRow className={classes.important}>
                    <TableCell>Petition filed with County Auditor or WD Secretary</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.812#stat.103E.812.2">
                            MS 103E.812
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[0].completedate"} id="date-0-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/Template_AL:_Petition_for_Abandonment">
                            Template AL: Petition for Abandonment
                        </Link>
                    </TableCell>
                    <TableCell>
                        Petition
                        <br />
                        <span style={{color: "red"}}>/ (see statute)</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,0)} style={{color: hasFile(0, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[0].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Board sets hearing date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[1].completedate"} id="date-1-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Approved Board Minutes
                        <br />
                        <span style={{color: "red"}}>Drainage Authority or Court Administrator</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,1)} style={{color: hasFile(1, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[1].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Publication of final hearing notice in newspaper or drainage authority website</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[2].completedate"} id="date-2-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Record of publication with dates
                        <br />
                        <span style={{color: "red"}}>/ Auditor</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,2)} style={{color: hasFile(2, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[2].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Provide notice by mail to owners of all property benefited by the system</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[3].completedate"} id="date-3-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,3)} style={{color: hasFile(3, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[3].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Attorney prepares finding of fact and order</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[4].completedate"} id="date-4-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Draft Order
                        <br />
                        <span style={{color: "red"}}>/ Attorney</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,4)} style={{color: hasFile(4, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[4].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Transfer hearing</TableCell>
                    <TableCell>
                        <Link target="_blank" href="https://www.revisor.mn.gov/statutes/cite/103E.812#stat.103E.812.5">
                            MS 103E.812
                        </Link>
                        <br />
                        <Link target="_blank" href="https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System">
                            Drainage Manual
                        </Link>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[5].completedate"} id="date-5-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,5)} style={{color: hasFile(5, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[5].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Appoint a technical panel as described in 103E.812, if a property owner files a written objection</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[6].completedate"} id="date-6-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,6)} style={{color: hasFile(6, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[6].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Reconvene hearing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[7].completedate"} id="date-7-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,7)} style={{color: hasFile(7, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[7].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.important}>
                    <TableCell>Board makes Order Transferring System or Dismissing Petition</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[8].completedate"} id="date-8-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        Signed Order
                        <br />
                        <span style={{color: "red"}}>/ Drainage Authority</span>
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,8)} style={{color: hasFile(8, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[8].legalnote"}/>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.cell}>
                    <TableCell>Miscellaneous documents related to proceedings</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <DatePicker format="MM/dd/yyyy" fullWidth field={"task[9].completedate"} id="date-9-rm" autoPopulate={false} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        <Button variant="contained" onClick={(e) => props.fileViewer(e,9)} style={{color: hasFile(9, props)}}>File Viewer</Button>
                    </TableCell>
                    <TableCell>
                        <TextField multiline fullWidth field={"task[9].legalnote"}/>
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default LegalRows;
