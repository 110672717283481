import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    coordinates: {
        position: "relative",
        padding: "12px 0px"
    },
    text: {
        padding: "0px 15px",
        fontSize: 14,
        fontFamily: "\"Avenir Next\",\"Helvetica Neue\",Helvetica,Arial,sans-serif",
        color: "#323232"
    },
    l: {
        marginLeft: 2,
        maxWidth: 173,
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgb(0 123 255 / 25%)"
        }
    },
    btn: {
        backgroundColor: "#00598e",
        border: "1px solid #00598e",
        color: "#fff",
        marginLeft: 3,
        cursor: "pointer",
        "&:focus": {
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
        }
    }
});

class Coordinates extends Component {
    state = {
        pt: null,
        showInputs: false
    }

    componentDidMount() {
        const { view } = this.props;
        var _this = this;
        view.on('pointer-move', function (evt) {
            const point = view.toMap({ x: evt.x, y: evt.y })
            _this.setState({ pt: point });
        });
    }

    showInputs = (e) => {
        const { addInteractivePoint, allowShowInputs } = this.props;
        const { pt, showInputs } = this.state;
        if (!showInputs && allowShowInputs) {
            addInteractivePoint(pt);
            this.setState({ showInputs: true, lat: pt ? pt.latitude.toFixed(4) : null, lng: pt ? pt.longitude.toFixed(4) : null });
        }
    }

    keyPressed = (e) => {
        const { tempGraphics } = this.props;
        switch (e.keyCode) {
            case 27: //Esc
                tempGraphics.removeAll();
                this.setState({ showInputs: false });
                break;
            case 13: //Enter
                tempGraphics.removeAll();
                this.setState({ showInputs: false });
                break;
            default:
                //this._handleSubmit();
                break;
        }
    }

    updatePoint = (values) => {
        const { tempGraphics, addInteractivePoint } = this.props;
        tempGraphics.removeAll();
        addInteractivePoint(values);
    }

    addPoint = (e) => {
        const { addPoint, tempGraphics } = this.props;
        const { lat, lng } = this.state;
        addPoint({ longitude: parseFloat(lng), latitude: parseFloat(lat) });
        tempGraphics.removeAll();
        this.updatePoint({ longitude: parseFloat(lng), latitude: parseFloat(lat) })
    }

    updateLat = (e) => {
        const { lng } = this.state;
        this.setState({ lat: e.target.value });
        this.updatePoint({ longitude: parseFloat(lng), latitude: parseFloat(e.target.value) });
    }

    updateLng = (e) => {
        const { lat } = this.state;
        this.setState({ lng: e.target.value });
        this.updatePoint({ longitude: parseFloat(e.target.value), latitude: parseFloat(lat) });
    }

    render() {
        const { classes } = this.props;
        const { lat, lng, pt, showInputs } = this.state;

        return (
            <div className={classes.coordinates} onClick={this.showInputs}>
                <div className={classes.text}>
                    {!showInputs && (
                        <>
                            Latitude: {pt ? pt.latitude.toFixed(4) : ""} Longitude: {pt ? pt.longitude.toFixed(4) : ""}
                        </>
                    )}
                    {showInputs && (
                        <>
                            Latitude:
                            <input id="" className={classes.l} type="text" value={lat} onChange={this.updateLat} onKeyUp={this.keyPressed} />
                            Longitude:
                            <input className={classes.l} type="text" value={lng} onChange={this.updateLng} onKeyUp={this.keyPressed} />
                            <button className={classes.btn} onClick={this.addPoint}>
                                <span className="esri-icon-blank-map-pin"></span>
                                Add Point Location
                            </button>
                        </>
                    )}
                </div>
            </div>
        )
    }

}


export default withStyles(styles)(Coordinates);