import React from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const styles = {
    submitWrapper: {
        position: "relative"
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    }
};

const Submit = ({ label, subLabel, altAction, altLabel, classes, loading, gridSize = 12, regular, parent, justify = "center" }) => {

    if(regular){
        return(
            <div className={classes.submitWrapper}>
                <Button variant="contained" fullWidth type="submit" color="primary" disabled={loading}>
                    {label} {subLabel}
                </Button>
                {loading && <CircularProgress size={24} className={classes.progress} />}
            </div>
        );
    } else if(!!parent){
        return( 
            <Grid container spacing={10} justify={justify}>
                {altAction ? (
                    <Grid item xs={gridSize / 2}>
                        <Button 
                            onClick={(e) => {
                                e.preventDefault();
                                parent(e);
                            }} 
                            variant="contained" 
                            fullWidth 
                            component={Link} 
                            to={altAction}
                        >
                            {altLabel}
                        </Button>
                    </Grid>
                ) : (
                    <div />
                )}
                <Grid item xs={gridSize / 2}>
                    <div className={classes.submitWrapper}>
                        <Button variant="contained" fullWidth type="submit" color="primary" disabled={loading}>
                            {label} {subLabel}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.progress} />}
                    </div>
                </Grid>
            </Grid>
        );
    } else {
        return( 
            <Grid container spacing={10} justify={justify}>
                {altAction ? (
                    <Grid item xs={gridSize / 2}>
                        <Button variant="contained" fullWidth component={Link} to={altAction}>
                            {altLabel}
                        </Button>
                    </Grid>
                ) : (
                    <div />
                )}
                <Grid item xs={gridSize / 2}>
                    <div className={classes.submitWrapper}>
                        <Button variant="contained" fullWidth type="submit" color="primary" disabled={loading}>
                            {label} {subLabel}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.progress} />}
                    </div>
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(styles)(Submit);
