import React, { Component } from "react";
import { FormField } from "react-form";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "4px 12px",
        //width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #be0000"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8,
        marginBottom: -12,
        //position: "relative",
        whiteSpace: "normal"
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    formControl: {
        marginTop: 16,
        maxWidth: "100%",
        minWidth: 150,
        marginBottom: 8
    }
});

const MenuProps = {
    PaperProps : {
        style: {
            maxHeight: 48 * 4.5,
        }
    }
}


class MultiSelectWrapper extends Component {
    
    handleChange = e => {
        let value = e.target.value;
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        const arr = value.filter(x => x.length > 0).join(',');

        setValue(arr);
        if (eventHandle) eventHandle(arr);
    };
    render() {
        const { classes, fieldApi, children, label, native, options, fullWidth, required, disabled, ...rest } = this.props;
        const { getValue } = fieldApi;

        const val = getValue();
	var renderValue = [""];
	if(typeof(val) === "number"){
	  renderValue = val ? val.toString().split(',') : [""];
	} else {
          renderValue = val ? val.split(',') : [""];
	}

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, val && classes.labelSelected, disabled && classes.disabled),
            error: classes.bootstrapInputError
        };
        
        return (
            <FormControl className={classes.formControl} error={true} required={required} fullWidth>
                <InputLabel 
                    shrink={true}
                    classes={{
                        root: classes.rootLabel,
                        shrink: classes.rootLabelShrink
                    }}>
                        {label}
                </InputLabel>
                <Select
                    multiple
                    disabled={disabled}
                    disableUnderline={true}
                    className={classes.bootstrapInput}
                    error={true}
                    value={renderValue}
                    onChange={this.handleChange}
                    input={<Input />}
                    renderValue={selected => selected.join(', ')}
                    {...rest}
                    MenuProps={MenuProps}
                >
                {options.map(option => (
                    <MenuItem key={option.label + option.value} value={option.label}>
                    <Checkbox checked={renderValue.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
                </Select>
          </FormControl>
        );
    }
}



/*
classes={{
                            input: classNames(classes.bootstrapInput, disabled && classes.disabled),
                            error: classes.bootstrapInputError
                    }}


*/

const MultiSelect = FormField(MultiSelectWrapper)
export default withStyles(styles)(MultiSelect);
