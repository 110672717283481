import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import Select from "../common/Select";
import states from "../common/states.json";
import WarningDialog from "../common/WarningDialog";
import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "red",
        textAlign: "center"
    },
    marginTop: {
        marginTop: 40
    }
};

class UpdatePage extends Component {
    state = {
        dialog: false
    }

    errorValidator = values => {
        const validateName = name => {
            return !name ? "Name is required" : null;
        };
        const validateUserName = name => {
            return !name ? "Username is required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };

        return {
            name: validateName(values.name),
            email: validateEmail(values.email),
            username: validateUserName(values.username)
        };
    };
    errorValidatorPassword = values => {
        const validatePasswordSingle = password => {
            if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password) return "Password cannot be empty"
            else if(password.length < 8) return "Minimum 8 characters";
            else return null;
        };
        return {
            current_password: validatePasswordSingle(values.current_password),
            new_password: validatePassword(values.new_password, values.re_new_password),
            re_new_password: validatePassword(values.new_password, values.re_new_password)
        };
    };

    submitForm = values => {
        this.props.authUpdate(values).then(() => {
            if (this.props.update.success) {
                this.props.history.push("/map");
            }
        });
    };

    submitFormPassword = values => {
        this.props.authPasswordChange(values).then(() => {
            if (this.props.change.success) {
                this.props.history.push("/map");
            }
        });
    };

    parent = (e) => {
        if(this.userApi && Object.keys(this.userApi.getTouched()).length > 0){
            e.preventDefault();
            this.setState({dialog:  true});
        } else {
            this.props.history.push('/map');
        }
    }

    render() {
        const { classes, authState, change, history, update } = this.props;
        const { dialog } = this.state;
        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Form
                        getApi={el => (this.userApi = el)}
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={authState.user}
                        validateError={this.errorValidator}
                        onSubmit={this.submitForm}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Typography variant="h5" gutterBottom className={classes.marginTop}>
                                                User Profile
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} gutterBottom>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="email" label="Email" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="username" label="Username" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="title" label="Title" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="organization" label="Organization" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="address" label="Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="address2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="zip" label="Zip Code" placeholder="55555" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField
                                                field="phone_cell"
                                                label="Mobile Phone"
                                                placeholder="555-555-5555"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField
                                                field="phone_office"
                                                label="Office Phone"
                                                placeholder="555-555-5555"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Submit
                                                label="Update User Information"
                                                altAction="/map"
                                                altLabel="Return to Dashboard"
                                                loading={update.pending}
                                                parent={this.parent}
                                            />
                                            <Typography className={classes.registerError}>{update.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                    <Grid item xs={12} md={8} lg={6}>
                        <Divider />
                    </Grid>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        validateError={this.errorValidatorPassword}
                        onSubmit={this.submitFormPassword}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    {console.log(this.formApi && this.formApi.getTouched())}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Typography variant="h5" gutterBottom>
                                                Update Password
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} gutterBottom>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="current_password"
                                                label="Current Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={10} sm={6} md={4} lg={3}>
                                            <TextField
                                                field="new_password"
                                                label="New Password"
                                                type="password"
                                                fullWidth
                                            />
                                            <TextField
                                                field="re_new_password"
                                                label="Retype New Password"
                                                type="password"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={8} lg={6}>
                                            <Submit
                                                label="Update Password"
                                                altAction="/map"
                                                altLabel="Return to Dashboard"
                                                loading={change.pending}
                                            />
                                            <Typography className={classes.registerError}>{change.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        this.setState({dialog: true});
                        history.push('/map')
                    }}
                    cancelAction={() => this.setState({ dialog: false })}
                    open={dialog}
                    title="You may have unsaved changes!"
                    text={"Are you sure you wish to navigate to dashboard?"}
                    confirmText="Yes"
                    cancelText="No"
                />
            </AppContainer>
        );
    }
}

UpdatePage = connect(
    state => ({
        authState: state.auth,
        update: (state.auth && state.auth.update) || {},
        change: (state.auth && state.auth.change) || {}
    }),
    authActions
)(UpdatePage);

export default withStyles(styles)(withRouter(UpdatePage));
