import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: "#74A94C",
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#74A94C",
        fontWeight: 500
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    primaryButton: {
        backgroundColor: "#74A94C",
        color: "#fff"
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
};

const WarningDialog = ({
    title,
    text,
    lock,
    classes,
    confirmAction,
    cancelAction,
    confirmText,
    cancelText,
    noActions,
    onClose,
    ...other
}) => (
    <Dialog {...other} onClose={onClose} classes={{ paper: classes.nooverflow }}>
        <DialogTitle className={classes.titleContainer}>
            <ErrorOutline className={classes.supportIcon} />
            <IconButton 
                disabled={lock}
                onClick={noActions ? onClose : cancelAction} 
                className={classes.closeIcon}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography variant="title" className={classes.questionTitle} gutterBottom>
                {title}
            </Typography>
            <DialogContentText>{text}</DialogContentText>
            {!noActions && (
                <DialogActions className={classes.centerButtons}>
                    <div className={classes.wrapper}>
                        <Button 
                            disabled={lock}
                            onClick={confirmAction} 
                            color="primary" 
                            variant="outlined">
                            {confirmText ? confirmText : "Delete Entry"}
                        </Button>
                        {lock && (<CircularProgress color="primary" size={24} className={classes.buttonProgress} />)}
                    </div>
                    <div>
                        <Button 
                            disabled={lock}
                            onClick={cancelAction} 
                            variant="outlined" 
                            className={classes.greyButton} 
                            autoFocus>
                            {cancelText ? cancelText : "Do Not Delete"}
                        </Button>
                    </div>
                </DialogActions>
            )}
        </DialogContent>
    </Dialog>
);

export default withStyles(styles)(WarningDialog);
