import React from "react";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';

import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const styles = theme => ({
    flex: {
        flex: 1
    }
});

const DialogForm = ({classes, header, dialog, onClose, children}) => {
    return (
        <Dialog open={dialog} classes={{ paper: classes.minWidth }}>
            <Toolbar>
                <Typography variant="h5" className={classes.flex}>{header}</Typography>
                <IconButton onClick={() => onClose()}> <Close /> </IconButton>
            </Toolbar>
            <DialogContent className={classes.dialogMin}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(styles)(DialogForm);