import { APP_MOUNTED } from "./actions";
export function appReducer(state = {mounted: false}, action){

    switch(action.type){
        case APP_MOUNTED:
            return{
                ...state,
                mounted: action.payload
            };
        default:
            return state;
    }
}