import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { getValue } from "../../api/utils";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";

import { ExcelIcon, ShapeIcon } from '../common/IconFunctions';

import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';

import ExpansionPanel from "../common/ExpansionPanel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import { formateDate } from "../../api/utils";

import * as authActions from "../auth/actions";

const styles = theme => ({
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    mB: {
        marginBottom: 10
    },
    mr: {
        marginRight: 5
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px"
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
    }
});

const columnData = [
    { id: "drainage__drainage", numeric: false, label: "System Name", allowSort: true },
    { id: "repair", numeric: false, label: "Repair #", allowSort: true },
    { id: "datereceived", date: true, label: "Date Received", allowSort: true },
    { id: "priority", numeric: false, label: "Priority", allowSort: true },
    { id: "township", numeric: false, label: "Township", allowSort: true },
    { id: "section", numeric: false, label: "Section", allowSort: true, required: true },
    { id: "peoplelandowner", numeric: false, label: "Landowner", allowSort: true, required: true  },
    { id: "peoplerequest", numeric: false, label: "Person Requesting Repair", allowSort: true, required: true  },
    { id: "comments", numeric: false, label: "Problem/Proposed Work", allowSort: true, required: true  },
    { id: "contractor", numeric: false, label: "Assigned Contractor", allowSort: true, required: true  },
];

class RepairBoard extends Component {
    constructor(props){
        super(props);

        let status = "";
        let shapeFileType = "";

        if((props.authState && props.authState.user && props.authState.user.role === "Contractor") || props.match.params.status === "assigned"){
            status = "Assigned";
            shapeFileType = "allShapeFilesRepairAssigned";
        } else {
            status = props.match.params.status === "review" ? "For Review" : "In Progress";
            shapeFileType = props.match.params.status === "review" ? "allShapeFilesRepairForReview" : "allShapeFilesRepairInProgress";
        }

        this.state = {
            rowsPerPage: 100,
            page: 0,
            data: [],
            status,
            shapeFileType
        }

        if((props.authState && props.authState.user && props.authState.user.role === "Contractor") || props.match.params.status === "assigned"){
            this.getData("Assigned");
        } else {
            this.getData(props.match.params.status === "review" ? "For Review" : "In Progress");
        }
    }

    getData = (status) => {
        const { authState } = this.props; 
	const token = authState && authState.user ? authState.user.auth_token : "";

        fetch(`/list/all/repair?status=${status}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + token
            }
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            var tmp_list = data.map(x => ({
                ...x,
                peoplelandowner: x.peoplelandowner__name,
                peoplerequest: x.peoplerequest__name,
                contractor: x.contractor__user__name,
            }));
            this.setState({data: tmp_list});
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {                
                this.setState({
                    data: []
                });
            }
        });
    }


    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    go = (e) => {
        this.props.history.push(`/repair/${e.id}/${e.drainage}/${e.drainage__drainage}`)
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        const data = this.state.data.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (a[orderBy] === "") return 1;
                else if (b[orderBy] === "") return -1;
                
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy, data });
    }

    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    return true;
                }
            });
        } else {
            return data;
        }
    }

    getSearchStr = () => {
	const { authState } = this.props;
        const { status } = this.state;
        const user_str = authState && authState.user ? `&user=${authState.user.id}` : "";

        if(this.filters && this.filters.column !== "" && this.filters.filter !== ""){
            return `?type=${status}&column=${this.filters.column}&searchstring=${this.filters.filter}${user_str}`;
        } else {
            return `?type=${status}${user_str}`;
        }
    }

    render(){
        const { classes, authState, match } = this.props;
        const { data, page, shapeFileType, status, rowsPerPage } = this.state;

        const role = getValue(authState, 'user.role');
        var title = status;
        if(role === 'Contractor'){
            title = "Assigned To Me";
        } else if(match.params.status === "assigned"){
            title = "Assigned To Contractors";
        }

        const filtered = this.filter(data);
        return(
            <AppContainer authenticated adminPage={true} noScroll>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Repairs - {title}</Typography>
                        <Divider className={classes.mB}/>
                        <ExpansionPanel noSlice onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData}/>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10}}>
                        <div className={classes.root}>
                            <Button 
                                variant="contained"
                                target="_blank" 
                                href={`/reports/all/list${this.getSearchStr()}`}
                                className={classes.mr}>
                                <ExcelIcon /> 
                                    &nbsp;&nbsp;Export to Excel
                            </Button>
                            <Button 
                                variant="contained"
                                target="_blank" 
                                href={`/export/shapefile/${shapeFileType}${this.getSearchStr()}`}>
                                <ShapeIcon />
                                &nbsp;&nbsp;Export to Shapefile
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} columnData={columnData} onRequestSort={this.handleRequestSort}/>
                            <TableBody>
                                {(rowsPerPage > 0 ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filtered).map(x => (
                                    <TableRow key={x.id} hover onClick={(e) => this.go(x)}>
                                        <CustomTableCell>{x.drainage__drainage}</CustomTableCell>
                                        <CustomTableCell>{x.repair}</CustomTableCell>
                                        <CustomTableCell>{formateDate(x.datereceived)}</CustomTableCell>
                                        <CustomTableCell>{x.priority}</CustomTableCell>
                                        <CustomTableCell>{x.township}</CustomTableCell>
                                        <CustomTableCell>{x.section}</CustomTableCell>
                                        <CustomTableCell>{x.peoplelandowner}</CustomTableCell>
                                        <CustomTableCell>{x.peoplerequest}</CustomTableCell>
                                        <CustomTableCell>{x.comments}</CustomTableCell>
                                        <CustomTableCell>{x.contractor}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={columnData.length}
                                        count={filtered.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                        rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                </Grid>    
            </AppContainer>
        )
    }
}

RepairBoard = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...authActions
    }
)(RepairBoard);

export default withStyles(styles)(withRouter(RepairBoard));
