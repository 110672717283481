/******************************************************************************
 * All Drainage Systems
 *******************************************************************************/
import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { ExcelIcon } from '../common/IconFunctions';
import AppContainer from '../common/AppContainer';
import CustomTableCell from '../common/TableCell';
import TablePaginationActionsWrapped from "../common/Paginator";
import ExpansionPanel from "../common/ExpansionPanel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import { formateDate, formatMoney } from "../../api/utils";
import * as authActions from "../auth/actions";

const styles = theme => ({
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    mB: {
        marginBottom: 10
    },
    mr: {
        marginRight: 5
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    listselectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "300px"
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        },
    }
});

const columnData = [
    { id: "drainage", numeric: false, label: "System Name", allowSort: true },
    { id: "fullsystemname", numeric: false, label: "Full System Name", allowSort: true },
    { id: "status", numeric: false, label: "Status", allowSort: true },
    { id: "jurisdiction", date: true, label: "Legal Type", allowSort: true },
    { id: "joints", date: true, label: "Joint Counties", allowSort: true },
    { id: "ditchtype", numeric: false, label: "Ditch Type", allowSort: true },
    { id: "year", numeric: false, label: "Year Established", allowSort: true, required: true },
    { id: "rob", numeric: false, label: "Most Recent ROB", allowSort: true, required: true  },
    { id: "totallength", numeric: true, label: "Total Length(miles)", allowSort: true, required: true  },
    { id: "improvedates", numeric: false, label: "Improve Dates", allowSort: true, required: true  },
    { id: "repairdates", numeric: false, label: "Repair Dates", allowSort: true, required: true  },
    { id: "redeterbenefitacres", numeric: true, label: "ROB Benefit Acres", allowSort: true, required: true  },
    { id: "origbenefitdollars", numeric: true, label: "ROB Benefit Dates", allowSort: true, required: true  },
    { id: "ifsnumber", numeric: true, label: "IFS#", allowSort: true, required: true  },
    { id: "comments", numeric: false, label: "Notes", allowSort: true, required: true  },
    { id: "issue", numeric: false, label: "Issue Needs Consideration", allowSort: true, required: true  },
    { id: "acctfundbalance", numeric: true, label: "Account Fund Balance", allowSort: true, required: true  },
    { id: "updateacctbalance", date: true, label: "Account Balace Date", allowSort: true, required: true  },
    { id: "r_5", numeric: true, label: "Repairs Past 5yrs", allowSort: true, required: true  },
    { id: "c_5", numeric: true, label: "Conditions Past 5yrs", allowSort: true, required: true  },
    { id: "v_5", numeric: true, label: "Violations Past 5yrs", allowSort: true, required: true  },
    { id: "annual_years", numeric: false, label: "Inspection Years", allowSort: true, required: true  },
    { id: "inspectyear", numeric: false, label: "Next Sceduled Inspection Year", allowSort: true, required: true  },
];

function displayMiles(x){
    if(x.lengthtype === "Feet"){
        return !isNaN(x.totallength) ? Math.round(((x.totallength / 5280) + Number.EPSILON) * 100) / 100 : "";
    } else {
        return x.totallength;
    }
}

class AllSystems extends Component {
    constructor(props){
        super(props);

        this.state = {
            rowsPerPage: 100,
            page: 0,
            data: [],
            order: "",
            orderBy: ""
        }

        if(props.authState && props.authState.user){
            this.getData();
        }
    }

    getData = () => {
        const { authState } = this.props; 

        fetch(`/list/all/drainage`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
        })
        .then(data => {
            var tmp = [];
            var j_dict = {};
            var a_dict= {};
            
            data.j.forEach(x => {
                var sx = String(x.drainage);
                console.log((sx in j_dict))
                if(!(sx in j_dict) || ((sx in j_dict) && j_dict[sx] === "")){
                    j_dict[sx] = `${x.county__county}:${x.percentage}%`;
                } else {
                    j_dict[sx] += `,${x.county__county}:${x.percentage ? x.percentage : ""}%`;
                }
            });

            data.a.forEach(x => {
                var sx = String(x.drainage);
                if(!(sx in a_dict) || ((sx in a_dict) && a_dict[sx] === "")){
                    a_dict[sx] = `${x.year}`;
                } else {
                    a_dict[sx] += `,${x.year}`;
                }
            });

            tmp = data.d.map(x => ({
                ...x,
                annual_years: a_dict[String(x.id)],
                joints: j_dict[String(x.id)]
            }));
            this.setState({data: tmp});
        })
        .catch(e => {
            var message = e.message;
            if(message && message === '{"detail":"Token has expired."}'){
                this.props.authLogout();
                this.props.history.push("/");
            } else {                
                this.setState({
                    data: []
                });
            }
        });
    }


    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    go = (e) => {
        this.props.history.push(`/drainage/${e.id}/${e.drainage}`)
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        // Total Length is calculated on fly during render so do same for sort
        if(property === "totallength"){
            const data = this.state.data.sort(function(a, b) {
                const aa = displayMiles(a);
                const bb = displayMiles(b);

                if (aa === bb) return 0;
                else if (aa === null || aa === "" || typeof aa === "undefined") return 1;
                else if (bb === null || bb === "" || typeof bb === "undefined") return -1;

                if (order === "desc") return parseFloat(bb || 0) < parseFloat(aa || 0) ? -1 : 1;
                else return parseFloat(aa || 0) < parseFloat(bb || 0) ? -1 : 1;
            });
            this.setState({ order, orderBy, data });
            return;
        }

        const data = this.state.data.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;

            if (a[orderBy] === b[orderBy]) return 0;
            else if (a[orderBy] === null || a[orderBy] === "" || typeof a[orderBy] === "undefined") return 1;
            else if (b[orderBy] === null || b[orderBy] === "" || typeof b[orderBy] === "undefined") return -1;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ order, orderBy, data });
    }

    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(column === "totallength" && x["lengthtype"] === "Feet"){
                        const test = parseFloat(x[column]);
                        return !isNaN(test) && (String(test / 5280) || "").toUpperCase().includes((filter || "").toUpperCase());
                    } else if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    if(x[this.filters.column] && x[this.filters.column].length !== 0)
                        return true;
                    else 
                        return false;
                }
            });
        } else {
            return data;
        }
    }

    getSearchStr = () => {
        if(this.filters && this.filters.column !== "" && this.filters.filter !== ""){
            return `?type=drainage&column=${this.filters.column}&searchstring=${this.filters.filter}`;
        } else {
            return "?type=drainage";
        }
    }

    render(){
        const { classes } = this.props;
        const { data, page, rowsPerPage } = this.state;

        const filtered = this.filter(data);

        return(
            <AppContainer authenticated adminPage={true} noScroll> 
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Drainage Systems</Typography>
                        <Divider className={classes.mB}/>
                        <ExpansionPanel noSlice onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData.filter(x=>x.id !== "issue")}/>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8} xl={6} style={{ marginBottom: 10}}>
                        <div className={classes.root}>
                            <Button 
                                variant="contained"
                                target="_blank" 
                                href={`/reports/all/list${this.getSearchStr()}`}
                                className={classes.mr}>
                                <ExcelIcon /> 
                                    &nbsp;&nbsp;Export to Excel
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <EnhancedTableHead order={this.state.order} orderBy={this.state.orderBy} columnData={columnData} onRequestSort={this.handleRequestSort}/>
                            <TableBody>
                                {(rowsPerPage > 0 ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filtered).map(x => (
                                    <TableRow key={x.id} hover onClick={(e) => this.go(x)}>
                                        <CustomTableCell>{x.drainage}</CustomTableCell>
                                        <CustomTableCell>{x.fullsystemname}</CustomTableCell>
                                        <CustomTableCell>{x.status}</CustomTableCell>
                                        <CustomTableCell>{x.jurisdiction}</CustomTableCell>
                                        <CustomTableCell>{x.joints}</CustomTableCell>
					                    <CustomTableCell>{x.ditchtype}</CustomTableCell>
                                        <CustomTableCell>{x.year}</CustomTableCell>
                                        <CustomTableCell>{formateDate(x.rob)}</CustomTableCell>
                                        <CustomTableCell>{displayMiles(x)}</CustomTableCell>
                                        <CustomTableCell>{x.improvedates}</CustomTableCell>
                                        <CustomTableCell>{x.repairdates}</CustomTableCell>
                                        <CustomTableCell>{x.redeterbenefitacres}</CustomTableCell>
                                        <CustomTableCell>{formatMoney(x.origbenefitdollars)}</CustomTableCell>
                                        <CustomTableCell>{x.ifsnumber}</CustomTableCell>
                                        <CustomTableCell>{x.comments}</CustomTableCell>
					                    <CustomTableCell>{x.issue !== null ? x.issue ? "True": "False" : ""}</CustomTableCell>
                                        <CustomTableCell>{formatMoney(x.acctfundbalance)}</CustomTableCell>
                                        <CustomTableCell>{formateDate(x.updateacctbalance)}</CustomTableCell>
                                        <CustomTableCell>{x.r_5}</CustomTableCell>
                                        <CustomTableCell>{x.c_5}</CustomTableCell>
                                        <CustomTableCell>{x.v_5}</CustomTableCell>
                                        <CustomTableCell>{x.annual_years}</CustomTableCell>
                                        <CustomTableCell>{x.inspectyear}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs>
                        <Table>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={columnData.length}
                                        count={filtered.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                        rowsPerPageOptions={[100, { label: 'All', value: -1 }]}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                </Grid>    
            </AppContainer>
        )
    }
}

AllSystems = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        ...authActions
    }
)(AllSystems);

export default withStyles(styles)(withRouter(AllSystems));
