import React, { Component } from "react";
import { FormField } from "react-form";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        display: "flex"
    },
    inline: {
        display: "inline",
        cursor: "pointer"
    },
    icon: {
        "& > span:first-child": {
            //switchBase broken?
            height: "19px"
        }
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#f44336"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
        width: "100%",
        display: "inline"
    },
    formControlNoMargin: {
        marginTop: theme.spacing.unit * 2,
        width: "100%",
        display: "inline"
    }
});

class SwitchWrapper extends Component {
    handleChange = (event, value) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;
        setValue(value);

        if (eventHandle) eventHandle(value);
    };

    render() {
        const {
            fieldApi,
            label,
            classes,
            required,
            field,
            helperText,
            noMargin,
            labelNo,
            labelYes,
            ...rest
        } = this.props;

        const { getValue, getError } = fieldApi;

        const value = getValue() || "",
            error = getError();

        return (
            <FormControl
                component="fieldset"
                required={required}
                error={error}
                className={noMargin ? classes.formControlNoMargin : classes.formControl}>
                <FormLabel component="legend" classes={{ error: classes.error }}>
                    <Typography>{label}</Typography>
                </FormLabel>
                <Typography className={classes.inline} onClick={() => this.handleChange(null, false)}>
                    {labelNo ? labelNo : "No"}
                </Typography>
                <Switch
                    checked={value}
                    onChange={this.handleChange}
                    value={field}
                    color="primary"
                    classes={{
                        root: classes.icon
                    }}
                    {...rest}
                />
                <Typography className={classes.inline} onClick={() => this.handleChange(null, true)}>
                    {labelYes ? labelYes : "Yes"}
                </Typography>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppSwitch = FormField(SwitchWrapper);

export default withStyles(styles)(FppSwitch);
