import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { withStyles } from "@material-ui/core";

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import SnackBarError from '../common/SnackBarError';
import States from "../common/states";
import CheckBox from "../common/Checkbox";
import Select from '../common/Select';
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";
import { Vendor } from '../mypage/models'
import RecordTable from "../common/RecordTable";

const getVendors = Vendor.selectAll();

const styles = theme => ({
    space: {
        marginTop: 88
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    italic: {
        fontStyle: "italic"
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "number", numeric: true, label: "Vendor #", allowSort: true },
    { id: "name", numeric: false, label: "Company Name", allowSort: true },
    { id: "first_name", numeric: false, label: "First Name", allowSort: true },
    { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
    { id: "inactive_label", bool: true, label: "Inactive", allowSort: true },
    { id: "address", numeric: false, label: "Address", allowSort: true },
    { id: "city", numeric: false, label: "City", allowSort: true },
    { id: "state", numeric: false, label: "State", allowSort: true },
    { id: "zip", numeric: false, label: "Zip", allowSort: true },
    { id: "phone_work", numeric: false, label: "Work Phone", allowSort: true },
    { id: "phone_cell", numeric: false, label: "Cell Phone", allowSort: true },
    { id: "fax", numeric: false, label: "Fax", allowSort: true },
    { id: "email", numeric: false, label: "Email", allowSort: true },
    { id: "comments", numeric: false, label: "Comments", allowSort: true },
];

class ManageVendors extends Component {
    state = {
        active: {},
        deleteDialog: false,
        dialog: false, 
        id: null,
        snack: false,
        snackOpen: false
    };

    save = values => {
        const { ormVendorUpdatePromise, ormVendorCreate, saveBad, saveGood } = this.props;
        if(values.id){
            ormVendorUpdatePromise({
                ...values
            }).then(id => {
                this.setState({dialog: false});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormVendorCreate({
                ...values
            }).then(id => {
                this.setState({dialog: false});
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
    }

    validator = values => {
        const number = value => {
            if(value && value.length > 0){
                var re = /^\d+$/;
                return !re.test(value) ? "Enter Valid Number" : null
            }
            return null;
        }

        const validateEmail = value => {
            if (value) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return !re.test(value) ? "Invalid email address" : null;
            }
            return null;
        }

        const validatePhoneAndFax = value => {
            if(value){
                var re = /^\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/;
                return !re.test(value) ? "Invalid phone number format (10 numbers no slashes)" : null;
            }
            return null;
        }

        return {
            number: number(values.number),
            email: validateEmail(values.email),
            phone_work: validatePhoneAndFax(values.phone_work),
            phone_cell: validatePhoneAndFax(values.phone_cell),
            fax: validatePhoneAndFax(values.fax),
        };
    }

    addRecord = () => {
        this.setState({
            active: {},
            dialog: true
        });
    }

    editRecord = (values) => {
        this.setState({
            active: values,
            dialog: true
        });
    }

    deleteRecord = (id) => {
        const { vendors } = this.props;
        const index = vendors.findIndex(x => x.id === id);
        if(index > -1 && !vendors[index].can_delete){
            this.setState({vendorError: true});
            return;
        }

        this.setState({
            id: id,
            deleteDialog: true
        });
    }

    render() {
        const { classes, ormVendorDeletePromise, vendors, saveBad, saveGood } = this.props;
        const { 
            active,
            dialog, 
            deleteDialog, 
            id, 
            vendorError,
        } = this.state;

        return (
            <div className={classes.space}>
                <Typography variant="h5" className={classes.grow}>All Vendors/Contractors</Typography>
                <Typography variant="body1" className={classes.italic}>Use the table below, the filter tool or the map to identify and select an existing record or create a new vendor/contractor.</Typography>
                <Divider className={classes.mB}/>
                <RecordTable 
                    noScroll
                    addRecord={this.addRecord}
                    addName="Add Vendor/Contractor"
                    columnData={columnData}
                    creatable={true}
                    editable={true}
                    deletable={true}
                    data={vendors}
                    deleteRecord={this.deleteRecord}
                    editRecord={this.editRecord}
                />
                <Dialog open={dialog} classes={{ paper: classes.minWidth }} maxWidth="md" fullWidth>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Vendor
                        </Typography>
                        <IconButton onClick={() => this.setState({ dialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            validateError={this.validator}
                            defaultValues={active}
                            onSubmit={this.save}
                            getApi={el => (this.formApi = el)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField field="number" label="Vendor #" fullWidth required={true} />
                                            <TextField field="name" label="Company Name" fullWidth required={true} />                     
                                            <TextField field="first_name" label="First Name" fullWidth/>
                                            <TextField field="last_name" label="Last Name" fullWidth/>
                                            <TextField field="address" label="Address" fullWidth/>
                                            <TextField field="city" label="City" fullWidth/>
                                            <Select field="state" label="State" fullWidth options={States}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="zip" label="Zip" fullWidth/>
                                            <TextField field="phone_work" label="Work Phone" fullWidth/>
                                            <TextField field="phone_cell" label="Cell Phone" fullWidth/>
                                            <TextField field="fax" label="Fax" fullWidth/>
                                            <TextField field="email" label="Email" fullWidth/>
                                            <TextField field="comments" label="Comments" fullWidth/>
                                            <div style={{marginTop: 16}}>
                                                <CheckBox field="inactive" label="Inactive" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormVendorDeletePromise(id).then(id => {
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                        });
                        this.setState({ deleteDialog: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialog: false })}
                    open={deleteDialog}
                    title="Delete Vendor"
                    text={"Are you sure you wish to permanently delete?"}
                />
                <SnackBarError 
                    open={vendorError} 
                    close={e => this.setState({vendorError: false})} 
                    customMessage="There are invoices attached to this vendor and cannot be deleted" 
                />
            </div>
        );
    }
}

ManageVendors = connect(
    state => ({ 
        vendors: getVendors(state) 
    }),
    {
        ...Vendor.actions
    }
)(ManageVendors);

export default withStyles(styles)(withRouter(ManageVendors));

