import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import { withStyles } from "@material-ui/core";

import * as appActions from "../common/Navigation/actions";
import { reloadAll } from '../common/orm';

import Header from "./Header";
import NotReady from "./NotReady";

const styles = theme => ({
    appContainer: {
        // The parent container of the header, sidebar, content
        width: "100%",
        paddingTop: "8rem",
        //paddingTop: 80,
        height: "calc(100% - 8rem)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    publicContainer: {
        width: "100%",
        paddingTop: "4.5rem",
        height: "calc(100% - 4.5rem)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    adminContainer: {
        // The parent container of the header, sidebar, content
        width: "100%",
        //paddingTop: "8rem",
        paddingTop: 80,
        height: "calc(100% - 80px)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    mapContainer: {
        // The parent container of the header, sidebar, content
        width: "100%",
        //paddingTop: "8rem",
        paddingTop: 98,
        height: "calc(100% - 98px)"
    },
    contentContainer: {
        // The sibling container to the header, sidebar that contains the main content
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form div:first-child": {
            width: "calc(100% - 40px)",
            //marginTop: -12, // Assuming spacing = 24 on parent grid container
            marginLeft: 10, //20
            marginRight: 10, //20
            marginBottom: 32
        }
    },
    noPaddingContainer: {
        // The sibling container to the header, sidebar that contains the main content
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form div:first-child": {
            //marginTop: -12, // Assuming spacing = 24 on parent grid container
        }
    },
    contentContainerOpen: {
        [theme.breakpoints.up("lg")]: {
            //paddingLeft: 325 // 325 = Width of Sidebar
        },
        [theme.breakpoints.down("md")]: {
            //paddingLeft: 275 // 325 = Width of Sidebar
        }
    },
    contentContainerClosed: {
        //paddingLeft: 44 // Width of Closed Sidebar
    },
    height: {
        height: "100%"
    },
    overflowX: {
        "& > div:first-child": {
            overflowX: "hidden !important"
        }
    },
    noScrollParent: {
        overflowX: "hidden !important",
        width: "100%",
        //paddingTop: "8rem",
        paddingTop: 80,
        height: "calc(100% - 80px)" 
    },
    noScrollContent: {
        overflow: "auto"
    }
});

class AppContainer extends Component {
    renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: "#e1d4d8",
            zIndex: "9999"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }
    renderThumb2({ style, ...props }) {
        const thumbStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...thumbStyle }} />;
    }

    componentDidUpdate = () => {
        const { passWordPage, portal, mounted, synced, noRedirect } = this.props;
        if(!passWordPage && !portal && synced && synced.ready && mounted && !mounted.mounted){
            this.props.appMounted(true);

            if(this.props.match.path !== "/" && !noRedirect){
                this.props.history.push("/map");
            }

            // If auth token is in redux 
            if(this.props.match.path === "/map"){
                this.props.reloadAll();
            }

        } else if(portal && synced && synced.ready && mounted && !mounted.mounted){
            //handles case starting on public and going to authenitcated portion
            this.props.appMounted(true);
        }
    }

    render() {
        const { 
            children, 
            classes, 
            authenticated, 
            onlyAdmin, 
            adminPage, 
            navState, 
            noPadding, 
            mapPage, 
            authState, 
            mounted,
            noScroll,
            hideToolbar
        } = this.props;

        //if portal is true it's the public portal page else redirect user to dashboards once synced
        /*if(!portal && synced && !mounted){
            this.props.appMounted(true);
            this.props.history.push("/map");
        }*/




        if(noScroll){
            return (
                <div className={classes.noScrollParent}>
                    <Header authenticated hideToolbar={hideToolbar} />
                    <div
                        className={classNames(
                            classes.noScrollContent,
                            noPadding ? classes.noPaddingContainer : classes.contentContainer,
                            classes.height,
                            navState.navigationOpen ? classes.contentContainerOpen : classes.contentContainerClosed
                        )}>
                        {authState && authState.user ? (
                            mounted && mounted.mounted? (
                                (onlyAdmin && authState.user.role !== "Admin" ? <Redirect to="/" /> : children)
                            ) : (
                                <NotReady message="Loading..." />
                            )
                        ) : (
                            <NotReady storeReady={navState.storeReady} />
                        )}
                    </div>
                </div>
            );
        }

        const toHome = false

        return authenticated ? ( // Logged In
            <Scrollbars
                renderThumbVertical={this.renderThumb}
                renderThumbHorizontal={this.renderThumb2}
                className={classes.overflowX}>
                <div className={adminPage ? classes.adminContainer : mapPage? classes.mapContainer: classes.appContainer}>
                    <Header authenticated hideToolbar={hideToolbar} />
                    <div
                        className={classNames(
                            noPadding ? classes.noPaddingContainer : classes.contentContainer,
                            classes.height,
                            navState.navigationOpen ? classes.contentContainerOpen : classes.contentContainerClosed
                        )}>
                        {authState && authState.user ? (
                            mounted && mounted.mounted? (
                                (onlyAdmin && authState.user.role !== "Admin" && toHome ? <Redirect to="/" /> : children)
                            ) : (
                                <NotReady message="Loading..." />
                            )
                        ) : (
                            <NotReady storeReady={navState.storeReady} />
                        )}
                    </div>
                </div>
            </Scrollbars>
        ) : (
            // Public
            <Scrollbars
                renderThumbVertical={this.renderThumb}
                renderThumbHorizontal={this.renderThumb2}
                className={classes.overflowX}>
                <div className={classes.publicContainer}>
                    <Header hideToolbar={hideToolbar} />
                    <div className={classNames(
                        classes.contentContainer,
                        classes.height
                    )}>
                        {children}
                    </div>
                </div>
            </Scrollbars>
        );
    }
}

AppContainer = connect(state => (
    {
        navState: state.nav,
        authState: state.auth,
        synced: state.sync,
        mounted: state.app
    }
),  
    {
        ...appActions,
        reloadAll
    }
)(AppContainer);

export default withStyles(styles)(withRouter(AppContainer));
