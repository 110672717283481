import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { getValue } from "../../api/utils";

import PageHeader from '../common/PageHeader';
import { createSelector } from '../common/orm';
import RecordTable from "../common/RecordTable";
import CorrespondenceForm from './CorrespondenceForm';

const getCorrespondence = createSelector(
    (state, ownProps) => ownProps.repair.id,
    (session, repair) => {
        return session.Correspondence.filter(c => c.repair === repair).orderBy('date', 'desc').toModelArray();
    }
);

const styles = theme => ({
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
});

const correspondenceColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "user_label", numeric: false, label: "Username", allowSort: true, skipLabelReplace: true },
    {id: "recipient_label", numeric: false, label: "Recipient", allowSort: true, skipLabelReplace: true },
    {id: "date", numeric: false, label: "Date", allowSort: true },
    {id: "email_label", numeric: false, label: "Email Sent", allowSort: true, skipLabelReplace: true },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
];



class Correspondence extends Component {
    state = {
        correspondenceDialog: false,
        id: null
    }

    canEdit = (l) => {
        const { authState } = this.props;
        const username = getValue(authState, "user.username");
        const userID = getValue(authState, "user.id");

        return l.ref.user === userID || l.modified_user === username;
    }

    render(){
        const { 
            authState,
            classes, 
            repair, 
            system,
            correspondences,
            saveGood,
            saveBad 
        } = this.props;
        const { 
            correspondenceDialog,
            id
        } = this.state;

        const role = getValue(authState, "user.role");

        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <div style={{marginTop: 15}}>
                    <RecordTable 
                        noScroll
                        creatable={true}
                        viewOnly={false} 
                        columnData={correspondenceColumnData}
                        data={correspondences}
                        addName="Add Correspondence"
                        addRecord={() => this.setState({ correspondenceDialog: true, id: null })}
                        editRecord={(values) => this.setState({ correspondenceDialog: true, id: values.id })}
                        style={{marginTop: 15}}
                        hasCustomPermissions={true}
                        customPermissions={{
                            Contractor: {
                                canEdit: this.canEdit,
                                canDelete: (l) => false,
                                canView: (l) => false
                            },
                            Admin: {
                                canEdit: (l) => true,
                                canDelete: (l) => true,
                                canView: (l) => false
                            },
                            User: {
                                canEdit: (l) => true,
                                canDelete: (l) => true,
                                canView: (l) => false
                            },
                            "View Only": {
                                canEdit: (l) => false,
                                canDelete: (l) => false,
                                canView: (l) => false
                            }
                        }}
                        role={role}
                    />
                </div>
                <CorrespondenceForm
                    open={correspondenceDialog}
                    onClose={() => this.setState({ correspondenceDialog: false })}
                    edit={true}
                    selected={id}
                    repair={repair}
                    saveGood={saveGood} 
                    saveBad={saveBad} 
                />
            </div>
        )
    }
}

Correspondence = connect(
    (state, ownProps) => ({
        authState: state.auth,
        correspondences: getCorrespondence(state, ownProps),
    }),
    {
        ...Correspondence.actions,
    }
)(Correspondence);

export default withStyles(styles)(withRouter(Correspondence));
