import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { MAKE_OPTIONS_VID } from "../../api/constants";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import UpdateIcon from '@material-ui/icons/Update';
import { withStyles } from "@material-ui/core";

import Close from "@material-ui/icons/Close";
import CustomTableCell from '../common/TableCell';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanel from "../common/ExpansionPanel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Select from '../common/Select';
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";
import States from '../common/states';
import { User } from '../mypage/models'
import { createSelector } from "../common/orm";

const getUsers = User.selectAll();
const getVendors = createSelector(schema => {
    return schema.Vendor.all().orderBy('name').toModelArray();
});

const styles = theme => ({
    minWidth: {
        minWidth: 400
    },
    noOverFlow: {
        overflowX: "auto"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0"
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    space: {
        marginTop: 88
    },
    italic: {
        fontStyle: "italic"
    }
});

const roles = [
    {label: "Contractor", value: "Contractor"},
    {label: "User", value: "User"},
    {label: "View Only", value: "View Only"},
    {label: "Admin", value: "Admin"}
];

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: true, label: "Name", allowSort: true, required: true },
    { id: "title", numeric: false, label: "Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "phone_office", numeric: false, label: "Office Phone", allowSort: true },
    { id: "role", numeric: false, label: "Role", allowSort: true, required: true, options: roles },
];

const restData = [
    {id: "vendor", numeric: false, label: "Vendor", allowSort: true, required: true, options: [] },
    {id: "city", label: "City"},
    {id: "email", label: "Email", required: true},
    {id: "username", label: "Username", required: true},
    {id: "phone_cell", label: "Mobile Phone"},
    {id: "password", label: "Password", required: true},
    {id: "password2", label: "Retype Password", required: true},
    {id: "state", label: "State", options: States},
    {id: "zip", label: "Zip"},
];

class ManageUsers extends Component {
    state = {
        activeLayer: { on_by_default: true },
        baseMap: false,
        deleteDialogOpen: false,
        editUser: false,
        layerDialogOpen: false,
        order: "asc",
        orderBy: "",
        passwordDialogOpen: false,
        showLayerType: null
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.props["users"].sort((a,b) => {
            var aField = a[orderBy];
            var bField = b[orderBy];
    
            if(aField === null){
                return 1;
            } else if(bField === null){
                return -1;
            } else  if(aField === bField){
                return 0;
            }
    
            if (order === "desc"){
                return (bField.toUpperCase() < aField.toUpperCase() ? -1 : 1);
            } else {
                return (aField.toUpperCase() < bField.toUpperCase() ? -1 : 1);
            }
        });
        this.setState({ order, orderBy });
    };

    errorValidatorLayer = values => {
        const { users } = this.props;
        const {editUser} = this.state;

        var username_test = -1;
        if(editUser){
            username_test = users.findIndex(x => x.username === values.username && x.id !== values.id);
        } else {
            username_test = users.findIndex(x => x.username === values.username);
        }

        
        const validateRole = role => {
            return !role ? "User Role is required" : null;
        }

        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };

        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };

        if(editUser){
            return {
                username: username_test > -1 ? "Username taken" : null,
                email: validateEmail(values.email),
                role: validateRole(values.role),
                vendor: values.role === "Contractor" && !!values.vender ? "Required" : null
            }
        } else {
            return {
                username: username_test > -1 ? "Username taken" : null,
                email: validateEmail(values.email),
                password: validatePassword(values.password, values.password2),
                password2: validatePassword(values.password, values.password2),
                role: validateRole(values.role),
                vendor: values.role === "Contractor" && !!values.vender ? "Required" : null
            }
        }
        
    }

    passwordValidator = values => {
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2)
        };
    };

    userForm = values => {
        const{ ormUserCreate, ormUserUpdatePromise, saveBad, saveGood } = this.props
        const { editUser } = this.state;
        
        // if edit remove passwordkey 
        if(editUser){
            delete values["password"];
        }
        if(values.id){
            ormUserUpdatePromise({
                id: values.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        } else {
            ormUserCreate({
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
            });
        }
        this.setState({ editUser: false, layerDialogOpen: false });
    };

    passwordUpdate = (values, activeLayer) => {
        const{ ormUserUpdatePromise, saveBad, saveGood } = this.props

        console.log("password upate", values, activeLayer);
        ormUserUpdatePromise({
            id: activeLayer.id,
            password: values.password
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });

        this.setState({passwordDialogOpen: false });
    };

    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    filter = (data) => {  
        if(this.filters && this.filters.column){
            return data.filter(x => {
                var column = this.filters.column;
                var filter = this.filters.filter
                if(filter.length > 0){
                    if(this.filters.filter && (String(x[column]) || "").toUpperCase().includes((filter || "").toUpperCase()))
                        return true;
                    else 
                        return false;
                } else {
                    if(x[this.filters.column] && x[this.filters.column].length !== 0)
                        return true;
                    else 
                        return false;
                }
            });
        } else {
            return data;
        }
    }

    render() {
        const { 
            classes, 
            ormUserDeletePromise, 
            users, 
            vendors,
            saveBad, 
            saveGood 
        } = this.props;
        const { 
            activeLayer, 
            deleteDialogOpen, 
            editUser, 
            layerDialogOpen, 
            passwordDialogOpen, 
            order, 
            orderBy
        } = this.state;

        return (
            <div className={classes.space}>
                <Grid container>
                    <Grid container spacing={3} className={classes.mB}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.grow}>All Users</Typography>
                            <Typography variant="body1" className={classes.italic}>Use the table below, the filter tool or the map to identify and select an existing user or create a new user.</Typography>
                            <Divider className={classes.mB}/>
                            <ExpansionPanel onRef={el => (this.filters = el)} dummyState={this.dummyState} columnData={columnData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                onClick={() => this.setState({editUser: false, layerDialogOpen: true, activeLayer:{role: "User"}})}
                                variant="contained"
                                color="primary"
                                fullWidth>
                                <AddCircleOutlineIcon />
                                &nbsp;&nbsp;&nbsp;Add User</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: 20}} className={classes.noOverFlow}>
                    <Table className={classes.minWidth}>
                            <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                            <TableBody>
                                {this.filter(users).map((l, index) => {
                                    return (
                                        <TableRow hover key={"user_" + index}>
                                            <CustomTableCell className={classes.noWrap}>
                                                <Tooltip title="Edit">
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({editUser: true, layerDialogOpen: true, activeLayer: l, showLayerType: l.layer_type })
                                                        }
                                                        className={classes.deleteWidth}>
                                                        <EditIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <Button
                                                        onClick={() => this.setState({ deleteDialogOpen: true, activeLayer: l })}
                                                        className={classes.deleteWidth}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Change Password">
                                                    <Button
                                                        onClick={() => this.setState({ passwordDialogOpen: true, activeLayer: l })}
                                                        className={classes.deleteWidth}>
                                                        <UpdateIcon />
                                                    </Button>
                                                </Tooltip>
                                                
                                            </CustomTableCell>
                                            <CustomTableCell>{l.name ? l.name : "-"}</CustomTableCell>
                                            <CustomTableCell>{l.title ? l.title : "-"}</CustomTableCell>
                                            <CustomTableCell>{l.organization ? l.organization : "-"}</CustomTableCell>
                                            <CustomTableCell>{l.phone_office ? l.phone_office : "-"}</CustomTableCell>
                                            <CustomTableCell>{l.role ? l.role : "-"}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {users.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                            No Map Layers Defined
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog open={layerDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            User
                        </Typography>
                        <IconButton onClick={() => this.setState({ layerDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidatorLayer}
                            defaultValues={activeLayer}
                            onSubmit={values => this.userForm(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {[...(columnData.slice(1)), ...restData].map(x => {
                                                    if(x.options){
                                                        if(x.id === "vendor" && formApi.values.role && formApi.values.role === "Contractor"){
                                                            return <Select 
                                                                required={x.required}
                                                                fullWidth
                                                                options={MAKE_OPTIONS_VID(vendors, "name")}
                                                                key={x.id}
                                                                field={x.id}
                                                                label={x.label} />;
                                                        } else if(x.id !== "vendor"){
                                                            return <Select
                                                                required={x.required}
                                                                fullWidth
                                                                options={x.options}
                                                                key={x.id}
                                                                field={x.id}
                                                                label={x.label} />;
                                                        }
                                                    } else {
                                                        if(editUser){
                                                            if(x.id !== "password" && x.id !== "password2"){
                                                                return <TextField field={x.id} fullWidth required={x.required} key={x.id} label={x.label} />;
                                                            }
                                                        } else {
                                                            const temp = x.id.indexOf("password") !== -1 ? "password" : "";
                                                            const autocomplete = temp && temp === "password" ? "new-password" : "";
                                                            return <TextField field={x.id} autoOff={autocomplete} fullWidth required={x.required} key={x.id} type={temp} label={x.label} />;
                                                        }
                                                    }
                                                    return null;
                                                })
                                            }
                                        <Grid item xs={12} className={classes.centerAlign}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={passwordDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            Update Password
                        </Typography>
                        <IconButton onClick={() => this.setState({ passwordDialogOpen: false, editUser: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <Form
                            id="updatePasswordForm"
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.passwordValidator}
                            onSubmit={values => this.passwordUpdate(values, activeLayer)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="password" autoOff={true} fullWidth required={true} type="password" label="Password" />
                                            <TextField field="password2" autoOff={true} fullWidth required={true} type="password" label="Retype New Password" />
                                        <Grid item xs={12} className={classes.centerAlign}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormUserDeletePromise(activeLayer.id).then(id => {
                            this.setState({ deleteDialogOpen: false });
                            if(!id){
                                saveBad();
                            } else {
                                saveGood();
                            }
                        });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete User"
                    text={"Are you sure you wish to permanently delete " + activeLayer.name + "?"}
                />
            </div>
        );
    }
}

ManageUsers = connect(
    state => ({ 
        users: getUsers(state),
        vendors: getVendors(state)
    }),
    User.actions
)(ManageUsers);

export default withStyles(styles)(withRouter(ManageUsers));
