import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { withStyles, Grid, TableBody, Table, TableRow, Tooltip, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { connect } from "react-redux";

import TextField from "../common/TextField";
import DatePicker from "..//common/DatePicker";
import HelpLabel from "../common/HelpLabel";
import FileFormInput from "../common/FileFormInput";
import Checkbox from "../common/Checkbox";
import Select from "../common/Select";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import { Violationstatus, Inspectiontype } from "../common/SelectOptions";
import {MAKE_OPTIONS} from "../../api/constants";
import { Inspectionviolation, PhotoviolationInspection, Violation } from "../mypage/models";
import { createSelector } from '../common/orm';
import { getToday } from '../../api/utils';
import { panelChange } from "../common/actions";

const getInspections = createSelector(
    (state, ownProps) => ownProps.violation,
    (session, violation) => {
        return session.Inspectionviolation.filter(x => x.violation === violation.id).toRefArray();
    }
);

const getPhotos = createSelector(
    (state, ownProps) => ownProps.violation,
    (session, violation) => {
        return session.PhotoviolationInspection.filter(x => x.violation === violation.id).toRefArray();
    }
);

const styles = theme => ({
    table: {
        minWidth: 500,
        marginTop: 10
    },
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    date: {
        minWidth: 200
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        grow: 1,
        display: "inline-flex",
    },
    right: {
        float: "right"
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
});

const inspectionColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "inspectiondate", numeric: false, label: "Date", allowSort: true, type: "date"},
    {id: "modified_user", numeric: false, label: "Inspector", allowSort: true, type: "text" },
    {id: "status", numeric: false, label: "Status", allowSort: true, type: "select" },
    {id: "inspection_type", numeric: false, label: "Inspection Type", allowSort: true, type: "select"},
    {id: "notes", numeric: false, label: "Notes", allowSort: true, type: "text" },
];

const inspectionPhoto = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "date", numeric: false, label: "Date", allowSort: true, type: "date"},
    {id: "caption", numeric: false, label: "Caption", allowSort: true, type: "date"},
    {id: "notes", numeric: false, label: "Notes", allowSort: true, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: true, type: "text" },
];

class Inpsections extends Component {
    state = {
        confirmUpload: false, 
        loading: false,
        inpsectionDelete: false,
        photoDelete: false,
        inspectionID: null,
        photoID: null,
        order: "asc",
        orderBy: "",
        activeId: null,
        activePhoto: null,
        inspectionDialog: false,
        photoDialog: false,
        selectedInspection: null,
        calculated: ""
    }
    Formref = React.createRef();

    componentWillUnmount = () => {
        const { violation, ormViolationUpdateLocalOnly } = this.props;
        const state = this.Formref.current.store.getState();
        ormViolationUpdateLocalOnly({
            ...violation,
            ...state.values
        });
    }

    saveInspection = values => {
        const { ormInspectionviolationUpdatePromise, saveBad, saveGood } = this.props;
        ormInspectionviolationUpdatePromise({
            ...values
        }).then(id => {
            if(id){
                saveGood();
                this.setState({inspectionDialog: false, selectedInspection: values.id});
            } else {
                saveBad();
            }
        });

        this.props.updateViolation();
    }

    createInspection = values => {
        const { authState, ormInspectionviolationCreate, violation, saveBad } = this.props;

        var once = ormInspectionviolationCreate({
            drainage_id: violation.drainage,
            violation_id: violation.id,
            modified_user: authState.user.username
        });

        once.then(id => {
            if(id){
                this.setState({activeId: id, inspectionDialog: true});
                this.props.updateViolation();
            } else {
                saveBad();
            }
        });
    }

    deleteInspection(id) {
        const { ormInspectionviolationDeletePromise, saveBad, saveGood } = this.props;
        ormInspectionviolationDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
        this.props.updateViolation();
    }

    handleFiles = (val, formApi) => {
        const { fileSize } = this.props;
        this.setState({ files: val });

        if(val !== null){
            const file_list = [...val];
            var total_size = 0;

            file_list.forEach(x => {
                total_size += x.size;
            });

            total_size = total_size / 1000000;

            if(total_size > 250){
                formApi.setValue("files", null);
                this.setState({confirmUpload: false});
                fileSize();
            } else if(total_size > 100){
                this.setState({confirmUpload: true, calculated: `${Math.round(total_size / 40)} Minutes`});
            } else {
                this.setState({confirmUpload: false});
                formApi.setValue("lock_submit", false);
            }
        } else {
            this.setState({confirmUpload: false});
            formApi.setValue("lock_submit", false);
        }
    }

    uploadPhotos = values => {
        const { ormPhotoviolationInspectionCreateForm, violation, saveBad, saveGood } = this.props;
        const { activeId, files } = this.state;

        const files_list = [...files];
        var count = 0;

        this.setState({loading: true});

        files_list.forEach(x => {
            var form_data = new FormData();
            form_data.append('inspection_id', activeId);
            form_data.append('drainage_id', violation.drainage);
            form_data.append('violation_id', violation.id);
            form_data.append('uploaddate', getToday());
            form_data.append('link', x, x.name);

            ormPhotoviolationInspectionCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                }

                count += 1;
                if(count === files_list.length){
                    this.setState({loading: false});
                    saveGood();
                }
            })
        });
        this.props.updateViolation();
    }

    deletePhoto = id => {
        const { ormPhotoviolationInspectionDeletePromise, saveBad, saveGood } = this.props;
        ormPhotoviolationInspectionDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
        this.props.updateViolation();
    }

    savePhoto = values => {
        const { ormPhotoviolationInspectionUpdatePromise, saveBad, saveGood } = this.props;
        delete values.link;
        ormPhotoviolationInspectionUpdatePromise({
            ...values
        }).then(id => {
            this.setState({photoDialog: false});
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.updateViolation();
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props["inspections"].sort((a,b) => {
            var aField = a[orderBy];
            var bField = b[orderBy];

            if(aField === null){
                return 1;
            } else if(bField === null){
                return -1;
            } else  if(aField === bField){
                return 0;
            }

            if (order === "desc"){
                return (bField.toUpperCase() < aField.toUpperCase() ? -1 : 1);
            } else {
                return (aField.toUpperCase() < bField.toUpperCase() ? -1 : 1);
            }
        });
        this.setState({ order, orderBy });
    };

    saveViolation = values => {
        const { violation, ormViolationUpdatePromise, saveBad, saveGood } = this.props;
        ormViolationUpdatePromise({
            ...violation,
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        })
    }
    
    errorValidator = values => {
        const checkIfEmpty = value => {
            if (!value) {
                return "Required";
            }
            return null;
        }

        return {
            files: checkIfEmpty(values.files)
        };
    }

    getDefaultValues = (violation) => {
        var defaultValues = {};
        defaultValues.inspectiondone = violation.inspectiondone;
        defaultValues.next_compliance_inspection = violation.next_compliance_inspection;
        return defaultValues;
    }

    render(){
        const { authState, classes, system, violation, inspections, photos } = this.props;
        const { 
            activeId, 
            activePhoto, 
            inpsectionDelete, 
            photoDelete, 
            inspectionDialog, 
            inspectionID,
            photoID,
            order, 
            orderBy, 
            photoDialog, 
            selectedInspection, 
            rowSelected,
            confirmUpload,
            loading,
            calculated
        } = this.state;

        const activeInspection = inspections.find(x => x.id === activeId);

        const viewOnly = authState.user.role === "View Only";

        //if it doesn't exist the delete button was clicked
        const inspectionPhotos = inspections.find(x => x.id === selectedInspection) ? photos.filter(x => x.inspection === selectedInspection) : [];

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Violation #: " + violation.vnum} />
                <Typography variant="h5" className={classes.grow}>Inspection Log</Typography>
                <Divider className={classes.mb}/>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Form 
                            ref={this.Formref}
                            getApi={el => (this.formApi = el)}
                            id="violationinspectiondone"
                            defaultValues={this.getDefaultValues(violation)}
                            onSubmit={this.saveViolation}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Checkbox label="No Further Compliance Inspections Needed" field="inspectiondone" labelPlacement="end" disabled={viewOnly}/>
                                    <DatePicker label="Next Inspection Date" field="next_compliance_inspection" disabled={viewOnly}/>
                                    {!viewOnly && (
                                        <Button
                                            style={{marginTop: 5}}
                                            type="submit" 
                                            variant="contained" 
                                            color="primary" 
                                            ><SaveIcon />&nbsp;&nbsp;Save</Button>
                                    )}
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.mb}/>
                        <Button
                            onClick={this.createInspection}
                            disabled={viewOnly}
                            variant="contained" 
                            color="primary" 
                            fullWidth
                            >
                            <AddCircleOutlineIcon /> 
                            &nbsp;&nbsp;Add
                        </Button>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                columnData={inspectionColumnData}
                            />
                            <TableBody>
                                {inspections.map( (n, index) => { return (
                                    <TableRow
                                        selected={index === rowSelected} 
                                        hover 
                                        key={n.id} 
                                        onClick={() => {this.setState({selectedInspection: n.id, rowSelected: index})}}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            {!viewOnly && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={() => {this.setState({activeId: n.id, inspectionDialog: true})}} 
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({ inpsectionDelete: true, inspectionID: n.id })}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <DeleteIcon /> 
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.inspectiondate}</CustomTableCell>
                                        <CustomTableCell>{n.modified_user}</CustomTableCell>
                                        <CustomTableCell>{n.status}</CustomTableCell>
                                        <CustomTableCell>{n.inspection_type}</CustomTableCell>
                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {inspections.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={inspectionColumnData.length} className={classes.centerAlign}>
                                            No Inspections
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Typography variant="h5" className={classes.grow}>Photo List For Selected Inspection</Typography>
                <Divider className={classes.mb}/>
                <Grid container spacing={3} style={{marginTop: 20}}>
                    <Grid item xs>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={inspectionPhoto}
                            />
                            <TableBody>
                                {inspectionPhotos.map( n => { return (
                                    
                                    <TableRow hover key={n.id}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            {!viewOnly && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={() => this.setState({ activePhoto: n, photoDialog: n })}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({ photoDelete: true, photoID: n.id })}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <DeleteIcon /> 
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.uploaddate}</CustomTableCell>
                                        <CustomTableCell>{n.caption}</CustomTableCell>
                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                        <CustomTableCell>
                                            <Tooltip title="View Attachment">
                                                <a target="_blank" rel="noopener noreferrer" href={n.link} className={classes.linkColor}>
                                                    Open File
                                                </a>
                                            </Tooltip>
                                        </CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {inspectionPhotos.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={inspectionPhoto.length} className={classes.centerAlign}>
                                            No Inspection Photos
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog open={inspectionDialog} maxWidth="md" fullWidth> 
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Inspection</Typography>
                        <IconButton disabled={loading} aria-label="close" className={classes.right} onClick={() => {this.setState({inspectionDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            defaultValues={activeInspection}
                            onSubmit={this.saveInspection}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField field="modified_user" label="Inspector" fullWidth></TextField>
                                        <DatePicker className={classes.date} field="inspectiondate" label="Date" autoPopulate={true} />
                                        <Select field="status" label="Status" options={MAKE_OPTIONS(Violationstatus)} fullWidth/>
                                        <Select field="inspection_type" label="Inspection Type" options={MAKE_OPTIONS(Inspectiontype)} fullWidth/>
                                        <TextField field="notes" label="Inspection Notes" fullWidth></TextField>
                                        <Button disabled={loading} type="submit" variant="contained" color="primary">Save</Button>
                                        &nbsp;
                                    </form>
                                )}
                        </Form>
                        <div style={{marginTop: 20}}>
                            <Divider />
                            <Form 
                                id="photos"
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                defaultValues={{lock_submit: false}}
                                onSubmit={this.uploadPhotos}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <FileFormInput
                                                disabled={loading}
                                                eventHandle={val => this.handleFiles(val, formApi)}
                                                fullWidth 
                                                label="Upload Files"
                                                id="photoUpload"
                                                field="files"
                                                accept="image/*"
                                                multiFile={true} 
                                            />
                                            {confirmUpload && (
                                                <Subquestion
                                                    component={
                                                        <RadioGroup
                                                            field="lock_submit"
                                                            name="lock_submit"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                    title={true}
                                                                    showLabel={true}
                                                                    helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                                />
                                                            }
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "true" },
                                                                { label: "No", value: "false" }
                                                            ]}
                                                            disabled={loading}
                                                            alignment={true}></RadioGroup>
                                                    }
                                                />
                                            )}
                                            <br />
                                            <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                                Upload Files
                                                {loading && <CircularProgress size={24} className={classes.progress} />}
                                            </Button>
                                        </form>
                                    )}
                            </Form>
                        </div>
                    
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.deleteInspection(inspectionID);
                        this.setState({ inpsectionDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            inpsectionDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={inpsectionDelete}
                    title={"Delete Inspection"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.deletePhoto(photoID    );
                        this.setState({ photoDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            photoDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={photoDelete}
                    title={"Delete Photo"}
                    text={"Are you sure you wish to delete?"}
                />
                <Dialog open={photoDialog}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Photo</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({photoDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            id="photoedit"
                            defaultValues={activePhoto}
                            onSubmit={this.savePhoto}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <TextField 
                                        field="caption" 
                                        label="Caption" 
                                        fullWidth
                                    />
                                    <DatePicker 
                                        className={classes.date} 
                                        field="uploaddate" 
                                        label="Date" 
                                        autoPopulate={true} 
                                        fullWidth
                                    />
                                    <TextField 
                                        field="notes" 
                                        label="Notes" 
                                        fullWidth
                                    />      
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
            </Grid>
        );
    }
}


Inpsections = connect(
    (state, ownProps) => ({
        authState: state.auth,
        inspections: getInspections(state, ownProps),
        photos: getPhotos(state, ownProps)
    }),
    {
        ...Violation.actions,
        ...Inspectionviolation.actions,
        ...PhotoviolationInspection.actions,
        panelChange
    }
)(Inpsections);


export default withStyles(styles)(withRouter(Inpsections));