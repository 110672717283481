import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import logo from "./images/drainagedbBanner.png";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    appLogo: {
        '@media(max-width: 500px)': {
            width: 150,
            paddingTop: 15
        }
    },
    zIndex: {
        zIndex: 1300,
        backgroundColor: "#3454ae",
    },
});

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        fetch("/error/post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                error: error.stack,
                error_info: errorInfo
            })
        })
    }
    render() {
        const { classes } = this.props;

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <AppBar position="fixed" className={classes.zIndex}>
                        <Toolbar>
                            <img src={logo} alt="DrainageDB" className={classes.appLogo} />
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={3} style={{marginLeft:24,marginTop:80}}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                You have encountered an unhandled error!
                            </Typography>
                            <Typography gutterBottom>
                                The error has been logged. Any details you can provide to Houston Engineering about what you were doing prior to this message will be helpful.
                            </Typography>
                            <Typography>
                                <a
                                    rel="noopener noreferrer"
                                    href="/">
                                    Return to Application
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
        return this.props.children;
    }
}
export default withStyles(styles)(ErrorBoundary);