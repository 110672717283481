import React, { Component } from "react";
import { FormField } from "react-form";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core";
const styles = theme => ({
    input: {
        display: "none"
    },
    inline: {
        display: "inline",
        marginLeft: 16
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
    red: {
        color: "red"
    }
});
// See https://react-form.js.org/#/custom-input
class FileInputWrapper extends Component {
    constructor(props, context) {
        super(props, context);
        // This logic is probably unused in ms4front
        var displayLabel = null;
        var val = this.props.fieldApi.getValue();
        if (val) displayLabel = val.replace("/api/db/proposals/", "/media/");
        this.state = {
            displayLabel: displayLabel,
            useUrl: val ? true : false
        };
    }
    getBase64 = file => {
        const { multiFile } = this.props;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            //const reader = new FileReaderS();
            reader.readAsDataURL(file);
            if(multiFile){
                reader.onload = () => resolve({data: reader.result, name: file.name});
            } else {
                reader.onload = () => resolve(reader.result);
            }
            reader.onerror = error => {
                console.log(error)
                reject(error)
            };
        });
    };
    handleChange = e => {
        let value;
        const { multiFile } = this.props;
        if(multiFile){
            value = e.target.files;
        } else {
            value = e.target.files[0];
        }
        if (!value) return;
        const { fieldApi, eventHandle, withName } = this.props;
        const { setValue } = fieldApi;
        if(multiFile){
            this.setState({ displayLabel: e.target.files.length + " Files", useUrl: false });
        } else {
            this.setState({ displayLabel: value.name, useUrl: false });
        }

        if(multiFile){
            var getArray = Array.from(value).map(x => {
                return this.getBase64(x);
            });

            Promise.all(getArray).then(y => {
                console.log("promises", y);
                setValue(y);
                if (eventHandle) {
                    eventHandle(y);
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            this.getBase64(value).then(data => {
                if(withName){
                    setValue({data: data, name: value.name});
                } else {
                    setValue(data);
                }
                if (eventHandle) {
                    eventHandle(data);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    delete = () => {
        const { id, fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;
        this.setState({displayLabel: null, useUrl: false});

        // If same file is upload after delete onChange won't fire 
        const temp = document.getElementById(id);
        if(temp){
            temp.value = "";
        }

        setValue(null);
        if (eventHandle) {
            eventHandle(null);
        }
    };

    render() {
        const { canDelete, classes, label, children, id, fieldApi, multiFile, view, ...rest } = this.props;
        const { displayLabel, useUrl } = this.state;
        const { getError, getWarning, getSuccess } = fieldApi;
        console.log(!useUrl && canDelete);
        const error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success;

        const noView = view ? true : false;
        return (
            <>
                <div style={{ display: "inline" }}>
                    <Typography gutterBottom>{label || children}</Typography>
                    <input className={classes.input} id={id} type="file" onChange={this.handleChange} {...rest} multiple={multiFile}/>
                    <label htmlFor={id}>
                        <Button raised component="span" variant="contained" className={hasError && !displayLabel && classes.red}>
                            Select File
                        </Button>
                    </label>
                    <Typography className={classes.inline}>
                        {useUrl && (
                            <div style={{display: "inline"}}>
                                <a className={classes.linkColor} href={displayLabel} target="_blank" rel="noopener noreferrer">
                                    View File
                                </a>
                                {noView && (
                                    <div style={{marginLeft: 8, display: "inline"}}>
                                        <Tooltip title="Delete Attachment" placement="right">
                                            <Fab onClick={this.delete} color="secondary" size="medium">
                                                <DeleteIcon />
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}
                        {!useUrl && (
                            <div style={{display: "inline"}}>
                            <span>
                                {displayLabel}
                            </span>
                            {displayLabel && canDelete && (
                                <>
                                    {noView && (
                                        <div style={{marginLeft: 8, display: "inline"}}>
                                            <Tooltip title="Delete Attachment" placement="right">
                                                <Fab onClick={this.delete} color="secondary" size="medium">
                                                    <DeleteIcon />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    )}
                                </>
                            )}
                            </div>
                        )}
                    </Typography>
                </div>
                {hasError && !displayLabel && (
                    <FormHelperText style={{ display: "inline" }} error={true}>
                        {errorText}
                    </FormHelperText>
                )}
            </>
        );
    }
}
const FileInput = FormField(FileInputWrapper);
export default withStyles(styles)(FileInput);