
export const LegalLinks = {
    "Abandonment of Drainage System": {"label": "MS 103E.811", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.811", "drainage": "https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System" },
    "Consolidation or Division of Drainage Systems": {"label": "MS 103E.801", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.801", "drainage": "https://drainage.pca.state.mn.us/index.php/K._Consolidation_or_Division_of_Drainage_Systems" },
    "Impounding, Rerouting, And Diverting Drainage System Waters": {"label": "MS 103E.227", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.227", "drainage": "https://drainage.pca.state.mn.us/index.php/F._Impounding,_Rerouting,_and_Diverting_Drainage_System_Waters" },
    "Improvement of Drainage System": {"label": "MS 103E.215", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.215", "drainage": "https://drainage.pca.state.mn.us/index.php?title=C._Improvement_of_Drainage_System" },
    "Improvement of Outlets": {"label": "MS 103E.221", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.221", "drainage": "https://drainage.pca.state.mn.us/index.php/D._Improvement_of_Outlets" },
    "Lateral": { "label": "MS 103E.225", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.225", "drainage": "https://drainage.pca.state.mn.us/index.php/E._Laterals" },
    "New Drainage System": {"label": "MS 103E.212", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.212", "drainage": "https://drainage.pca.state.mn.us/index.php/B._New_Drainage_System_Projects" },
    "Partial Abandonment of Drainage System": {"label": "MS 103E.806", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.806", "drainage": "https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System" },
    "Petitioned Repair": {"label": "MS 103E.715", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.715", "drainage": "https://drainage.pca.state.mn.us/index.php/G._Drainage_System_Repairs" },
    "Petition to Use Established Drainage System as Outlet": {"label": "MS 103E.401", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.401", "drainage": "https://drainage.pca.state.mn.us/index.php/J._Use_of_the_Drainage_System_as_Outlet" },
    "Reestablishment of Drainage System Records": {"label": "MS 103E.101", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.101", "drainage": "https://drainage.pca.state.mn.us/index.php?title=H._Reestablishment_of_Drainage_System_Records" },
    "Removal of Property from the Drainage System": {"label": "MS 103E.805", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.805", "drainage": "https://drainage.pca.state.mn.us/index.php/L._Removal_of_Property_from_a_Drainage_System" },
    "Redetermination of Benefits": {"label": "MS 103E.351", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.351", "drainage": "https://drainage.pca.state.mn.us/index.php/I._Redetermination_of_Benefits_and_Damages" },
    "Transfer All or Part of a Drainage System": {"label": "MS 103E.812", "mn": "https://www.revisor.mn.gov/statutes/cite/103E.812", "drainage": "https://drainage.pca.state.mn.us/index.php/N._Abandonment_of_Drainage_System" },
};