import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { panelChange } from "./actions";

const styles = theme => ({
    header: {
        backgroundColor: "#eeeeee",
        margin: "0 -20px",
        padding: "20px 30px 10px 30px !important",
        flexBasis: "auto",
        width: "calc(100% + 40px)",
        maxWidth: "calc(100% + 40px)",
        maxHeight: 54,
        borderBottom: "3px solid " + theme.palette.primary.main
    },
    nodec: {
        textDecoration: "none",
        color: "#212529",
        '&:visited' : {
            color: "##212529"
        }
    },
    top: {
        marginTop: "48px !important"
    },
    bottom: {
        marginBottom: 2
    }
});

class Footer extends Component {

    resetPanel = () => {
        this.props.panelChange(0);
    }

    contractor = ({ title, drainage }) => {
        const { classes, authState } = this.props;
        const contractor = authState && authState.user && authState.user.role === "Contractor";

        if(contractor){
            return title + drainage;
        } else {
            return <Link onClick={this.resetPanel} to={"/drainage/" + this.props.match.params.drainage + "/" + drainage} className={classes.nodec}>{title + drainage}</Link>
        }
    }

    render() {
        const { classes, drainage, title, rightTitle, topSpace, bottomSpace } = this.props;
        return (
            <>
                {drainage && (
                    <>
                        {rightTitle ? 
                            <Grid item xs={12} className={classNames(classes.header, topSpace && classes.top, bottomSpace && classes.bottom)}>
                                <Typography style={{display: "inline-flex"}}>
                                    <this.contractor 
                                        title={title}
                                        drainage={drainage}
                                    />
                                </Typography>
                                <Typography style={{float: "right"}}>{rightTitle}</Typography>
                            </Grid>
                        :
                            <Grid item xs={12} className={classNames(classes.header, topSpace && classes.top, bottomSpace && classes.bottom)}>
                                <Typography style={{color: "##212529"}}>{title + drainage}</Typography>
                            </Grid>
                        }
                    </>
                )}
                {!drainage && (
                    <>
                        {rightTitle ? 
                            <Grid item xs={12} className={classNames(classes.header, topSpace && classes.top, bottomSpace && classes.bottom)}>
                                <Typography style={{display: "inline-flex"}}>{title }</Typography>
                                <Typography style={{float: "right"}}>{rightTitle}</Typography>
                            </Grid>
                        :
                            <Grid item xs={12} className={classNames(classes.header, topSpace && classes.top, bottomSpace && classes.bottom)}>
                                <Typography style={{color: "##212529"}}>{title}</Typography>
                            </Grid>
                        }
                    </>
                )}
            </>
        );
    }
}

Footer = connect(
    (state, ownProps) => ({
        authState: state.auth
    }),
    {
        panelChange
    }
)(Footer);

export default withStyles(styles)(withRouter(Footer));
