import React, { Component } from "react";
import { FormField } from "react-form";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core";
const styles = theme => ({
    input: {
        display: "none"
    },
    inline: {
        display: "inline",
        marginLeft: 16
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
    red: {
        color: "red"
    }
});
// See https://react-form.js.org/#/custom-input
class FileInputWrapper extends Component {
    constructor(props, context) {
        super(props, context);
        // This logic is probably unused in ms4front
        var displayLabel = null;
        var val = this.props.fieldApi.getValue();
        if (val) displayLabel = val.replace("/api/db/proposals/", "/media/");
        this.state = {
            displayLabel: displayLabel,
            useUrl: val ? true : false
        };
    }
    
    handleChange = e => {
        const { fieldApi, eventHandle, multiFile, withName } = this.props;
        const { setValue } = fieldApi;
        let value;

        if(multiFile){
            if(e.target.files.length === 0){
                value = null;
                this.setState({ displayLabel: null});
                setValue(null);
                eventHandle(null);
                return;
            } else {
                value = e.target.files;
            }
        } else {
            value = e.target.files[0];
        }


        if (!value){
            this.setState({ displayLabel: null});
            setValue(null);
            eventHandle(null);
            return;
        };


        if(multiFile){
            //Always keep label empty or formatted correctly if overs max size
            const temp = [...e.target.files];
            var name = e.target.files.length + ` File${e.target.files.length !== 1 ? "s" : ""}`;

            var size = 0;
            temp.forEach(x => {
                size += x.size;
            });

            if((size / 1000000) > 250){
                name = null;
            }

            this.setState({ displayLabel: name, useUrl: false });
        } else {
            //Always keep label empty or formatted correctly if overs max size
            if((value.size / 1000000) > 250){
                this.setState({ displayLabel: null, useUrl: false });
            } else {
                this.setState({ displayLabel: value.name, useUrl: false });
            }
        }

        if(multiFile){
            setValue(e.target.files);
            if (eventHandle) {
                eventHandle(e.target.files);
            }
        } else {
            if(withName){
                setValue({data: e.target.files[0], name: e.target.files[0].name});
            } else {
                setValue(e.target.files[0]);
            }
            if (eventHandle) {
                eventHandle(e.target.files[0]);
            }
        }
    };

    delete = () => {
        const { id, fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;
        this.setState({displayLabel: null, useUrl: false});

        // If same file is upload after delete onChange won't fire 
        const temp = document.getElementById(id);
        if(temp){
            temp.value = "";
        }

        setValue(null);
        if (eventHandle) {
            eventHandle(null);
        }
    };

    render() {
        const { canDelete, classes, label, children, id, fieldApi, multiFile, view, ...rest } = this.props;
        const { displayLabel, useUrl } = this.state;
        const { getError, getWarning, getSuccess } = fieldApi;
        
        const error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success;

        const noView = view ? true : false;
        return (
            <>
                <div style={{ display: "inline" }}>
                    <Typography gutterBottom>{label || children}</Typography>
                    <input className={classes.input} id={id} type="file" onChange={this.handleChange} {...rest} multiple={multiFile}/>
                    <label htmlFor={id}>
                        <Button disabled={rest.disabled} raised component="span" variant="contained" className={hasError && !displayLabel && classes.red}>
                            Select File
                        </Button>
                    </label>
                    <Typography className={classes.inline}>
                        {useUrl && (
                            <div style={{display: "inline"}}>
                                <a className={classes.linkColor} href={displayLabel} target="_blank" rel="noopener noreferrer">
                                    View File
                                </a>
                                {noView && (
                                    <div style={{marginLeft: 8, display: "inline"}}>
                                        <Tooltip title="Delete Attachment" placement="right">
                                            <Fab disabled={rest.disabled} onClick={this.delete} color="secondary" size="medium">
                                                <DeleteIcon />
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}
                        {!useUrl && (
                            <div style={{display: "inline"}}>
                            <span>
                                {displayLabel}
                            </span>
                            {displayLabel && canDelete && (
                                <>
                                    {noView && (
                                        <div style={{marginLeft: 8, display: "inline"}}>
                                            <Tooltip title="Delete Attachment" placement="right">
                                                <Fab disabled={rest.disabled} onClick={this.delete} color="secondary" size="medium">
                                                    <DeleteIcon />
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    )}
                                </>
                            )}
                            </div>
                        )}
                    </Typography>
                </div>
                {hasError && !displayLabel && (
                    <FormHelperText style={{ display: "inline" }} error={true}>
                        {errorText}
                    </FormHelperText>
                )}
            </>
        );
    }
}
const FileInput = FormField(FileInputWrapper);
export default withStyles(styles)(FileInput);