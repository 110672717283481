export const CHANGE_MAP_STATE = "CHANGE_MAP_STATE";
export const GEOMETRY_CENTER = "GEOMETRY_CENTER";
export const CHANGE_EDIT_STATE = "CHANGE_EDIT_STATE";
export const CHANGE_DRAW_STATE = "CHANGE_DRAW_STATE";
export const CHANGE_LOCATION_PAYLOAD = "CHANGE_LOCATION_PAYLOAD";
export const EMPTY_LOCATION_PAYLOAD = "EMPTY_LOCATION_PAYLOAD";
export const CHANGE_SYSTEMS = "CHANGE_SYSTEMS";
export const EMPTY_SYSTEMS = "EMPTY_SYSTEMS";
export const CHANGE_TOWNSHIPS = "CHANGE_TOWNSHIPS";
export const EMPTY_TOWNSHIPS = "EMPTY_TOWNSHIPS";
export const CHANGE_COMMISSIONERS = "CHANGE_COMMISSIONERS";
export const EMPTY_COMMISSIONERS = "EMPTY_COMMISSIONERS";
export const CHANGE_QUARTERS = "CHANGE_QUARTERS";
export const EMPTY_QUARTERS = "EMPTY_QUARTERS";
export const CHANGE_SECTIONS = "CHANGE_SECTIONS";
export const EMPTY_SECTIONS = "EMPTY_QSECTIONS";
export const CHANGE_RANGES = "CHANGE_RANGES";
export const EMPTY_RANGES = "EMPTY_RANGES";
export const CHANGE_TWPS = "CHANGE_TWPS";
export const EMPTY_TWPS = "EMPTY_TWPS";
export const AUTO_ERROR = "AUTO_ERROR";
export const MAP_LOADING = "MAP_LOADING";
export const LOOKUP_LOADING = "LOOKUP_LOADING";

export function changedMapState(state){
    return {
        type: CHANGE_MAP_STATE,
        state: state
    };
}

export function geometry_center(state){
    return {
        type: GEOMETRY_CENTER,
        state: state
    };
}

export function changeEditState(state){
    return {
        type: CHANGE_EDIT_STATE,
        state: state
    };
}

export function changeDrawState(state){
    return {
        type: CHANGE_DRAW_STATE,
        state: state
    };
}

export function changeLocationPayload(state){
    return {
        type: CHANGE_LOCATION_PAYLOAD,
        state: state
    };
}

export function emptyLocationPayload(){
    return {
        type: EMPTY_LOCATION_PAYLOAD
    };
}

export function changeSystems(state){
    return {
        type: CHANGE_SYSTEMS,
        state: state
    };
}

export function emptySystems(){
    return {
        type: EMPTY_SYSTEMS
    };
}

export function changeTownships(state){
    return {
        type: CHANGE_TOWNSHIPS,
        state: state
    };
}

export function emptyTownships(){
    return {
        type: EMPTY_TOWNSHIPS
    };
}

export function changeCommissioners(state){
    return {
        type: CHANGE_COMMISSIONERS,
        state: state
    };
}

export function emptyCommissioners(){
    return {
        type: EMPTY_COMMISSIONERS
    };
}

export function changeQuarters(state){
    return {
        type: CHANGE_QUARTERS,
        state: state
    };
}

export function emptyQuarters(){
    return {
        type: EMPTY_QUARTERS
    };
}

export function changeSections(state){
    return {
        type: CHANGE_SECTIONS,
        state: state
    };
}

export function emptySections(){
    return {
        type: EMPTY_SECTIONS
    };
}

export function changeRanges(state){
    return {
        type: CHANGE_RANGES,
        state: state
    };
}

export function emptyRanges(){
    return {
        type: EMPTY_RANGES
    };
}

export function changeTwps(state){
    return {
        type: CHANGE_TWPS,
        state: state
    };
}

export function emptyTwps(){
    return {
        type: EMPTY_TWPS
    };
}

export function AutoError(state){
    return {
        type: AUTO_ERROR,
        error: state
    }
}

export function MapLoading(state){
    return {
        type: MAP_LOADING,
        state: state
    }
}

export function lookUpLoading(state){
    return {
        type: LOOKUP_LOADING,
        state: state
    }
}