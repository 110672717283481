import { AUTH_LOGOUT } from "../../auth/actions";
export const BOARD_DATE_UPDATED = "BOARD_DATE_UPDATED";

function isRealUser(authState) {
    return authState && authState.user;
}

export function boardUpdated(payload){

    return function(dispatch, getState) {
        const state = getState(),
        { auth } = state;

        if (!isRealUser(state.auth)) {
            return;
        }

        fetch('/manage/board/boarddate', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + auth.user.auth_token
            }
        })
        .then(result => {
            if (!result.ok) {
                return result.json().then(message => {
                    throw new Error(message.detail);
                });
            } 
            //else {
            //    dispatch({
            //        type: BOARD_DATE_UPDATED,
            //        payload: payload
            //    });
            //}
        })
        .catch(e => {
            if (e === "Invalid token." || e === "Token has expired.") {
                dispatch({
                    type: AUTH_LOGOUT
                });
            }
        });
    }
}

export function processBoardDate() {
    return function(dispatch) {
        dispatch(boardUpdated(true));
    };
}