import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Delete from "./Delete";
import {createSelector, reloadByType} from "../common/orm";
import * as authActions from "../auth/actions";
import {Bmp} from "../mypage/models";
import ChangeNumber from "../common/manage/ChangeNumber";


const getBMPs = createSelector(session => {
    return session.Bmp.all().orderBy('bmpnum').toRefArray();
});

const styles = theme => ({
});

class ManageBMPs extends Component {

    render() {
        const { classes, bmps, saveGood, saveBad, ormBMPLoadDetail } = this.props;

        return (
            <div className={classes.space}>
                <ChangeNumber data={bmps} loadDetail={ormBMPLoadDetail} type={'Bmp'} />
                <Delete saveGood={saveGood} saveBad={saveBad} type={'Bmp'} />
            </div>
        );
    }
}

ManageBMPs = connect(
    (state, ownProps) => ({
        authState: state.auth,
        bmps: getBMPs(state, ownProps),
    }),
    {
        ...Bmp.actions,
        ...authActions,
        reloadByType
    }
)(ManageBMPs);

export default withStyles(styles)(withRouter(ManageBMPs));
