import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import NotReady from "../common/NotReady";
import AppContainer from "../common/AppContainer";
import RecordTable from "../common/RecordTable";
import Snackbar from "../common/Snackbar";
import {ExcelIcon} from "../common/IconFunctions";
import {formateDateSlash} from "../../api/utils";

const styles = theme => ({
    top: {
        marginTop: 10,
    },
    flex: {
        flex: 1
    },
    mB: {
        marginBottom: 10
    },
    grow: {
        marginTop: 10,
        display: "inline-flex",
        flex: 1
    },
    descMargin: {
        margin: '10px 0 10px 80px'
    }
});

const systemData = [
    {id: "actions", numeric: false, label: "", allowSort: false},
    {id: "drainage", numeric: false, label: "System Name", allowSort: true},
    {id: "total", numeric: true, label: "Total Count", allowSort: true},
    {id: "status", numeric: false, label: "Status", allowSort: true},
    {id: "jurisdiction", numeric: false, label: "Jurisdiction", allowSort: true},
    {id: "ditchtype", numeric: false, label: "Ditch Type", allowSort: true},
    {id: "year", numeric: false, label: "Year Constructed", allowSort: true},
    {id: "rob", date: true, label: "Most Recent Redetermination", allowSort: true}
];

const documentData = [
    {id: "actions", numeric: false, label: "", allowSort: false},
    {id: "year", numeric: true, label: "Year", allowSort: true},
    {id: "caption", numeric: false, label: "Document Title", allowSort: true},
    {id: "type", numeric: false, label: "Type", allowSort: true},
    {id: "creationdate", date: true, label: "Document Date", allowSort: true},
    {id: "uploaddate", date: true, label: "Upload Date", allowSort: true},
    {id: "notes", numeric: false, label: "Notes", allowSort: true},
    {id: "publiclink", numeric: false, label: "Link To Open", allowSort: false, link: true},
];

class Portal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            name: "",
            link: "",
            systems: [],
            docs: [],
            publicStatus: {},
            loaded: false,
            welcomeText: "",
            mapViewerText: "",
            showAnnouncements: false,
            announcements: []
        }
        this.tableRef = React.createRef();
    }

    componentDidMount = () => {
        this.getSystem();
        this.getPortalLink();
    }

    getSystem = () => {
        fetch('/public/drainagesystems')
            .then(result => result.json())
            .then(data => {
                this.setState({systems: data});
            }).catch(err => {
            console.log("Error!")
        });
    }

    getPortalLink = () => {
        fetch('/public/portallink')
            .then(result => result.json())
            .then(data => {
                this.setState({
                    link: data['link'],
                    publicStatus: data['show_public'] ?
                        {portal: true} :
                        {authenticated: true, hideToolbar: true, noRedirect: true},
                    welcomeText: data['welcome_text'] ? data['welcome_text'] :
                        '<p>Welcome to the online drainage portal. Through this portal you can access historic documents and an interactive map of the ditches.</p>',
                    mapViewerText: data['map_viewer_text'] ? data['map_viewer_text'] :
                        '<p>The map gives you access to ditches in the county. <br/> Click on the link to open the interactive map in a new window. <a target="_blank" rel="noopener noreferrer" href={`${data["link"]}`}>Click Here</a></p>',
                    loaded: true,
                    showAnnouncements: data['show_announcements'],
                    announcements: data['announcements']
                });
            }).catch(err => {
            console.log("Error!")
        });
    }

    view = (id) => {
        const {systems} = this.state;
        const system = systems.find(x => x.id === id);

        fetch('/public/documentsdrainage/' + id)
            .then(result => result.json())
            .then(data => {
                if (system.total > 0) {
                    this.setState({id: id, name: system.drainage, docs: data});
                } else {
                    this.setState({snack: true, name: system.drainage});
                }
            }).catch(err => {
            console.log("Error!")
        });
    }

    back = () => {
        this.getSystem();
        this.setState({id: null, name: ""});
    }

    getSearchStr = () => {
        const {filters} = this.state;

        if (filters && filters.column !== "" && filters.filter !== "") {
            return `?column=${filters.column}&searchstring=${filters.filter}`;
        } else {
            return ``;
        }
    }

    parentStateSet = (filters) => {
        this.setState({filters});
    }

    render() {
        const {classes} = this.props;
        const {
            docs,
            systems,
            id,
            name,
            snack,
            publicStatus,
            loaded,
            welcomeText,
            announcements,
            showAnnouncements,
            mapViewerText
        } = this.state;

        return !!loaded ? (
            <AppContainer {...publicStatus}>
                <Grid container>
                    {!id && (
                        <>
                            <Grid item xs={showAnnouncements? 6: 12}>
                                <Typography variant="h5" className={classes.grow}>Welcome</Typography>
                                <Divider className={classes.mB}/>
                                <Typography dangerouslySetInnerHTML={{__html: welcomeText}}/>
                                <Typography variant="h5" className={classes.grow}>GIS Map Viewer</Typography>
                                <Divider className={classes.mB}/>
                                <Typography dangerouslySetInnerHTML={{__html: mapViewerText}}/>
                                <Typography variant="h5" className={classes.grow}>Historic Documents</Typography>
                                <Divider className={classes.mB}/>
                                <Typography className={classes.mB}>Click on the eye on any ditch system to view available
                                    historic documents.</Typography>
                                <Button
                                    variant="contained"
                                    target="_blank"
                                    href={`/reports/public/systemslist/excel${this.getSearchStr()}`}
                                    className={classes.mB}>
                                    <ExcelIcon/>
                                    &nbsp;&nbsp;Export to Excel
                                </Button>
                            </Grid>
                            {!!showAnnouncements && (
                                <Grid item xs={6}>
                                    <Typography variant="h5" className={classes.grow}>Annoucements</Typography>
                                    <Divider className={classes.mB}/>
                                    {announcements.map(annoucement => {
                                        return (
                                            <>
                                                <Typography variant={'subtitle1'}>
                                                    {`${formateDateSlash(annoucement.created_at.slice(0, 10))} - ${annoucement.title} scheduled for ${formateDateSlash(annoucement.expiry_date)}`}
                                                </Typography>
                                                <Typography className={classes.descMargin} dangerouslySetInnerHTML={{__html: annoucement.description}}/>
                                            </>
                                        )
                                    })}
                                    {!announcements.length && (
                                        <Typography variant={'subtitle1'}>Nothing to show</Typography>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs>
                                <RecordTable
                                    noScroll
                                    parentStateSet={this.parentStateSet}
                                    columnData={systemData}
                                    data={systems}
                                    viewRecord={this.view}
                                    viewable={true}
                                    creatable={false}
                                    editable={false}
                                    deletable={false}
                                />
                            </Grid>
                        </>
                    )}
                    {id && (
                        <Grid container>
                            <Grid item xs={12} className={classes.top}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        this.back()
                                    }}>
                                    <KeyboardBackspaceIcon/>
                                    &nbsp;&nbsp;Portal DashBoard
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" className={classes.grow}>Historic Documents
                                    - {name}</Typography>
                                <Divider className={classes.mB}/>
                            </Grid>
                            <RecordTable
                                noScroll
                                columnData={documentData}
                                data={docs}
                                viewable={false}
                                creatable={false}
                                editable={false}
                                deletable={false}
                            />
                        </Grid>
                    )}
                </Grid>
                <Snackbar
                    snackbarOpen={snack}
                    message={`No Documents exist for system: ${name}`}
                    handleSnackbarClose={() => this.setState({snack: false})}
                    isSuccess={false}
                    time={3500}
                />
            </AppContainer>
        ) : <NotReady message="Loading..." />
    }
}

Portal = connect(state => (
    {
        authState: state.auth,
    }
))(Portal);


export default withStyles(styles)(withRouter(Portal));
