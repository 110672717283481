import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {Form} from "react-form";

import {withStyles, Grid, Button, Dialog, DialogTitle, IconButton, DialogContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CloseIcon from "@material-ui/icons/Close";

import {formateDateSlash, prepareDraftToHtml, prepareHtmlToDraft} from "../../api/utils";
import {Announcement, MapSetting} from "../mypage/models";
import { createSelector } from '../common/orm';
import RecordTable from "../common/RecordTable";
import WarningDialog from "../common/WarningDialog";
import TextField from "../common/TextField";
import EditorField from "../common/EditorField";
import DatePicker from "../common/DatePicker";
import HelpLabel from "../common/HelpLabel";


const getSetting = createSelector(schema => {
    const setting =  schema.MapSetting.first();
    return {
        id: setting.id,
        show_public: setting.show_public,
        welcome_text: setting.welcome_text,
        map_viewer_text: setting.map_viewer_text,
        show_announcements: setting.show_announcements
    }
});

const getAnnouncements = createSelector(schema => {
    return schema.Announcement.all().orderBy('created_at').toModelArray().map(a => {
        a.expiry_date = formateDateSlash(a.expiry_date)
        return a
    })
});


const styles = theme => ({
    space: {
        marginTop: 88
    },
    summary: {
        backgroundColor: "rgb(221, 221, 221)"
    },
    titlePadding: {
        padding: "18px 8px 18px 8px"
    },
    titleMargin: {
        margin: "4px 0 0 10px"
    },
    margin: {
        margin: '10px 0',
    },
    buttonMargin: {
        marginLeft: "8px"
    },
    dateMargin: {
        margin: '16px 0px 0px 2%'
    },
    titleWidth: {
        width: '47%'
    },
    right: {
        float: 'right'
    }
});

const announcementsData = [
    {id: "actions", numeric: false, label: "", allowSort: false},
    {id: "title", numeric: false, label: "Title", allowSort: true},
    {id: "description", numeric: false, label: "Description", allowSort: true},
    {id: "expiry_date", numeric: false, label: "Expiration Date", allowSort: true},
]

class PublicPortalConfiguration extends Component {
    state = {
        dialog: false,
    }

    componentDidMount() {
        this.prepareSetting()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.prepareSetting()
        }
    }

    prepareSetting = () => {
        const {setting} = this.props;
        this.setState((prev) => ({...prev, setting: {
                show_public: setting.show_public,
                welcome_text: setting.welcome_text,
                map_viewer_text: setting.map_viewer_text,
                show_announcements: setting.show_announcements,
                portal_map_url: setting.portal_map_url
            }
        }))
    }

    changeStatus = (val, field) => {
        if (!val) return
        const {ormMapSettingUpdatePromise, saveBad, saveGood} = this.props
        const {id} = this.props.setting
        const {setting} = this.state
        setting[field] = val === 'unlock'
        ormMapSettingUpdatePromise({id, [field]: setting[field]}).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
                this.prepareSetting()
            }
        })
    }

    saveSetting = (values, field='welcome_text') => {
        const {ormMapSettingUpdatePromise, saveBad, saveGood} = this.props
        const {id} = this.props.setting
        const {setting} = this.state
        values[field] = prepareDraftToHtml(setting[field])
        ormMapSettingUpdatePromise({
            id,
            [field]: values[field]
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
                this.prepareSetting()
            }
        })
    }

    saveAnnouncement = (values) => {
        const { ormAnnouncementCreate, ormAnnouncementUpdatePromise, saveBad, saveGood } = this.props;
        const {active} = this.state
        values.description = prepareDraftToHtml(active.description)
        if (values.id) {
            ormAnnouncementUpdatePromise(values).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                    this.setState({dialog: false, active: null});
                }
            })
            return
        }
        ormAnnouncementCreate(values).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
                this.setState({dialog: false, active: null});
            }
        })
    }

    errorValidator = (values, field=null) => {
        const isRequired = (value) => {
            if (value === undefined || value === null) return "Required";
            else return null;
        };

        if (field) {
            return {[field]: isRequired(values[field])}
        }

        return {
            title: isRequired(values.title),
            description: isRequired(values.description),
            expiry_date: isRequired(values.expiry_date)
        };
    }

    render() {
        const { classes, announcements, ormAnnouncementDeletePromise, saveBad, saveGood } = this.props;
        const { dialog, id, active, setting } = this.state;

        if (setting) {
            if (!setting.welcome_text || setting.welcome_text.length <= 7) {
                setting.welcome_text = '<p>Welcome to the online drainage portal. Through this portal you can access historic documents and an interactive map of the ditches.</p>'
            }
            if (!setting.map_viewer_text || setting.map_viewer_text.length <= 7) {
                setting.map_viewer_text = `<p>The map gives you access to ditches in the county. <br/>Click on the link to open the interactive map in a new window. <a target="_blank" rel="noopener noreferrer" href="${null}">Click Here</a></p>`
            }
            if (typeof setting.welcome_text !== 'object') {
                setting.welcome_text = prepareHtmlToDraft(setting.welcome_text)
            }
            if (typeof setting.map_viewer_text !== 'object') {
                setting.map_viewer_text = prepareHtmlToDraft(setting.map_viewer_text)
            }
        }

        return (
            <>
                {!!setting && (
                    <Grid container className={classes.space}>
                        <Grid item xs={12} spacing={2}>
                            <Typography className={classes.titleMargin}>
                                NOTE: This admin page is a new enhancement being worked on and is currently only in a
                                beta release. This is a feature to allow admin's to configure their own public portal
                                for their DrainageDB account. In order to test this new feature a temporary public
                                portal page has been created at <a target={'_blank'} href={'https://yourdomain.drainaged' +
                                'b.net/#/portal2'}>https://yourdomain.drainagedb.net/#/portal2</a> (ex. <a target={'_bla' +
                                'nk'} href={'https://faribault.drainagedb.net/#/portal2'} >
                                https://faribault.drainagedb.net/#/portal2</a>). Please use this page for testing until
                                we release this feature in the future. This new enhancement should be a considered a
                                work in progress and will go thru multiple iterations.
                            </Typography>
                            <Divider className={classes.margin}/>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <Typography className={classes.titleMargin}>
                                <HelpLabel
                                    inputLabel="Display Public Portal:"
                                    helpText={<div>If the lock is selected the public portal URL will not be accessible
                                        for this account. If you want the public portal accessible to a non-logged in
                                        user then ensure this setting is unlocked. The setting will automatically
                                        save.</div>}
                                />
                                <ToggleButtonGroup
                                    value={!setting.show_public ? "lock": "unlock"}
                                    exclusive
                                    onChange={(e, val) => this.changeStatus(val, 'show_public')}
                                    className={classes.titleMargin}
                                >
                                    <ToggleButton value={"lock"} >
                                        <LockIcon fontSize="small" color={'error'} />
                                    </ToggleButton>
                                    <ToggleButton value={"unlock"} >
                                        <LockOpenIcon fontSize="small" color={'primary'} />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Typography>
                            <Divider className={classes.margin}/>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <Typography className={classes.titleMargin}>
                                <HelpLabel
                                    inputLabel="Display Announcements Section:"
                                    helpText={<div>If the lock is selected the Announcements section will not be
                                        displayed on the public portal page. If you want the announcements section
                                        displayed then ensure this setting is unlocked. The setting will automatically
                                        save.</div>}
                                />
                                <ToggleButtonGroup
                                    value={!setting.show_announcements ? "lock": "unlock"}
                                    exclusive
                                    onChange={(e, val) => this.changeStatus(val, 'show_announcements')}
                                    className={classes.titleMargin}
                                >
                                    <ToggleButton value={"lock"} >
                                        <LockIcon fontSize="small" color={'error'} />
                                    </ToggleButton>
                                    <ToggleButton value={"unlock"} >
                                        <LockOpenIcon fontSize="small" color={'primary'} />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Typography>
                            <Divider className={classes.margin}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.titleMargin}>
                                Welcome Section Text:
                                <Form
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    validateError={(values) => this.errorValidator(values, 'welcome_text')}
                                    defaultValues={setting}
                                    onSubmit={(values) => this.saveSetting(values)}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <EditorField
                                                required
                                                value={setting.welcome_text}
                                                onChange={(value) => this.setState(prev => ({
                                                        ...prev,
                                                        setting: {...setting, welcome_text: value}
                                                    }
                                                ))}
                                            />
                                            <Button type="submit" variant="contained" color="primary">Save</Button>
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                            <Divider className={classes.margin}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.titleMargin}>
                                GIS Map Viewer Section Text:
                                <Form
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    validateError={(values) => this.errorValidator(values, 'map_viewer_text')}
                                    defaultValues={setting}
                                    onSubmit={(values) => this.saveSetting(values, 'map_viewer_text')}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <EditorField
                                                required
                                                value={setting.map_viewer_text}
                                                onChange={(value) => this.setState(prev => ({
                                                        ...prev,
                                                        setting: {...setting, map_viewer_text: value}
                                                    }
                                                ))}
                                            />
                                            <Button type="submit" variant="contained" color="primary">Save</Button>
                                        </form>
                                    )}
                                </Form>
                            </Typography>
                            <Divider className={classes.margin}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.titleMargin}>
                                <HelpLabel
                                    inputLabel="Announcement Section Postings:"
                                    helpText={<div>Click the add announcement button to add a posting. Postings will be
                                        displayed in by date with most recent date listed first. You can edit or delete
                                        a posting at anytime by click the pencil or trash can icon next to the posting.
                                        If you enter an expiration date the posting will automatically not display once
                                        the date has been reached but will not actually delete the posting from the
                                        configuration page.</div>}
                                />
                                <RecordTable
                                    noScroll
                                    parentStateSet={(filters) => this.setState({filters})}
                                    columnData={announcementsData}
                                    data={announcements}
                                    addName="Add Announcement"
                                    creatable={true}
                                    editable={true}
                                    deletable={true}
                                    addRecord={() => this.setState({
                                        dialog: true,
                                        id: null,
                                        active: {title: '', expiry_date: '', description: prepareHtmlToDraft('')}
                                    })}
                                    editRecord={(values) => this.setState({
                                        dialog: true,
                                        active: {
                                            ...values,
                                            description: prepareHtmlToDraft(values.description),
                                            expiry_date: values.expiry_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")
                                        }
                                    })}
                                    deleteRecord={(id) => this.setState({id: id, delete: true})}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!!active && (
                    <Dialog
                        open={dialog}
                        maxWidth="md"
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h5" className={classes.flex}>Announcement
                            <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({dialog: false, active: null})}}>
                                <CloseIcon />
                            </IconButton></Typography>
                        </DialogTitle>
                        <DialogContent className={classes.dialogMin}>
                            <Form
                                id="announcementsForm"
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                defaultValues={active}
                                onSubmit={this.saveAnnouncement}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid item xs={12}>
                                            <TextField className={classes.titleWidth} required field="title" label="Title" />
                                            <DatePicker className={classes.dateMargin} required field="expiry_date" label="Expiration Date" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EditorField
                                                required
                                                label="Description"
                                                value={active.description}
                                                onChange={(value) => this.setState(prev => ({
                                                        ...prev,
                                                        active: {...active, description: value}
                                                    }
                                                ))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary">Save</Button>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContent>
                    </Dialog>
                )}
            <WarningDialog
                confirmAction={() => {
                    ormAnnouncementDeletePromise(id).then(id => {
                        this.setState({ id: false });
                        if(!id){
                            saveBad();
                        } else {
                            saveGood();
                        }
                    });
                }}
                cancelAction={() => this.setState({ id: false })}
                open={!!id}
                title="Delete Announcement"
                text={"Are you sure you wish to permanently delete?"}
            />
        </>
    )}
}

PublicPortalConfiguration = connect(
    (state, ownProps) => ({
        authState: state.auth,
        announcements: getAnnouncements(state, ownProps),
        setting: getSetting(state, ownProps)
    }),
    {
        ...MapSetting.actions,
        ...Announcement.actions
    }
)(PublicPortalConfiguration);

export default withStyles(styles)(withRouter(PublicPortalConfiguration));
