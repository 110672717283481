import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from 'react-form';
import { withStyles } from "@material-ui/core";
import { createSelector } from "../common/orm";
import { AgolDrainageOption, JointOptions } from '../mypage/models'
import TransferList from "../common/TransferList";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import WarningFormDialog from "../common/WarningFormDialog";

import { AGOLCredenitials } from "./ManageSystems";

import Notifications from "./Notifications";

const getDrainageData = createSelector(session => {
    return session.AgolDrainageOption.all().toModelArray().map(x => ({...x, label: x.table_label}));
})

const getJointData = createSelector(session => {
    return session.JointOptions.all().orderBy('county').toModelArray().map(x => ({...x, label: x.county}));
});

const styles = theme => ({
    space: {
        marginTop: 88
    },
    italic: {
        fontStyle: "italic"
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: "#74A94C",
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#74A94C",
        fontWeight: 500
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    primaryButton: {
        backgroundColor: "#74A94C",
        color: "#fff"
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class DropDowns extends Component {
    state = {
        hideDrainage: false,
        hideJoint: false,
        lock: false,
        agolDialog: false,
        agolValues: {}
    }

    saveJointOptions = (values) => {
        const { ormJointOptionsUpdateBatch, saveBad, saveGood } = this.props;

        // hide form since set values in form will cause a way to many renders and crash
        this.setState({hideJoint: true});
        
        ormJointOptionsUpdateBatch("api/db/jointoptionss", null, values).then(result => {
            if(!!result){
                saveGood();
            } else {    
                saveBad();
            }
            this.setState({hideJoint: false});
        });
    }

    confirmAgolDrainage = (values) => {
        this.setState({
            agolValues: values, 
            agolDialog: true,
            lock: false
        });
    }

    saveAgolDrainage = (values, formApi) => {
        const { ormAgolDrainageOptionUpdateBatch, saveBad, saveGood } = this.props;
        const { agolValues } = this.state;

        // hide form since set values in form will cause a way to many renders and crash
        this.setState({hideDrainage: true, lock: true});

        var data = {
            data: agolValues,
            ...values
        };
        
        ormAgolDrainageOptionUpdateBatch("api/db/agoldrainageoptions", null, data).then(result => {
            if(result && result.message){
                formApi.setError("username", result.message);
                formApi.setError("password", result.message);
            } else if(!!result){
                saveGood();
		        this.setState({hideDrainage: false, agolDialog: false});
            } else {    
                saveBad();
            }
            this.setState({lock: false});
        });
    }

    validator = (values) => {
        const isEmpty = value => {
            if(value === undefined || value === null){
                return "Required";
            }
            return null;
        }
        
	return {
            username: isEmpty(values.username),
            password: isEmpty(values.password),
        };
    } 

    render() {
        const { 
            classes, 
            drainageData, 
            jointData,
            saveGood,
            saveBad,
        } = this.props;
        const { 
            hideJoint, 
            hideDrainage,
            lock,
            agolDialog
        } = this.state;

        return (
            <div className={classes.space}>
                <Notifications
                    saveGood={saveGood}
                    saveBad={saveBad}
                />
                <TransferList
                    data={jointData}
                    foreignKey="drainage_id"
                    hide={hideJoint}
                    panelTitle="Joint and Judicial Options"
                    ormParentUpdateLocalOnly={this.props.ormJointOptionsUpdateLocalOnly}
                    save={this.saveJointOptions}
                />
                <TransferList
                    data={drainageData}
                    foreignKey="drainage_id"
                    hide={hideDrainage}
                    panelTitle="Agol Drainage Options"
                    ormParentUpdateLocalOnly={this.props.ormAgolDrainageOptionUpdateLocalOnly}
                    save={this.confirmAgolDrainage}
                />
                <WarningFormDialog
                    lock={lock}
                    cancelAction={() => this.setState({ agolDialog: false })}
                    open={agolDialog}
                    form={
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.validator}
                            onSubmit={(values, e, formApi) => this.saveAgolDrainage(values, formApi)}
                        >
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <DialogContent>
                                        <Typography variant="title" className={classes.questionTitle} gutterBottom>
                                            Drainage System Option Update
                                        </Typography>
                                        <DialogContentText>This operation may take 20-60 seconds. Do you wish to continue and update ArcGIS Online?</DialogContentText>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <AGOLCredenitials lock={lock} />
                                            </Grid>
                                        </Grid>
                                        <DialogActions className={classes.centerButtons}>
                                            <div className={classes.wrapper}>
                                                <Button 
                                                    disabled={lock}
                                                    type="submit" 
                                                    color="primary" 
                                                    variant="outlined">
                                                    Yes
                                                </Button>
                                                {lock && (<CircularProgress color="primary" size={24} className={classes.buttonProgress} />)}
                                            </div>
                                            <div>
                                                <Button 
                                                    disabled={lock}
                                                    onClick={() => this.setState({ deleteDialog: false })}
                                                    variant="outlined" 
                                                    className={classes.greyButton} 
                                                    autoFocus>
                                                    No
                                                </Button>
                                            </div>
                                        </DialogActions>
                                    </DialogContent>
                                </form>
                            )}
                        </Form>
                    }
                />
            </div>
        );
    }
}

DropDowns = connect(
    (state, ownProps) => ({ 
        authState: state.auth,
        jointData: getJointData(state, ownProps),
        drainageData: getDrainageData(state, ownProps)
    }),
    {
        ...JointOptions.actions,
        ...AgolDrainageOption.actions
    }
)(DropDowns);

export default withStyles(styles)(withRouter(DropDowns));
