import { 
    CHANGE_MAP_STATE, 
    GEOMETRY_CENTER, 
    CHANGE_EDIT_STATE, 
    CHANGE_DRAW_STATE, 
    CHANGE_LOCATION_PAYLOAD,
    EMPTY_LOCATION_PAYLOAD,
    CHANGE_SYSTEMS,
    EMPTY_SYSTEMS,
    CHANGE_TOWNSHIPS,
    EMPTY_TOWNSHIPS,
    CHANGE_COMMISSIONERS,
    EMPTY_COMMISSIONERS,
    CHANGE_QUARTERS,
    EMPTY_QUARTERS,
    CHANGE_SECTIONS,
    EMPTY_SECTIONS,
    CHANGE_RANGES,
    EMPTY_RANGES,
    CHANGE_TWPS,
    EMPTY_TWPS,
    AUTO_ERROR,
    MAP_LOADING,
    LOOKUP_LOADING
 } from "./actions";

const intital = {
    edit: false,
    draw: false,
    loading: false,
    lookup_loading: false,
    townships: [],
    systems: [],
    commissioners: [],
    quarters: [],
    ranges: [],
    twps: []
};

function mapReducer(state = intital, action){

    switch(action.type){
        case CHANGE_MAP_STATE:
            return{
                ...state,
                map: action.state
            };
        case GEOMETRY_CENTER:
            return{
                ...state,
                geometry_center: action.state
            };
        case CHANGE_EDIT_STATE: 
            return{
                ...state,
                edit: action.state
            };
        case CHANGE_DRAW_STATE:
            return{
                ...state,
                draw: action.state
            };
        case CHANGE_LOCATION_PAYLOAD:
            return{
                ...state,
                payload: action.state
            };
        case EMPTY_LOCATION_PAYLOAD:
            return{
                ...state,
                payload: {}
            };
        case CHANGE_SYSTEMS:
            return{
                ...state,
                systems: action.state
            };
        case EMPTY_SYSTEMS:
            return{
                ...state,
                systems: []
            };
        case CHANGE_TOWNSHIPS:
            return{
                ...state,
                townships: action.state
            };
        case EMPTY_TOWNSHIPS:
            return{
                ...state,
                townships: []
            };
        case CHANGE_COMMISSIONERS:
            return{
                ...state,
                commissioners: action.state
            };
        case EMPTY_COMMISSIONERS:
            return{
                ...state,
                commissioners: []
            };
        case CHANGE_QUARTERS:
            return{
                ...state,
                quarters: action.state
            };
        case EMPTY_QUARTERS:
            return{
                ...state,
                quarters: []
            };
        case CHANGE_SECTIONS:
            return{
                ...state,
                sections: action.state
            };
        case EMPTY_SECTIONS:
            return{
                ...state,
                sections: []
            };
        case CHANGE_RANGES:
            return{
                ...state,
                ranges: action.state
            };
        case EMPTY_RANGES:
            return{
                ...state,
                ranges: []
            };
        case CHANGE_TWPS:
            return{
                ...state,
                twps: action.state
            };
        case EMPTY_TWPS:
            return{
                ...state,
                twps: []
            };
        case AUTO_ERROR:
            return {
                ...state,
                error: action.state
            };
        case MAP_LOADING:
            return {
                ...state,
                loading: action.state
            };
        case LOOKUP_LOADING:
            return {
                ...state,
                lookup_loading: action.state
            };
        default:
            return state;
    }
}

export default mapReducer;