import { ORM, Model as ORMModel, ForeignKey, createSelector as ormSelector } from "redux-orm";
import { AUTH_LOGOUT } from "../auth/actions";
//import axios from 'axios';

const orm = new ORM();
export default orm;

export function createSelector(...args) {
    return ormSelector(orm, state => state.orm, ...args);
}

export function selectAll(cls, serialize = obj => obj.ref) {
    return createSelector(schema =>
        schema[cls.modelName]
            .all()
            .toModelArray()
            .map(serialize)
    );
}

export function selectAllOrder(cls, serialize = obj => obj.ref) {
    return createSelector(schema =>
        schema[cls.modelName]
            .all()
            .orderBy(cls.defaultKeySort)
            .toModelArray()
            .map(serialize)
    );
}

export function selectFirst(cls, serialize = obj => obj.ref) {
    return createSelector(schema =>
        schema[cls.modelName]
            .first()
    );
}

export function selectByUrlId(cls, serialize = obj => obj.ref, param = "id") {
    return createSelector(
        (state, ownProps) => ownProps.match.params[param],
        (schema, objId) => {
            cls = schema[cls.modelName];
            if (cls.hasId(objId)) {
                var obj = cls.withId(objId);
                return serialize(obj);
            } else {
                // FIXME: Render a 404 page?
                return { name: "Not Found" };
            }
        }
    );
}

function isRealUser(authState) {
    return authState && authState.user;
}

export class BaseModel extends ORMModel {
    static selectAll(serializer) {
        return selectAll(this, serializer);
    }

    static selectAllOrder(serializer) {
        return selectAllOrder(this, serializer);
    }

    static selectFirst(serializer) {
        return selectFirst(this, serializer);
    }

    static selectByUrlId(serializer, param = "id") {
        return selectByUrlId(this, serializer, param);
    }

    static get source() {
        return null;
    }
    static get isUserData() {
        return null;
    }
    static get loadOnInit(){
        return null;
    }
    static get adminDefault(){
        return null;
    }
    static get drainageDefault(){
        return null;
    }
    static get repairDefault(){
        return null;
    }
    static get violationDefault(){
        return null;
    }
    static get bmpDefault(){
        return null;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`;
        return {
            [`${functionPrefix}Reload`]: () => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    if (cls.isUserData) {
                        if (!isRealUser(state.auth)) {
                            return;
                        }
                        if(cls.loadOnInit === false){
                            return;
                        }
                    }
                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&t=${Date.now()}${cls.modelName === "TrackingBoard" ? "&board=t": ""}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data =>
                            dispatch({
                                type: `${typePrefix}_PULLED`,
                                payload: data
                            })
                        )
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}ReloadAdmin`]: () => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    if (cls.isUserData) {
                        if (!isRealUser(state.auth)) {
                            return;
                        }
                        if(cls.adminDefault === false){
                            return;
                        }
                    }
                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&t=${Date.now()}&adminpage=true`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data =>
                            dispatch({
                                type: `${typePrefix}_PULLED`,
                                payload: data
                            })
                        )
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}ReloadByDrainage`]: (objId) => {
                var cls = this;
                return function(dispatch, getState){
                    const state = getState();
                    if(cls.isUserData){
                        if(!cls.drainageDefault){
                            return;
                        }
                    }
                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&t=${Date.now()}&drainage=${objId}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data =>
                            dispatch({
                                type: `${typePrefix}_PULLED_DRAINAGE`,
                                payload: data
                            })
                        )
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}ReloadByType`]: (objId, type) => {
                var cls = this;
                return function(dispatch, getState){
                    const state = getState();
                    if(cls.isUserData){
                        
                        if(!cls[type + "Default"]){
                            return;
                        }
                    }
                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&t=${Date.now()}&${type}=${objId}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data =>
                            dispatch({
                                type: `${typePrefix}_PULLED_TYPE`,
                                payload: data,
                                key: type
                            })
                        )
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            }

        };
    }

    static fromResponse(data) {
        return data;
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`;
        switch (action.type) {
            case `${prefix}_PULLED`:
                const ids = action.payload.map(obj => obj.id);
                cls.exclude(obj => ids.includes(obj.id)).delete();
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_PULLED_DRAINAGE`: //Update set not take only new
                //const drainageIds = action.payload.map(obj => obj.id);
                //var drainageId = null;
                //if(action.payload.length > 0){
                //    drainageId = action.payload[0].drainage_id;
                //}
                // if not in set and system matches then it's been deleted.
                //cls.exclude(obj => drainageIds.includes(obj.id) && obj.drainage_id !== drainageId).delete();
                //action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj))); 

                cls.all().delete();
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj))); 
                break;
            case `${prefix}_PULLED_TYPE`: //Update set not take only new
                //const typeIds = action.payload.map(obj => obj.id);
                //var typeId = null;
                //if(action.payload.length > 0){
                //    typeId = action.payload[0][`${action.type}_id`];
                //}
                // if not in set and system matches then it's been deleted.
                //cls.exclude(obj => typeIds.includes(obj.id) && obj[`${action.type}_id`] !== typeId).delete();
                //action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj))); 
                cls.all().delete();
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj))); 
                break;
            default:
                break;
        }
    }
}

export class Model extends BaseModel {
    static get source() {
        return `/api/db/${this.pluralName}`;
    }
    static get pluralName() {
        return `${this.modelName.toLowerCase()}s`;
    }
    static get isUserData() {
        return true;
    }
    static get loadOnInit(){
        return false;
    }
    static get adminDefault(){
        return false;
    }
    static get drainageDefault(){
        return false;
    }
    static get repairDefault(){
        return false;
    }
    static get violationDefault(){
        return false;
    }
    static get bmpDefault(){
        return false;
    }

    static createAction({ type, payload, effectIfLoggedIn, generateId }) {
        return (dispatch, getState) => {
            const state = getState();
            const { auth } = state;
            if (generateId) {
                if (!payload) {
                    payload = {};
                }
                payload.id = this.generateId(payload);
            }
            let action = {
                type: type,
                payload: payload
            };
            if (isRealUser(auth)) {
                action.meta = {
                    offline: {
                        effect: {
                            ...effectIfLoggedIn,
                            body: JSON.stringify(this.toRequest(payload)),
                            headers: {
                                Accept: "application/json",
                                Authorization: "Token " + auth.user.auth_token
                            }
                        },
                        commit: {
                            type: `${type}_PUSHED`
                        },
                        rollback: {
                            type: `${type}_PUSHERROR`,
                            meta: { objectId: payload.id }
                        }
                    }
                };
            }
            dispatch(action);
            if (generateId) {
                return payload.id;
            }
        };
    }

    static fail(message) {
        message = `ORM Error: ${message}`;
        console.error(message);
        throw new Error(message);
    }

    static generateId(payload) {
        if (payload.id) {
            this.fail(`Unexpected id in orm${this.modelName}Create payload`);
        }

        return Math.floor(Math.random() * (99000000000 - 1000000000) + 1000000000);
    }

    static toRequest(data) {
        var req = {};
        Object.entries(data).forEach(([key, value]) => {
            if (this.fields[key] instanceof ForeignKey) {
                req[key + "_id"] = value;
            } else if (!(key in req)) {
                req[key] = value;
            }
        });
        return req;
    }

    static fromResponse(data) {
        Object.keys(data).forEach(key => {
            const field = key.replace(/_id$/, "");
            if (field !== key && this.fields[field] instanceof ForeignKey) {
                data[field] = data[key];
                delete data[key];
            }
        });
        return data;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`,
            baseActions = super.actions;

        return {
            [`${functionPrefix}Create`]: payload => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_CREATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_CREATE_PUSHING`
                    });
                    return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
                        method: "POST",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then(data => {
                            data = cls.fromResponse(data);
                            dispatch({
                                type: `${typePrefix}_CREATE`,
                                payload: data
                            });
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHED`
                            });
                            return data.id;
                        })
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                        
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHERROR`,
                                error: e
                            })
                        });
                };
            },
            [`${functionPrefix}CreateForm`]: formData => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_CREATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_CREATE_PUSHING`
                    });
                    return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
                        method: "POST",
                        body: formData,
                        headers: {
                            //Accept: "application/json",
                            //"Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then(data => {
                            data = cls.fromResponse(data);
                            dispatch({
                                type: `${typePrefix}_CREATE`,
                                payload: data
                            });
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHED`
                            });
                            return data.id;
                        })
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                        
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHERROR`,
                                error: e
                            })
                        });
                };
            },
            [`${functionPrefix}LoadDetail`]: (id, callback) => {
                var cls = this;
                var modelName = cls.modelName;
                return function(dispatch, getState) {
                    const state = getState();
                    const { auth } = state;
                    
                    if(!isRealUser(auth)){
                        return;
                    }

                    /*if(!cls.loadOnInit){
                        return;
                    }*/

                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}/${id}?format=json`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            // Data comes back as an object - needs to be an array?
                            if (!(data instanceof Object)) {
                                throw new Error(data.detail || data);
                            }
                            
                            var temp;
                            //return foreign key objects just want key
                            if(modelName === "Repair"){
                                temp = data["drainage"];
                                data["drainage"] = temp.id;
                            }
                            if(modelName === "Violation"){
                                temp = data["drainage"];
                                data["drainage"] = temp.id;
                            }
                            if(modelName === "Condition"){
                                temp = data["drainage"];
                                data["drainage"] = temp.id;
                            }
                            if(modelName === "Bmp"){
                                temp = data["drainage"];
                                data["drainage"] = temp.id;
                            }
                            return data;
                        })
                        .then(data => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data
                            });
                            if (callback) callback(data);
                        })
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }

                            dispatch({
                                type: `${typePrefix}_DETAIL_ERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}CreateLocalOnly`] : payload => ({
                type: `${typePrefix}_CREATE`,
                payload: payload
            }),
            [`${functionPrefix}UpdateForm`]: formData => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_UPDATE_PUSHING`
                    });
                    return fetch(`${cls.source}/${formData.get('id')}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: formData,
                        headers: {
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        data = cls.fromResponse(data);
                        dispatch({
                            type: `${typePrefix}_UPDATE`,
                            payload: data
                        });
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHED`
                        });
                        return data.id;
                    })
                    .catch(e => {
                        if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                            dispatch({
                                type: AUTH_LOGOUT
                            });
                        }
                    
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: e
                        })
                    });
                };
            },
            [`${functionPrefix}UpdateBatch`]: (url, id, payload) => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_BATCH_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_UPDATE_BATCH_PUSHING`
                    });
                    var final_url = `${url}/batch`;
                    if(id !== null){
                        final_url = `${url}/${id}/batch`;
                    }
                    return fetch(final_url, {
                        method: "POST",
                        body: JSON.stringify(payload),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        if(data && data.message){
                            return data;	
                        }

                        data = cls.fromResponse(data);
                        dispatch({
                            type: `${typePrefix}_UPDATE_BATCH`,
                            payload: data
                        });
                        dispatch({
                            type: `${typePrefix}_UPDATE_BATCH_PUSHED`
                        });
                        return data;
                    })
                    .catch(e => {
                        if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                            dispatch({
                                type: AUTH_LOGOUT
                            });
                        }
                    });
                };
            },
            [`${functionPrefix}UpdatePromise`]: payload => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_UPDATE_PUSHING`
                    });
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        data = cls.fromResponse(data);
                        dispatch({
                            type: `${typePrefix}_UPDATE`,
                            payload: data
                        });
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHED`
                        });
                        return data.id;
                    })
                    .catch(e => {
                        if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                            dispatch({
                                type: AUTH_LOGOUT
                            });
                        }
                    
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: e
                        })
                    });
                };
            },
            [`${functionPrefix}UpdatePromiseData`]: payload => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_UPDATE_PUSHING`
                    });
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        data = cls.fromResponse(data);
                        dispatch({
                            type: `${typePrefix}_UPDATE`,
                            payload: data
                        });
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHED`
                        });
                        return data;
                    })
                    .catch(e => {
                        if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                            dispatch({
                                type: AUTH_LOGOUT
                            });
                        }
                    
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: e
                        })
                    });
                };
            },
            [`${functionPrefix}Update`]: payload =>
                this.createAction({
                    type: `${typePrefix}_UPDATE`,
                    payload: payload,
                    effectIfLoggedIn: {
                        url: `${this.source}/${payload.id}?t=${Date.now()}`,
                        method: "PATCH"
                    }
                }),
            [`${functionPrefix}UpdateLocalOnly`]: payload => ({
                type: `${typePrefix}_UPDATE`,
                payload: payload
            }),
            [`${functionPrefix}DeletePromise`]: id => {
                var cls = this;
                return function(dispatch, getState) {
                    // Used when you need to wait for request to perform something because with offilne it isn't easy to catch
                    // when operation is performed with out bunch of work.
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    return fetch(`${cls.source}/${id}?&t=${Date.now()}`, {
                        method: "DELETE",
                        payload: { id },
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DELETE`,
                                    payload: { id: id }
                                });
                                dispatch({
                                    type: `${typePrefix}_PUSHED`
                                });
                                return id;
                            }
                        })
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                        
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHERROR`,
                                error: e
                            })
                        });
                };
            },
            [`${functionPrefix}DeleteFormPromise`]: payload => {
                var cls = this;
                return function(dispatch, getState) {
                    // Used when you need to wait for request to perform something because with offilne it isn't easy to catch
                    // when operation is performed with out bunch of work.
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    const id = payload.id;
                    return fetch(`${cls.source}/${id}?&t=${Date.now()}`, {
                        method: "DELETE",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DELETE`,
                                    payload: { id: id }
                                });
                                dispatch({
                                    type: `${typePrefix}_PUSHED`
                                });
                                return id;
                            }
                        })
                        .catch(e => {
                            if (e.message === "Invalid token." || e.message === "Token has expired." || e.message === '{"detail":"Invalid token."}') {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                        
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHERROR`,
                                error: e
                            })
                        });
                };
            },
            [`${functionPrefix}DeleteLocalOnly`]: objId => ({
                type: `${typePrefix}_DELETE`,
                payload: { id: objId }
            }),
            [`${functionPrefix}Delete`]: objId =>
                this.createAction({
                    type: `${typePrefix}_DELETE`,
                    payload: { id: objId },
                    effectIfLoggedIn: {
                        url: `${this.source}/${objId}?&t=${Date.now()}`,
                        method: "DELETE"
                    }
                }),
            ...baseActions
        };
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`,
            errorPattern = new RegExp(`^${prefix}_([^_]+)_PUSHERROR$`),
            { payload, meta } = action,
            objId = (payload && payload.id) || (meta && meta.objectId);
        switch (action.type) {
            case `${prefix}_CREATE`:
                cls.create(payload || {});
                break;
            case `${prefix}_UPDATE`:
            case `${prefix}_CREATE_PUSHED`:
            case `${prefix}_UPDATE_PUSHED`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update(action.payload);
                break;
            case `${prefix}_UPDATE_BATCH`:
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_DETAIL_LOADED`:
                /*if (!cls.hasId(objId)) {
                    break;
                }
                action.payload.synced = true;
                cls.withId(objId).update(action.payload);*/
                cls.upsert(action.payload); 
                break;
            case `${prefix}_CREATE_PUSHERROR`:
            case `${prefix}_UPDATE_PUSHERROR`:
            case `${prefix}_UPDATE_BATCH_PUSHERROR`:
            case `${prefix}_DETAIL_ERROR`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update({
                    serverError: payload.response || payload.status
                });
                break;
            case `${prefix}_DELETE`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).delete();
                break;
            case `${prefix}_DELETE_ALL_RECORDS`:
                cls.all().delete();
                break;
            default:
                if (action.type.match(errorPattern)) {
                    console.warn(action);
                } else {
                    super.reducer(action, cls);
                }
        }
    }

    _onDelete() {
        const virtualFields = this.getClass().virtualFields;
        for (const key in virtualFields) {
            // eslint-disable-line
            if (this[key] !== null) {
                const relatedQs = this[key];
                if (relatedQs.exists()) {
                    relatedQs.delete();
                }
            }
        }
    }
}

// reloadAll is called in a variety of places - most noticeably if a user first hits the main pages this is called, and then called again when they log in.
// This creates a backload of requests which can take 10-20 or more seconds to clear out.
// To help alleviate this only call every model once during the initial page load in index.js.
// But everywhere else we don't need to reload the ReadOnlyModels (!isUserData) since they never change.
// This should cut down the number of requests to the server.
export function reloadAll() {
    return function(dispatch) {
        orm.registry.forEach(model => {
            const fn = model.actions[`orm${model.modelName}Reload`];
            dispatch(fn());
        });
    };
}

export function reloadAdmin() {
    return function(dispatch) {
        orm.registry.forEach(model => {
            const fn = model.actions[`orm${model.modelName}ReloadAdmin`];
            dispatch(fn());
        });
    };
}

export function reloadListOfModels(models) {
    return function(dispatch) {
        models.forEach(x => {
            const model = orm.registry.find(z => z.modelName === x);
            const fn = model.actions[`orm${model.modelName}Reload`];
            dispatch(fn());
        });
    };
}

// Basically empties redux. Used on log out to reset redux in case any issues with data in the store.
export function clearAll() {
    return function(dispatch) {
        orm.registry.forEach(model => {
            dispatch({
                type: `ORM_${model.modelName.toUpperCase()}_DELETE_ALL_RECORDS`
            });
        });
    };
}

export function ormLogOutSync() {
    return function(dispatch) {
        dispatch({
            type: "ORM_LOGOUT_SYNC"
        });
    };
}

export function reloadByDrainage(objId) {
    return function(dispatch) {
        /*orm.registry.forEach(model => {
            const fn = model.actions[`orm${model.modelName}ReloadByDrainage`];
            dispatch(fn(objId));
        });*/

        // All models that have related objects needs to be loaded first otherwise
        // when loaded it will delete all models and related.
        const firstModels = ['Repair', 'Condition', 'Violation', 'Bmp'];

        orm.registry.forEach(model => {
            if(firstModels.includes(model.modelName)){
                const fn = model.actions[`orm${model.modelName}ReloadByDrainage`];
                dispatch(fn(objId));
            }
        });

        orm.registry.forEach(model => {
            if(!firstModels.includes(model.modelName)){
                const fn = model.actions[`orm${model.modelName}ReloadByDrainage`];
                dispatch(fn(objId));
            }
        });

    };
}

export function reloadByType(objId, type) {
    return function(dispatch) {
        orm.registry.forEach(model => {
            const fn = model.actions[`orm${model.modelName}ReloadByType`];
            dispatch(fn(objId, type));
        });
    };
}

export function syncReducer(state = {}, action) {
    let { type } = action;
    if (action.meta && action.meta.offline) {
        type = `${type}_PUSHING`;
    }
    const logOutSyncPattern = /^ORM_LOGOUT_SYNC$/;
    const pushPattern = /^ORM_([^_]+)_([^_]+)_(PUSH[^_]+)$/;
    const pullPattern = /^ORM_([^_]+)_((PULL[^_]+))$/;
    const pullDrainagePattern = /^ORM_([^_]+)_((PULLED_DRAINAGE))$/;
    const pullTypePattern = /^ORM_([^_]+)_((PULLED_TYPE))$/;
    const detailPattern = /^ORM_([^_]+)_(DETAIL)_([^_]+)$/;
    
    var match = type.match(pushPattern) || type.match(pullPattern) || type.match(detailPattern) || type.match(pullDrainagePattern) || type.match(pullTypePattern) || type.match(logOutSyncPattern);
    if (!match) {
        return state;
    }

    if (type.match(logOutSyncPattern)) {
        let count = 0;
        orm.registry.forEach(model => {
            if (!model.isUserData) {
                return;
            }
            count += 1;
        });
        return {
            ready: true,
            progress: count,
            total: count,
            pending: {},
            error: {}
        };
    }

    let pending = {},
        error = {};
    let [, modelName, actionName, statusName] = match;
    let total = 0;
    orm.registry.forEach(model => {
        if (!model.isUserData) {
            return;
        }
        total += 1;
        if (model.modelName.toUpperCase() === modelName) {
            modelName = model.modelName;
        } else if (state.error && state.error[model.modelName]) {
            error[model.modelName] = state.error[model.modelName];
        } else if (state.pending && state.pending[model.modelName]) {
            pending[model.modelName] = state.pending[model.modelName];
        }
    });

    switch (statusName) {
        case "PUSHING":
        case "PULLING":
        case "LOADING":
            pending[modelName] = actionName;
            break;
        case "PUSHED":
        case "PULLED":
        case "LOADED":
            break;
        case "PUSHERROR":
        case "PULLERROR":
        case "ERROR":
            error[modelName] = actionName;
            break;
        default:
            break;
    }
    const pendingCount = Object.keys(pending).length;
    let progress, ready;
    if (pendingCount > 0) {
        progress = total - pendingCount;
        ready = false;
    } else {
        progress = total;
        ready = true;
    }
    return {
        ready,
        progress,
        total,
        pending,
        error
    };
}
