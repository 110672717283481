import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class BoardDate extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "BoardDate";
    }
    static get loadOnInit(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(BoardDate);

export class User extends Model {
    static get fields() {
        return {
            vendor: fk("Vendor", "vendor_user"),
        };
    }
    static get modelName() {
        return "User";
    }
    static get adminDefault() {
        return true;
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(User);

export class Agol extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Agol";
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Agol);

export class MapLayer extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "MapLayer";
    }
    static get adminDefault() {
        return true;
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(MapLayer);


export class GISAutoPopulate extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "GISAutoPopulate";
    }
    static get adminDefault() {
        return true;
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(GISAutoPopulate);

export class MapSetting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "MapSetting";
    }
    static get loadOnInit(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(MapSetting);

export class PictometrySetting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "PictometrySetting";
    }
    static get loadOnInit(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(PictometrySetting);

export class DrainageAnnual extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "DrainageAnnual";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageAnnual);

export class DrainageAssessment extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "DrainageAssessment";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageAssessment);

export class DrainageExpenditure extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "DrainageExpenditure";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageExpenditure);

export class DrainageSystem extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "DrainageSystem";
    }
    static get loadOnInit(){
        return false;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(DrainageSystem);

export class Drainagenote extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageNote")
        };
    }
    static get modelName() {
        return "Drainagenote";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(Drainagenote);

export class DrainageLegal extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageLegal")
        };
    }
    static get modelName() {
        return "DrainageLegal";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageLegal);

export class DrainageLegalDetail extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageLegal_SysDetail"),
            legalid: fk("DrainageLegal", "drainageLegal_Detail")
        };
    }
    static get modelName() {
        return "DrainageLegalDetail";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageLegalDetail);

export class DrainageLegalAttach extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageLegal_Attach")
        };
    }
    static get modelName() {
        return "DrainageLegalAttach";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(DrainageLegalAttach);

export class Photosdrainage extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "photosDrainage")
        };
    }
    static get modelName() {
        return "Photosdrainage";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(Photosdrainage);

export class Documentsdrainage extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "documentsDrainage")
        };
    }
    static get modelName() {
        return "Documentsdrainage";
    }

    static get drainageDefault(){
        return true;
    }
}
orm.register(Documentsdrainage);

export class Documentsviolation extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "documentsviolation"),
            vnum: fk("Violation", "doc_violation")
        };
    }
    static get modelName() {
        return "Documentsviolation";
    }
    static get violationDefault(){
        return true;
    }
}
orm.register(Documentsviolation);

export class Repair extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "system")
        };
    }
    static get modelName() {
        return "Repair";
    }
    static get loadOnInit(){
        return false;
    }
    static get drainageDefault(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Repair);

export class Violation extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "vsystem")
        };
    }
    static get modelName() {
        return "Violation";
    }
    static get loadOnInit(){
        return false;
    }
    static get drainageDefault(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Violation);

export class Peoplelandowner extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "peoplelandownersystem"),
            repair: fk("Repair", "repair_landowner")
        };
    }
    static get modelName() {
        return "Peoplelandowner";
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(Peoplelandowner);

export class PeopleRequest extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "peoplerequest"),
            repair: fk("Repair", "requesting")
        };
    }
    static get modelName() {
        return "PeopleRequest";
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
    static get adminDefault() {
        return false;
    }
}
orm.register(PeopleRequest);

export class Tracking extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "tracking"),
            repair: fk("Repair", "repair_tracking")
        };
    }
    static get modelName() {
        return "Tracking";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Tracking);

export class TrackingBoard extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "trackingboard"),
            repair: fk("Repair", "repair_tracking_board")
        };
    }
    static get modelName() {
        return "TrackingBoard";
    }
    static get loadOnInit(){
        return true;
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(TrackingBoard);

export class Inspection extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainagerepair_inspection"),
            repair: fk("Repair", "repair_inspection"),
            user: fk("User", "repair_inspection_user"),
        };
    }
    static get modelName() {
        return "Inspection";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Inspection);

export class PhotoInspection extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_photo_inspection"),
            repair: fk("Repair", "repair_photo_inspection"),
            inspection: fk("Inspection", "photo_inspection")
        };
    }
    static get modelName() {
        return "PhotoInspection";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(PhotoInspection);

export class Vendor extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Vendor";
    }
    static get repairDefault(){
        return false;
    }
    static get loadOnInit(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Vendor);

export class Invoice extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "invoice"),
            repair: fk("Repair", "repair_invoice"),
            vendor: fk("Vendor", "vendor_invoice"),
        };
    }
    static get modelName() {
        return "Invoice";
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(Invoice);

export class InvoiceCost extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "invoice_cost"),
            repair: fk("Repair", "repair_invoicecost"),
            ordernumb: fk("Invoice", "invoice")
        };
    }
    static get modelName() {
        return "InvoiceCost";
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(InvoiceCost);

export class Estimate extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_estimate"),
            repair: fk("Repair", "repair_estimate")
        };
    }
    static get modelName() {
        return "Estimate";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Estimate);

export class EstimateCost extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_estimatecost"),
            repair: fk("Repair", "repair_estimatecost"),
            ordernumb: fk("Estimate", "estimate")
        };
    }
    static get modelName() {
        return "EstimateCost";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(EstimateCost);

export class Local_twp extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Local_twp";
    }
    static get loadOnInit(){
        return false;
    }
}
orm.register(Local_twp);

export class Local_Range extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Local_Range";
    }
    static get loadOnInit(){
        return false;
    }
}
orm.register(Local_Range);

export class Comm_Dist extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Comm_Dist";
    }
}
orm.register(Comm_Dist);

export class Local_Twpshipxy extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Local_Twpshipxy";
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(Local_Twpshipxy);

export class Local_Map extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Local_Map";
    }
}
orm.register(Local_Map);

export class Local_commish extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Local_commish";
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(Local_commish);

export class Sections extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Sections";
    }
}
orm.register(Sections);

export class Inspectionviolation extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageviolation_inspection"),
            violation: fk("Violation", "violation_inspection")
        };
    }
    static get modelName() {
        return "Inspectionviolation";
    }
    static get violationDefault(){
        return true;
    }
}
orm.register(Inspectionviolation);

export class PhotoviolationInspection extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainageviolation_inspectionphoto"),
            violation: fk("Violation", "violation_inspectionphoto"),
            inspection: fk("Inspection", "violation_photo")
        };
    }
    static get modelName() {
        return "PhotoviolationInspection";
    }
    static get violationDefault(){
        return true;
    }
}
orm.register(PhotoviolationInspection);

export class Condition extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_condition")
        };
    }
    static get modelName() {
        return "Condition";
    }
    static get loadOnInit(){
        return false;
    }
    static get drainageDefault(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Condition);

export class Bmp extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_bmp")
        };
    }
    static get modelName() {
        return "Bmp";
    }
    static get loadOnInit(){
        return false;
    }
    static get drainageDefault(){
        return true;
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Bmp);

export class PhotosCondition extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_photoscondition"),
            condition: fk("Condition","photoscondition")
        };
    }
    static get modelName() {
        return "PhotosCondition";
    }
    static get conditionDefault(){
        return true;
    }
}
orm.register(PhotosCondition);

export class PhotosBmp extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_photosbmp"),
            bpm: fk("Bmp", "photosbmp")
        };
    }
    static get modelName() {
        return "PhotosBmp";
    }
    static get bmpDefault(){
        return true;
    }
}
orm.register(PhotosBmp);

export class Photos extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "draiange_repair_photos"),
            repair: fk("Repair", "repair_photos"),
        };
    }
    static get modelName() {
        return "Photos";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Photos);

export class Documentsrepair extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_repair_documents"),
            repair: fk("Repair", "repair_documents"),
        };
    }
    static get modelName() {
        return "Documentsrepair";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Documentsrepair);

/// Table Views

export class PeopleRequestView extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "peoplerequest_view"),
            repair: fk("Repair", "requesting_view")
        };
    }
    static get modelName() {
        return "PeopleRequestView";
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(PeopleRequestView);

export class DrainageSystemView extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "DrainageSystemView";
    }
    static get loadOnInit(){
        return true;
    }
    static get defaultKeySort() {
        return "drainage";
    }
}
orm.register(DrainageSystemView);

export class RepairView extends Model {
    static get fields() {
        return {
            drainage_id: fk("DrainageSystemView", "system_view")
        };
    }
    static get modelName() {
        return "RepairView";
    }
    static get loadOnInit(){
        return true;
    }
    static get defaultKeySort() {
        return "repair";
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(RepairView);

export class ConditionView extends Model {
    static get fields() {
        return {
            drainage_id: fk("DrainageSystemView", "condition_view")
        };
    }
    static get modelName() {
        return "ConditionView";
    }
    static get defaultKeySort() {
        return "condition";
    }
    static get loadOnInit(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(ConditionView);

export class BmpView extends Model {
    static get fields() {
        return {
            drainage_id: fk("DrainageSystemView", "bmp_view")
        };
    }
    static get modelName() {
        return "BmpView";
    }
    static get defaultKeySort() {
        return "bmpnum";
    }
    static get loadOnInit(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(BmpView);

export class ViolationView extends Model {
    static get fields() {
        return {
            drainage_id: fk("DrainageSystemView", "violation_view")
        };
    }
    static get modelName() {
        return "ViolationView";
    }
    static get defaultKeySort() {
        return "vnum";
    }
    static get loadOnInit(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(ViolationView);

export class CheckBook extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_checkbook"),
        };
    }
    static get modelName() {
        return "CheckBook";
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(CheckBook);

export class Transfer extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_transfer"),
        };
    }
    static get modelName() {
        return "Transfer";
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(Transfer);

export class JointOptions extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "JointOptions";
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(JointOptions);

export class JointSystem extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_jointparentsystem"),
            county: fk("JointOptions", "joint_county"),
        };
    }
    static get modelName() {
        return "JointSystem";
    }
    static get drainageDefault(){
        return true;
    }
}
orm.register(JointSystem);


export class AgolDrainageOption extends Model {
    static get fields() {
        return {
            drainage: fk("DrainageSystem", "drainage_agoloption"),
        };
    }
    static get modelName() {
        return "AgolDrainageOption";
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(AgolDrainageOption);

export class Contractor extends Model {
    static get fields() {
        return {
            user: fk("Repair", "contractor_user"),
            repair: fk("Repair", "contractor_repair"),
        };
    }
    static get modelName() {
        return "Contractor";
    }
    static get repairDefault(){
        return true;
    }
    static get drainageDefault(){
        return true;
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(Contractor);

export class Correspondence extends Model {
    static get fields() {
        return {
            user: fk("User", "correspondence_user"),
            recipient: fk("User", "correspondence_recipient"),
            repair: fk("Repair", "correspondence_repair"),
        };
    }
    static get modelName() {
        return "Correspondence";
    }
    static get repairDefault(){
        return true;
    }
}
orm.register(Correspondence);


export class NotificationType extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "NotificationType";
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(NotificationType);

export class Notification extends Model {
    static get fields() {
        return {
            notification_type: fk("NotificationType", "notification_type"),
            user: fk("User", "notification_user"),
        };
    }
    static get modelName() {
        return "Notification";
    }
    static get adminDefault() {
        return true;
    }
}
orm.register(Notification);

export class Announcement extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Announcement";
    }
    static get loadOnInit(){
        return true;
    }
}
orm.register(Announcement);
