import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import { addToForm, addtoFormFile } from "../../api/utils";
import { withStyles, Grid, TableBody, Table, TableRow, Tooltip, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import Select from "../common/Select";
import TextField from "../common/TextField";
import FileFormInput from "../common/FileFormInput";
import WarningDialog from "../common/WarningDialog";
import PageHeader from "../common/PageHeader";
import HelpLabel from "../common/HelpLabel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import RadioGroup from "../common/RadioGroup";
import Subquestion from "../common/Subquestion";
import { Estimate, EstimateCost } from "../mypage/models";
import { createSelector } from '../common/orm';
import { panelChange } from "../common/actions";

const getEstimates = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.Estimate.filter(x => x.repair === repair.id).orderBy("estimatenumb", "acs").toRefArray();
    }
);

const getEstimateCosts = createSelector(
    (state, ownProps) => ownProps.repair,
    (session, repair) => {
        return session.EstimateCost.filter(x => x.repair === repair.id).toRefArray();
    }
);

const getBoardDate = createSelector(session => {
    return session.BoardDate.first();
});
const getVendors = createSelector(schema => {
    return schema.Vendor.all().orderBy('name').toModelArray();
});

const styles = theme => ({
    table: {
        minWidth: 500,
        marginTop: 10
    },
    space: {
        marginTop: 48
    },
    mb: {
        marginBottom: 10
    },
    mt: {
        marginTop: 35
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        grow: 1,
        display: "inline-flex",
    },
    right: {
        float: "right"
    },
    grow: {
        display: "inline-flex",
        flex: 1,
        marginTop: 20
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8"
        }
    },
});

const esitmateColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "estimatenumb", numeric: true, label: "Order #", allowSort: true},
    {id: "datecreated", numeric: false, label: "Date Created", allowSort: true, date: true},
    {id: "boarddate", numeric: false, label: "Board Action Date", allowSort: true, date: true},
    {id: "contractor", numeric: false, label: "Contractor", allowSort: true },
    {id: "vendor", numeric: false, label: "Vendor", allowSort: true },
    {id: "totalcost", numeric: true, label: "Total", allowSort: true },
    {id: "attachment", numeric: false, label: "Attachment", allowSort: false },
    {id: "notes", numeric: false, label: "Notes", allowSort: true },
];

const costColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "unittype", numeric: false, label: "Cost Type", allowSort: true },
    {id: "quanity", numeric: false, label: "Quantity", allowSort: true },
    {id: "perunit", numeric: false, label: "Per Units Costs", allowSort: true },
    {id: "unittype", numeric: false, label: "Units", allowSort: true },
    {id: "totalcost", numeric: true, label: "Total", allowSort: true },
    {id: "costcomments", numeric: false, label: "Cost Notes", allowSort: true },
];

const costTypes = [
    {label: "Labor" , value: "Labor"},
    {label: "Material" , value: "Material"},
    {label: "Equipment" , value: "Equipment"},
    {label: "Other" , value: "Other"},
];

class Estimates extends Component {
    state = {
        confirmUpload: false, 
        loading: false,
        estimateDelete: false, 
        estimateID: null,
        costDelete: false,
        costObj: null,
        activeId: null,
        activeCost: null,
        estimateDialog: false,
        costDialog: false,
        selectedEstimate: null,
        snackOpen: false,
        calculated: ""
    }

    handleFile = (val, formApi) => {
        const { fileSize } = this.props;
        
        if(val && (val.size / 1000000 > 250)){
            formApi.setValue("attachment", null);
            formApi.setValue("lock_submit", false);
            this.setState({file: null, confirmUpload: false});
            fileSize();
        } else if(val && (val.size / 1000000 > 100)){
            this.setState({confirmUpload: true, calculated: `${Math.round((val.size / 1000000) / 40)} Minutes`})
        } else {
            formApi.setValue("lock_submit", false);
            this.setState({file: val, confirmUpload: false});
        }
    }

    createEstimate = values => {
        const { boardDate, ormEstimateCreateForm, repair, saveBad, saveGood } = this.props;

        var form_data = new FormData();
        form_data.append('drainage_id', repair.drainage);
        form_data.append('repair_id', repair.id);
        form_data.append('totalcost', "0.00");
        addToForm(form_data, 'boarddate', boardDate ? boardDate.date : null);
        ormEstimateCreateForm(form_data).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
            this.setState({activeId: id, estimateDialog: true, rowSelected: id, file: null});
        });
    }

    saveEstimate = values => {
        const { ormEstimateUpdatePromise, ormEstimateUpdateForm, saveBad, saveGood } = this.props;
        const { file } = this.state;

        var form_data = new FormData();
        form_data.append('id', values.id);
        form_data.append('drainage_id', values.drainage);
        form_data.append('repair_id', values.repair);
        form_data.append('totalcost', values.totalcost);
        addtoFormFile(form_data, 'attachment', file)
        addToForm(form_data, 'comments', values.comments)
        addToForm(form_data, 'vendor', values.vendor)
        addToForm(form_data, 'contractor', values.contractor)
        this.setState({loading: true});

        if(file !== null){
            ormEstimateUpdateForm(form_data).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({estimateDialog: false, selectedEstimate: values.id});
                    saveGood();
                }
            });
        } else {
            ormEstimateUpdatePromise({
                ...values
            }).then(id => {
                this.setState({loading: false});
                if(!id){
                    saveBad();
                } else {
                    this.setState({estimateDialog: false, selectedEstimate: values.id});
                    saveGood();
                }
            });
        }
    }

    estimateDelete = id => {
        const { ormEstimateDeletePromise, saveBad, saveGood } = this.props;
        ormEstimateDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    createCost = values => {
        const { ormEstimateCostCreate, saveBad, saveGood } = this.props;
        
        ormEstimateCostCreate({
            ...values
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    saveCost = values => {
        const { costs, ormEstimateCostUpdatePromise, ormEstimateUpdatePromise, saveBad, saveGood } = this.props;
        const { activeCost } = this.state;
        var totalcost = parseFloat(values["perunit"]) * parseFloat(values["quanity"] || "0");

        totalcost = isNaN(totalcost) ? "" : totalcost;
        values["totalcost"] = totalcost;
        ormEstimateCostUpdatePromise({
            ...values,
        }).then(id => {
            if(!id){
                this.setState({snackOpen: true});
            } else {
                this.setState({costDialog: false});

                var total = 0;
                costs.forEach(cost => {
                    if(cost.ordernumb === activeCost.ordernumb && cost.id !== values.id && cost.totalcost){
                        total += parseFloat(cost.totalcost);
                    }
                });
                if(typeof(total) === "undefined"){
                    total = totalcost;
                } else {
                    total += totalcost;
                }

                ormEstimateUpdatePromise({
                    id: values.ordernumb,
                    totalcost: total
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                });
            }
        });
    }

    costDelete = (id, cost, estimateID) => {
        const { estimates, ormEstimateCostDeletePromise, ormEstimateUpdatePromise, saveBad, saveGood } = this.props;
        ormEstimateCostDeletePromise(id).then(id => {
            if(!id){
                this.setState({snackOpen: true});
            }
        });
        
        var esitmate = estimates.find(x => x.id === estimateID);

        var total = parseFloat(esitmate.totalcost) - parseFloat(cost || "0.00");
        ormEstimateUpdatePromise({
            id: estimateID,
            totalcost: total
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });

    }

    addCost = (id, order) => {
        const { ormEstimateCostCreate, repair, saveBad, saveGood } = this.props;
        
        ormEstimateCostCreate({
            ordernumb_id: id,
            drainage_id: repair.drainage,
            repair_id: repair.id,
            ordernumb_old: order,
            perunit: 0,
            quanity: 0,
            totalcost: "0.00"
        }).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
    }

    handleChange = (e, api) => {
        const { vendors } = this.props;

        if(e){
            var vendor = vendors.find(x => x.name === e);
            api.setValue("vendor", vendor.number);
        }
    }

    totalCost = (api) => { 
        const { activeCost } = this.props;
        var perunit = api.values["perunit"];
        var quanity = api.values["quanity"];
        if(typeof(perunit) === undefined){
            perunit = activeCost.perunit;
        } else {
            perunit = parseFloat(perunit);
        }
        if(typeof(perunit) === undefined){
            quanity = activeCost.quanity;
        }

        var total = parseFloat(perunit) * parseFloat(quanity);
        
        return isNaN(total) ? "" : total.toFixed(2);
    }

    displayCost = (value) => {
        if(value){
            var parse = parseFloat(value);
            if(parse >= 0){
                return `$${parse.toLocaleString(undefined, {minimumFractionDigits: 2})}`;
            } else {
                return `($${Math.abs(parse).toLocaleString(undefined, {minimumFractionDigits: 2})})`;
            }
        } else{
            return "$0.00";
        }
    }

    handleRequestSort = (event, property, propName) => {
        const orderBy = property;
        let order = "desc";
        if (this.state[`${propName}OrderBy`] === property && this.state[`${propName}Order`] === "desc") {
            order = "asc";
        }

        const columns = propName === "estimates" ? esitmateColumnData : costColumnData;

        this.props[propName].sort((a,b) => {
            var date = columns.find(cD => cD.id === orderBy).date;
            var numeric = columns.find(cD => cD.id === orderBy).numeric;
            var bool = columns.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined" || isNaN(parseFloat(a[orderBy]))) return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined" || isNaN(parseFloat(b[orderBy]))) return -1;

                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });
        this.setState({ [`${propName}Order`]: order, [`${propName}OrderBy`]: orderBy });
    };

    render(){
        const { authState, classes, costs, estimates, system, repair, vendors } = this.props;
        const { 
            activeCost, 
            activeId, 
            estimateDelete, 
            estimateID, 
            costObj, 
            costDelete, 
            costDialog, 
            estimateDialog, 
            selectedEstimate, 
            rowSelected,
            confirmUpload,
            loading,
            calculated
        } = this.state;

        const vendorOptions = vendors.map(x => {
            return {
                label: x.name, value: x.name
            };
        });

        const activeEstimate = estimates.find(x => x.id === activeId);

        const viewOnly = authState.user.role === "View Only";

        //if it doesn't exist the delete button was clicked
        const estimateCosts = estimates.find(x => x.id === selectedEstimate) ? costs.filter(x => x.ordernumb === selectedEstimate) : [];

        return(
            <Grid container>
                <PageHeader topSpace={true} title={"Drainage System : "} drainage={system} rightTitle={"Repair #: " + repair.repair} />
                <Typography variant="h5" className={classes.grow}>Repair Estimates</Typography>
                <Divider className={classes.mb}/>
                <Grid container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                onClick={this.createEstimate}
                                disabled={viewOnly}
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                >
                                <AddCircleOutlineIcon /> 
                                &nbsp;&nbsp;Add
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{overflowX: "auto"}}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={this.state.estimatesOrder}
                                orderBy={this.state.estimatesOrderBy}
                                propName="estimates"
                                columnData={esitmateColumnData}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {estimates.map( (n, index) => { return (
                                    <TableRow
                                        selected={n.id === rowSelected} 
                                        hover 
                                        key={n.id} 
                                        onClick={() => {this.setState({selectedEstimate: n.id, rowSelected: n.id})}}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            <Tooltip title="Report">
                                                <Button
                                                    color="primary"
                                                    target="_blank" 
                                                    href={`/reports/drainagerepair/${repair.id}/${n.boarddate}/${n.id}/${n.vendor || " "}/estimate?company=${n.contractor || " "}`}
                                                    className={classes.deleteWidth}
                                                >
                                                    <AssignmentIcon color="primary"/> 
                                                </Button>
                                            </Tooltip>
                                            {!viewOnly && (
                                                <>
                                                    <Tooltip title="Add Cost">
                                                        <Button
                                                            onClick={() => {this.addCost(n.id, n.estimatenumb)}}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <AddCircleOutlineIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={() => {this.setState({file: null, activeId: n.id, activeEstimate: n, estimateDialog: true})}} 
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({estimateDelete: true, estimateID: n.id})}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <DeleteIcon /> 
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.estimatenumb}</CustomTableCell>
                                        <CustomTableCell>{n.datecreated}</CustomTableCell>
                                        <CustomTableCell>{n.boarddate}</CustomTableCell>
                                        <CustomTableCell>{n.contractor ? n.contractor : "-"}</CustomTableCell>
                                        <CustomTableCell>{n.vendor ? n.vendor : "-"}</CustomTableCell>
                                        <CustomTableCell>{this.displayCost(n.totalcost)}</CustomTableCell>
                                        <CustomTableCell>
                                            {n.attachment && (
                                                <Tooltip title="View Attachment">
                                                    <a target="_blank" rel="noopener noreferrer" href={n.attachment} className={classes.linkColor}>
                                                        Open File
                                                    </a>
                                                </Tooltip>
                                                )
                                            }
                                            {!n.attachment &&("-")}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.comments ? n.comments : "-"}</CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {estimates.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={esitmateColumnData.length} className={classes.centerAlign}>
                                            No Estimates
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Typography variant="h5" className={classes.grow}>Item Costs</Typography>
                <Divider className={classes.mb}/>
                <Grid container style={{marginTop: 20, marginBottom: 10}}>
                    <Grid item xs={12} style={{overflowX: "auto"}}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                order={this.state.costsOrder}
                                orderBy={this.state.costsOrderBy}
                                propName="costs"
                                columnData={costColumnData}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {estimateCosts.map( n => { return (
                                    
                                    <TableRow hover key={n.id}>
                                        <CustomTableCell className={classes.actionWidth}>
                                            {!viewOnly && (
                                                <>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={() => this.setState({activeCost: n, costDialog: true})}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <EditIcon color="primary"/> 
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({
                                                                costObj: {
                                                                    id: n.id, 
                                                                    cost: n.totalcost, 
                                                                    order: n.ordernumb
                                                                },
                                                                costDelete: true
                                                            })}
                                                            className={classes.deleteWidth}
                                                        >
                                                            <DeleteIcon /> 
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.costtype ? n.costtype : "-"}</CustomTableCell>
                                        <CustomTableCell>{Number(n.quanity)}</CustomTableCell>
                                        <CustomTableCell>{this.displayCost(n.perunit)}</CustomTableCell>
                                        <CustomTableCell>{n.unittype ? n.unittype : "-"}</CustomTableCell>
                                        <CustomTableCell>{this.displayCost(n.totalcost)}</CustomTableCell>
                                        <CustomTableCell>{n.costcomments ? n.costcomments : "-"}</CustomTableCell>
                                    </TableRow>
                                );
                                })}
                                {estimateCosts.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={costColumnData.length} className={classes.centerAlign}>
                                            No Estimate Costs
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog open={estimateDialog} maxWidth="md" fullWidth> 
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Estimate</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({estimateDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            defaultValues={{...activeEstimate, lock_submit: false}}
                            onSubmit={this.saveEstimate}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <b>Order #: {activeEstimate.estimatenumb}</b>
                                        <Select 
                                            disabled={loading} 
                                            field="contractor" 
                                            label="Contractor" 
                                            fullWidth 
                                            options={vendorOptions} 
                                            eventHandle={ (e) => { this.handleChange(e, formApi) }}
                                        />
                                        <TextField 
                                            disabled={true} 
                                            field="vendor" 
                                            label="Vendor #" 
                                            fullWidth
                                        />
                                        <TextField 
                                            disabled={loading} 
                                            field="comments" 
                                            label="Estimate Notes" 
                                            fullWidth
                                        />
                                        &nbsp;
                                        <FileFormInput
                                            eventHandle={val => this.handleFile(val, formApi)}
                                            canDelete
                                            fullWidth 
                                            view={true}
                                            disabled={loading}
                                            label="Upload File"
                                            id="attachmentUpload"
                                            field="attachment"
                                        />
                                        <br />
                                        {confirmUpload && (
                                            <Subquestion
                                                component={
                                                    <RadioGroup
                                                        field="lock_submit"
                                                        name="lock_submit"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="This is a large file and may take a few minutes to upload, do you wish to continue?"
                                                                title={true}
                                                                showLabel={true}
                                                                helpText={`This upload will approixmity take ${calculated}, but will vary depending on internet connection.`}
                                                            />
                                                        }
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        disabled={loading}
                                                        alignment={true}></RadioGroup>
                                                }
                                            />
                                        )}
                                        <br />
                                        <Button disabled={loading || (confirmUpload && !formApi.values.lock_submit)} type="submit" variant="contained" color="primary" className={classes.mt} >
                                            Save
                                            {loading && <CircularProgress size={24} className={classes.progress} />}
                                        </Button>
                                    </form>
                                )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <Dialog open={costDialog}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" className={classes.flex}>Cost Item</Typography>
                        <IconButton aria-label="close" className={classes.right} onClick={() => {this.setState({costDialog: false})}}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogMin}>
                        <Form 
                            id="costedit"
                            defaultValues={activeCost}
                            onSubmit={this.saveCost}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select field="costtype" label="Cost Type" fullWidth options={costTypes}/>
                                    <TextField field="quanity" label="Quantity" fullWidth useDecimalFormat></TextField>
                                    <TextField field="perunit" label="Per Unit Cost" fullWidth useTextFormat allowNegative></TextField>       
                                    <TextField field="unittype" label="Units" fullWidth></TextField>       
                                    <br />
                                    <b>Total Cost: &nbsp;&nbsp;${this.totalCost(formApi)}</b>
                                    <br />
                                    <br />
                                    <TextField field="costcomments" label="Cost Notes" fullWidth></TextField>       
                                    <br />
                                    <Button type="submit" variant="contained" color="primary">Save</Button>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.estimateDelete(estimateID);
                        this.setState({ estimateDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            estimateDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={estimateDelete}
                    title={"Delete Estimate"}
                    text={"Are you sure you wish to delete?"}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.costDelete(costObj.id, costObj.cost, costObj.order);
                        this.setState({ costDelete: false });
                    }}
                    cancelAction={
                        () => this.setState({ 
                            costDelete: false
                        })
                    }
                    confirmText="Delete Entry"
                    cancelText="Do Not Delete"
                    open={costDelete}
                    title={"Delete Cost"}
                    text={"Are you sure you wish to delete?"}
                />
            </Grid>
        );
    }
}


Estimates = connect(
    (state, ownProps) => ({
        authState: state.auth,
        costs: getEstimateCosts(state, ownProps),
        estimates: getEstimates(state, ownProps),
        vendors: getVendors(state),
        boardDate: getBoardDate(state)
    }),
    {
        ...Estimate.actions,
        ...EstimateCost.actions,
        panelChange
    }
)(Estimates);


export default withStyles(styles)(withRouter(Estimates));
