import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles, IconButton } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'; 
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Box from "@material-ui/core/Box";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SnackBarError from '../common/SnackBarError';

import { DrainageSystemView, Repair } from '../mypage/models'
import { createSelector, reloadAll } from '../common/orm';

import * as authActions from "../auth/actions";
import * as mapActions from "../common/map/actions";


function checkStatusBoard(board, status, obj, session){
    const temp = session.TrackingBoard.all().filter(x => x.repair === obj.id && x.status === status).toRefArray();
    return temp.length > 0;
}

const getDrainageSystem = DrainageSystemView.selectAllOrder();
const getRepair = createSelector(session => {
    const boarddate = session.BoardDate.first();

    return session.RepairView.all().orderBy('repair').toModelArray().map(repair => {
        return {
            ...repair.ref,
            name: repair.drainage_id ? repair.drainage_id.drainage : null,
            approval: checkStatusBoard(boarddate.date, "For Approval", repair, session),
            payment: checkStatusBoard(boarddate.date, "For Payment", repair, session)
       };
    });
}); 
const getViolation = createSelector(session => {
    return session.ViolationView.all().orderBy('vnum').toModelArray().map(violation => {
        return {
            ...violation.ref,
            name: violation.drainage_id ? violation.drainage_id.drainage : null,
            foraction: !violation.inspectiondone && (new Date() < new Date(violation.next_compliance_inspection))
       };
    });
}); 

const styles = theme => ({
    horizontal: {
        flexDirection: "row"
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px",
        margin: "0 3px"
    },
    bNone: {
        '& td': {
            border: "none"
        }
    },
    pl: {
        paddingLeft: 8
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(4)
    }, 
    overviewPrjname: {
        cursor: "pointer",
        color: "#76c0f0",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: 14
    },
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        marginTop: 80,
        width: 342,
        height: "calc(100% - 50px)",
        left: 0,
        right: "auto"
    },
    box: {
        marginTop: 10,
        marginBottom: 10,
        display: "inline-flex"
    },
    boxNonAdmin: {
        marginBottom: 10
    },
    list: {
        padding: 0,
        borderBottom: "none"
    },
    list2: {
        padding: 4,
        borderBottom: "none"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    listItemAdmin: {
        flex: "none",
        width: "auto",
        display: "inline-flex"
    },
});


class GoTo extends Component {
    constructor(props){
        super(props);

        this.state = {
            violationError: null,
            repairError: null,
            systemError: null,
            showError: false,
            message: ""
        }
    }

    createRepair = () => {
        const { authState, history, systems } = this.props;

        const index = systems.findIndex(x => x.drainage === this.state.drainage.label);
        if(index < 0){
            this.setState({showError: true, message: "Error!"});
            return;
        }

        const drainage_id = systems[index].id;

        fetch(`manage/repair/new/${drainage_id}`, {
            method: 'GET',
            headers: {
                Authorization: "Token " + authState.user.auth_token
            }
        })
        .then(result => {
            if (!result.ok) {
                return result.text().then(text => {
                    throw new Error(text);
                });
            } else {
                return result.json();
            }
        })
        .then( data => {
            if(data.route){
                history.push(data.route);
            }
        }).catch(err => {
            var message = err.message;
            if(message && (message === '{"detail":"Token has expired."}' || message === '{"detail":"Invalid token."}')){
                this.props.authLogout();
                this.props.history.push("/");
            } else {                
            	this.setState({showError: true, message: "Error!"});
            }
        });
    }

    goDrainage = (e) => {
        const index = this.props.systems.findIndex(x => x.drainage === this.state.drainage);
        if(this.state.drainage && this.state.drainage.value){
            this.props.history.push(this.state.drainage.value);
        } else if (this.state.drainage && !this.state.drainage.value && index > -1){
            const v = this.props.systems[index];
            this.props.history.push(`/drainage/${v.id}/${v.drainage}`);
        } else {
            if(e){
                e.preventDefault();
            }
            this.setState({systemError: true, showError: true, message: "Please select a drainage system"});
        }
    }

    goRepair = (e) => {
        const index = this.props.repairs.findIndex(x => x.repair === this.state.repair);
        if(this.state.repair && this.state.repair.value){
            this.props.history.push(this.state.repair.value);
        } else if (this.state.repair && !this.state.repair.value && index > -1){
            const v = this.props.repairs[index];
            this.props.history.push(`/repair/${v.id}/${v.drainage_id}/${v.name}`);
        } else {
            if(e){
                e.preventDefault();
            }
            this.setState({repairError: true, showError: true, message: "Please select repair number"});
        }
    }

    goViolation = (e) => {
        const index = this.props.violations.findIndex(x => x.vnum === this.state.violation);
        if(this.state.violation && this.state.violation.value){
            this.props.history.push(this.state.violation.value);
        } else if (this.state.violation && !this.state.violation.value && index > -1){
            const v = this.props.violations[index];
            this.props.history.push(`/violation/${v.id}/${v.drainage_id}/${v.name}`);
        } else {
            if(e){
                e.preventDefault();
            }
            this.setState({violationError: true, showError: true, message: "Please select violation number"});
        }
    }

    render(){
        const { 
            authState, 
            classes, 
            repairs, 
            systems, 
            violations,
            map_loading
        } = this.props;
        const { 
            drainage, 
            repair, 
            violation, 
            systemError, 
            repairError, 
            violationError, 
            showError, 
            message 
        } = this.state;

        const is_admin = authState.user && authState.user.role === "Admin";
        const contractor = authState.user && authState.user.role === "Contractor";

        const all = systems.map(x => ({
            label: x.drainage,
            value: "/drainage/" + x.id + "/" + x.drainage
        }));

        const allRepairs = repairs.map(x => ({
            label: x.repair,
            value: "/repair/" + x.id + "/" + x.drainage_id + "/" + x.name
        })).reverse();

        const allViolations = violations.map(x => ({
            label: x.vnum,
            value: "/violation/" + x.id + "/" + x.drainage_id + "/" + x.name
        })).reverse();
        
        return(
            <>
                {is_admin && (
                    <Box className={classes.box}>
                        <List
                            style={{paddingTop: 0, paddingBottom: 0}}    
                        >
                            <ListItem
                                title="Collapse Panel"
                                button
                                onClick={this.props.navigationClick}
                                className={classes.listItemAdmin}>
                                <ListItemIcon className={classes.pageText}>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                        <Button 
                            disabled={!drainage}
                            onClick={this.createRepair}
                            variant="contained"
                            color="primary"
                            >
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp; Create Repair
                        </Button>
                    </Box>
                )}
                {!is_admin && (
                    <Box className={classes.boxNonAdmin}>
                        <List
                            style={{paddingTop: 0, paddingBottom: 0}}    
                        >
                            <ListItem
                                title="Collapse Panel"
                                button
                                onClick={this.props.navigationClick}
                            >
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary="Collapse Panel"
                                />
                            </ListItem>
                        </List>
                    </Box>
                )}
                <List
                    dense={true}
                    component="nav"
                    className={classes.root}
                    >
                        <ListItem
                            onClick={this.props.goToNavClick}
                            title="Go To"
                            button
                        >
                            <ListItemText
                                primary="Go To"
                            />
                            {this.props.goToNav ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.props.goToNav} timeout="auto" unmountOnExit>
                            <ListItem>
                                <Table>
                                    <TableBody>
                                        {!contractor && (
                                            <TableRow>
                                                <TableCell className={classes.list2}>
                                                    <form onSubmit={this.goDrainage}>
                                                        <Autocomplete
                                                            id="Drainage System"
                                                            options={all}
                                                            value={drainage}
                                                            onChange={(e, value) => this.setState({drainage: value, systemError: null})}
                                                            onInputChange={(e, value) => this.setState({drainage: value, repairError: null})}
                                                            getOptionLabel={(option) => option.label}
                                                            style={{ width: 200 }}
                                                            renderInput={(params) => <TextField {...params} label="Drainage System" variant="outlined" error={systemError}/>}
                                                            disabled={map_loading}
                                                        />
                                                    </form>
                                                </TableCell>
                                                <TableCell className={classes.list2}>
                                                    <IconButton 
                                                        aria-label="Go To Drainage"
                                                        disabled={map_loading} 
                                                        onClick={() => this.goDrainage()}
                                                    >
                                                        <ExitToAppIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell className={classes.list2}>
                                                <form onSubmit={this.goRepair}>
                                                    <Autocomplete
                                                        id="Repair"
                                                        options={allRepairs}
                                                        value={repair}
                                                        onChange={(e, value) => this.setState({repair: value, repairError: null})}
                                                        onInputChange={(e, value) => this.setState({repair: value, repairError: null})}
                                                        getOptionLabel={(option) => option.label}
                                                        style={{ width: 200 }}
                                                        renderInput={(params) => <TextField {...params} label="Repair" variant="outlined" error={repairError} />}
                                                        disabled={map_loading}
                                                        />
                                                </form>
                                            </TableCell>
                                            <TableCell className={classes.list2}>
                                                <IconButton 
                                                    aria-label="Go To Repair"
                                                    disabled={map_loading} 
                                                    onClick={() => this.goRepair()}
                                                >
                                                    <ExitToAppIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        {!contractor && (
                                            <TableRow>
                                                <TableCell className={classes.list2}>
                                                    <form onSubmit={this.goViolation}>
                                                        <Autocomplete
                                                            id="Violation"
                                                            options={allViolations}
                                                            value={violation}
                                                            onChange={(e, value) => this.setState({violation: value, violationError: null})}
                                                            onInputChange={(e, value) => this.setState({violation: value, violationError: null})}
                                                            getOptionLabel={(option) => option.label}
                                                            style={{ width: 200 }}
                                                            renderInput={(params) => <TextField {...params} label="Violation" variant="outlined" error={violationError} />}
                                                            disabled={map_loading}
                                                            />
                                                    </form>
                                                </TableCell>
                                                <TableCell className={classes.list2}>
                                                    <IconButton 
                                                        aria-label="Go To Violation"
                                                        disabled={map_loading} 
                                                        onClick={() => this.goViolation()}
                                                    >
                                                        <ExitToAppIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </ListItem>
                        </Collapse>
                    </List>
                    <SnackBarError open={showError} close={e => this.setState({showError: false})} customMessage={message} />
            </>
        );
    }
}


GoTo = connect(
    (state, ownProps) => ({ 
        sync: state.sync,
        authState: state.auth,
        systems: getDrainageSystem(state, ownProps),
        repairs: getRepair(state, ownProps),
        violations: getViolation(state, ownProps),
        map_loading: state.map.loading
    }),
    {
        ...mapActions,
        ...Repair.actions,
        ...authActions,
        reloadAll
    }
)(GoTo);

export default withStyles(styles)(withRouter(GoTo));
