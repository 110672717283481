// Convert API name/id into react label/value
export function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

export function MAKE_OPTIONS_KEY(table, key){
    return table.map(row => ({
        label: row[key],
        value: row[key]
    }));
}

export function MAKE_OPTIONS_KEY_WTH_NONE(table, key){
    var temp = table.map(row => ({
        label: row[key],
        value: row[key]
    }));
    return [{label: "-- Empty --", value: null}, ...temp];
}

export function MAKE_OPTIONS_VID(table, key){
    return table.map(row => ({
        label: row[key],
        value: row['id']
    }));
}
