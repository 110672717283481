import React, {Component} from 'react'
import { withRouter } from "react-router-dom";
import { withStyles, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { addToForm, getToday } from '../../api/utils';
import { ProceedType } from "../common/SelectOptions";
import PageHeader from "../common/PageHeader";

import LargeFileTable from "../common/LargeFileTable";
import Typography from '@material-ui/core/Typography';

import { createSelector } from '../common/orm';
import {Photosdrainage, Documentsdrainage, DrainageLegalAttach, DrainageSystem } from "../mypage/models";


const getPhotosdrainage = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        var temp = session.Photosdrainage.filter(sys => sys.drainage === system.id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "photo";
        });
        return temp;
    }
);


const getDocumentsdrainage = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        var temp = session.Documentsdrainage.filter(sys => sys.drainage === system.id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = "doc";
        });
        return temp;
    }
);

const getDrainageLegalAttach = createSelector(
    (state, ownProps) => ownProps.system,
    (session, system) => {
        var temp = session.DrainageLegalAttach.filter(sys => sys.drainage === system.id).toRefArray();
        temp.forEach( x => {
            x["deleteProp"] = null;
        });
        return temp;
    }
);

const photoColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "uploaddate", numeric: false, label: "Upload Date", allowSort: true, type: "date", noEdit: true },
    {id: "creationdate", numeric: false, label: "Photo Creation Date", allowSort: true, type: "date" },
    {id: "caption", numeric: false, label: "Caption", allowSort: false, required: true, type: "text" },
    {id: "notes", numeric: false, label: "Notes", allowSort: false, type: "text" },
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file", required:true, accept: "image/*" },
]
const photokeyData = photoColumnData.slice(1);

const docColumnData = [
    {id: "actions", numeric: false, label: "", allowSort: false },
    {id: "public", bool: true, label: "Public", allowSort: true, type:"check" },
    {id: "caption", numeric: false, label: "Document Title", allowSort: true, type:"text", required: true },
    {id: "type", numeric: false, label: "Legal Type", allowSort: true, type:"select", options: ProceedType },
    {id: "creationdate", numeric: false, label: "Document Date", allowSort: false, type: "date" },
    {id: "year", numeric: false, label: "Year", allowSort: true, type: "text", format: "int" },
    {id: "notes", numeric: false, label: "Notes", allowSort: false, type: "text" },
    {id: "proceedtype", numeric: false, label: "Legal Proceeding", allowSort: true, type:"text", noEdit: true},
    {id: "link", numeric: false, label: "Link To Open", allowSort: false, openWindow: true, type: "file", required: true },
    {id: "uploaddate", numeric: false, label: "Upload Date", allowSort: true, type: "date", noEdit: true },
]
const dockeyData = docColumnData.slice(1);


const styles = theme => ({
    space: {
        marginTop: 48
    },
    m: {
        marginTop: 40
    },
    mb: {
        marginBottom: 20
    },
});

class Attachments extends Component {
    state = {
        photoUploading: false,
        docUploading: false,
        forceReset: performance.now()
    }

    submitPhoto = (values, file_interface) => {
        const { ormPhotosdrainageUpdatePromise, ormPhotosdrainageCreateForm, system, saveBad, saveGood} = this.props;

        delete values["deleteProp"];
        if(values.id){
            ormPhotosdrainageUpdatePromise({
                drainage_id: system.id,
                ...values
            }).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        } else {
            var form_data = new FormData();
            form_data.append('drainage_id', system.id);
            form_data.append('uploaddate', getToday());
            form_data.append('creationdate', values.creationdate);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'notes', values.notes);
            form_data.append('link', file_interface, file_interface.name);
            this.setState({photoUploading: true});
            ormPhotosdrainageCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }
        
        this.props.updateSystem();
    }

    submitDocument = (values, file_interface) => {
        const { ormDocumentsdrainageUpdatePromise, ormDrainageLegalAttachUpdatePromise, ormDocumentsdrainageCreateForm, system, saveBad, saveGood } = this.props;

        if(typeof(values["public"]) === "undefined"){
            values["public"] = false;
        }

        var deleteProp = values["deleteProp"];
        delete values["deleteProp"];
        if(values.id){
            if(deleteProp === "doc"){
                ormDocumentsdrainageUpdatePromise({
                    drainage_id: system.id,
                    ...values
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                    this.setState({forceReset: performance.now()});
                });
            } else {
                ormDrainageLegalAttachUpdatePromise({
                    drainage_id: system.id,
                    ...values
                }).then(id => {
                    if(!id){
                        saveBad();
                    } else {
                        saveGood();
                    }
                    this.setState({forceReset: performance.now()});
                });
            }
        } else {
            var form_data = new FormData();
            form_data.append('drainage_id', system.id);
            form_data.append('public', values.public);
            form_data.append('caption', values.caption);
            addToForm(form_data, 'type', values.type);
            addToForm(form_data, 'year', values.year);
            addToForm(form_data, 'notes', values.notes);
            addToForm(form_data, 'proceedtype', values.proceedtype);
            addToForm(form_data, 'creationdate', values.creationdate);
            form_data.append('uploaddate', getToday());
            form_data.append('link', file_interface, file_interface.name);
            ormDocumentsdrainageCreateForm(form_data).then(id => {
                if(!id){
                    saveBad();
                } else {
                    saveGood();
                }
                this.setState({forceReset: performance.now()});
            });
        }

        this.props.updateSystem();
    }

    deletePhoto = (id) => {
        const { ormPhotosdrainageDeletePromise, saveBad, saveGood } = this.props
        ormPhotosdrainageDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.updateSystem();
    }

    deleteDrainageDoc = (id) => {
        const { ormDocumentsdrainageDeletePromise, saveBad, saveGood } = this.props
        ormDocumentsdrainageDeletePromise(id).then(id => {
            if(!id){
                saveBad();
            } else {
                saveGood();
            }
        });
        this.props.updateSystem();
    }

    customValidation = (values) => {
        //fix
        if((values.year && values.year !== "") && values.year.length !== 4){
            return { year: "Must be a valid year"};
        } else {
            return {};
        }
    }

    render(){
        const { authState, classes, documents, fileSize, legalAttachments, photos, ormDrainageLegalAttachDelete, system } = this.props;
        const { forceReset } = this.state;
        const viewOnly = authState.user.role === "View Only";

        return(
            <div className={classes.space}>
                <PageHeader title={"Drainage System : "} drainage={system.drainage} />
                <Typography variant="h5" style={{marginTop: 20}}>Photos</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable
                    name="photo_attachement" 
                    id="1-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly}
                    data={photos}
                    noHelp    
                    title="Photo"
                    emptyTxt="No Photos"
                    columns={photoColumnData} 
                    keys={photokeyData}
                    parentSubmitForm={this.submitPhoto}
                    addFiles={true}
                    forceReset={forceReset}
                    fileSize={fileSize}
                    deleteProp={{"photo": this.deletePhoto}}/>
                <Typography variant="h5" className={classes.m}>Documents</Typography>
                <Divider className={classes.mb}/>
                <LargeFileTable 
                    name="document_attachment"
                    id="2-table"
                    viewOnly={viewOnly} 
                    canReport={false}
                    canEdit={!viewOnly}
                    canDelete={!viewOnly}
                    data={[...documents, ...legalAttachments]} 
                    noHelp               
                    title="Document"
                    emptyTxt="No Documents"
                    columns={docColumnData} 
                    keys={dockeyData}
                    parentSubmitForm={this.submitDocument}
                    addFiles={true}
                    forceReset={forceReset}
                    customValidation={this.customValidation}
                    fileSize={fileSize}
                    deleteProp={{"doc": this.deleteDrainageDoc, "legal": ormDrainageLegalAttachDelete}}/>
            </div>
        )
    }
}

Attachments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        photos: getPhotosdrainage(state, ownProps),
        documents: getDocumentsdrainage(state, ownProps),
        legalAttachments: getDrainageLegalAttach(state, ownProps)
    }),
    {
        ...Photosdrainage.actions,
        ...DrainageSystem.actions,
        ...Documentsdrainage.actions,
        ...DrainageLegalAttach.actions
    }
)(Attachments);

export default withStyles(styles)(withRouter(Attachments));