import React from "react";
import SubquestionIcon from "./SubquestionIcon";
import Grid from "@material-ui/core/Grid";
const Subquestion = ({ component }) => (
    <Grid container>
        <Grid item xs={1}>
            <SubquestionIcon />
        </Grid>
        <Grid item xs={11}>
            {component}
        </Grid>
    </Grid>
);
export default Subquestion;